import * as Yup from 'yup'
import {
  api,
  useCreateGroupPageSponsorMutation,
  useUpdateGroupPageSponsorMutation,
} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {SharpImage} from 'src/components/SharpImage'
import {UploadPinturaImageFormModal} from 'src/components/UploadPinturaImageForm'
import {guessError} from 'src/helpers/error-utils'
import {uploadImage} from '@cheddarup/core'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

const SPONSOR_NAME_MAX_LEN = 25

interface SponsorFormValues {
  name: string
  url: string
  logo: Api.S3Image | WebUI.PinturaDefaultImageWriterResult | null
}

const SponsorForm = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const growlActions = WebUI.useGrowlActions()
  const [managerRoleId] = useManagerRoleId()

  const {data: sponsor} = api.groupPageSponsors.detail.useQuery(
    {
      // biome-ignore lint/style/noNonNullAssertion:
      pathParams: {id: urlParams.id!},
    },
    {enabled: urlParams.id != null},
  )
  const createGroupPageSponsorMutation = useCreateGroupPageSponsorMutation()
  const updateGroupPageSponsorMutation = useUpdateGroupPageSponsorMutation()
  const uploadPinturaImageFormModalRef = useRef<WebUI.DialogInstance>(null)
  const [localLogo, setLocalLogo] = useState<Blob | null>(null)

  const formik = useFormik<SponsorFormValues>({
    enableReinitialize: true,
    initialValues: {
      name: sponsor?.name ?? '',
      url: sponsor?.url ?? '',
      logo: sponsor?.logo ?? null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .max(SPONSOR_NAME_MAX_LEN, 'Name can be ${max} characters at max'),
      url: Yup.string().url('Invalid format'),
      logo: Yup.mixed().required('Required'),
    }),
    onSubmit: async (values) => {
      let savedSponsor = sponsor ?? null

      const payload = {
        name: values.name,
        url: values.url,
      }

      try {
        if (!savedSponsor) {
          savedSponsor = await createGroupPageSponsorMutation.mutateAsync({
            body: payload,
          })
        }

        let logoId = values.logo && 'id' in values.logo ? values.logo.id : null
        if (values.logo && 'dest' in values.logo) {
          const uploadedLogo = await uploadImage(
            `users/group_page/sponsor/sponsor_items/${savedSponsor.id}`,
            values.logo.dest,
            undefined,
            {
              userId: managerRoleId ?? undefined,
              metadata: {
                pintura: values.logo.imageState,
              },
            },
          )

          if (logoId != null) {
            api.groupPageSponsors.deleteLogo.fetch({
              pathParams: {
                sponsorId: savedSponsor.id,
                imageId: logoId,
              },
            })
          }

          logoId = uploadedLogo.id
        }

        if (savedSponsor) {
          await updateGroupPageSponsorMutation.mutateAsync({
            pathParams: {
              id: savedSponsor.id,
            },
            body: {
              ...payload,
              logo_id: logoId,
            },
          })
        }
      } catch (err) {
        growlActions.show('error', {
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <WebUI.Modal
      aria-label="Group page sponsor form"
      className="[&_>_.ModalContentView]:max-w-md [&_>_.ModalContentView]:px-11"
      onDidHide={() => navigate('..')}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.ModalHeader className="pl-1">
            {sponsor ? 'Edit Sponsor' : 'Add Sponsor'}
          </WebUI.ModalHeader>
          <form
            className="flex min-h-0 grow flex-col divide-y divide-solid divide-borderPrimary"
            noValidate
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <div className="flex grow flex-col gap-7 overflow-y-auto px-2 pt-8 pb-13">
              <UploadPinturaImageFormModal
                key={sponsor?.logo?.id}
                ref={uploadPinturaImageFormModalRef}
                imageCropAspectRatio={1 / 1}
                imageCropLimitToImage={false}
                utils={['crop', 'filter', 'finetune', 'fill']}
                uploaderOptions={['upload']}
                initialImage={sponsor?.logo}
                image={localLogo}
                onSubmitAsPinturaRes={(newPinturaRes) =>
                  formik.setFieldValue('logo', newPinturaRes)
                }
                disclosure={
                  <WebUI.FormField error={formik.errors.logo}>
                    {formik.values.logo ? (
                      <div className="relative h-[170px] w-[170px]">
                        <WebUI.DialogDisclosure
                          className="cursor-pointer overflow-hidden rounded-default"
                          as="button"
                        >
                          {'id' in formik.values.logo ? (
                            <SharpImage
                              alt="Sponsor logo"
                              height={170}
                              width={170}
                              image={formik.values.logo}
                            />
                          ) : (
                            <BlobImage
                              alt="Sponsor logo"
                              height={170}
                              width={170}
                              blob={formik.values.logo.dest}
                            />
                          )}
                        </WebUI.DialogDisclosure>
                        <WebUI.IconButton
                          className="absolute top-2 right-2 text-ds-md"
                          size="default_alt"
                          variant="secondary"
                          onClick={() => {
                            formik.setFieldValue('logo', null)
                            setLocalLogo(null)
                          }}
                        >
                          <WebUI.PhosphorIcon icon="x" />
                        </WebUI.IconButton>
                      </div>
                    ) : (
                      <WebUI.FileUploader
                        accept={{'image/*': []}}
                        onDropAccepted={([imageFile]) => {
                          if (imageFile) {
                            uploadPinturaImageFormModalRef.current?.show()
                            setLocalLogo(imageFile)
                          }
                        }}
                      >
                        <WebUI.FileUploaderInput />
                        <WebUI.FileUploaderButton
                          className="!h-[170px] w-[170px] border border-solid [&_.Button-content]:max-w-24 [&_.Button-content]:flex-0 [&_.Button-content]:whitespace-normal"
                          orientation="vertical"
                          variant="secondary"
                          iconBefore={
                            <WebUI.PhosphorIcon
                              className="text-ds-2xl text-teal-600"
                              icon="upload-simple"
                            />
                          }
                        >
                          Upload a small image or logo
                        </WebUI.FileUploaderButton>
                      </WebUI.FileUploader>
                    )}
                  </WebUI.FormField>
                }
              />

              <WebUI.FormField
                label="Sponsor Name"
                error={formik.errors.name}
                required
              >
                <WebUI.Input
                  name="name"
                  placeholder="Sponsor Name"
                  maxLength={SPONSOR_NAME_MAX_LEN}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField label="Sponsor URL" error={formik.errors.url}>
                <WebUI.Input
                  name="url"
                  type="url"
                  placeholder="Sponsor URL"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </div>
            <div className="-mx-11 flex flex-row gap-4 px-11 py-6">
              <WebUI.Button
                type="button"
                loading={formik.isSubmitting}
                onClick={async () => {
                  const errors = await formik.validateForm()

                  if (Object.values(errors).length === 0) {
                    await formik.submitForm()
                    dialog.hide()
                  }
                }}
              >
                Save
              </WebUI.Button>
              {!sponsor && (
                <WebUI.Button
                  type="button"
                  loading={formik.isSubmitting}
                  onClick={async () => {
                    const errors = await formik.validateForm()

                    if (Object.values(errors).length === 0) {
                      await formik.submitForm()
                      formik.resetForm()
                    }
                  }}
                >
                  Save and add another
                </WebUI.Button>
              )}
            </div>
          </form>
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – BlobImage

interface BlobImageProps extends WebUI.ImageProps {
  blob: Blob
}

const BlobImage = ({blob, ...restProps}: BlobImageProps) => {
  const objectUrl = useMemo(() => URL.createObjectURL(blob), [blob])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [objectUrl])

  return <WebUI.Image src={objectUrl} {...restProps} />
}

export default SponsorForm
