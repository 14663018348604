import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect, useMemo, useState} from 'react'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import MarchingBandTrumpet from 'src/images/marching-band-trumpet.png'
import {MiniBadge} from 'src/views/collection/build/items/ItemsPage/components/ItemsNav'

import {Link} from './Link'
import {LinkButton, LinkButtonProps} from './LinkButton'

export interface PremiumFeaturesSideSheetProps
  extends Omit<WebUI.ModalProps, 'children'>,
    Pick<PremiumFeaturesCtaProps, 'tabId' | 'enforcedPremiumMeta'> {
  children?: React.ReactNode
}

export const PremiumFeaturesSideSheet = React.forwardRef<
  WebUI.DialogInstance,
  PremiumFeaturesSideSheetProps
>(
  (
    {
      tabId,
      enforcedPremiumMeta,
      contentViewAppearance = 'rightToLeft',
      initialVisible = false,
      visible,
      onDidHide,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [premiumFeatureSheetVisible, setPremiumFeatureSheetVisible] =
      useState(false)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const value = useMemo(
      () => ({premiumFeatureSheetVisible, setPremiumFeatureSheetVisible}),
      [premiumFeatureSheetVisible, setPremiumFeatureSheetVisible],
    )

    return (
      <PremiumFeaturesSideSheetContext.Provider value={value}>
        <WebUI.Modal
          ref={forwardedRef}
          className={WebUI.cn(
            'PremiumFeaturesSideSheet',
            '[&_>_.SideSheetContentView]:w-[360px]',
            className,
          )}
          initialVisible={initialVisible}
          visible={visible ?? premiumFeatureSheetVisible}
          modal={false}
          contentViewAppearance={contentViewAppearance}
          disclosure={children}
          onDidHide={() => {
            setPremiumFeatureSheetVisible(false)
            onDidHide?.()
          }}
          {...restProps}
        >
          <WebUI.ModalCloseButton />

          <PremiumFeaturesCta
            className="overflow-y-auto px-11 py-16"
            tabId={tabId}
            enforcedPremiumMeta={enforcedPremiumMeta}
          />
        </WebUI.Modal>
      </PremiumFeaturesSideSheetContext.Provider>
    )
  },
)

// MARK: – PremiumFeatureSideSheetDisclosure

export interface PremiumFeatureSideSheetDisclosureProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  featureKeys?: FeatureKey[]
  tabId?: number
  disclosureVariant?: 'default' | 'mini'
}

export const PremiumFeatureSideSheetDisclosure = React.forwardRef<
  HTMLButtonElement,
  PremiumFeatureSideSheetDisclosureProps
>(
  (
    {
      iconBefore = <WebUI.PhosphorIcon icon="star" />,
      size = 'compact',
      variant = 'secondaryAlt',
      disclosureVariant = 'default',
      className,
      children = 'Paid',
      onClick,
      featureKeys = [],
      tabId,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {premiumFeatureSheetVisible, setPremiumFeatureSheetVisible} =
      usePremiumFeaturesSideSheetContext()

    const tabQuery = api.tabs.detail.useQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          tabId: tabId!,
        },
      },
      {
        enabled: tabId != null,
      },
    )

    const plan = useMemo(() => {
      const premiumMeta = Util.mapValues(
        tabQuery.data?.options?.premiumMeta ?? {pro: {}, team: {}},
        (planMeta) =>
          Object.keys(Util.pickBy(planMeta, (isEnabled: boolean) => isEnabled)),
      )
      const enabledFeatureKeys = [
        ...(premiumMeta.pro || []),
        ...(premiumMeta.team || []),
        ...featureKeys,
      ]

      return enabledFeatureKeys.length > 0
        ? enabledFeatureKeys.some((key) =>
            Object.keys(TEAM_FEATURE_LABEL_MAP).includes(key),
          )
          ? 'team'
          : 'pro'
        : undefined
    }, [tabQuery.data, featureKeys])

    return (
      <WebUI.Tooltip>
        <WebUI.TooltipAnchor
          className="w-fit"
          render={
            disclosureVariant === 'mini' ? (
              <WebUI.DialogDisclosure as={MiniBadge} />
            ) : (
              <WebUI.DialogDisclosure
                ref={forwardedRef}
                className={WebUI.cn(
                  'bg-teal-80 text-teal-600 active:text-accent300 data-[active]:text-accent300',
                  className,
                )}
                size={size}
                variant={variant}
                iconBefore={iconBefore}
                onClick={(event) => {
                  onClick?.(event)
                  if (!event.defaultPrevented) {
                    event.preventDefault()
                    setPremiumFeatureSheetVisible(!premiumFeatureSheetVisible)
                  }
                }}
                {...restProps}
              >
                {children}
              </WebUI.DialogDisclosure>
            )
          }
        />
        <WebUI.TooltipContent variant="light" className="p-6">
          <WebUI.VStack className="gap-3">
            <span className="text-left">
              In order to publish your collection you’ll need to upgrade to a
              paid plan
            </span>
            <WebUI.HStack className="gap-2">
              <WebUI.Button
                size="compact"
                variant="secondaryAlt"
                className="bg-teal-80 text-tint"
                onClick={() => setPremiumFeatureSheetVisible(true)}
              >
                View your paid features
              </WebUI.Button>
              <LinkButton
                size="compact"
                variant="default"
                preserveSearch
                to={{
                  pathname: 'i/plans',
                  search: `recommendedPlan=${plan}`,
                }}
              >
                Upgrade
              </LinkButton>
            </WebUI.HStack>
          </WebUI.VStack>
        </WebUI.TooltipContent>
      </WebUI.Tooltip>
    )
  },
)

// MARK: – PremiumFeaturesCta

export interface PremiumFeaturesCtaProps
  extends React.ComponentPropsWithoutRef<'div'> {
  tabId: number | null | undefined
  enforcedPremiumMeta?: Util.PartialDeep<Api.TabPremiumMeta>
}

export const PremiumFeaturesCta = ({
  tabId,
  enforcedPremiumMeta,
  className,
  ...restProps
}: PremiumFeaturesCtaProps) => {
  const {setPremiumFeatureSheetVisible} = usePremiumFeaturesSideSheetContext()
  const tabQuery = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: tabId!,
      },
    },
    {
      enabled: tabId != null,
    },
  )

  const enabledFeatureKeys = useMemo(() => {
    const premiumMeta = {
      pro: tabQuery.data?.is_pro
        ? undefined
        : {
            ...tabQuery.data?.options?.premiumMeta?.pro,
            ...enforcedPremiumMeta?.pro,
          },
      team: tabQuery.data?.is_team
        ? undefined
        : {
            ...tabQuery.data?.options?.premiumMeta?.team,
            ...enforcedPremiumMeta?.team,
          },
    }

    return Util.mapValues(premiumMeta, (planMeta) =>
      Object.keys(Util.pickBy(planMeta ?? {}, (isEnabled) => isEnabled)),
    ) as {
      [K in keyof Api.TabPremiumMeta]: Array<keyof Api.TabPremiumMeta[K]>
    }
  }, [enforcedPremiumMeta?.pro, enforcedPremiumMeta?.team, tabQuery.data])

  const teamFeaturesEnabled = enabledFeatureKeys.team.length > 0
  const proFeaturesEnabled = enabledFeatureKeys.pro.length > 0

  useEffect(() => {
    if (
      !tabQuery.isFetching &&
      Object.values(enabledFeatureKeys).flat().length === 0
    ) {
      setPremiumFeatureSheetVisible(false)
    }
  }, [tabQuery.isFetching, enabledFeatureKeys, setPremiumFeatureSheetVisible])

  return (
    <WebUI.VStack className={WebUI.cn('gap-6', className)} {...restProps}>
      <WebUI.Image
        loading="eager"
        alt="Marching band trumpet"
        width={134}
        height={154}
        src={MarchingBandTrumpet}
      />
      <WebUI.VStack className="gap-4">
        <WebUI.Heading className="font-accent text-ds-md">
          You’ve got a great thing going!
        </WebUI.Heading>
        <WebUI.Text className="text-ds-sm">
          This collection uses features that require an upgrade to publish and
          share. When you upgrade, you get all of these features{' '}
          <Link variant="primary" to="i/plans">
            and more
          </Link>{' '}
          with one of our paid plans.
        </WebUI.Text>
      </WebUI.VStack>

      {(proFeaturesEnabled || teamFeaturesEnabled) && (
        <>
          {proFeaturesEnabled && (
            <PremiumFeaturesList
              heading="Pro plan features"
              featureKeys={enabledFeatureKeys.pro}
            />
          )}
          {teamFeaturesEnabled && (
            <PremiumFeaturesList
              heading="Team plan features"
              featureKeys={enabledFeatureKeys.team}
            />
          )}
        </>
      )}

      <LinkButton
        className="flex-0"
        variant="primary"
        to={`i/plans?recommendedPlan=${teamFeaturesEnabled ? 'team' : 'pro'}`}
      >
        Upgrade
      </LinkButton>
    </WebUI.VStack>
  )
}

// MARK: – PremiumFeaturesList

interface PremiumFeaturesListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading?: React.ReactNode
  featureKeys: Array<keyof Api.TabProPremiumMeta | keyof Api.TabTeamPremiumMeta>
}

export const PremiumFeaturesList: React.FC<PremiumFeaturesListProps> = ({
  heading,
  featureKeys,
  className,
  ...restProps
}) => (
  <WebUI.VStack className={WebUI.cn('gap-3', className)} {...restProps}>
    {heading ? (
      <WebUI.Heading className="text-ds-sm uppercase" as="h3">
        {heading}
      </WebUI.Heading>
    ) : (
      heading
    )}
    <WebUI.RadioGroup
      aria-readonly="true"
      className="[&_>_.Radio]:cursor-default"
      size="compact"
    >
      {featureKeys.map((featureKey) => (
        <WebUI.Radio key={featureKey} checked>
          {FEATURE_LABEL_MAP[featureKey]}
        </WebUI.Radio>
      ))}
    </WebUI.RadioGroup>
  </WebUI.VStack>
)

// MARK: - PremiumFeaturesSideSheetProvider

interface PremiumFeaturesSideSheetContextValue {
  premiumFeatureSheetVisible: boolean
  setPremiumFeatureSheetVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const PremiumFeaturesSideSheetContext =
  React.createContext<PremiumFeaturesSideSheetContextValue>(
    {} as PremiumFeaturesSideSheetContextValue,
  )

export const usePremiumFeaturesSideSheetContext = () =>
  React.useContext(PremiumFeaturesSideSheetContext)

// MARK: - PlanUpgradeButton

export interface PlanUpgradeButtonProps
  extends Omit<
    Util.Merge<React.ComponentPropsWithoutRef<'a'>, LinkButtonProps>,
    'to'
  > {
  upgradeTo: 'team' | 'pro'
  asPaidBadge?: boolean
}

export const PlanUpgradeButton: React.FC<PlanUpgradeButtonProps> = ({
  upgradeTo,
  className,
  children,
  asPaidBadge = false,
  ...restProps
}) => (
  <WebUI.Tooltip>
    <WebUI.TooltipAnchor className="w-fit">
      <LinkButton
        className={WebUI.cn(
          'bg-teal-80 text-teal-600 active:text-accent300 data-[active]:text-accent300',
          className,
        )}
        size="compact"
        variant="secondaryAlt"
        to={`i/plans?recommendedPlan=${upgradeTo}`}
        preserveSearch
        {...restProps}
      >
        {children ??
          (asPaidBadge ? (
            <div className="flex gap-2">
              <WebUI.PhosphorIcon
                className="text-teal-600"
                icon="star"
                width={12}
              />
              <span>Paid</span>
            </div>
          ) : upgradeTo === 'team' ? (
            'Go Team'
          ) : (
            'Go Pro'
          ))}
      </LinkButton>
    </WebUI.TooltipAnchor>
    <WebUI.TooltipContent variant="light" className="p-6">
      <WebUI.VStack className="items-start gap-3">
        <span className="text-left">
          In order to use this feature you’ll need to upgrade to a{' '}
          {Util.capitalize(upgradeTo)} plan.
        </span>
        <LinkButton
          size="compact"
          variant="default"
          to={`i/plans?recommendedPlan=${upgradeTo}`}
          preserveSearch
        >
          Upgrade
        </LinkButton>
      </WebUI.VStack>
    </WebUI.TooltipContent>
  </WebUI.Tooltip>
)

// MARK: – Helpers

export const PRO_FEATURE_LABEL_MAP = {
  moreThanFiveItems: 'More than 5 items',
  itemWithMultipleImages: 'More than 1 item image',
  itemVariations: 'Item variations',
  multipleForms: 'More than 1 form or sign up',
  startAndStopTimes: 'Start and stop times',
  discounts: 'Discount codes',
  accessCode: 'Access codes',
  fundraisingGoal: 'Fundraising goal',
  customReceipt: 'Custom payer receipt',
  eSign: 'eSignature on form',
  initials: 'Add initials on form',
  fileField: 'Upload file on form',
  waiver: 'Waiver(s)',
  shipping: 'Built-in Shipping',
  visitorReport: 'Visitor Report',
  taxes: 'Include Taxes',
  paymentByEcheck: 'eCheck payment method',
  videoLink: 'Banner video',
  multipleBannerImages: 'Multiple Banner Images',
}

export const TEAM_FEATURE_LABEL_MAP = {
  recurringPayments: 'Recurring Payments',
  ticketing: 'Ticketing',
  waitlist: 'Waitlist',
  faqs: 'FAQ',
  dateTime: 'Date and time',
  location: 'Location',
  nonProfitStatus: 'Non Profit Status',
  mainContact: 'Main Contact',
  groupPage: 'Group Page',
  dynamicLinkedItems: 'Dynamic Forms',
}

export const FEATURE_LABEL_MAP = {
  ...PRO_FEATURE_LABEL_MAP,
  ...TEAM_FEATURE_LABEL_MAP,
}

export type ProFeatureKey = keyof typeof PRO_FEATURE_LABEL_MAP
export type TeamFeatureKey = keyof typeof TEAM_FEATURE_LABEL_MAP
export type FeatureKey = ProFeatureKey | TeamFeatureKey
