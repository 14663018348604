import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import React from 'react'

export interface WeekdaySelectorProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onSelect'> {
  weekday?: string | null
  onSelect: (day: number) => void
}

const WeekdaySelector = ({
  weekday,
  onSelect,
  ...restProps
}: WeekdaySelectorProps) => (
  <div {...restProps}>
    {[
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ].map((wd, idx) => (
      <div
        key={wd}
        className={WebUI.cn(
          idx !== 0 && 'mt-4',
          'cursor-pointer text-left font-normal',
        )}
        onClick={() => onSelect(idx)}
      >
        {wd === weekday && (
          <WebUI.PhosphorIcon
            className="mr-2"
            icon="check"
            width={10}
            color={tailwindConfig.theme.colors.teal[600]}
          />
        )}
        {wd}
      </div>
    ))}
  </div>
)

export default WeekdaySelector
