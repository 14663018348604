import {useParams} from 'react-router-dom'
import React, {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import {useDeleteCartTimeSlotMutation} from '@cheddarup/api-client'
import type {SignUpFormValues} from 'src/views/collection/build/forms/SignUpFormPage/SignUpFormPage'
import * as Util from '@cheddarup/util'
import {getLocalTimeZone} from '@internationalized/date'

import {SharpAvatar} from './SharpAvatar'
import {PayerBrandKitColors} from '@cheddarup/core'
import {getCUReadableColor} from 'src/helpers/color-utils'

interface SignupSpotsByDateDatum {
  id: string
  visible: boolean
  date: Date
  spots: Api.PublicSignUpSpot[]
}

export interface SignupViewListProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<ScheduleListDateListRowProps, 'onEdit'> {
  cart?: Api.Cart
  signup: Api.PublicTabSignup
  brandKitColors: PayerBrandKitColors
  previewMode?: boolean
}

export const SignupViewList: React.FC<SignupViewListProps> = ({
  cart,
  signup,
  onEdit,
  className,
  brandKitColors,
  previewMode,
  ...restProps
}: SignupViewListProps) => {
  const [groupBy, setGroupBy] = useState<Api.SignUpGroupedBy>(
    signup.options.groupedBy ?? 'spot',
  )
  const [filters, setFilters] = useState({isOpenOnly: false})
  const [visibleRowIdsMap, setVisibleRowIdsMap] = useState<
    Record<string, boolean>
  >({})
  const onEditRef = useLiveRef(onEdit)

  const data = useMemo(() => {
    const spots = Util.sort(
      signup.visible_spots
        .filter(
          (s) =>
            !filters.isOpenOnly ||
            s.time_slots.some(
              (ts) =>
                ts.available_quantity == null || ts.available_quantity > 0,
            ),
        )
        .map((s) => ({
          ...s,
          time_slots: filters.isOpenOnly
            ? s.time_slots.filter(
                (ts) =>
                  ts.available_quantity == null || ts.available_quantity > 0,
              )
            : s.time_slots,
        })),
    ).asc((s) => s.position)

    if (groupBy == null || groupBy === 'spot') {
      return spots.map((s) => ({
        ...s,
        visible: visibleRowIdsMap[s.id] ?? true,
      }))
    }

    const timeSlots = spots.flatMap((s) =>
      s.time_slots.map((ts) => ({
        ...ts,
        spotId: s.id,
      })),
    )
    const dates = Util.sort(
      Util.uniqueBy(
        timeSlots
          .map((ts) => ts.options.startTime)
          .filter((startTime) => startTime != null)
          .map((startTime) => new Date(startTime)),
        (startTime) => startTime.toDateString(),
      ),
    ).asc()

    return dates.map(
      (date) =>
        ({
          id: date.toDateString(),
          visible: visibleRowIdsMap[date.toDateString()] ?? true,
          date,
          spots: spots
            .map((s) => ({
              ...s,
              time_slots: timeSlots.filter(
                (ts) =>
                  ts.spotId === s.id &&
                  new Date(ts.options.startTime ?? '').toDateString() ===
                    date.toDateString(),
              ),
            }))
            .filter((s) => s.time_slots.length > 0),
        }) as SignupSpotsByDateDatum,
    )
  }, [groupBy, filters.isOpenOnly, signup.visible_spots, visibleRowIdsMap])

  const TimeSlotCardWithHandlers: WebUI.ListRowComponentType<Api.PublicSignUpSpot> =
    useMemo(
      () =>
        React.forwardRef((rowProps, rowForwardedRef) => (
          <TimeSlotCard
            ref={rowForwardedRef}
            cart={cart}
            signup={signup}
            onEdit={(timeSlot) => onEditRef.current?.(timeSlot, rowProps.data)}
            brandKitColors={brandKitColors}
            {...rowProps}
          />
        )),
      [cart, signup, brandKitColors],
    )

  const ScheduleListSpotListRowWithHandlers: WebUI.ListRowComponentType<
    Api.PublicSignUpSpot & {visible: boolean}
  > = useMemo(
    () =>
      React.forwardRef((rowProps, rowForwardedRef) => (
        <ScheduleListSpotListRow
          brandKitColors={brandKitColors}
          ref={rowForwardedRef as any}
          cart={cart}
          onVisibleChange={(newVisible) =>
            setVisibleRowIdsMap((prevVisibleRowIdsMap) => ({
              ...prevVisibleRowIdsMap,
              [rowProps.data.id]: newVisible,
            }))
          }
          signup={signup}
          onEdit={(timeSlot) => onEditRef.current?.(timeSlot, rowProps.data)}
          {...rowProps}
        />
      )),
    [cart, signup, brandKitColors],
  )

  const ScheduleListDateListRowWithHandlers: WebUI.ListRowComponentType<SignupSpotsByDateDatum> =
    useMemo(
      () =>
        React.forwardRef((rowProps, rowForwardedRef) => (
          <ScheduleListDateListRow
            ref={rowForwardedRef as any}
            cart={cart}
            onVisibleChange={(newVisible) =>
              setVisibleRowIdsMap((prevVisibleRowIdsMap) => ({
                ...prevVisibleRowIdsMap,
                [rowProps.data.id]: newVisible,
              }))
            }
            signup={signup}
            onEdit={onEditRef.current}
            brandKitColors={brandKitColors}
            {...rowProps}
          />
        )),
      [cart, signup, brandKitColors],
    )

  return (
    <div className={WebUI.cn('flex flex-col gap-4', className)} {...restProps}>
      <div
        className={WebUI.cn(
          'flex flex-col gap-4 px-3 sm:px-6',
          previewMode && signup.options.signupType === 'list' && 'sm:px-9',
        )}
      >
        <div className="flex flex-col gap-2">
          <WebUI.Heading className="font-semibold" as="h2">
            {signup.name}
          </WebUI.Heading>
          {signup.options.signupListDate && (
            <WebUI.Text className="mb-1 ml-1 font-semibold text-ds-sm">
              {Util.nativeFormatDate(signup.options.signupListDate, {
                dateStyle: 'medium',
              })}
            </WebUI.Text>
          )}
          {Util.stripMarkdown(signup.description ?? '').replaceAll('\n', '')
            .length > 0 && (
            <WebUI.MarkdownParagraph
              className="text-ds-sm"
              markdown={signup.description ?? ''}
            />
          )}
        </div>
        <WebUI.Separator variant="primary" />
      </div>

      <WebUI.List<Api.PublicSignUpSpot | SignupSpotsByDateDatum>
        className={WebUI.cn(
          'xs: overflow-y-visible px-3 sm:px-6',
          signup.options.signupType === 'list' ? 'gap-3' : 'gap-4',
          signup.options.signupType === 'list' && '[&_>_.List-item]:mx-3',
        )}
        data={data}
        HeaderComponent={
          <div
            className={
              'SignUpViewListHeader xs:sticky xs:top-0 xs:right-0 xs:left-0 xs:z-[2] flex flex-col items-stretch justify-center gap-3 bg-natural-100 xs:py-3 sm:flex-row sm:items-center sm:justify-between'
            }
          >
            {signup.options.signupType === 'schedule' ? (
              <div className="flex flex-row gap-3">
                {data.length > 0 && (
                  <WebUI.Button
                    size="compact"
                    variant="outlined"
                    onClick={() => {
                      setVisibleRowIdsMap((prevVisibleRowIdsMap) => {
                        const shouldExpandAll =
                          Object.values(prevVisibleRowIdsMap).includes(false)

                        // @ts-expect-error
                        return Util.mapToObj(data, (di) => [
                          di.id,
                          shouldExpandAll,
                        ])
                      })
                    }}
                  >
                    {Object.values(visibleRowIdsMap).includes(false)
                      ? 'Expand All'
                      : 'Collapse All'}
                  </WebUI.Button>
                )}
                <WebUI.DropdownSelect<Api.SignUpGroupedBy>
                  size="small"
                  value={groupBy}
                  onValueChange={(newGroupBy) => {
                    if (newGroupBy) {
                      setGroupBy(newGroupBy)
                    }
                  }}
                >
                  <WebUI.DropdownSelectOption value="date">
                    Display by Date
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value="spot">
                    Display by Spot
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
              </div>
            ) : (
              <div />
            )}
            {!previewMode && (
              <WebUI.Switch
                size="small"
                checked={filters.isOpenOnly}
                onChange={(event) =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    isOpenOnly: event.target.checked,
                  }))
                }
              >
                Open Spots Only
              </WebUI.Switch>
            )}
          </div>
        }
        RowComponent={
          {
            list: TimeSlotCardWithHandlers,
            schedule:
              groupBy === 'date'
                ? ScheduleListDateListRowWithHandlers
                : ScheduleListSpotListRowWithHandlers,
          }[signup.options.signupType] as any
        }
      />
    </div>
  )
}

// MARK: – ScheduleListSpotListRow

interface ScheduleListSpotListRowProps
  extends WebUI.ListRowComponentProps<
      Api.PublicSignUpSpot & {visible: boolean}
    >,
    Pick<WebUI.DisclosureProps, 'onVisibleChange'> {
  cart?: Api.Cart
  signup: Api.PublicTabSignup
  onEdit: (timeSlot: Api.PublicTimeSlot) => void
  brandKitColors: PayerBrandKitColors
}

const ScheduleListSpotListRow = React.memo(
  React.forwardRef<HTMLDivElement, ScheduleListSpotListRowProps>(
    (
      {
        cart,
        signup,
        onEdit,
        onVisibleChange,
        data: spot,
        index,
        brandKitColors,
        ...restProps
      },
      forwardedRef,
    ) => {
      const media = WebUI.useMedia()
      const onEditRef = useLiveRef(onEdit)

      const hasPublicParticipants = spot.time_slots.some(
        (ts) => ts.public_participants.length > 0,
      )

      const listData = useMemo(
        () =>
          Util.sort(
            spot.time_slots.map((ts) => ({
              ...ts,
              spot,
            })),
          ).asc((ts) => ts.options.startTime),
        [spot],
      )

      const columnHelper = useMemo(
        () => WebUI.createColumnHelper<Api.PublicTimeSlot>(),
        [],
      )

      const columns = useMemo(
        () => [
          columnHelper.accessor((ts) => new Date(ts.options.startTime ?? ''), {
            id: 'date',
            size: 132,
            sortingFn: 'datetime',
            meta: {
              subtle: false,
            },
            header: 'Date',
            cell: ({cell}) =>
              Util.nativeFormatDate(cell.getValue(), {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: signup.options.timeZone,
              }),
          }),
          columnHelper.accessor(
            (ts) =>
              [
                new Date(ts.options.startTime ?? ''),
                new Date(ts.options.endTime ?? ''),
              ] as const,
            {
              id: 'time',
              size: 140,
              header: 'Time',
              cell: ({cell}) => (
                <>
                  {Util.formatDateRange(
                    cell.getValue()[0],
                    cell.getValue()[1],
                    {
                      dateTimeFormatOptions: {
                        day: undefined,
                        weekday: undefined,
                        month: undefined,
                        year: undefined,
                        timeZoneName: 'short',
                        timeZone: signup.options.timeZone,
                      },
                    },
                  )}
                </>
              ),
            },
          ),
          columnHelper.display({
            id: 'participants',
            size: 160,
            header: hasPublicParticipants ? 'Participants' : '',
            cell: ({row: {original: ts}}) => (
              <PublicParticipantGroup
                maxVisibleCount={2}
                publicParticipants={ts.public_participants}
                commentsHidden={
                  signup.options.comment.hiddenFromPublicView === true
                }
              />
            ),
          }),
          columnHelper.accessor(
            (ts) => {
              const totalQuntityInCart = Util.sumBy(
                cart?.time_slots.filter((cTs) => cTs.time_slot.id === ts.id) ??
                  [],
                (cTs) => cTs.quantity,
              )
              return ts.available_quantity == null
                ? null
                : ts.available_quantity - totalQuntityInCart
            },
            {
              id: 'available_quantity',
              size: 96,
              header: 'Spots Available',
              cell: ({cell}) => cell.getValue() ?? 'Unlimited',
            },
          ),
          columnHelper.display({
            id: 'action',
            size: 90,
            cell: ({row: {original: ts}}) => (
              <SignUpButton
                className="w-full"
                cart={cart}
                size="compact"
                timeSlot={ts}
                onEdit={onEditRef.current}
                brandKitColors={brandKitColors}
              />
            ),
          }),
        ],
        [
          cart,
          columnHelper,
          hasPublicParticipants,
          signup.options.comment.hiddenFromPublicView,
          brandKitColors,
          signup.options.timeZone,
        ],
      )

      const TimeSlotCardWithHandlers = useMemo(
        () =>
          React.forwardRef<
            HTMLDivElement,
            WebUI.ListRowComponentProps<
              Api.PublicTimeSlot & {spot: Api.PublicSignUpSpot}
            >
          >((rowProps, rowForwardedRef) => (
            <TimeSlotCard
              ref={rowForwardedRef}
              titleDisplay="date"
              cart={cart}
              signup={signup}
              onEdit={onEditRef.current}
              brandKitColors={brandKitColors}
              {...rowProps}
            />
          )),
        [cart, signup, brandKitColors],
      )

      const initialSortingState = useMemo(
        () => [
          {
            id: 'date',
            desc: false,
          },
        ],
        [],
      )

      return (
        <WebUI.Panel ref={forwardedRef} {...restProps}>
          <WebUI.Disclosure
            visible={spot.visible}
            onVisibleChange={onVisibleChange}
          >
            <WebUI.AccordionHeader className="text-teal-600 [&_.AccordionHeader-content]:p-4">
              <div className="flex flex-col">
                <WebUI.Text className="text-wrap text-teal-600">
                  {spot.name}
                </WebUI.Text>
                {Util.stripMarkdown(spot.description ?? '').replaceAll('\n', '')
                  .length > 0 && (
                  <WebUI.MarkdownParagraph
                    className="text-ds-xs"
                    markdown={spot.description ?? ''}
                  />
                )}
              </div>
            </WebUI.AccordionHeader>
            <WebUI.DisclosureContent>
              {media.sm ? (
                <WebUI.TableView
                  className={
                    'p-5 [&_.TableView-body]:text-ds-sm [&_.TableView-headerText]:px-0 [&_.TableViewCell]:px-0'
                  }
                  sortable
                  initialState={{sorting: initialSortingState}}
                  columns={columns}
                  data={spot.time_slots}
                />
              ) : (
                <WebUI.VirtualizedList
                  className="overflow-y-visible p-3 [&_>_.List-rowsContainer]:gap-3"
                  estimateRowSize={148}
                  data={listData}
                  RowComponent={TimeSlotCardWithHandlers}
                />
              )}
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        </WebUI.Panel>
      )
    },
  ),
)

// MARK: – ScheduleListDateListRow

interface ScheduleListDateListRowProps
  extends WebUI.ListRowComponentProps<SignupSpotsByDateDatum>,
    Pick<WebUI.DisclosureProps, 'onVisibleChange'> {
  cart?: Api.Cart
  signup: Api.PublicTabSignup
  onEdit?: (timeSlot: Api.PublicTimeSlot, spot: Api.PublicSignUpSpot) => void
  brandKitColors: PayerBrandKitColors
}

const ScheduleListDateListRow = React.memo(
  React.forwardRef<HTMLDivElement, ScheduleListDateListRowProps>(
    (
      {
        cart,
        signup,
        onEdit,
        onVisibleChange,
        data,
        index,
        brandKitColors,
        ...restProps
      },
      forwardedRef,
    ) => {
      const media = WebUI.useMedia()
      const onEditRef = useLiveRef(onEdit)

      const timeSlots = useMemo(
        () =>
          Util.sort(
            data.spots.flatMap((s) =>
              s.time_slots.map((ts) => ({
                ...ts,
                spot: s,
              })),
            ),
          ).by([
            {asc: (ts) => ts.spot.position},
            {asc: (ts) => ts.options.startTime},
          ]),
        [data.spots],
      )

      const hasPublicParticipants = timeSlots.some(
        (ts) => ts.public_participants.length > 0,
      )

      const columnHelper = useMemo(
        () => WebUI.createColumnHelper<(typeof timeSlots)[number]>(),
        [],
      )

      const columns = useMemo(
        () =>
          [
            columnHelper.accessor((ts) => ts.spot.name, {
              id: 'spot',
              size: 160,
              header: 'Spot',
              cell: (cellProps) =>
                'isRowSpanned' in cellProps.cell &&
                cellProps.cell.isRowSpanned ? null : (
                  <WebUI.Ellipsis className="font-semibold">
                    {cellProps.cell.getValue()}
                  </WebUI.Ellipsis>
                ),
            }),
            columnHelper.accessor(
              (ts) =>
                [
                  new Date(ts.options.startTime ?? ''),
                  new Date(ts.options.endTime ?? ''),
                ] as const,
              {
                id: 'time',
                size: 140,
                header: 'Time',
                cell: ({cell}) => (
                  <>
                    {Util.formatDateRange(
                      cell.getValue()[0],
                      cell.getValue()[1],
                      {
                        dateTimeFormatOptions: {
                          day: undefined,
                          weekday: undefined,
                          month: undefined,
                          year: undefined,
                          timeZoneName: 'short',
                          timeZone: signup.options.timeZone,
                        },
                      },
                    )}
                  </>
                ),
              },
            ),
            columnHelper.display({
              id: 'participants',
              size: 160,
              header: hasPublicParticipants ? 'Participants' : '',
              cell: ({row: {original: ts}}) => (
                <PublicParticipantGroup
                  maxVisibleCount={2}
                  publicParticipants={ts.public_participants}
                  commentsHidden={
                    signup.options.comment.hiddenFromPublicView === true
                  }
                />
              ),
            }),
            columnHelper.accessor(
              (ts) => {
                const totalQuntityInCart = Util.sumBy(
                  cart?.time_slots.filter(
                    (cTs) => cTs.time_slot.id === ts.id,
                  ) ?? [],
                  (cTs) => cTs.quantity,
                )
                return ts.available_quantity == null
                  ? null
                  : ts.available_quantity - totalQuntityInCart
              },
              {
                id: 'available_quantity',
                size: 96,
                header: 'Spots Available',
                cell: ({cell}) => cell.getValue() ?? 'Unlimited',
              },
            ),
            columnHelper.display({
              id: 'action',
              size: 90,
              cell: ({row: {original}}) => (
                <SignUpButton
                  className="w-full"
                  size="compact"
                  cart={cart}
                  timeSlot={original}
                  onEdit={(timeSlot) =>
                    onEditRef.current?.(timeSlot, original.spot)
                  }
                  brandKitColors={brandKitColors}
                />
              ),
            }),
          ].filter((c) => c != null),
        [
          cart,
          columnHelper,
          hasPublicParticipants,
          signup.options.comment.hiddenFromPublicView,
          brandKitColors,
          signup.options.timeZone,
        ],
      )

      const TimeSlotCardWithHandlers = useMemo(
        () =>
          React.forwardRef<
            HTMLDivElement,
            WebUI.ListRowComponentProps<
              Api.PublicTimeSlot & {spot: Api.PublicSignUpSpot}
            >
          >((rowProps, rowForwardedRef) => (
            <TimeSlotCard
              ref={rowForwardedRef}
              cart={cart}
              signup={signup}
              onEdit={(timeSlot) =>
                onEditRef.current?.(timeSlot, rowProps.data.spot)
              }
              brandKitColors={brandKitColors}
              {...rowProps}
            />
          )),
        [cart, signup, brandKitColors],
      )

      const initialTableState = useMemo(() => ({}), [])

      return (
        <WebUI.Panel ref={forwardedRef} {...restProps}>
          <WebUI.Disclosure
            visible={data.visible}
            onVisibleChange={onVisibleChange}
          >
            <WebUI.AccordionHeader
              className={`text-teal-600 [&_.AccordionHeader-button[aria-expanded="true"]]:bg-teal-80 [&_.AccordionHeader-content]:px-4`}
            >
              {Util.nativeFormatDate(data.date, {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: signup.options.timeZone,
              })}
            </WebUI.AccordionHeader>
            <WebUI.DisclosureContent>
              {media.sm ? (
                <WebUI.TableView
                  className={
                    'p-5 [&_.TableView-body]:text-ds-sm [&_.TableView-headerText]:px-0 [&_.TableViewCell]:px-0'
                  }
                  sortable={false}
                  initialState={initialTableState}
                  columns={columns}
                  data={timeSlots}
                />
              ) : (
                <WebUI.VirtualizedList
                  className="gap-3 overflow-y-visible p-3"
                  estimateRowSize={148}
                  data={timeSlots}
                  RowComponent={TimeSlotCardWithHandlers}
                />
              )}
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        </WebUI.Panel>
      )
    },
  ),
)

// MARK: – TimeSlotCard

interface TimeSlotCardProps
  extends WebUI.ListRowComponentProps<
      Api.PublicSignUpSpot | (Api.PublicTimeSlot & {spot: Api.PublicSignUpSpot})
    >,
    Pick<SignUpButtonProps, 'onEdit'> {
  titleDisplay?: 'spot' | 'date'
  cart?: Api.Cart
  signup: Api.PublicTabSignup
  brandKitColors: PayerBrandKitColors
}

const TimeSlotCard = React.forwardRef<HTMLDivElement, TimeSlotCardProps>(
  (
    {
      titleDisplay = 'spot',
      cart,
      signup,
      onEdit,
      data,
      index,
      className,
      brandKitColors,
      ...restProps
    },
    forwardedRef,
  ) => {
    const media = WebUI.useMedia()

    const spot = 'spot' in data ? data.spot : data
    const timeSlot =
      'spot' in data
        ? data
        : data.time_slots.find((ts) => ts.options.isSignupListDefaultSlot)

    const totalQuntityInCart = Util.sumBy(
      cart?.time_slots.filter((cTs) => cTs.time_slot.id === data.id) ?? [],
      (cTs) => cTs.quantity,
    )
    const spotsAvailable =
      timeSlot?.available_quantity == null
        ? null
        : timeSlot.available_quantity - totalQuntityInCart

    return (
      <WebUI.Card
        ref={forwardedRef}
        className={WebUI.cn(
          'flex flex-col items-stretch justify-center gap-3 p-3 text-ds-sm sm:flex-row sm:items-center sm:justify-between sm:p-5',
          className,
        )}
        {...restProps}
      >
        <div className="flex flex-col gap-1 sm:max-w-[240px] sm:flex-[3_0_0px]">
          <WebUI.Text>
            {titleDisplay === 'spot'
              ? spot.name
              : Util.nativeFormatDate(timeSlot?.options.startTime ?? '', {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  timeZone: signup.options.timeZone,
                })}
          </WebUI.Text>
          {!('spot' in data) &&
            !!spot.description &&
            Util.stripMarkdown(spot.description).replaceAll('\n', '').length >
              0 && (
              <WebUI.MarkdownParagraph
                className="line-clamp-3 text-ds-xs"
                markdown={spot.description}
              />
            )}
          {timeSlot?.options.startTime && timeSlot?.options.endTime && (
            <WebUI.Ellipsis>
              {Util.formatDateRange(
                timeSlot.options.startTime,
                timeSlot.options.endTime,
                {
                  dateTimeFormatOptions: {
                    day: undefined,
                    weekday: undefined,
                    month: undefined,
                    year: undefined,
                    timeZoneName: 'short',
                    timeZone: signup.options.timeZone,
                  },
                },
              )}
            </WebUI.Ellipsis>
          )}
        </div>
        {(media.sm ||
          (timeSlot && timeSlot.public_participants.length > 0)) && (
          <PublicParticipantGroup
            className="sm:flex-[1_0_0px]"
            maxVisibleCount={2}
            publicParticipants={timeSlot?.public_participants ?? []}
            commentsHidden={
              signup.options.comment.hiddenFromPublicView === true
            }
          />
        )}
        <WebUI.Ellipsis className="inline font-light text-ds-xs text-gray600 sm:hidden">
          Spots available: {spotsAvailable ?? 'Unlimited'}
        </WebUI.Ellipsis>
        <WebUI.Ellipsis className="mr-8 hidden max-w-[120px] flex-[2_0_0px] font-light text-ds-sm sm:inline">
          {spotsAvailable ?? 'Unlimited'} available
        </WebUI.Ellipsis>
        {!!timeSlot && (
          <SignUpButton
            className="w-[124px] self-start sm:w-[90px]"
            size="compact"
            cart={cart}
            timeSlot={timeSlot}
            onEdit={onEdit}
            brandKitColors={brandKitColors}
          />
        )}
      </WebUI.Card>
    )
  },
)

// MARK: – SignUpButton

type SignUpButtonProps = React.ComponentPropsWithoutRef<'div'> &
  Pick<WebUI.ButtonProps, 'size'> &
  React.ComponentPropsWithoutRef<'button'> & {
    cart?: Api.Cart
    timeSlot: Api.PublicTimeSlot
    onEdit: (timeSlot: Api.PublicTimeSlot) => void
    brandKitColors: PayerBrandKitColors
  }

const SignUpButton: React.FC<SignUpButtonProps> = ({
  size,
  cart,
  timeSlot,
  onEdit,
  onClick,
  className,
  brandKitColors,
  ...restProps
}) => {
  const urlParams = useParams()
  const deleteCartTimeSlotMutation = useDeleteCartTimeSlotMutation()
  const isSelected = WebUI.useIsSelected(timeSlot.id)
  const selectActions = WebUI.useSelectionActions()

  const cartTimeSlot = cart?.time_slots.find(
    (cTs) => cTs.time_slot.id === timeSlot?.id,
  )

  if (isSelected) {
    return (
      <WebUI.DeprecatedTooltip label="Click to remove spot">
        <WebUI.IconButton
          className="rounded-full text-ds-lg"
          size="default_alt"
          variant="primary"
          onClick={() => selectActions.unselect(timeSlot.id)}
          style={{
            backgroundColor: brandKitColors.secondaryButton,
            color: getCUReadableColor(brandKitColors.secondaryButton),
          }}
        >
          <WebUI.PhosphorIcon icon="check" />
        </WebUI.IconButton>
      </WebUI.DeprecatedTooltip>
    )
  }

  const totalQuntityInCart = Util.sumBy(
    cart?.time_slots.filter((ts) => ts.time_slot.id === timeSlot?.id) ?? [],
    (ts) => ts.quantity,
  )
  const availableQuantity =
    timeSlot?.available_quantity == null
      ? null
      : timeSlot.available_quantity - totalQuntityInCart
  const isFull = availableQuantity === 0

  if (cartTimeSlot) {
    return (
      <div
        className={WebUI.cn('flex flex-row items-center gap-2', className)}
        {...restProps}
      >
        <WebUI.DeprecatedTooltip label="Click to remove spot">
          <WebUI.IconButton
            className="rounded-full text-ds-lg"
            size="default_alt"
            variant="primary"
            loading={deleteCartTimeSlotMutation.isPending}
            onClick={() => {
              if (cart) {
                deleteCartTimeSlotMutation.mutate({
                  pathParams: {
                    // biome-ignore lint/style/noNonNullAssertion:
                    tabId: (urlParams.tabSlug || urlParams.collection)!,
                    cartUuid: cart.uuid,
                    timeSlotId: cartTimeSlot.id,
                  },
                })
              }
            }}
            style={{
              backgroundColor: brandKitColors.secondaryButton,
              color: getCUReadableColor(brandKitColors.secondaryButton),
            }}
          >
            <WebUI.PhosphorIcon icon="check" />
          </WebUI.IconButton>
        </WebUI.DeprecatedTooltip>
        <WebUI.IconButton
          className="rounded-full text-ds-lg"
          size="default_alt"
          variant="secondary"
          onClick={() => onEdit(timeSlot)}
        >
          <WebUI.PhosphorIcon icon="pencil" />
        </WebUI.IconButton>
      </div>
    )
  }

  return (
    <WebUI.Button
      className={className}
      size={size}
      disabled={isFull}
      onClick={(event) => {
        onClick?.(event)

        if (event.defaultPrevented) {
          return
        }

        selectActions.select(timeSlot.id)
      }}
      style={{
        backgroundColor: brandKitColors.primaryButton,
        color: getCUReadableColor(brandKitColors.primaryButton),
      }}
      {...restProps}
    >
      {isFull ? 'Full' : 'Sign Up'}
    </WebUI.Button>
  )
}

// MARK: – PublicParticipantGroup

interface PublicParticipantGroupItem {
  name: string
  image: null
  comment: string | null | undefined
}

interface PublicParticipantGroupProps
  extends Omit<
    WebUI.AvatarGroupProps<PublicParticipantGroupItem>,
    'data' | 'AvatarComp'
  > {
  commentsHidden?: boolean
  publicParticipants: Api.TimeSlotPublicParticipant[]
}

const PublicParticipantGroup: React.FC<PublicParticipantGroupProps> = ({
  commentsHidden = false,
  publicParticipants,
  className,
  ...restProps
}) => (
  <WebUI.AvatarGroup<PublicParticipantGroupItem>
    className={WebUI.cn(
      '[&_.Avatar_>_.Avatar-label]:bg-aquaLight [&_.Avatar_>_.Avatar-label_>_.Avatar-labelText]:text-gray800',
      className,
    )}
    AvatarComp={SharpAvatar}
    data={publicParticipants.map((pp) => ({
      name: `${pp.firstName} ${pp.lastName}`,
      image: null,
      comment: commentsHidden ? null : pp.comment,
    }))}
    renderSummary={(di) => (
      <div className="flex flex-col gap-0_5">
        <WebUI.Text className="text-ds-sm">{di.name}</WebUI.Text>
        {!!di.comment && (
          <WebUI.Text className="font-light text-ds-xs">
            {di.comment}
          </WebUI.Text>
        )}
      </div>
    )}
    {...restProps}
  />
)

// MARK: – Helpers

export function makeTransientSignup(
  signupValues: SignUpFormValues,
  spots: Api.SignUpSpot[],
): Api.PublicTabSignup {
  return {
    id: -1,
    name: signupValues.name,
    description: signupValues.description,
    required: false,
    position: 0,
    options: {
      comment: signupValues.comment,
      timeZone: signupValues.timeZone.enabled
        ? signupValues.timeZone.value
        : getLocalTimeZone(),
      signupType: signupValues.type,
      groupedBy: signupValues.groupedBy,
      hideNamesFromPublicView:
        signupValues.hideNamesFromPublicView.enabled &&
        signupValues.hideNamesFromPublicView.value === 'hide',
      abbreviateNamesFromPublicView:
        signupValues.hideNamesFromPublicView.enabled &&
        signupValues.hideNamesFromPublicView.value === 'abbreviate',
      perPersonSpotLimit: {
        ...signupValues.perPersonSpotLimit,
        value: Number(signupValues.perPersonSpotLimit.value),
      },
      signupListDate:
        signupValues.signupListDate?.toDate(getLocalTimeZone()).toISOString() ??
        null,
    },
    fields: [
      {
        id: -1,
        field_type: 'text' as const,
        metadata: {
          isTimeSlotField: true,
          timeSlotFieldType: 'first_name' as const,
        },
        name: 'First Name',
        position: 0,
        required: true,
        values: null,
      },
      {
        id: -2,
        field_type: 'text' as const,
        metadata: {
          isTimeSlotField: true,
          timeSlotFieldType: 'last_name' as const,
        },
        name: 'Last Name',
        position: 1,
        required: true,
        values: null,
      },
      signupValues.comment.enabled
        ? {
            id: -3,
            field_type: 'text' as const,
            metadata: {
              isTimeSlotField: true,
              timeSlotFieldType: 'comment' as const,
            },
            name: signupValues.comment.fieldName,
            position: 2,
            required: signupValues.comment.required,
            values: null,
          }
        : undefined,
    ].filter((f) => f != null),
    visible_spots: spots.map((s) => ({
      ...s,
      required: false,
      time_slots: s.time_slots.map((ts) => ({
        ...ts,
        public_participants: [],
        required: false,
        description: '',
      })),
    })),
  }
}
