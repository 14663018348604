import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import {useFormik, usePrevious, useUpdateEffect} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import Papa from 'papaparse'
import React, {useEffect, useState} from 'react'

import {ContactListPanel} from './ContactListPanel/ContactListPanel'
import {ContactsAutosuggestCombobox} from '../ContactsAutosuggestCombobox'
import {CopyAndPastePanel} from './CopyAndPastePanel'
import {RecipientsTableView} from './RecipientsTableView'

export interface RecipientsModalRecipient {
  name: string
  email: string
}

export interface RecipientsModalProps extends WebUI.ModalProps {
  initialRecipients?: RecipientsModalRecipient[]
  isContactsModal?: boolean
  onRecipientsSave: (
    recipients: RecipientsModalRecipient[],
  ) => void | Promise<void>
}

export const RecipientsModal = React.forwardRef<
  WebUI.DialogInstance,
  RecipientsModalProps
>(
  (
    {
      initialRecipients = [],
      isContactsModal,
      onRecipientsSave,
      initialVisible = false,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const media = WebUI.useMedia()
    const [recipients, setRecipients] = useState(initialRecipients)
    const [isSaving, setIsSaving] = useState(false)
    const growlActions = WebUI.useGrowlActions()

    const recipientsCount = recipients.length
    const prevRecipientsCount = usePrevious(recipientsCount)

    useEffect(() => {
      if (initialRecipients.length === 0) {
        setRecipients([])
      }
    }, [initialRecipients])

    useUpdateEffect(() => {
      if (
        prevRecipientsCount != null &&
        recipientsCount > prevRecipientsCount
      ) {
        const newRecipientsCount = recipientsCount - prevRecipientsCount
        growlActions.show('success', {
          title: 'Success',
          body: `You've added ${Util.pluralize(
            'recipient',
            newRecipientsCount,
            true,
          )}`,
        })
      }
    }, [growlActions, prevRecipientsCount, recipientsCount])

    return (
      <WebUI.Modal
        ref={forwardedRef}
        aria-label="Add recipients"
        className={WebUI.cn(
          '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-xl',
          className,
        )}
        initialVisible={initialVisible}
        {...restProps}
      >
        {(dialog) => (
          <>
            <WebUI.ModalCloseButton />

            <WebUI.VStack className="grow overflow-hidden">
              <WebUI.VStack className="min-h-0 grow lg:flex-row">
                <WebUI.VStack
                  className={
                    'flex-[2_1_640px] xs:flex-[2_1_auto] gap-4 overflow-y-auto xs:overflow-y-visible xs:pb-8'
                  }
                >
                  <WebUI.ModalHeader variant="compact">
                    {isContactsModal ? 'Add Contacts' : 'Recipients'}
                  </WebUI.ModalHeader>
                  <WebUI.Tabs className="px-7" variant="underlined">
                    <WebUI.VStack className="gap-8">
                      <WebUI.TabList
                        aria-label="Recipients navigation"
                        className="[&_>_.Tab_>_.Button-content]:font-normal [&_>_.Tab_>_.Button-content]:text-ds-base"
                      >
                        <WebUI.Tab id="my-contacts">
                          {isContactsModal ? 'Contact Lists' : 'My Contacts'}
                        </WebUI.Tab>
                        <WebUI.Tab id="add-manually">Add Manually</WebUI.Tab>
                        <WebUI.Tab id="copy-paste">Copy and Paste</WebUI.Tab>
                        {media.sm && (
                          <WebUI.Tab id="upload-file">Upload a File</WebUI.Tab>
                        )}
                      </WebUI.TabList>

                      <WebUI.TabPanel tabId="my-contacts">
                        <ContactListPanel
                          onAddRecipients={(newRecipients) =>
                            setRecipients((prevPrecipients) =>
                              Util.uniqueBy(
                                [...prevPrecipients, ...newRecipients],
                                (r) => r.email,
                              ),
                            )
                          }
                        />
                      </WebUI.TabPanel>

                      <WebUI.TabPanel tabId="add-manually">
                        <AddManualPanel
                          recipients={recipients}
                          onAddRecipient={(newRecipient) =>
                            setRecipients((prevPrecipients) => [
                              ...prevPrecipients,
                              newRecipient,
                            ])
                          }
                        />
                      </WebUI.TabPanel>
                      <WebUI.TabPanel tabId="copy-paste">
                        <CopyAndPastePanel
                          onAddRecipients={(newRecipients) =>
                            setRecipients((prevPrecipients) =>
                              Util.uniqueBy(
                                [...prevPrecipients, ...newRecipients],
                                (r) => r.email,
                              ),
                            )
                          }
                        />
                      </WebUI.TabPanel>
                      <WebUI.TabPanel tabId="upload-file">
                        <UploadFilePanel
                          onAddRecipients={(newRecipients) =>
                            setRecipients((prevPrecipients) =>
                              Util.uniqueBy(
                                [...prevPrecipients, ...newRecipients],
                                (r) => r.email,
                              ),
                            )
                          }
                        />
                      </WebUI.TabPanel>
                    </WebUI.VStack>
                  </WebUI.Tabs>
                </WebUI.VStack>

                <WebUI.Separator
                  orientation={media.sm ? 'vertical' : 'horizontal'}
                  variant="primary"
                />
                <WebUI.VStack className="flex-[1_1_480px] gap-8 overflow-hidden px-7 py-8 xs:pb-2">
                  <WebUI.Heading as="h5">
                    Recipient List: {recipients.length}
                  </WebUI.Heading>

                  <RecipientsTableView
                    className="xs:my-2 overflow-y-auto xs:py-2"
                    recipients={recipients}
                    onRecipientsChange={(newRecipients) =>
                      setRecipients(newRecipients)
                    }
                  />
                </WebUI.VStack>
              </WebUI.VStack>

              <WebUI.Separator variant="primary" />
              <WebUI.HStack className="flex-0 items-center px-8 py-4">
                <WebUI.Button
                  variant="primary"
                  size="large"
                  disabled={recipients.length === 0}
                  loading={isSaving}
                  onClick={async () => {
                    try {
                      setIsSaving(true)
                      await onRecipientsSave(
                        Util.uniqueBy(
                          recipients.filter((r) =>
                            Yup.string().email().isValidSync(r.email),
                          ),
                          (r) => r.email,
                        ),
                      )
                      dialog.hide()
                    } catch {
                      dialog.hide()
                    } finally {
                      setIsSaving(false)
                    }
                  }}
                >
                  {isContactsModal ? 'Add Contacts' : 'Save Recipients'}
                </WebUI.Button>
              </WebUI.HStack>
            </WebUI.VStack>
          </>
        )}
      </WebUI.Modal>
    )
  },
)

// MARK: – UploadFilePanel

interface UploadFilePanelProps extends React.ComponentPropsWithoutRef<'div'> {
  onAddRecipients: (recipients: RecipientsModalRecipient[]) => void
}

const UploadFilePanel = ({
  onAddRecipients,
  className,
  ...restProps
}: UploadFilePanelProps) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const growlActions = WebUI.useGrowlActions()
  return (
    <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
      <WebUI.VStack className="gap-2">
        <WebUI.Heading as="h4">Upload a .csv file</WebUI.Heading>

        <WebUI.FileUploader
          accept={{'text/csv': ['.csv']}}
          onDrop={([file]) => {
            if (file) {
              setUploadedFile(file)
            }
          }}
        >
          <WebUI.VStack className="items-start gap-3">
            <WebUI.FileUploaderInput />
            {uploadedFile && (
              <span>
                <WebUI.Ellipsis>{uploadedFile.name}</WebUI.Ellipsis>{' '}
                <WebUI.Button
                  className="text-ds-sm"
                  variant="link"
                  onClick={() => setUploadedFile(null)}
                >
                  Remove file
                </WebUI.Button>
              </span>
            )}
            {uploadedFile ? (
              <WebUI.Button
                onClick={() =>
                  Papa.parse<{Name: string; Email: string}>(uploadedFile, {
                    header: true,
                    skipEmptyLines: 'greedy',
                    complete: ({data, meta}) => {
                      if (
                        !['Name', 'Email'].every((field) =>
                          meta.fields?.includes(field),
                        )
                      ) {
                        growlActions.show('error', {
                          body: 'Missing "Name" and "Email" headers. Please check that you are uploading a .csv file with the required format provided in the template.',
                        })
                        setUploadedFile(null)
                        return
                      }

                      const nonEmptyRows = data.filter(
                        (row) => !!row.Name || !!row.Email,
                      )

                      if (nonEmptyRows.length === 0) {
                        growlActions.show('error', {
                          body: 'No valid rows found.',
                        })
                        setUploadedFile(null)
                        return
                      }

                      const recipients = nonEmptyRows.map(
                        (row): RecipientsModalRecipient => ({
                          name: row.Name,
                          email: row.Email.trim().toLowerCase(),
                        }),
                      )
                      onAddRecipients(recipients)
                      setUploadedFile(null)
                    },
                    error: (err) => {
                      growlActions.show('error', {body: err.message})
                      setUploadedFile(null)
                    },
                  })
                }
              >
                Add File Contacts
              </WebUI.Button>
            ) : (
              <WebUI.FileUploaderButton>Upload File</WebUI.FileUploaderButton>
            )}
          </WebUI.VStack>
        </WebUI.FileUploader>
      </WebUI.VStack>

      <WebUI.Separator variant="primary" />

      <WebUI.VStack className="gap-2 [&_>_span]:text-ds-sm">
        <WebUI.Heading as="h4">Need help?</WebUI.Heading>
        <span>
          1.{' '}
          <WebUI.Anchor
            href="data:application/octet-stream,Name%2CEmail%0AGreg%20Heffley%2Cgheffley@yahoo.com%0ARomana%20Quimby%2Crquimby@hotmail.com%0ABinur%2Cbinur95@gmail.com%0A"
            download="template.csv"
          >
            Download a template
          </WebUI.Anchor>{' '}
          to see an example of the required format.
        </span>
        <span>
          2. Fill in your guests names and emails in the format below.
        </span>
        <span>3. Save your spreadsheet as a .csv before uploading.</span>
      </WebUI.VStack>

      <table
        className={
          'mt-4 max-w-[380px] table-fixed border-collapse [&_td]:border [&_td]:border-grey-400 [&_td]:px-3 [&_td]:py-2'
        }
      >
        <thead>
          <tr className="border-b">
            <td>Name</td>
            <td>Email</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Greg Heffley</td>
            <td>gheffley@yahoo.com</td>
          </tr>
          <tr>
            <td>Romana Quimby</td>
            <td>rquimby@hotmail.com</td>
          </tr>
        </tbody>
      </table>
    </WebUI.VStack>
  )
}

// MARK: – AddManualPanel

interface AddManualPanelProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  recipients: RecipientsModalRecipient[]
  onAddRecipient: (recipient: RecipientsModalRecipient) => void
}

const AddManualPanel = ({
  recipients,
  onAddRecipient,
  ...restProps
}: AddManualPanelProps) => {
  const [errors, setErrors] = useState({email: ''})
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid format').required('Required'),
    }),
    initialValues: {
      name: '',
      email: '',
    },
    onSubmit: (values, formikHelpers) => {
      if (
        recipients
          ?.map((r) => r.email.trim().toLowerCase())
          .find((email) => email === values.email.trim().toLowerCase())
      ) {
        return setErrors({email: 'Email already added'})
      }
      setErrors({email: ''})

      onAddRecipient({name: values.name, email: values.email})
      formikHelpers.resetForm()
    },
  })

  return (
    <WebUI.Form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.FormFieldGroup>
        <WebUI.FormField label="Name" error={formik.errors.name}>
          <ContactsAutosuggestCombobox
            field="name"
            placeholder="Name"
            inputValue={formik.values.name}
            selectedEmail={formik.values.email}
            onInputValueChange={(event) =>
              formik.setFieldValue('name', event.inputValue ?? '')
            }
            onSelectedContactChange={(newContact) => {
              if (newContact) {
                formik.setValues({
                  name: newContact.name,
                  email: newContact.email,
                })
              }
            }}
          />
        </WebUI.FormField>
        <WebUI.FormField label="Email" error={formik.errors.email}>
          <ContactsAutosuggestCombobox
            field="email"
            placeholder="Email"
            inputValue={formik.values.email}
            selectedEmail={formik.values.email}
            onInputValueChange={(event) =>
              formik.setFieldValue('email', event.inputValue ?? '')
            }
            onSelectedContactChange={(newContact) => {
              if (newContact) {
                formik.setValues({
                  name: newContact.name,
                  email: newContact.email,
                })
              }
            }}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      {errors.email && (
        <p className="text-center font-normal text-ds-sm text-orange-500">
          {errors.email}
        </p>
      )}

      <WebUI.Button className="self-start" type="submit">
        Add
      </WebUI.Button>
    </WebUI.Form>
  )
}
