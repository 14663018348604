import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {useMemo} from 'react'
import config from 'src/config'
import {LinkButton} from 'src/components/LinkButton'
import {Logo} from 'src/components/Logo'
import {Link} from 'src/components/Link'
import {fetchAndSave} from 'src/helpers/api-helpers'
import CardTabPencilIcon from 'src/images/CardTabPencilIcon.svg'
import CardCircleIcon from 'src/images/CardCircleIcon.svg'
import ProfileCirclesIcon from 'src/images/ProfileCirclesIcon.svg'
import PhoneCardIcon from 'src/images/PhoneCardIcon.svg'
import FormAndSignupIcon from 'src/images/FormAndSignupIcon.svg'
import LadyInPinkSweater from 'src/images/LadyInPinkSweater.png'
import TadaConfetti from 'src/images/TadaConfetti.svg'

import usePublicCollection from './hooks/usePublicCollection'

const CheckoutThankYouPage = () => {
  WebUI.useScrollToTop()
  const growlActions = WebUI.useGrowlActions()
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const [calendarButtonVisible] = useQueryParam('add-to-calendar', BooleanParam)
  const [paymentUuid] = useQueryParam('payment-uuid', StringParam)
  const [payerName] = useQueryParam('payer-name', StringParam)
  const [payerEmail] = useQueryParam('payere', StringParam)
  const media = WebUI.useMedia()
  const {publicCollection} = usePublicCollection()
  const decodedPayerEmail = useMemo(
    () => atob(atob((payerEmail ?? '') as string)),
    [payerEmail],
  )

  return (
    <div className="flex min-h-full min-w-0 flex-col bg-trueWhite">
      <div className="flex flex-row justify-between gap-3 px-4 py-12 sm:self-stretch sm:py-12 sm:pr-32 sm:pl-12">
        <WebUI.Anchor
          href={config.links.marketingPage}
          rel="noopener noreferrer"
        >
          <Logo
            className={
              '[&_>_.LogoIcon-graphics]:h-[27px] sm:[&_>_.LogoIcon-graphics]:h-9 [&_>_.LogoIcon-text]:h-[17.25px] sm:[&_>_.LogoIcon-text]:h-[23px]'
            }
            forceDisplayText
          />
        </WebUI.Anchor>

        <div className="flex flex-row">
          <LinkButton className="uppercase" preserveSearch to="help">
            Help
          </LinkButton>

          {publicCollection.organizer.groupPage.published && (
            <LinkButton
              className="uppercase"
              to={`/me/${publicCollection.organizer.groupPage.slug}`}
            >
              Collections
            </LinkButton>
          )}
        </div>
      </div>

      <div
        className={WebUI.cn(
          'flex grow flex-col items-center pt-16',
          addPayment ? 'gap-8' : 'gap-16',
        )}
      >
        <div className="flex flex-col gap-4 px-8 text-center">
          <WebUI.Heading className="font-accentAlt text-ds-xl sm:text-ds-3xl">
            {addPayment
              ? 'Order recorded'
              : `Thank you${payerName ? `, ${payerName}` : ''}!`}
          </WebUI.Heading>
          <WebUI.Text className="font-light text-ds-lg">
            {payerEmail ? (
              <>
                You will receive an email confirmation shortly at{' '}
                <span className="font-bold">{decodedPayerEmail}</span>
              </>
            ) : (
              'An email confirmation will be sent shortly.'
            )}
          </WebUI.Text>

          {calendarButtonVisible && !!paymentUuid && (
            <WebUI.Button
              className="self-center [&_>_.Button-content]:font-bold [&_>_.Button-content]:text-ds-sm"
              variant="link"
              iconBefore={<WebUI.PhosphorIcon icon="calendar-blank-bold" />}
              onClick={() =>
                fetchAndSave({
                  url: `users/payments/add_to_calendar?payment_uuid=${paymentUuid}`,
                  fileName: `${publicCollection.name}-events.ics`,
                })
              }
            >
              Add to Calendar
            </WebUI.Button>
          )}
        </div>
        <div className="relative mt-24 flex max-w-md sm:mt-0">
          {!media.sm && (
            <div className="absolute right-24 bottom-[124px] m-3">
              <img
                src={TadaConfetti}
                alt=""
                className="-left-12 absolute top-6 h-[100px]"
              />
              <img
                src={LadyInPinkSweater}
                alt=""
                className="-top-24 h-[240px]"
              />
            </div>
          )}
          <div className="flex justify-end rounded-extended bg-[linear-gradient(287deg,rgba(255,255,255,0.00)_49.92%,rgba(255,255,255,0.44)_103.57%),#8F83B3] bg-pink-10 px-6">
            <div className="flex max-w-[300px] flex-col gap-4 py-3 text-natural-100 sm:max-w-[60%] sm:p-8 sm:px-0 sm:py-5">
              <WebUI.Heading className="font-accentAlt" as="h2">
                Wasn’t that easy?
              </WebUI.Heading>
              <WebUI.Text className="text-ds-sm">
                If you loved this simple payment, tag a group organizer who
                would love it too. Let’s make life easier for all!
              </WebUI.Text>
              <WebUI.Heading className="font-accentAlt" as="h3">
                Share this collection
              </WebUI.Heading>

              <div className="mr-4 flex justify-between gap-1">
                <WebUI.RoundedButton
                  as={WebUI.AnchorButton}
                  variant="primary"
                  size="default"
                  href={`https://www.facebook.com/dialog/feed?app_id=413118622200717&link=${encodeURIComponent(config.helpers.shareUrl(publicCollection.slug))}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WebUI.PhosphorIcon
                    icon="facebook-logo-fill"
                    className="text-ds-lg"
                  />
                </WebUI.RoundedButton>
                <WebUI.RoundedButton
                  as={WebUI.AnchorButton}
                  variant="primary"
                  size="default"
                  href={`mailto:?&subject=You're invited to All icons everything!&body=https://form.cheddarup.com `}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WebUI.PhosphorIcon
                    icon="envelope-simple"
                    className="text-ds-lg"
                  />
                </WebUI.RoundedButton>
                <WebUI.RoundedButton
                  as={WebUI.AnchorButton}
                  variant="primary"
                  className="font-bold"
                  size="default"
                  rel="noopener noreferrer"
                  iconBefore={
                    <WebUI.PhosphorIcon icon="link" className="text-ds-lg" />
                  }
                  onClick={() => {
                    WebUI.copyToClipboard(
                      config.helpers.shareUrl(publicCollection.slug),
                    )
                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Link copied',
                    })
                  }}
                >
                  Copy link
                </WebUI.RoundedButton>
              </div>
            </div>
          </div>
          {media.sm && (
            <>
              <img
                src={TadaConfetti}
                alt=""
                className="-left-[60px] absolute top-2 h-[140px] w-[200px]"
              />
              <img
                src={LadyInPinkSweater}
                alt=""
                className="-bottom-0 absolute left-10"
              />
            </>
          )}
        </div>

        {addPayment ? (
          <LinkButton
            className={
              'h-[3.125rem] self-center rounded-[28px] px-8 text-ds-md [&_>_.Button-content]:font-bold'
            }
            variant="primary"
            to={`/collection/${publicCollection.id}/manage`}
          >
            Back to Collection Manager
          </LinkButton>
        ) : (
          <div className="flex shrink-0 grow basis-auto flex-col gap-9 self-stretch bg-teal-80 p-14 sm:p-16">
            <WebUI.Text className="text-center font-accentAlt text-ds-md sm:text-ds-xl">
              Cheddar Up is the go-to for groups
            </WebUI.Text>

            {media.sm ? (
              <div
                className={
                  'flex w-full max-w-[720px] flex-row justify-between gap-4 self-center [&_>_.Stack]:shrink-0 [&_>_.Stack]:grow [&_>_.Stack]:basis-[0%] [&_>_.Stack]:items-center [&_>_.Stack]:justify-center [&_>_.Stack_>_.Text]:font-bold [&_>_.Stack_>_.Text]:text-ds-sm [&_>_.Stack_>_div]:flex [&_>_.Stack_>_div]:h-[100px] [&_>_.Stack_>_div]:items-center [&_>_.Stack_>_div]:justify-center'
                }
              >
                <div className="flex flex-col gap-2">
                  <div>
                    <img className="h-[100px]" src={CardCircleIcon} alt="" />
                  </div>
                  <WebUI.Text>Frictionless Payments</WebUI.Text>
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <img className="h-[62px]" src={ProfileCirclesIcon} alt="" />
                  </div>
                  <WebUI.Text>Instant Tracking</WebUI.Text>
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <img className="h-18" src={FormAndSignupIcon} alt="" />
                  </div>
                  <WebUI.Text>Forms + Sign Ups</WebUI.Text>
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <img className="h-17" src={PhoneCardIcon} alt="" />
                  </div>
                  <WebUI.Text>Point of Sale</WebUI.Text>
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-6 self-center">
                <img className="h-[100px]" src={CardTabPencilIcon} alt="" />
                <ul className="list-disc font-normal text-ds-sm">
                  <li>Frictionless Payments</li>
                  <li>Instant Tracking</li>
                  <li>Customer Forms</li>
                  <li>Point of Sale</li>
                </ul>
              </div>
            )}

            <WebUI.Button
              className={
                'h-[3.125rem] self-center rounded-[28px] px-8 text-ds-md [&_>_.Button-content]:font-bold'
              }
              variant="primary"
              as={WebUI.Anchor}
              href={config.links.marketingPage}
              rel="noopener noreferrer"
            >
              FREE for your group
            </WebUI.Button>
          </div>
        )}
      </div>

      <WebUI.IconButton
        className="fixed top-4 right-4 text-8xl hover:text-gray800 sm:top-10 sm:right-10"
        size="default_alt"
        {...(addPayment && publicCollection.userManagesCollection
          ? ({
              as: Link,
              to: `/collection/${publicCollection.id}/manage`,
            } as any)
          : ({
              as: WebUI.Anchor,
              rel: 'noopener noreferrer',
              href: config.links.marketingPage,
            } as any))}
      >
        <WebUI.PhosphorIcon icon="x-bold" />
      </WebUI.IconButton>
    </div>
  )
}

export default CheckoutThankYouPage
