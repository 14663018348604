import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {Link} from 'src/components/Link'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {SharpImage} from 'src/components/SharpImage'
import CollectionPlaceholderIcon from 'src/images/CollectionPlaceHolderIcon.svg'

export interface MarketplaceSalesItemProps
  extends Omit<WebUI.DisclosureProps, 'children'> {
  organizer: Api.MarketplaceOrganizer & {
    collections?: Api.Tab[] | Api.MarketplaceCollection[]
  }
}

const MarketplaceSalesItem = React.memo(
  ({organizer, ...restProps}: MarketplaceSalesItemProps) => {
    const collectionsCount = Array.isArray(organizer.collections)
      ? organizer.collections.length
      : 0

    return (
      <WebUI.Disclosure {...restProps}>
        {(disclosure) => (
          <>
            <WebUI.HStack
              aria-expanded={disclosure.visible}
              className={
                'h-[88px] w-full items-center justify-between rounded bg-trueWhite p-8 shadow-z2 aria-expanded:bg-teal-80'
              }
            >
              <WebUI.HStack className="gap-4">
                <SharpAvatar
                  size={48}
                  image={organizer.profilePic}
                  name={organizer.display_name}
                />
                <WebUI.VStack className="justify-around">
                  <WebUI.Ellipsis className="font-accent text-ds-base italic">
                    {organizer.display_name ||
                      `${organizer.first_name} ${organizer.last_name}`}
                  </WebUI.Ellipsis>
                  <div className="text-ds-sm text-gray400">
                    {collectionsCount} Sale{collectionsCount === 1 ? '' : 's'}
                  </div>
                </WebUI.VStack>
              </WebUI.HStack>
              <WebUI.DisclosureButton
                className="text-ds-sm"
                variant="link"
                arrow={false}
              >
                {disclosure.visible ? 'Close' : 'View'}
              </WebUI.DisclosureButton>
            </WebUI.HStack>
            <WebUI.DisclosureContent className="bg-trueWhite">
              <WebUI.VStack className="gap-4">
                {organizer.collections?.map((collection) => (
                  <MarketplaceSalesItemCollectionItem
                    key={collection.id}
                    collection={collection}
                  />
                ))}
              </WebUI.VStack>
            </WebUI.DisclosureContent>
          </>
        )}
      </WebUI.Disclosure>
    )
  },
)

// MARK: – MarketplaceSalesItemCollectionItem

interface MarketplaceSalesItemCollectionItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.MarketplaceCollection | Api.Tab
}

const MarketplaceSalesItemCollectionItem = React.memo(
  ({
    collection,
    className,
    ...restProps
  }: MarketplaceSalesItemCollectionItemProps) => {
    const hasItemCount = 'itemCount' in collection && collection.itemCount

    return (
      <Link
        className="block"
        target="_blank"
        to={`/c/${collection.slug}?access-code=${collection.access_code}`}
      >
        <WebUI.VStack
          className={WebUI.cn(
            'gap-8 bg-trueWhite p-4 sm:flex-row sm:p-8',
            className,
          )}
          {...restProps}
        >
          <WebUI.HStack
            className={WebUI.cn(
              'relative h-[6rem] w-[12rem] items-center justify-center',
              !collection.featured_image && 'bg-teal-80',
            )}
          >
            <SharpImage
              className={WebUI.cn(
                'flex items-center justify-center',
                !collection.featured_image && 'bg-teal-80',
              )}
              width={192}
              height={96}
              alt={`${collection.name} header`}
              image={collection.featured_image}
              errorFallback={
                <img
                  className="h-3/4"
                  src={CollectionPlaceholderIcon}
                  alt="collection's banner"
                />
              }
            />
          </WebUI.HStack>
          <WebUI.VStack className="justify-center gap-2">
            <WebUI.Ellipsis
              className={WebUI.cn(
                'font-semibold text-ds-base',
                hasItemCount && 'text-teal-600',
              )}
            >
              {collection.name}
            </WebUI.Ellipsis>
            <Link
              className="text-ds-base"
              variant={hasItemCount ? 'default' : 'primary'}
              target="_blank"
              to={
                hasItemCount
                  ? `/c/${collection.slug}?access-code=${collection.access_code}`
                  : `/collection/${collection.id}/manage?mrId=${collection.user_id}`
              }
            >
              {hasItemCount
                ? `${collection.itemCount} item${
                    collection.itemCount === 1 ? '' : 's'
                  }`
                : 'Manage'}
            </Link>
          </WebUI.VStack>
        </WebUI.VStack>
      </Link>
    )
  },
)

export default MarketplaceSalesItem
