import * as Yup from 'yup'
import {
  Radio as ReakitRadio,
  RadioGroup as ReakitRadioGroup,
  useRadioState,
} from 'reakit'
import * as WebUI from '@cheddarup/web-ui'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import React, {useRef} from 'react'
import config from 'src/config'
import CAFlagIcon from 'src/images/CAFlag.svg'
import USFlagIcon from 'src/images/USFlag.svg'
import {useSignup} from 'src/hooks/useAuth'

export interface SignUpFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onReset' | 'onSubmit'> {
  orgSlug: string
  orgMetadata: Api.OrgMetadata
  countrySelectDisplayed?: boolean
  submitButtonColor: string
  contentColor: string
}

const SignUpForm = ({
  orgSlug,
  orgMetadata,
  countrySelectDisplayed,
  submitButtonColor,
  contentColor,
  ...restProps
}: SignUpFormProps) => {
  const location = useLocation()
  const growlActions = WebUI.useGrowlActions()
  const recaptchaRef = useRef<WebUI.RecaptchaInstance>(null)
  const [signup] = useSignup({recaptchaRef})

  const queryParams = queryString.parse(location.search)
  const defaultBestDescribesYou: string = orgMetadata?.type ?? ''

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Invalid format'),
      password: Yup.string()
        .required('Required')
        .min(6, 'Your password must be at least 6 characters.')
        .matches(/\d/, 'Password must contain at least 1 number'),
      bestDescribesYou: Yup.string().required('Required'),
      country: countrySelectDisplayed
        ? Yup.string().nullable().required('Required')
        : Yup.string().nullable(),
      organizationIdentifier: Yup.object({
        fieldName:
          orgMetadata?.organizationIdentifier?.enabled &&
          orgMetadata.organizationIdentifier.required
            ? Yup.string().required()
            : Yup.string(),
        fieldValue:
          orgMetadata?.organizationIdentifier?.enabled &&
          orgMetadata.organizationIdentifier.required
            ? Yup.string().required()
            : Yup.string(),
      }),
    }),
    initialValues: {
      first_name:
        typeof queryParams.first_name === 'string'
          ? queryParams.first_name
          : '',
      last_name:
        typeof queryParams.last_name === 'string' ? queryParams.last_name : '',
      email: typeof queryParams.email === 'string' ? queryParams.email : '',
      country: orgMetadata?.country,
      password: '',
      bestDescribesYou: defaultBestDescribesYou,
      organizationIdentifier: {
        enabled: false,
        required: false,
        fieldName: '',
        fieldValue: '',
      },
    },
    onSubmit: async (values) => {
      try {
        const res = await signup({
          partner: orgSlug,
          display_name:
            values.organizationIdentifier.fieldValue && orgMetadata
              ? `${orgMetadata.name ?? ''} ‒ ${
                  values.organizationIdentifier.fieldName
                } ${values.organizationIdentifier.fieldValue}`
              : undefined,
          invite_code: queryParams.invite_code,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          country: values.country ?? undefined,
          password: values.password,
          profile: {
            bestDescribesYou:
              defaultBestDescribesYou || values.bestDescribesYou,
            referrer: {
              url: queryString.parse(window.location.search),
            },
          },
        })

        if (!res) {
          return
        }
      } catch (err) {
        if (err instanceof Error) {
          growlActions.show('error', {
            title: 'Error',
            body: err.message,
          })
        }
      }
    },
  })
  return (
    <WebUI.Form
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.FormFieldGroup>
        <WebUI.FormField error={formik.errors.first_name}>
          <WebUI.Input
            className="grow"
            name="first_name"
            placeholder="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField error={formik.errors.last_name}>
          <WebUI.Input
            className="grow"
            name="last_name"
            placeholder="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>
      {orgMetadata?.organizationIdentifier?.enabled && (
        <WebUI.FormFieldGroup>
          <WebUI.FormField
            error={formik.errors.organizationIdentifier?.fieldName}
          >
            <WebUI.DropdownSelect
              name="organizationIdentifier.fieldName"
              placeholder="Unit Type"
              value={formik.values.organizationIdentifier.fieldName}
              onValueChange={(newValue) =>
                formik.setFieldValue(
                  'organizationIdentifier.fieldName',
                  newValue,
                )
              }
              onBlur={formik.handleBlur}
            >
              {orgMetadata.organizationIdentifier.options.map((option) => (
                <WebUI.DropdownSelectOption key={option} value={option} />
              ))}
            </WebUI.DropdownSelect>
          </WebUI.FormField>
          <WebUI.FormField
            error={formik.errors.organizationIdentifier?.fieldValue}
          >
            <WebUI.Input
              name="organizationIdentifier.fieldValue"
              placeholder="Troop or Service Unit Number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        </WebUI.FormFieldGroup>
      )}
      <WebUI.FormField error={formik.errors.email}>
        <WebUI.Input
          name="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField error={formik.errors.password}>
        <WebUI.Input
          type="password"
          name="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      {!defaultBestDescribesYou && (
        <WebUI.FormField error={formik.errors.bestDescribesYou}>
          <WebUI.Select
            className="[&_>_.Select-select]:min-h-10"
            name="bestDescribesYou"
            value={formik.values.bestDescribesYou}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">What best describes you?</option>
            {Object.values(ORG_TYPES).map((ot) => (
              <option key={ot.value} value={ot.value}>
                {ot.label}
              </option>
            ))}
          </WebUI.Select>
        </WebUI.FormField>
      )}
      <WebUI.VStack className="items-center gap-9">
        {countrySelectDisplayed && (
          <WebUI.FormField
            style={{color: contentColor}}
            label="Choose your currency"
            error={formik.errors.country}
          >
            <CountryRadioGroup
              name="country"
              state={formik.values.country}
              onChange={(event: any) =>
                formik.setFieldValue('country', event.target.value)
              }
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        )}
        <WebUI.Button
          className="w-[240px]"
          style={{backgroundColor: submitButtonColor}}
          type="submit"
          size="large"
          loading={formik.isSubmitting}
        >
          Get Started
        </WebUI.Button>
        {config.isProd && <WebUI.Recaptcha ref={recaptchaRef} />}
      </WebUI.VStack>
    </WebUI.Form>
  )
}

// MARK: – CountryRadioGroup

const CountryRadioGroup = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof ReakitRadioGroup>
>(({state, className, ...restProps}, forwardedRef) => {
  const radio = useRadioState({state})
  return (
    <ReakitRadioGroup
      ref={forwardedRef}
      aria-label="Country radio group"
      className={WebUI.cn(
        'CountryRadioGroup-radioGroup justify-center gap-4',
        className,
      )}
      as={WebUI.HStack}
      {...radio}
      {...restProps}
    >
      <RadioFlag value="United States" {...radio} />
      <RadioFlag value="Canada" {...radio} />
    </ReakitRadioGroup>
  )
})

// MARK: – FlagButton

const RadioFlag = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<typeof ReakitRadio>
>(({value, className, onMouseDown, ...restProps}, forwardedRef) => {
  const ownRef = useRef<HTMLInputElement>(null)
  const ref = useForkRef(ownRef, forwardedRef)

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'RadioFlag relative inline-flex cursor-pointer items-center',
        className,
      )}
      onMouseDown={onMouseDown}
      onClick={() => ownRef.current?.click()}
    >
      <ReakitRadio
        ref={ref}
        className="RadioFlag-input absolute z-[-1] h-px w-px overflow-hidden opacity-0"
        value={value}
        {...restProps}
      />
      <div
        className={`RadioFlag-icon ml-0 h-14 w-14 rounded-full shadow-[inset_0_0_0_1px_theme(colors.grey.300)] transition-shadow duration-100 ease-in-out [.RadioFlag-input:disabled_~_&]:cursor-not-allowed [.RadioFlag-input:disabled_~_&]:opacity-50 [.RadioFlag-input:focus[data-focus-visible-added]_~_&]:shadow-[0_0_0_1px_theme(colors.accent300)] [.RadioFlag-input[aria-checked="true"]_~_&]:shadow-[0_0_0_1px_theme(colors.teal.600)] [.RadioFlag-input[aria-disabled="true"]_~_&]:cursor-not-allowed [.RadioFlag-input[aria-disabled="true"]_~_&]:opacity-50 [.RadioFlag:hover_>_&]:shadow-[0_0_0_1px_theme(colors.teal.80)]`}
      >
        <img
          className="RadioFlag-icon"
          src={value === 'Canada' ? CAFlagIcon : USFlagIcon}
          alt={value === 'Canada' ? 'Canada flag' : 'US flag'}
        />
      </div>
    </WebUI.HStack>
  )
})

// MARK: – Helpers

const ORG_TYPES = {
  clubAssociation: {
    label: 'Club / Association',
    value: 'Club / Association',
  },
  individual: {
    label: 'Individual',
    value: 'Individual',
  },
  other: {
    label: 'Other',
    value: 'Other',
  },
  schoolPta: {
    label: 'School / PTA',
    value: 'School / PTA',
  },
  seller: {
    label: 'Seller',
    value: 'Seller',
  },
  sportsTeamLeague: {
    label: 'Sports Team / League',
    value: 'Sports Team / League',
  },
  unknown: {
    label: 'Unknown',
    value: 'Unknown',
  },
}

export default SignUpForm
