import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import React, {useState} from 'react'
import ImagesUtils from 'src/helpers/ImagesUtils'

const getListingImageIdx = (
  listing: Api.VariantListing,
  orderedImages: Api.S3Image[],
) => {
  const listingImageIdx = orderedImages.findIndex(
    ({id}) => id === listing.imageId,
  )

  return listingImageIdx === -1 ? 0 : listingImageIdx
}

export interface ItemViewImageGalleryProps
  extends React.ComponentPropsWithoutRef<'div'> {
  listing: Api.VariantListing | null
  images: Api.S3Image[]
  onImageLoadError?: React.ReactEventHandler<HTMLImageElement>
}

const ItemViewImageGallery = ({
  listing,
  images,
  className,
  onImageLoadError,
  ...restProps
}: ItemViewImageGalleryProps) => {
  const orderedImages = ImagesUtils.sortImages(images)
  const [selectedImageIdx, setSelectedImageIdx] = useState(
    listing ? getListingImageIdx(listing, orderedImages) : 0,
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    if (listing) {
      setSelectedImageIdx(getListingImageIdx(listing, orderedImages))
    }
  }, [listing])

  // biome-ignore lint/style/noNonNullAssertion:
  const selectedImage = orderedImages[selectedImageIdx]!

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.ImageMagnifier
        className="[@media_(pointer:coarse)]:pointer-events-none"
        width="100%"
        mgWidth={200}
        mgHeight={200}
        src={ImagesUtils.getCroppedImageUrl(selectedImage)}
        onError={onImageLoadError}
      />
      {orderedImages.length > 1 && (
        <div
          className="before:table after:clear-both after:table"
          style={{zoom: 1}}
        >
          {orderedImages.map((image, idx) => (
            <WebUI.Button
              key={image.id}
              aria-selected={selectedImageIdx === idx}
              className={
                'float-left inline w-1/3 p-2 transition-colors duration-150 ease-in-out hover:bg-grey-200 focus:bg-grey-200 aria-selected:bg-grey-200'
              }
              variant="text"
              onClick={() => setSelectedImageIdx(idx)}
            >
              <img
                className="block w-full object-contain"
                alt="Item thumbnail"
                src={ImagesUtils.getCroppedImageUrl(image, {
                  width: 200,
                  height: 200,
                })}
              />
            </WebUI.Button>
          ))}
        </div>
      )}
    </WebUI.VStack>
  )
}

export default ItemViewImageGallery
