import * as WebUI from '@cheddarup/web-ui'
import {useRef} from 'react'
import {AuthBackground} from 'src/components/AuthBackground'
import {ParammedTabs} from 'src/components/ParammedTabs'
import AppStoreFakeReviewWidget from 'src/images/AppStoreFakeReviewWidget.svg'

import {SignUpPageHelmet} from '../components'
import {Logo} from '../../../components'
import {Link} from '../../../components/Link'
import EntityTypeForm, {type EntityTypeFormValues} from './EntityTypeForm'
import CustomerCategoryForm, {
  type CustomerCategoryFormValues,
} from './CustomerCategoryForm'
import AccountDetailsForm from './AccountDetailsForm'
import config from 'src/config'

const SignUpPage = () => {
  const [signUpData, setSignUpData] = WebUI.useSessionStorage<
    EntityTypeFormValues & CustomerCategoryFormValues
  >('signupData', {
    entityType: null,
    customerCategory: '',
  })

  const tabsRef = useRef<WebUI.TabsInstance>(null)

  return (
    <WebUI.HStack className="h-screen bg-trueWhite">
      <AuthBackground className="hidden max-w-screen-sm shrink grow lg:block">
        <SignUpPageHelmet />

        <WebUI.VStack className="gap-4">
          <WebUI.Heading className="font-accentAlt text-h-1">
            Collect payments, forms, and sign ups for your group
          </WebUI.Heading>
          <WebUI.Text className="max-w-md text-ds-lg">
            Join the over 100,000 group organizers that use Cheddar Up to make
            life easy
          </WebUI.Text>
          <WebUI.HStack className="gap-2">
            <WebUI.Anchor
              href="https://www.capterra.com/p/276323/Cheddar-Up/reviews/"
              target="_blank"
            >
              <WebUI.Image
                width="110px"
                height="auto"
                alt="capterra reviews"
                src="https://assets.capterra.com/badge/dfa5eca7e5fb02de4f01aefc3f58c958.svg?v=2224873&p=276323"
              />
            </WebUI.Anchor>
            <WebUI.Anchor
              href="https://apps.apple.com/id/app/cheddar-up/id1141129202"
              target="_blank"
            >
              <WebUI.Image
                width="130px"
                height="auto"
                alt="app store reviews"
                src={AppStoreFakeReviewWidget}
              />
            </WebUI.Anchor>
          </WebUI.HStack>
        </WebUI.VStack>
      </AuthBackground>
      <WebUI.VStack className="relative block flex-1 justify-between overflow-y-auto p-7 pt-0 sm:justify-center sm:p-24">
        <WebUI.HStack className="-mx-7 mb-6 bg-grey-200 px-7 py-5 sm:hidden">
          <WebUI.Anchor href={config.links.marketingPage} target="_blank">
            <Logo
              className="gap-2 self-start"
              forceDisplayText
              iconVariant="flat"
            />
          </WebUI.Anchor>
        </WebUI.HStack>
        <WebUI.Text className="top-20 right-20 text-center font-light sm:absolute [&_>_.Anchor]:text-ds-base">
          Already have an account?{' '}
          <Link variant="primary" preserveSearch to="/login">
            Sign in
          </Link>
        </WebUI.Text>
        <ParammedTabs
          paneKeyName="signupStep"
          defaultPaneKey={
            signUpData.customerCategory
              ? 'account-details'
              : signUpData.entityType
                ? 'customer-category'
                : 'entity-type'
          }
          orientation="horizontal"
          variant="stepper"
          ref={tabsRef}
          className="mt-6 sm:mt-24"
        >
          {(tabs) => (
            <>
              <div className="flex flex-col gap-5">
                <WebUI.Text className="hidden sm:block">
                  Step{' '}
                  {tabs.items.findIndex((t) => t.id === tabs.selectedId) + 1} of{' '}
                  {tabs.items.length}
                </WebUI.Text>
                <WebUI.TabList className="top-20 left-6 mb-2 border-none sm:absolute sm:left-24 sm:mb-0">
                  <WebUI.Tab id="entity-type" />
                  <WebUI.Tab id="customer-category" />
                  <WebUI.Tab id="account-details" />
                </WebUI.TabList>
              </div>
              <WebUI.TabPanel tabId="entity-type">
                <EntityTypeForm
                  className="max-w-2xl"
                  initialValues={signUpData}
                  onSubmit={(newValues) => {
                    setSignUpData({...signUpData, ...newValues})
                    tabs.setSelectedId('customer-category')
                  }}
                />
              </WebUI.TabPanel>

              <WebUI.TabPanel tabId="customer-category">
                <CustomerCategoryForm
                  initialValues={signUpData}
                  onSubmit={(newValues) => {
                    setSignUpData({...signUpData, ...newValues})
                    tabs.setSelectedId('account-details')
                  }}
                />
              </WebUI.TabPanel>
              <WebUI.TabPanel tabId="account-details">
                <AccountDetailsForm values={signUpData} className="max-w-2xl" />
              </WebUI.TabPanel>
            </>
          )}
        </ParammedTabs>
      </WebUI.VStack>
    </WebUI.HStack>
  )
}

export default SignUpPage
