import UpgradePlanContainer from './components/UpgradePlanContainer'

const ProPlanPage = () => (
  <UpgradePlanContainer
    fieldPanels={[
      {
        title: 'Pro Annual',
        description: '$120 charged annually (equal to only $10/month)',
        value: 'pro_annual',
        price: 120,
        discountBadge: (
          <div className="text-ds-sm">
            <span className="font-bold text-orange-500">BEST VALUE!</span> Save
            33% with this plan (that's basically{' '}
            <span className="whitespace-nowrap font-semibold">4 months</span>{' '}
            free!). Use it all year round!
          </div>
        ),
      },
      {
        title: 'Pro Monthly',
        description: '$15 charged monthly',
        value: 'pro_monthly',
        price: 15,
      },
    ]}
    heading="The Pro Plan"
    subheading="The Cheddar Up Pro plan is paid annually or monthly. We make it simple to adjust your plan any time from My Account."
  />
)

export default ProPlanPage
