import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import React from 'react'
import {api} from '@cheddarup/api-client'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import {AbsoluteLinkButton} from 'src/components/LinkButton'
import * as Util from '@cheddarup/util'

export const ManagerAccounts = (
  props: React.ComponentPropsWithoutRef<'button'>,
) => {
  const [managerRole, setManagerRoleId] = useManagerRole()
  const sessionQuery = api.auth.session.useQuery()

  if (!sessionQuery.data || sessionQuery.data.manager_roles.length === 0) {
    return null
  }

  const personalAccountProfileItem = (
    <AccountProfileItem
      isPersonal
      managerRole={{
        id: sessionQuery.data.user.id,
        name: sessionQuery.data.user.display_name ?? '',
        profile_pic: sessionQuery.data.user.profile_pic ?? null,
      }}
    />
  )

  return (
    <WebUI.Menu>
      <WebUI.MenuButton
        className="w-full justify-between p-4"
        variant="text"
        iconAfter={<WebUI.PhosphorIcon icon="caret-down" width={20} />}
        {...props}
      >
        {managerRole ? (
          <AccountProfileItem managerRole={managerRole} />
        ) : (
          personalAccountProfileItem
        )}
      </WebUI.MenuButton>
      <WebUI.MenuList
        className="[&_>_.MenuList-inner_>_.MenuList-body_>_.MenuItem]:min-h-15"
        strictWidth
      >
        <div className="px-4 py-1 font-normal text-ds-sm uppercase">
          Switch To
        </div>
        {sessionQuery.data.manager_roles
          .filter((mr) => mr.id !== managerRole?.id)
          .map((mr) => (
            <WebUI.MenuItem key={mr.id} onClick={() => setManagerRoleId(mr.id)}>
              <AccountProfileItem managerRole={mr} />
            </WebUI.MenuItem>
          ))}
        {!!managerRole && (
          <WebUI.MenuItem onClick={() => setManagerRoleId(null)}>
            {personalAccountProfileItem}
          </WebUI.MenuItem>
        )}
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

// MARK: - AccountProfileItem

interface AccountProfileItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  isPersonal?: boolean
  managerRole: Pick<Api.ManagerRole, 'id' | 'name' | 'profile_pic'>
  showInUserMenu?: boolean
  showPlan?: boolean
  plan?: string
}

export const AccountProfileItem: React.FC<AccountProfileItemProps> = ({
  isPersonal = false,
  showInUserMenu = false,
  showPlan = false,
  plan = 'free',
  managerRole,
  className,
  ...restProps
}: AccountProfileItemProps) => (
  <WebUI.HStack
    className={WebUI.cn(
      'AccountProfileItem relative items-center gap-4 overflow-hidden',
      className,
    )}
    {...restProps}
  >
    <SharpAvatar
      className={WebUI.cn(
        'AccountProfileItem-avatar flex-0',
        !isPersonal && '[&_.Avatar-label]:bg-teal-600',
      )}
      image={managerRole.profile_pic}
      name={managerRole.name}
      size={showInUserMenu ? 30 : 40}
    />
    <WebUI.VStack className="AccountProfileItem-content min-w-0 gap-0_5">
      <WebUI.Ellipsis className="font-bold text-ds-sm">
        {managerRole.name}
      </WebUI.Ellipsis>
      <span className="font-light text-ds-sm">
        {isPersonal ? 'Personal Account' : 'Manager Account'}
      </span>
    </WebUI.VStack>
    {isPersonal && showPlan && (
      <AbsoluteLinkButton
        variant={
          ({team: 'secondary', pro: 'secondaryAlt', free: 'default'}[plan] ??
            'default') as any
        }
        style={{
          color:
            plan === 'free'
              ? tailwindConfig.theme.colors.trueWhite
              : tailwindConfig.theme.colors.teal[600],
          opacity: 'inherit',
        }}
        iconBefore={plan !== 'free' && <WebUI.PhosphorIcon icon="star" />}
        disabled={plan === 'team'}
        size="compact"
        to={plan === 'team' ? '#' : 'i/plans'}
      >
        {Util.capitalize(plan === 'free' ? 'basic' : plan)}
      </AbsoluteLinkButton>
    )}
  </WebUI.HStack>
)
