import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import CartHelpers from 'src/helpers/CartHelpers'
import * as Util from '@cheddarup/util'

import usePublicCollection from '../../hooks/usePublicCollection'
import useCart from '../../hooks/useCart'
import {CartInfoContainer} from '../containers'
import {usePayerUIState} from '../../PayerUIStateProvider'
import {PayerBrandKitColors} from '@cheddarup/core'
import CartIcon from './CartIcon'

export interface PayerCartPopoverProps {
  brandKitColors?: PayerBrandKitColors
}

export const PayerCartPopover: React.FC<PayerCartPopoverProps> = ({
  brandKitColors,
}) => {
  const media = WebUI.useMedia()
  const payerUIState = usePayerUIState()

  if (media.sm) {
    return (
      <WebUI.Popover
        placement="bottom-end"
        visible={payerUIState.cartVisible}
        gutter={16}
        onVisibleChange={(newIsVisible) =>
          payerUIState.setCartVisible(newIsVisible)
        }
      >
        <WebUI.PopoverDisclosure
          as={CartButton}
          cartIconStyles={{fill: brandKitColors?.primaryButton}}
        />

        <WebUI.PopoverContent
          aria-label="Cart overview"
          className="[&_>_.PopoverContent-inner]:w-[360px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:rounded-extended [&_>_.PopoverContent-inner_>_.PopoverContent-body]:bg-transparent [&_>_.PopoverContent-inner_>_.PopoverContent-body]:shadow-z3"
          fullWidth={false}
        >
          <CartInfoContainer />
        </WebUI.PopoverContent>
      </WebUI.Popover>
    )
  }

  return (
    <WebUI.Modal
      aria-label="Cart overview"
      contentViewAppearance="rightToLeft"
      visible={payerUIState.cartVisible}
      disclosure={
        <WebUI.DialogDisclosure
          as={CartButton}
          cartIconStyles={{fill: brandKitColors?.primaryButton}}
        />
      }
      onVisibleChange={(newIsVisible) =>
        payerUIState.setCartVisible(newIsVisible)
      }
    >
      <CartInfoContainer />
    </WebUI.Modal>
  )
}

// MARK: – CartButton

const CartButton = React.forwardRef<
  HTMLButtonElement,
  WebUI.ButtonProps &
    React.ComponentPropsWithoutRef<'button'> & {
      cartIconStyles?: React.CSSProperties
    }
>(
  (
    {variant = 'ghost', className, cartIconStyles, ...restProps},
    forwardedRef,
  ) => {
    const {publicCollection} = usePublicCollection()
    const {cart} = useCart()

    const recordsCount = CartHelpers.getRecordsCount(cart)

    return (
      <WebUI.Button
        ref={forwardedRef}
        className={WebUI.cn('px-1', className)}
        variant={variant}
        iconBefore={
          <CartIcon className="h-[2em] w-[2em]" style={cartIconStyles} />
        }
        {...restProps}
      >
        <div className="hidden sm:block">
          <span className="font-light">{recordsCount}</span>
          {publicCollection.items.length > 0 && (
            <>
              <span>&nbsp;/&nbsp;</span>
              <span className="font-semibold">
                {Util.formatAmount(cart?.subtotal ?? 0)}
              </span>
            </>
          )}
        </div>

        <div className="block sm:hidden">
          {recordsCount > 0 && (
            <div className="absolute top-0 right-0 flex h-6 w-6 items-center justify-center rounded-full bg-orange-500">
              <span className="text-center font-light text-trueWhite">
                {recordsCount}
              </span>
            </div>
          )}
        </div>
      </WebUI.Button>
    )
  },
)
