import {
  endOfDay,
  endOfYesterday,
  minDate,
  parseCalendarDate,
  startOfDay,
} from '@cheddarup/util'
import {generic, useUpdateEffect} from '@cheddarup/react-util'
import React, {useContext, useImperativeHandle, useMemo, useState} from 'react'
import {CalendarDate, getLocalTimeZone, today} from '@internationalized/date'

import {PhosphorIcon} from '../icons'
import {AmountInput} from './AmountInput'
import {Button} from './Button'
import {Checkbox} from './Checkbox'
import {DatePicker, DatePickerProps} from './DatePicker'
import {
  Disclosure,
  DisclosureCheckbox,
  DisclosureCheckboxProps,
  DisclosureContent,
} from './Disclosure'
import {
  DropdownSelect,
  DropdownSelectOption,
  DropdownSelectProps,
} from './DropdownSelect'
import {HStack, VStack} from './Stack'
import {Input, InputProps} from './Input'
import {Panel} from './Panel'
import {Radio, RadioGroup} from './Radio'
import {Text} from './Text'
import {cn} from '../utils'

export type FilterDataType =
  | 'number'
  | 'currency'
  | 'date'
  | 'radio'
  | 'checkbox'
  | 'boolean'
  | 'string'

export type FilterFieldOperator =
  | 'equals'
  | 'lt'
  | 'gt'
  | 'lte'
  | 'gte'
  | 'inDateRange'
  | 'beforeDate'
  | 'afterDate'
  | 'between'
  | 'dateEquals'
  | 'notEquals'
  | 'contains'
  | 'notContains'
  | 'startsWith'
  | 'endsWith'

export interface FilterEnumValue {
  title: string
  value: string
}

export interface FilterField {
  operator: FilterFieldOperator
  values: string[]
}

interface FiltersInternalContextValue {
  filters: Record<string, FilterField>
  getFilterById: (id: string) => FilterField | undefined
  setFilter: (id: string, newFilter: FilterField) => void
}

const FiltersInternalContext = React.createContext(
  {} as FiltersInternalContextValue,
)

// MARK: – Filters

export interface FiltersInstance extends FiltersInternalContextValue {}

export interface FiltersProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  filters: Record<string, FilterField>
  onFiltersApply?: (newFilters: Record<string, FilterField>) => void
  children: React.ReactNode | ((instance: FiltersInstance) => React.ReactNode)
}

export const Filters = React.forwardRef<FiltersInstance, FiltersProps>(
  (
    {filters: filtersProp, onFiltersApply, children, ...restProps},
    forwardedRef,
  ) => {
    const [filters, setFilters] = useState(filtersProp)

    useUpdateEffect(() => {
      if (filtersProp != null) {
        setFilters(filtersProp)
      }
    }, [filtersProp])

    const contextValue: FiltersInternalContextValue = useMemo(
      () => ({
        filters,
        getFilterById: (id) => filters[id],
        setFilter: (id, newFilter) =>
          setFilters((prevFilters) => ({...prevFilters, [id]: newFilter})),
      }),
      [filters],
    )

    useImperativeHandle(forwardedRef, () => contextValue, [contextValue])

    return (
      <FiltersInternalContext.Provider value={contextValue}>
        <VStack as={Panel} {...restProps}>
          <HStack
            className={'h-16 items-center justify-between gap-2 border-b px-5'}
          >
            <Button
              size="compact"
              variant="secondary"
              onClick={() => {
                setFilters({})
                onFiltersApply?.({})
              }}
            >
              Clear
            </Button>
            <Text className="text-ds-sm">Filters</Text>
            <Button size="compact" onClick={() => onFiltersApply?.(filters)}>
              Done
            </Button>
          </HStack>

          <VStack className="gap-4 p-5">
            {typeof children === 'function' ? children(contextValue) : children}
          </VStack>
        </VStack>
      </FiltersInternalContext.Provider>
    )
  },
)

// MARK: – FilterFieldEntry

export interface FilterFieldEntryProps<
  TFilterDataType extends FilterDataType = FilterDataType,
> extends DisclosureCheckboxProps {
  filterId: string
  dataType: TFilterDataType
  enumValues?: TFilterDataType extends 'radio'
    ? FilterEnumValue[]
    : TFilterDataType extends 'checkbox'
      ? FilterEnumValue[]
      : never
}

export const FilterFieldEntry = generic(
  <TFilterDataType extends FilterDataType = FilterDataType>({
    filterId,
    dataType,
    enumValues,
    children,
    ...restProps
  }: FilterFieldEntryProps<TFilterDataType>) => {
    const filters = useContext(FiltersInternalContext)

    const defaultOperatorForDataType = ({
      date: 'dateEquals',
    }[dataType as string] ?? 'equals') as FilterFieldOperator

    const filter = filters.getFilterById(filterId) ?? {
      operator: defaultOperatorForDataType,
      values: [],
    }

    return (
      <Disclosure
        className="gap-4"
        initialVisible={filter.values.length > 0}
        onVisibleChange={(newVisible) => {
          if (!newVisible) {
            filters.setFilter(filterId, {...filter, values: []})
          }
        }}
      >
        <DisclosureCheckbox size="compact" {...restProps}>
          {children}
        </DisclosureCheckbox>

        <DisclosureContent>
          <VStack className="gap-3">
            <FilterOperatorSelect
              dataType={dataType}
              operator={filter.operator}
              onOperatorChange={(newOperator) => {
                if (newOperator) {
                  filters.setFilter(filterId, {
                    ...filter,
                    operator: newOperator,
                  })
                }
              }}
            />
            <FilterValuesInput<TFilterDataType>
              filter={filter}
              dataType={dataType}
              enumValues={enumValues}
              onValuesChange={(newValues) =>
                filters.setFilter(filterId, {...filter, values: newValues})
              }
            />
          </VStack>
        </DisclosureContent>
      </Disclosure>
    )
  },
)

// MARK: – FilterOperatorSelect

interface FilterOperatorSelectProps
  extends DropdownSelectProps<FilterFieldOperator> {
  dataType: FilterDataType
  operator?: FilterFieldOperator
  onOperatorChange?: (newOperator: FilterFieldOperator | undefined) => void
}

export const FilterOperatorSelect = ({
  dataType,
  operator,
  onOperatorChange,
  ...restProps
}: FilterOperatorSelectProps) => {
  switch (dataType) {
    case 'number':
    case 'currency':
    case 'date':
      return (
        <DropdownSelect<FilterFieldOperator>
          size="compact"
          value={operator}
          onValueChange={onOperatorChange}
          {...restProps}
        >
          {(() => {
            switch (dataType) {
              case 'currency':
              case 'number':
                return (
                  <>
                    <DropdownSelectOption value="equals">
                      is exactly
                    </DropdownSelectOption>
                    <DropdownSelectOption value="between">
                      is between
                    </DropdownSelectOption>
                    <DropdownSelectOption value="lt">
                      is less than
                    </DropdownSelectOption>
                    <DropdownSelectOption value="gt">
                      is greater than
                    </DropdownSelectOption>
                  </>
                )
              case 'date':
                return (
                  <>
                    <DropdownSelectOption value="dateEquals">
                      is equal to
                    </DropdownSelectOption>
                    <DropdownSelectOption value="inDateRange">
                      is between
                    </DropdownSelectOption>
                    <DropdownSelectOption value="afterDate">
                      is after
                    </DropdownSelectOption>
                    <DropdownSelectOption value="beforeDate">
                      is before
                    </DropdownSelectOption>
                  </>
                )
            }
          })()}
        </DropdownSelect>
      )
    case 'string':
      return (
        <DropdownSelect<FilterFieldOperator>
          size="compact"
          value={operator}
          onValueChange={onOperatorChange}
          {...restProps}
        >
          <DropdownSelectOption value="equals">equals</DropdownSelectOption>
          <DropdownSelectOption value="notEquals">
            does not equal
          </DropdownSelectOption>
          <DropdownSelectOption value="contains">contains</DropdownSelectOption>
          <DropdownSelectOption value="notContains">
            does not contain
          </DropdownSelectOption>
          <DropdownSelectOption value="startsWith">
            starts with
          </DropdownSelectOption>
          <DropdownSelectOption value="endsWith">
            ends with
          </DropdownSelectOption>
        </DropdownSelect>
      )
    default:
      return null
  }
}

// MARK: – FilterValuesInput

interface FilterValuesInputProps<TFilterDataType extends FilterDataType>
  extends React.ComponentPropsWithoutRef<'div'> {
  filter: FilterField
  dataType: TFilterDataType
  enumValues?: TFilterDataType extends 'radio'
    ? FilterEnumValue[]
    : TFilterDataType extends 'checkbox'
      ? FilterEnumValue[]
      : never
  onValuesChange: (newValues: string[]) => void
}

export const FilterValuesInput = generic(
  <TFilterDataType extends FilterDataType>({
    filter,
    dataType,
    enumValues,
    onValuesChange,
    className,
    ...restProps
  }: FilterValuesInputProps<TFilterDataType>) => {
    switch (dataType) {
      case 'number':
      case 'currency':
        return (
          <HStack
            className={cn(className, 'items-center gap-3')}
            {...restProps}
          >
            <PhosphorIcon
              className="text-ds-md text-teal-600"
              icon="arrow-bend-down-right"
            />
            {(() => {
              switch (filter.operator) {
                case 'equals':
                case 'lt':
                case 'lte':
                case 'gt':
                case 'gte':
                  return (
                    <FilterInput
                      dataType={dataType}
                      value={filter.values[0]}
                      onValueChange={(newValue) => onValuesChange([newValue])}
                    />
                  )
                case 'between':
                  return (
                    <>
                      <FilterInput
                        dataType={dataType}
                        value={filter.values[0]}
                        onValueChange={(newValue) =>
                          onValuesChange([newValue, filter.values[1] ?? ''])
                        }
                      />
                      <Text className="text-ds-sm">and</Text>
                      <FilterInput
                        dataType={dataType}
                        value={filter.values[1]}
                        onValueChange={(newValue) =>
                          onValuesChange([filter.values[0] ?? '', newValue])
                        }
                      />
                    </>
                  )
                default:
                  return null
              }
            })()}
          </HStack>
        )
      case 'date':
        return (
          <HStack
            className={cn('items-center gap-3', className)}
            {...restProps}
          >
            <PhosphorIcon
              className="text-ds-md text-teal-600"
              icon="arrow-bend-down-right"
            />
            {(() => {
              switch (filter.operator) {
                case 'dateEquals':
                case 'afterDate':
                case 'beforeDate':
                  return (
                    <FilterDatePicker
                      value={
                        filter.values[filter.operator === 'beforeDate' ? 1 : 0]
                      }
                      onValueChange={(newValue) => {
                        let filterValues: [] | [string, string] = []

                        if (newValue && filter.operator === 'dateEquals') {
                          filterValues = [
                            startOfDay(new Date(newValue)).toISOString(),
                            endOfDay(new Date(newValue)).toISOString(),
                          ]
                        }
                        if (newValue && filter.operator === 'afterDate') {
                          filterValues = [
                            startOfDay(new Date(newValue)).toISOString(),
                            endOfYesterday().toISOString(),
                          ]
                        }
                        if (newValue && filter.operator === 'beforeDate') {
                          filterValues = [
                            startOfDay(new Date(1970, 1, 31)).toISOString(), // 1970 must be good enough
                            endOfDay(new Date(newValue)).toISOString(),
                          ]
                        }
                        onValuesChange(filterValues)
                      }}
                    />
                  )
                case 'inDateRange':
                  return (
                    <>
                      <FilterDatePicker
                        value={filter.values[0]}
                        onValueChange={(newValue) =>
                          onValuesChange(
                            newValue
                              ? [newValue, filter.values[1] ?? '']
                              : ['', filter.values[1] ?? ''],
                          )
                        }
                      />
                      <FilterDatePicker
                        value={filter.values[1]}
                        onValueChange={(newValue) =>
                          onValuesChange(
                            newValue
                              ? [filter.values[0] ?? '', newValue]
                              : [filter.values[0] ?? '', ''],
                          )
                        }
                      />
                    </>
                  )
                default:
                  return null
              }
            })()}
          </HStack>
        )
      case 'radio':
      case 'checkbox':
        return (
          <VStack className={cn('ml-4 gap-4', className)} {...restProps}>
            {(() => {
              switch (dataType) {
                case 'radio':
                  return (
                    <RadioGroup
                      className="[&_>_.Radio]:text-gray800 [&_>_.Radio_>_.Radio-text]:font-light"
                      size="compact"
                      variant="secondary"
                      state={filter.values[0]}
                      onChange={(event) => onValuesChange([event.target.value])}
                    >
                      {(enumValues as FilterEnumValue[]).map((ev) => (
                        <Radio key={ev.value} value={ev.value}>
                          {ev.title}
                        </Radio>
                      ))}
                    </RadioGroup>
                  )
                case 'checkbox':
                  return (enumValues as FilterEnumValue[]).map((ev) => (
                    <Checkbox
                      key={ev.value}
                      size="compact"
                      checked={filter.values.includes(ev.value)}
                      onChange={(event) =>
                        onValuesChange(
                          event.target.checked
                            ? [...filter.values, ev.value]
                            : filter.values.filter((v) => v !== ev.value),
                        )
                      }
                    >
                      {ev.title}
                    </Checkbox>
                  ))
                default:
                  return null
              }
            })()}
          </VStack>
        )
      case 'boolean':
        return (
          <VStack className={cn('ml-4 gap-4', className)} {...restProps}>
            <Checkbox
              size="compact"
              checked={filter.values[0] === 'true'}
              onChange={(event) =>
                onValuesChange([event.target.checked ? 'true' : 'false'])
              }
            >
              {filter.operator === 'equals' ? 'Is true' : 'Is false'}
            </Checkbox>
          </VStack>
        )
      case 'string':
        return (
          <HStack
            className={cn(className, 'items-center gap-3')}
            {...restProps}
          >
            <PhosphorIcon
              className="text-ds-md text-teal-600"
              icon="arrow-bend-down-right"
            />
            {(() => {
              switch (filter.operator) {
                case 'equals':
                case 'notEquals':
                case 'contains':
                case 'notContains':
                case 'startsWith':
                case 'endsWith':
                  return (
                    <FilterInput
                      dataType={dataType}
                      value={filter.values[0]}
                      onValueChange={(newValue) => onValuesChange([newValue])}
                    />
                  )
                default:
                  return null
              }
            })()}
          </HStack>
        )
      default:
        return null
    }
  },
)

// MARK: – FilterInput

interface FilterInputProps
  extends InputProps,
    Omit<
      React.ComponentPropsWithoutRef<'input'>,
      'value' | 'defaultValue' | 'size' | 'step'
    > {
  dataType: FilterDataType
  value?: string
  onValueChange?: (newValue: string) => void
}

const FilterInput = ({
  className,
  dataType,
  value,
  onValueChange,
  ...restProps
}: FilterInputProps) => {
  const commonProps = {
    className: 'w-[84px]',
    size: 'compact' as const,
    ...restProps,
  }

  switch (dataType) {
    case 'currency':
      return (
        <AmountInput
          {...commonProps}
          value={value}
          onValueChange={(newAmount) => onValueChange?.(newAmount ?? '')}
        />
      )
    default:
      return (
        <Input
          {...commonProps}
          value={value ? String(value) : value}
          onChange={(event) => onValueChange?.(event.target.value)}
        />
      )
  }
}

// MARK: – FilterDatePicker

interface FilterDatePickerProps
  extends Omit<
    DatePickerProps<CalendarDate>,
    'defaultValue' | 'value' | 'onValueChange'
  > {
  defaultValue?: string
  value?: string | null
  onValueChange?: (newValue: string) => void
}

const FilterDatePicker = ({
  defaultValue,
  value,
  onValueChange,
  ...restProps
}: FilterDatePickerProps) => (
  <DatePicker
    size="compact"
    maxValue={today(getLocalTimeZone())}
    defaultValue={
      defaultValue == null ? defaultValue : parseCalendarDate(defaultValue)
    }
    value={value == null ? value : parseCalendarDate(value)}
    onValueChange={(newDate) =>
      onValueChange?.(
        minDate([newDate.toDate(getLocalTimeZone()), new Date()]).toISOString(),
      )
    }
    {...restProps}
  />
)
