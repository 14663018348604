import {debounce} from '@cheddarup/util'
import {
  ForwardRefComponent,
  genericForwardRef,
  useForkRef,
  useLiveRef,
  useMemoValue,
  useUpdateEffect,
} from '@cheddarup/react-util'
import {UseSelectProps, UseSelectReturnValue, useSelect} from 'downshift'
import flattenChildren from 'react-keyed-flatten-children'
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import {PhosphorIcon} from '../icons'
import {Button} from './Button'
import {Ellipsis} from './Ellipsis'
import {HStack, VStack} from './Stack'
import {Loader} from './Loader'
import {
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverInstance,
} from './Popover'
import {Select, SelectProps} from './Select'
import {cn} from '../utils'

export type DropdownSelectAnyValue = string | number | null

export interface SelectItem<TValue extends DropdownSelectAnyValue> {
  value: TValue
  displayTitle: React.ReactNode
}

interface InternalDropdownSelectContextValue<
  TValue extends DropdownSelectAnyValue = DropdownSelectAnyValue,
> extends UseSelectReturnValue<SelectItem<TValue>>,
    Pick<SelectProps, 'disabledVariant'> {
  size: DropdownSelectSize
  variant: DropdownSelectVariant
  disabled?: boolean
  placeholder: string
  items: Array<SelectItem<TValue>>
  setItems: React.Dispatch<React.SetStateAction<Array<SelectItem<TValue>>>>
  toggleButtonRef: React.Ref<'button'>
  selectedValues?: string[]
}

const InternalDropdownSelectContext = React.createContext(
  {} as InternalDropdownSelectContextValue,
)

// MARK: – DropdownSelect

export type DropdownSelectSize = 'default' | 'compact' | 'small'
export type DropdownSelectVariant = 'default' | 'secondary' | 'ghost'

export interface DropdownSelectInstance<TValue extends DropdownSelectAnyValue> {
  select: UseSelectReturnValue<SelectItem<TValue>>
  items: Array<SelectItem<TValue>>
  toggleButtonRef: React.Ref<'button'>
}

export interface DropdownSelectProps<
  TValue extends DropdownSelectAnyValue = DropdownSelectAnyValue,
> extends DropdownSelectButtonProps,
    Omit<React.ComponentPropsWithoutRef<'button'>, 'defaultValue' | 'value'>,
    Pick<SelectProps, 'disabledVariant'> {
  popoverClassName?: string
  listClassName?: string
  size?: DropdownSelectSize
  variant?: DropdownSelectVariant
  placeholder?: string
  defaultValue?: TValue
  value?: TValue | null
  selectedValues?: TValue[]
  disclosure?: React.ReactElement
  onValueChange?: (newValue: TValue | undefined) => void
  onStateChange?: UseSelectProps<SelectItem<TValue>>['onStateChange']
  stateReducer?: UseSelectProps<SelectItem<TValue>>['stateReducer']
}

export const DropdownSelect = genericForwardRef(
  <TValue extends DropdownSelectAnyValue>(
    {
      popoverClassName,
      listClassName,
      placeholder = 'Select',
      defaultValue,
      value,
      selectedValues,
      onValueChange,
      onStateChange,
      stateReducer = (_state, actionAndChanges) => actionAndChanges.changes,
      size = 'default',
      variant = 'default',
      disabledVariant = 'default',
      disabled,
      disclosure = <DropdownSelectButton />,
      children,
      ...restProps
    }: DropdownSelectProps<TValue> & {
      ref?: React.Ref<DropdownSelectInstance<TValue>>
    },
    forwardedRef: React.Ref<DropdownSelectInstance<TValue>>,
  ) => {
    const toggleButtonRef = useRef(null)
    const [items, setItems] = useState<Array<SelectItem<TValue>>>([])
    const onValueChangeRef = useLiveRef(onValueChange)

    const defaultSelectedItem = useMemo(
      () =>
        defaultValue == null
          ? undefined
          : (items.find((i) => i.value === defaultValue) ?? null),
      [defaultValue, items],
    )
    const selectedItem = useMemo(
      () =>
        value == null
          ? (value as null | undefined)
          : (items.find((i) => i.value === value) ?? null),
      [items, value],
    )

    // HACK: on touch devices, `onSelectedItemChange` is called twice
    const debouncedOnValueChange = useMemo(
      () =>
        debounce(
          (newValue: TValue | undefined) =>
            onValueChangeRef.current?.(newValue),
          200,
        ),
      [],
    )

    const select = useSelect({
      items,
      defaultSelectedItem,
      selectedItem,
      stateReducer,
      onSelectedItemChange: (change) =>
        debouncedOnValueChange(change.selectedItem?.value),
      onStateChange,
    })

    const selectItemRef = useLiveRef(select.selectItem)
    const selectedItemRef = useLiveRef(select.selectedItem)
    useUpdateEffect(() => {
      if (defaultSelectedItem && !selectedItemRef.current) {
        selectItemRef.current(defaultSelectedItem)
      }
    }, [defaultSelectedItem])

    useImperativeHandle(
      forwardedRef,
      () => ({select, items, toggleButtonRef}),
      [items, select],
    )

    const internalContextValue = useMemo(
      () =>
        ({
          ...select,
          selectedValues,
          size,
          variant,
          disabledVariant,
          disabled,
          placeholder,
          items,
          setItems,
          toggleButtonRef,
        }) as InternalDropdownSelectContextValue<TValue>,
      [
        select,
        selectedValues,
        size,
        variant,
        disabledVariant,
        disabled,
        placeholder,
        items,
      ],
    )

    return (
      <InternalDropdownSelectContext.Provider
        value={internalContextValue as any}
      >
        {React.cloneElement(disclosure, {...restProps, ...disclosure.props})}

        <DropdownSelectList
          className={listClassName}
          popoverClassName={popoverClassName}
        >
          {children}
        </DropdownSelectList>
      </InternalDropdownSelectContext.Provider>
    )
  },
)

// MARK: – DropdownSelectText

export interface DropdownSelectTextProps extends DropdownSelectProps<string> {}

export const DropdownSelectText = React.forwardRef<
  DropdownSelectInstance<any>,
  DropdownSelectTextProps
>((props, forwardedRef) => (
  <DropdownSelect
    ref={forwardedRef}
    size="compact"
    disclosure={
      <DropdownSelectButton
        className="text-ds-sm [&_>_.DropdownSelectButton-content]:mr-0"
        as={Button}
        variant="text"
        iconAfter={<PhosphorIcon icon="caret-down-fill" />}
      />
    }
    {...props}
  />
))

// MARK: – DropdownSelectButton

export interface DropdownSelectButtonProps {
  loading?: boolean
}

export const DropdownSelectButton = React.forwardRef(
  (
    {as: Comp = Select, loading, className, children, ...restProps},
    forwardedRef,
  ) => {
    const {
      size,
      variant,
      disabledVariant,
      disabled,
      placeholder,
      getToggleButtonProps,
      toggleButtonRef,
      selectedItem,
    } = useContext(InternalDropdownSelectContext)
    return (
      <Comp
        data-placeholder-visible={!selectedItem}
        data-loading={loading}
        className={cn(
          'DropdownSelectButton',
          `DropdownSelectButton-${variant}-${size}`,
          '[&_>_.Select-select[data-loading=true]]:pointer-events-none [&_>_.Select-select[data-loading=true]_>_:not(.DropdownSelectButton-spinner)]:opacity-0 [&_>_.Select-select[data-loading=true]_>_:not(.DropdownSelectButton-spinner)]:transition-opacity [&_>_.Select-select[data-loading=true]_>_:not(.DropdownSelectButton-spinner)]:duration-100 [&_>_.Select-select[data-loading=true]_>_:not(.DropdownSelectButton-spinner)]:ease-in-out [&_>_.Select-select]:inline-flex [&_>_.Select-select]:min-w-0 [&_>_.Select-select]:appearance-none [&_>_.Select-select]:items-center [&_>_.Select-select]:text-left',
          size === 'default' &&
            '[&_>_.Select-select]:min-h-10 [&_>_.Select-select_>_.DropdownSelectButton-content]:font-light [&_>_.Select-select_>_.DropdownSelectButton-content]:text-ds-base',
          size === 'compact' &&
            '[&_>_.Select-select]:h-9 [&_>_.Select-select_>_.DropdownSelectButton-content]:text-ds-sm',
          size === 'small' &&
            '[&_>_.Select-select]:h-[1.875rem] [&_>_.Select-select]:min-h-[1.875rem] [&_>_.Select-select_>_.DropdownSelectButton-content]:text-ds-xs',
          variant === 'default' &&
            '[&_>_.Select-select[data-placeholder-visible=true]]:text-inputPlaceholder',
          variant === 'secondary' && '[&_>_.Select-select]:bg-grey-200',
          variant === 'ghost' &&
            '[&_>_.Select-select:focus]:shadow-none [&_>_.Select-select]:shadow-none',
          className,
        )}
        as="button"
        type="button"
        size={size === 'small' ? 'compact' : size}
        disabledVariant={disabledVariant}
        {...getToggleButtonProps({
          ref: useForkRef(toggleButtonRef, forwardedRef),
          disabled,
        })}
        {...(restProps as any)}
      >
        {loading && (
          <HStack
            className={
              'DropdownSelectButton-spinner -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-6 items-center'
            }
          >
            <Loader size="1.75em" />
          </HStack>
        )}

        <Ellipsis
          className={cn(
            'DropdownSelectButton-content leading-[24px]',
            Comp === Select ? 'mr-5' : 'mr-0',
            size === 'default' && 'font-light text-ds-base',
            size === 'compact' && 'text-ds-sm',
            size === 'small' && 'text-ds-xs',
          )}
          aria-hidden={loading}
        >
          {
            children ?? selectedItem?.displayTitle ?? placeholder ?? <wbr /> // zero width space char: https://en.wikipedia.org/wiki/Zero-width_space
          }
        </Ellipsis>
      </Comp>
    )
  },
) as ForwardRefComponent<typeof Select, DropdownSelectButtonProps>

// MARK: – DropdownSelectList

interface DropdownSelectListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  popoverClassName?: string
}

const DropdownSelectList = React.forwardRef<
  HTMLDivElement,
  DropdownSelectListProps
>(({popoverClassName, children, className, ...restProps}, forwardedRef) => {
  const {getMenuProps, setItems} = useContext(InternalDropdownSelectContext)

  const newItems = useMemoValue(
    flattenChildren(children)
      .filter((child): child is React.ReactElement =>
        React.isValidElement(child),
      )
      .filter((el) => el.props.value !== undefined)
      .map((el) => ({
        value: el.props.value,
        displayTitle:
          el.props.displayTitle ??
          (typeof el.props.children === 'string'
            ? el.props.children
            : el.props.value),
      })),
  )

  useEffect(() => {
    setItems(newItems)
  }, [newItems, setItems])

  return (
    <DropdownSelectPopover
      {...getMenuProps(
        {
          ref: forwardedRef,
          className: popoverClassName,
          ...restProps,
        },
        {suppressRefError: true},
      )}
    >
      <VStack
        className={cn('DropdownSelectList w-full p-1 *:flex-0', className)}
      >
        {children}
      </VStack>
    </DropdownSelectPopover>
  )
})

// MARK: – DropdownSelectOption

export interface DropdownSelectOptionProps {
  value: DropdownSelectAnyValue
  displayTitle?: React.ReactNode
}

export const DropdownSelectOption = React.forwardRef(
  (
    {
      value,
      as: Comp = Button,
      size: sizeProp,
      variant = 'ghost',
      displayTitle,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {size, getItemProps, items, selectedItem, highlightedIndex} =
      useContext(InternalDropdownSelectContext)

    const item = items.find((i) => i.value === value)
    const index = items.findIndex((i) => i.value === value)

    if (!item || index === -1) {
      return null
    }

    return (
      <Comp
        className={cn(
          'DropdownSelectOption',
          'h-auto justify-start whitespace-normal rounded-none px-[0.5em] py-2 text-left font-light text-ds-base',
          'focus:focus-visible:bg-buttonGhostHoverBackground focus:focus-visible:shadow-none',
          '[&_>_.Button-iconBefore]:mr-[6px] [&_>_.Button-iconBefore]:text-[1.2em]',
          size === 'compact' && 'text-ds-sm',
          size === 'small' && 'text-ds-xs',
          highlightedIndex === index && 'bg-buttonGhostHoverBackground',
          selectedItem?.value === value && 'bg-teal-90',
          className,
        )}
        variant={variant}
        {...getItemProps({
          ref: forwardedRef,
          item,
          index,
          ...restProps,
        })}
      >
        {
          // HACK: avoiding Button's content styles
          ''
        }
        {children ?? value}
      </Comp>
    )
  },
) as ForwardRefComponent<typeof Button, DropdownSelectOptionProps>

// MARK: – DropdownSelectPopover

interface DropdownSelectPopoverProps
  extends PopoverContentProps,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {}

const DropdownSelectPopover = React.forwardRef<
  HTMLDivElement,
  DropdownSelectPopoverProps
>(({children, className, ...restProps}, forwardedRef) => {
  const {isOpen, toggleButtonRef} = useContext(InternalDropdownSelectContext)

  const popoverRef = useRef<PopoverInstance>(null)

  useEffect(() => {
    if (isOpen && popoverRef.current?.unstable_referenceRef) {
      // @ts-expect-error
      popoverRef.current.unstable_referenceRef.current = toggleButtonRef.current
    }
  }, [isOpen, toggleButtonRef])

  return (
    <Popover ref={popoverRef} placement="bottom-start" visible={isOpen}>
      <PopoverContent
        ref={forwardedRef}
        className={cn('DropdownSelectPopover-content', className)}
        aria-label="Dropdown select options"
        unstable_autoFocusOnShow={false}
        unstable_autoFocusOnHide={false}
        arrow={false}
        {...restProps}
      >
        {children}
      </PopoverContent>
    </Popover>
  )
})

export {useSelect}
