import {chunk} from '@cheddarup/util'
import React, {Fragment} from 'react'
import {
  OTPInput,
  OTPInputProps,
  SlotProps as PrimitiveSlotProps,
  RenderProps,
} from 'input-otp'

import {cn} from '../utils'
import {HStack} from './Stack'
import {Separator} from './Separator'

export type PinInputInstance = ReturnType<typeof OTPInput>
export interface PinInputProps
  extends Omit<OTPInputProps, 'render' | 'maxLength' | 'placeholder'> {
  maxLength?: number
  chunkSize?: number
}

export const PinInput = React.forwardRef<HTMLInputElement, PinInputProps>(
  (
    {
      maxLength = 6,
      chunkSize = 3,
      containerClassName,
      inputMode = 'numeric',
      autoComplete = 'one-time-code',
      noScriptCSSFallback,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <OTPInput
      ref={forwardedRef}
      containerClassName={cn(
        'PinInput-container group flex h-15 justify-center',
        containerClassName,
      )}
      maxLength={maxLength}
      noScriptCSSFallback={noScriptCSSFallback ?? undefined}
      inputMode={inputMode}
      autoComplete={autoComplete}
      render={
        (({slots}: RenderProps) => (
          <HStack className="relative inline-flex h-full w-full aria-disabled:pointer-events-none aria-disabled:opacity-50">
            {chunk(slots, chunkSize).map((slotsChunk, chunkIdx) => (
              <Fragment key={chunkIdx}>
                {chunkIdx > 0 && chunkIdx < slotsChunk.length && (
                  <Separator
                    className={cn(
                      'PinInput-cellsChunkSeparator',
                      '[&.PinInput-cellsChunkSeparator]:mx-2 [&.PinInput-cellsChunkSeparator]:w-[1.75em] [&.PinInput-cellsChunkSeparator]:self-center',
                    )}
                    variant="input"
                  />
                )}
                {slotsChunk.map((slotProps, idx) => (
                  <Slot
                    key={idx}
                    className="h-full shrink grow basis-0 bg-trueWhite first-of-type:rounded-l-default last-of-type:rounded-r-default"
                    {...slotProps}
                  />
                ))}
              </Fragment>
            ))}
          </HStack>
        )) as any
      }
      {...restProps}
    />
  ),
)

interface SlotProps
  extends PrimitiveSlotProps,
    React.ComponentPropsWithoutRef<'div'> {}

function Slot({
  isActive,
  className,
  char,
  hasFakeCaret,
  ...restProps
}: SlotProps) {
  return (
    <div
      data-active={isActive}
      className={cn(
        'PinInputCell',
        'relative flex cursor-text items-center justify-center font-body text-ds-md leading-[2] shadow-[inset_0_0_0_1px_theme(colors.grey.300)] transition-shadow duration-100 ease-in-out data-[active=true]:shadow-[inset_0_0_0_1px_theme(colors.teal.600)]',
        className,
      )}
      {...restProps}
    >
      {char !== null && <div>{char}</div>}
      {hasFakeCaret && <FakeCaret />}
    </div>
  )
}

function FakeCaret() {
  return (
    <div className="pointer-events-none absolute inset-0 flex animate-caret-blink items-center justify-center">
      <div className="h-8 w-px bg-trueBlack" />
    </div>
  )
}
