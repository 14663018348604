import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik, useInterval, useLiveRef} from '@cheddarup/react-util'
import React, {useEffect, useRef, useState} from 'react'
import {
  api,
  useSendEmailVerificationCodeMutation,
  useStartVerificationMutation,
  useUpdateUserMutation,
  useVerifyCodeMutation,
  useVerifyEmailVerificationCodeMutation,
} from '@cheddarup/api-client'
import {read2FAError} from 'src/helpers/error-formatting'
import * as Util from '@cheddarup/util'

const LazyBackUpCodePdfDocumentDownloadButton = React.lazy(() =>
  import('./BackUpCodePdfDocumentDownloadButton').then((module) => ({
    default: module.BackUpCodePdfDocumentDownloadButton,
  })),
)

interface PhoneNumberVerificationStepToPayload {
  'not-verified': Util.EmptyObject
  'email-verified': {
    emailToken: string
  }
  'verification-code': {
    phoneNumber: string
    via: 'sms' | 'call'
    emailToken: string
  }
  'backup-code': {
    backUpCode: string
  }
}

export type PhoneNumberVerificationStep =
  keyof PhoneNumberVerificationStepToPayload

interface PhoneNumberVerificationState<
  TStep extends PhoneNumberVerificationStep,
> {
  step: TStep
  payload: PhoneNumberVerificationStepToPayload[TStep]
}

// MARK: – PhoneNumberVerification

export interface PhoneNumberVerificationProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onStepChange?: (step: PhoneNumberVerificationStep) => void
  onToggleChange?: (toggle: boolean) => void
  errors?: {backupCode: boolean}
  hideInstructions?: boolean
}

export const PhoneNumberVerification = ({
  onStepChange,
  onToggleChange,
  errors,
  hideInstructions = false,
  ...restProps
}: PhoneNumberVerificationProps) => {
  const [phoneVerificationState, setPhoneVerificationState] = useState<
    PhoneNumberVerificationState<PhoneNumberVerificationStep>
  >({
    step: 'not-verified',
    payload: {},
  })
  const onStepChangeRef = useLiveRef(onStepChange)

  useEffect(() => {
    onStepChangeRef.current?.(phoneVerificationState.step)
  }, [phoneVerificationState.step])

  switch (phoneVerificationState.step) {
    case 'not-verified':
      return (
        <VerifyEmail
          onDidEmailVerify={(emailToken) => {
            setPhoneVerificationState({
              step: 'email-verified',
              payload: {
                emailToken,
              },
            })
          }}
          hideInstructions={hideInstructions}
          {...restProps}
        />
      )

    case 'email-verified': {
      const typedPhoneVerificationState =
        phoneVerificationState as PhoneNumberVerificationState<'email-verified'>
      const emailToken = typedPhoneVerificationState.payload.emailToken
      return (
        <VerifyPhoneNumber
          className="grow"
          emailToken={emailToken}
          hideInstructions={hideInstructions}
          onDidSubmit={(values) =>
            setPhoneVerificationState({
              step: 'verification-code',
              payload: {
                phoneNumber: values.phoneNumber,
                via: values.via,
                emailToken,
              },
            })
          }
          {...restProps}
        />
      )
    }
    case 'verification-code': {
      const typedPhoneVerificationState =
        phoneVerificationState as PhoneNumberVerificationState<'verification-code'>
      return (
        <ConfirmVerificationCode
          phoneNumber={typedPhoneVerificationState.payload.phoneNumber}
          via={typedPhoneVerificationState.payload.via}
          emailToken={typedPhoneVerificationState.payload.emailToken}
          defaultText={hideInstructions}
          onDidGetBackupCode={(backUpCode) =>
            setPhoneVerificationState({
              step: 'backup-code',
              payload: {backUpCode},
            })
          }
          {...restProps}
        />
      )
    }
    case 'backup-code': {
      const typedPhoneVerificationState =
        phoneVerificationState as PhoneNumberVerificationState<'backup-code'>
      return (
        <Backup
          hideInstructions={hideInstructions}
          backUpCode={typedPhoneVerificationState.payload.backUpCode}
          onToggleChange={onToggleChange}
          errors={errors}
          {...restProps}
        />
      )
    }
    default:
      return null
  }
}

// MARK: – VerifyEmail

interface EmailVerificationValues {
  emailVerificationCode: string
}

interface VerifyEmailProps extends React.ComponentPropsWithoutRef<'div'> {
  onDidEmailVerify?: (emailToken: string) => void
  hideInstructions?: boolean
}

const VerifyEmail = ({
  onDidEmailVerify,
  hideInstructions = false,
  className,
  ...restProps
}: VerifyEmailProps) => {
  const {data: session} = api.auth.session.useQuery()
  const emailVerificationAlertRef = useRef<WebUI.DialogInstance>(null)
  const sendEmailVerificationCodeMutation =
    useSendEmailVerificationCodeMutation()
  const verifyEmailVerificationCodeMutation =
    useVerifyEmailVerificationCodeMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik<EmailVerificationValues>({
    initialValues: {
      emailVerificationCode: '',
    },
    validationSchema: Yup.object({
      emailVerificationCode: Yup.string()
        .min(6, 'Verification code must be at least 6 characters')
        .required(),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await verifyEmailVerificationCodeMutation.mutateAsync({
          body: {
            code: values.emailVerificationCode,
          },
        })
        emailVerificationAlertRef.current?.hide()
        onDidEmailVerify?.(values.emailVerificationCode)
      } catch {
        formikHelpers.resetForm()

        growlActions.clear()
        growlActions.show('error', {
          title: 'Invalid verification code',
        })
      }
    },
  })

  const sendVerificationCode = async () => {
    try {
      await sendEmailVerificationCodeMutation.mutateAsync()
      emailVerificationAlertRef.current?.show()

      growlActions.clear()
      growlActions.show('success', {
        title: 'Code Sent',
        body: 'Verification code sent to your email address.',
      })
    } catch (err: any) {
      growlActions.clear()
      growlActions.show('error', {
        title: 'Error!',
        body:
          err.response?.data?.error ||
          err.message ||
          'Verification code was not sent. Please try again.',
      })
    }
  }

  return (
    <WebUI.VStack className={WebUI.cn('gap-6', className)} {...restProps}>
      {!hideInstructions && (
        <WebUI.Text className="font-light">
          We use two-factor authentication when key actions are taken on the
          platform.
          <br />
          <br />
          First, we'll need to verify the email address that you used to set up
          your account.
        </WebUI.Text>
      )}

      <WebUI.Button
        className="self-start"
        iconBefore={
          <WebUI.PhosphorIcon
            className="text-teal-500"
            icon="envelope"
            width={20}
          />
        }
        variant="secondary"
        loading={sendEmailVerificationCodeMutation.isPending}
        onClick={sendVerificationCode}
      >
        Send email verification code
      </WebUI.Button>

      <WebUI.Alert
        aria-label="Email Verification"
        ref={emailVerificationAlertRef}
        hideOnClickOutside={false}
      >
        <WebUI.AlertHeader>Enter Email Verification Code</WebUI.AlertHeader>

        <WebUI.VStack className="gap-4 p-7">
          <WebUI.Text className="font-light text-gray800">
            Enter the 6-digit alphanumeric code sent to {session?.user.email}.
            <br />
            Don't see the email ?{' '}
            <WebUI.Button
              variant="link"
              onClick={sendVerificationCode}
              loading={sendEmailVerificationCodeMutation.isPending}
            >
              Send a new code
            </WebUI.Button>
          </WebUI.Text>

          <WebUI.Form onSubmit={formik.handleSubmit}>
            <WebUI.FormField
              label="Enter Verification Code"
              error={formik.errors.emailVerificationCode}
            >
              <WebUI.Input
                name="emailVerificationCode"
                autoComplete="one-time-code"
                value={formik.values.emailVerificationCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-[280px]"
              />
            </WebUI.FormField>

            <WebUI.Button
              className="self-start"
              type="submit"
              variant="primary"
              loading={verifyEmailVerificationCodeMutation.isPending}
            >
              Verify
            </WebUI.Button>
          </WebUI.Form>
        </WebUI.VStack>
      </WebUI.Alert>
    </WebUI.VStack>
  )
}

// MARK: – VerifyPhoneNumber

interface VerifyPhoneNumberValues
  extends React.ComponentPropsWithoutRef<'div'> {
  phoneNumber: string
  via: 'sms' | 'call'
}

interface VerifyPhoneNumberProps extends React.ComponentPropsWithoutRef<'div'> {
  emailToken: string
  onDidSubmit?: (values: VerifyPhoneNumberValues) => void
  hideInstructions?: boolean
}

const VerifyPhoneNumber = ({
  emailToken,
  hideInstructions = false,
  onDidSubmit,
  className,
  ...restProps
}: VerifyPhoneNumberProps) => {
  const updateUserMutation = useUpdateUserMutation()
  const startVerificationMutation = useStartVerificationMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      via: '',
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required('Required'),
      via: Yup.string().required('Required'),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const parsedPhoneNumber = WebUI.parsePhoneNumber(values.phoneNumber)
        if (!parsedPhoneNumber) {
          throw new Error('Invalid phone number')
        }

        if (!emailToken) {
          throw new Error('Email token not found')
        }

        if (values.via !== 'sms' && values.via !== 'call' && !emailToken) {
          throw new Error('Something went wrong...')
        }

        const via = values.via as 'sms' | 'call'

        await updateUserMutation.mutateAsync({
          body: {
            profile: {
              phone: {
                country_code: parsedPhoneNumber.countryCallingCode as string,
                phone_number: parsedPhoneNumber.nationalNumber as string,
              },
            },
          },
        })
        await startVerificationMutation.mutateAsync({
          body: {via, security: {emailToken}},
        })

        onDidSubmit?.({...values, via})

        growlActions.clear()
        growlActions.show('success', {
          title: {sms: 'Code sent', call: 'Calling you now'}[values.via],
          body: {
            sms: 'Verification code sent to your mobile phone',
            call: 'Please answer your mobile phone to hear code',
          }[values.via],
        })
      } catch (err: any) {
        formikHelpers.setFieldValue('via', '')
        growlActions.clear()
        growlActions.show('error', {
          title: 'Error!',
          body:
            err.response?.data?.error ||
            err.message ||
            'Verification code was not sent. Please try again.',
        })
      }
    },
  })

  const handleViaButtonClick = async (via: 'sms' | 'call') => {
    if (formik.values.phoneNumber) {
      // TODO: replace with Yup validation
      if (WebUI.isPossiblePhoneNumber(formik.values.phoneNumber)) {
        await formik.setFieldValue('via', via)
        formik.submitForm()
      } else {
        formik.setErrors({phoneNumber: 'Invalid'})
      }
    }
  }

  return (
    <WebUI.VStack className={WebUI.cn('gap-6', className)} {...restProps}>
      {!hideInstructions && (
        <WebUI.Text className="font-light text-ds-md">
          Enter a phone number for receiving two-factor verifications below.
        </WebUI.Text>
      )}
      <WebUI.Form>
        <WebUI.FormField
          label={!hideInstructions && 'Two-Factor Authentication Phone Number'}
          error={formik.errors.phoneNumber}
        >
          <WebUI.PhoneInput
            autoFocus
            name="phoneNumber"
            placeholder="Enter your mobile phone number"
            maxLength={17}
            onChange={(newPhoneNumber) =>
              formik.setFieldValue('phoneNumber', newPhoneNumber)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        <WebUI.FormField label="Send verification codes via:">
          <WebUI.HStack className="gap-4">
            <WebUI.Button
              variant="secondary"
              disabled={formik.isSubmitting}
              loading={formik.values.via === 'sms' && formik.isSubmitting}
              type="button"
              iconBefore={
                <WebUI.PhosphorIcon
                  icon="chat-dots"
                  className="text-teal-600"
                  width={16}
                />
              }
              onClick={() => handleViaButtonClick('sms')}
            >
              Text Message
            </WebUI.Button>
            <WebUI.Button
              variant="secondary"
              disabled={formik.isSubmitting}
              loading={formik.values.via === 'call' && formik.isSubmitting}
              type="button"
              iconBefore={
                <WebUI.PhosphorIcon
                  icon="phone-call"
                  className="text-teal-600"
                  width={16}
                />
              }
              onClick={() => handleViaButtonClick('call')}
            >
              Phone Call
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.FormField>
      </WebUI.Form>
    </WebUI.VStack>
  )
}

// MARK: – ConfirmVerificationCode

interface ConfirmVerificationCodeProps
  extends React.ComponentPropsWithoutRef<'div'> {
  emailToken: string
  phoneNumber: string
  defaultText?: boolean
  via: 'sms' | 'call'
  onDidGetBackupCode?: (code: string) => void
}

const ConfirmVerificationCode = ({
  phoneNumber,
  via,
  emailToken,
  onDidGetBackupCode,
  defaultText = false,
  className,
  ...restProps
}: ConfirmVerificationCodeProps) => {
  const [secondsLeftToResend, setSecondsLeftToResend] = useState(45)
  const startVerificationMutation = useStartVerificationMutation()
  const verifyCodeMutation = useVerifyCodeMutation()
  const growlActions = WebUI.useGrowlActions()

  const VERIFICATION_CODE_LEN = 6

  const formik = useFormik({
    initialValues: {verificationCode: ''},
    validationSchema: Yup.object().shape({
      verificationCode: Yup.string()
        .required('Required')
        .length(
          VERIFICATION_CODE_LEN,
          `Verification code must be ${VERIFICATION_CODE_LEN} digit.`,
        ),
    }),
    onSubmit: async (values) => {
      try {
        const {reset_code: backupSecurityCode, success} =
          await verifyCodeMutation.mutateAsync({
            body: {token: values.verificationCode, security: {emailToken}},
          })
        if (success) {
          onDidGetBackupCode?.(backupSecurityCode)
        }
      } catch (err) {
        growlActions.clear()
        growlActions.show('error', {
          title: 'Error',
          body:
            read2FAError(err) || 'Invalid verification code. Please try again.',
        })
      }
    },
  })

  useInterval(() => {
    setSecondsLeftToResend((prev) => Math.max(prev - 1, 0))
  }, 1000)

  return (
    <WebUI.VStack className={WebUI.cn('gap-6', className)} {...restProps}>
      <WebUI.Text className={`${defaultText ? '' : 'text-ds-md'}font-light`}>
        We've sent a six-digit confirmation code to ***-***-**
        {phoneNumber?.slice(-2)}.
        <br />
        Once you receive it, enter the code below. It will expire shortly, so
        enter it soon.
      </WebUI.Text>

      <WebUI.Form onSubmit={formik.handleSubmit}>
        <WebUI.FormField
          label="Verification Code"
          error={formik.errors.verificationCode}
        >
          <WebUI.Input
            name="verificationCode"
            inputMode="numeric"
            autoComplete="one-time-code"
            className="w-1/2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            maxLength={VERIFICATION_CODE_LEN}
          />
        </WebUI.FormField>
        <div>
          <span className="mb-1 block font-normal text-ds-sm">
            It may take a minute to receive your new code. Haven't received it?
          </span>
          {secondsLeftToResend > 0 ? (
            <div className="cursor-not-allowed font-normal text-ds-sm text-teal-600">
              Seconds left to resend code: {secondsLeftToResend}
            </div>
          ) : (
            <WebUI.Button
              className="text-ds-xs"
              variant="link"
              onClick={async () => {
                setSecondsLeftToResend(45)
                await startVerificationMutation.mutateAsync({
                  body: {via, security: {emailToken}},
                })
                growlActions.clear()
                growlActions.show('success', {
                  title: 'Success',
                  body: 'Verification code sent to your phone',
                })
              }}
            >
              Resend a new code
            </WebUI.Button>
          )}
        </div>
        <WebUI.Button
          type="submit"
          className="self-start"
          variant="primary"
          loading={formik.isSubmitting}
        >
          Verify
        </WebUI.Button>
      </WebUI.Form>
    </WebUI.VStack>
  )
}

// MARK: – Backup

interface BackupProps extends React.ComponentPropsWithoutRef<'div'> {
  backUpCode: string
  onToggleChange?: (toggle: boolean) => void
  errors?: {backupCode: boolean}
  hideInstructions?: boolean
}

const Backup = ({
  backUpCode,
  onToggleChange,
  hideInstructions = false,
  errors,
  className,
  ...restProps
}: BackupProps) => {
  const growlActions = WebUI.useGrowlActions()

  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      const promptText = 'Are you sure you want to close this browser window?'
      event.returnValue = promptText
      return promptText
    }

    window.addEventListener('beforeunload', handleBeforeunload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [])

  return (
    <WebUI.VStack className={WebUI.cn('gap-6', className)} {...restProps}>
      {!hideInstructions && (
        <WebUI.Text className="font-light text-ds-md">
          Save your back-up code in a safe place.
        </WebUI.Text>
      )}
      <WebUI.VStack className="gap-4">
        {!hideInstructions && (
          <span className="font-medium text-ds-base">
            Your Back-Up Security Code
          </span>
        )}
        <WebUI.Text className={`${hideInstructions ? '' : 'text-ds-base'}`}>
          <span className="font-semibold text-orange-500">IMPORTANT:</span>{' '}
          <span className="font-light">
            This back-up security code is the ONLY way you’ll be able to recover
            your account for key actions on the platform if you do not have
            access to your phone or if the account owner may need to change at
            some point in the future. As such, it is imperative that you save
            this code in a safe place should you need it in the future.
          </span>
        </WebUI.Text>
        <div className="h-10 max-w-[340px] rounded bg-grey-200 p-2 font-light text-ds-base">
          {backUpCode}
        </div>
      </WebUI.VStack>
      <WebUI.HStack className="gap-4">
        <React.Suspense fallback={<div />}>
          <LazyBackUpCodePdfDocumentDownloadButton backUpCode={backUpCode}>
            Download
          </LazyBackUpCodePdfDocumentDownloadButton>
        </React.Suspense>
        <WebUI.Button
          size="compact"
          iconBefore={<WebUI.PhosphorIcon icon="copy" width={16} />}
          onClick={() => {
            WebUI.copyToClipboard(backUpCode)
            growlActions.show('success', {
              title: 'Success',
              body: 'Link copied',
            })
          }}
        >
          Copy
        </WebUI.Button>
      </WebUI.HStack>
      <WebUI.FormField error={errors?.backupCode && 'Required'}>
        <WebUI.Checkbox
          className="items-center"
          size="compact"
          onChange={(event) => onToggleChange?.(event.target.checked)}
        >
          <span>
            I have copied and saved this code.
            <span className="text-orange-500">*</span>
          </span>
        </WebUI.Checkbox>
      </WebUI.FormField>
    </WebUI.VStack>
  )
}
