import * as WebUI from '@cheddarup/web-ui'
import {useLocation, useParams} from 'react-router-dom'
import {useState} from 'react'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {useCubeQuery} from 'src/hooks/cube'
import {SharpImage} from 'src/components/SharpImage'
import SalesCollectionPlaceholderImage from 'src/images/SalesCollectionPlaceholderImage.svg'

import Visitors from './Visitors'
import Payments from './Payments'
import ReportingSharpAvatar from '../components/ReportingSharpAvatar'
import BalanceSummary from './BalanceSummary'

const SalesPagePage = () => {
  const location = useLocation()
  const uuid = location.state?.uuid ?? null
  const email = location.state?.email ?? null
  const {id} = useParams()
  const [detailView, setDetailView] = useState('payments')
  const collectionQuery = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: id!,
      },
      headers: {
        'User-Id': uuid,
      },
    },
    {
      enabled: !!id && !!uuid,
    },
  )
  const {resultSet: distributors} = useCubeQuery({
    dimensions: ['Users.profilePicturePath'],
    filters: [
      {
        member: 'Users.email',
        operator: 'equals',
        values: [`${email}`],
      },
    ],
    timeDimensions: [],
  })
  const distributor = distributors?.tablePivot()[0]

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <WebUI.Heading as="h2">
          {collectionQuery.data?.name ?? ''}
        </WebUI.Heading>
        <WebUI.Tag className="bg-teal-70 text-ds-xs">Active</WebUI.Tag>
      </WebUI.HStack>

      <WebUI.HStack className="items-start gap-4">
        <WebUI.VStack className="w-[320px]" as={WebUI.Panel}>
          <SharpImage
            width={320}
            height={123}
            image={collectionQuery.data?.featured_image}
            errorFallback={
              <img
                className="h-full"
                src={SalesCollectionPlaceholderImage}
                alt=""
              />
            }
            alt="Collection"
          />
          <WebUI.Separator variant="primary" />
          <WebUI.VStack className="gap-4 p-6">
            <WebUI.VStack className="gap-1">
              <div className="font-normal text-ds-md">
                {collectionQuery.data?.name}
              </div>
              <div className="font-normal text-ds-xs">
                {collectionQuery.data?.description}
              </div>
            </WebUI.VStack>
            <Link
              variant="primary"
              to={`/c/${collectionQuery.data?.slug}`}
              target="_blank"
              rel="noreferrer"
            >
              View Collection Page
            </Link>
          </WebUI.VStack>
          <WebUI.Separator variant="primary" />
          <WebUI.VStack className="gap-4 p-6">
            <WebUI.HStack className="items-center justify-between gap-3">
              <span className="grow font-normal text-ds-xs text-gray400">
                ORGANIZER
              </span>
              <WebUI.IconButton
                className="text-ds-lg"
                size="default_alt"
                as={LinkButton}
                target="_blank"
                to={`/reporting/collectors/${collectionQuery.data?.organizer?.uuid}`}
              >
                <WebUI.PhosphorIcon icon="dots-three-outline-fill" />
              </WebUI.IconButton>
            </WebUI.HStack>
            <WebUI.HStack className="items-center gap-4">
              <ReportingSharpAvatar
                size={36}
                image={distributor?.['Users.profilePicturePath'] as any}
              />
              <WebUI.VStack className="overflow-hidden">
                <WebUI.Ellipsis className="font-semibold text-ds-md">
                  {collectionQuery.data?.organizer?.name}
                </WebUI.Ellipsis>
                <WebUI.Ellipsis className="text-ds-sm text-gray400">
                  {collectionQuery.data?.organizer?.email}
                </WebUI.Ellipsis>
              </WebUI.VStack>
            </WebUI.HStack>
          </WebUI.VStack>
          <WebUI.Separator variant="primary" />
          <WebUI.VStack
            className={`p-4 [&_>_div[aria-selected="true"]]:border-teal-80 [&_>_div[aria-selected="true"]]:bg-teal-80 [&_>_div[aria-selected="true"]]:text-teal-600 [&_>_div]:flex [&_>_div]:h-10 [&_>_div]:cursor-pointer [&_>_div]:items-center [&_>_div]:justify-start [&_>_div]:rounded [&_>_div]:px-[15px] [&_>_div]:py-[6.4px] [&_>_div]:font-normal`}
          >
            <div
              aria-selected={detailView === 'payments'}
              onClick={() => setDetailView('payments')}
            >
              Payments
            </div>
            <div
              aria-selected={detailView === 'balance_summary'}
              onClick={() => setDetailView('balance_summary')}
            >
              Balance Summary
            </div>
            <div
              aria-selected={detailView === 'visitors'}
              onClick={() => setDetailView('visitors')}
            >
              Visitors
            </div>
          </WebUI.VStack>
        </WebUI.VStack>

        <div className="flex-[1]">
          {!!collectionQuery.data && detailView === 'payments' && (
            <Payments
              collection={collectionQuery.data}
              onShowBalanceSummary={() => setDetailView('balance_summary')}
            />
          )}
          {!!collectionQuery.data && detailView === 'balance_summary' && (
            <BalanceSummary collection={collectionQuery.data} />
          )}
          {detailView === 'visitors' && collectionQuery.data && (
            <Visitors collection={collectionQuery.data} />
          )}
        </div>
      </WebUI.HStack>
    </WebUI.VStack>
  )
}

export default SalesPagePage
