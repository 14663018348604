import {useParams} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {useDeleteCartFormMutation} from '@cheddarup/api-client'
import {SharpImage} from 'src/components/SharpImage'
import {Link} from 'src/components/Link'
import ImagesUtils from 'src/helpers/ImagesUtils'
import getCollectionFieldValueFormatted from 'src/helpers/getCollectionFieldValueFormatted'
import CartHelpers from 'src/helpers/CartHelpers'
import {
  getAttendeeEmail,
  getAttendeeName,
  getVisibleCartForms,
} from '@cheddarup/core'

import useCart from '../hooks/useCart'
import {usePayerUIState} from '../PayerUIStateProvider'
import {RecurringPaymentIndicator} from './RecurringPaymentIndicator'
import RemoveCartItemButton from './RemoveCartItemButton'

interface CartSignUp {
  signUp: Omit<Api.PublicTabSignup, 'visible_spots'>
  time_slots: Api.CheddarUpCartTimeSlot[]
}

const CartOverview = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const urlParams = useParams()
  const {cart} = useCart()
  const payerUIState = usePayerUIState()

  const cartSignUps = CartHelpers.getSignUps(cart)
  const isCartFormsOnly = !cart || cart.items.length === 0

  return (
    <WebUI.Panel
      className={WebUI.cn(
        'flex min-w-[320px] flex-col gap-4 overflow-y-auto px-8 py-8 sm:py-10',
        className,
      )}
      variant="capsule"
      {...restProps}
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <WebUI.Heading className="font-semibold" as="h3">
            {isCartFormsOnly ? 'Summary' : 'Order Summary'}
          </WebUI.Heading>
          {!isCartFormsOnly && (
            <div className="font-normal text-ds-sm">
              Subtotal:{' '}
              <span className="font-semibold">
                {Util.formatAmount(
                  (cart?.subtotal ?? 0) + (cart?.totalDiscount ?? 0),
                )}
              </span>
            </div>
          )}
        </div>
        <Link
          className="text-ds-sm"
          variant="primary"
          preserveSearch
          to="../items"
          onClick={() => payerUIState.setCartVisible(true)}
        >
          Edit Cart
        </Link>
      </div>

      <WebUI.Separator variant="primary" />

      {!!cart &&
        cart.items.some((i) => i.tab_item.available_quantity != null) && (
          <>
            <WebUI.Text className="text-ds-sm text-orange-500">
              Items with a limited quantity are only secured once checkout is
              complete.
            </WebUI.Text>
            <WebUI.Separator variant="primary" />
          </>
        )}

      {!!cart && cart.time_slots.length > 0 && (
        <>
          <WebUI.Text className="text-ds-sm text-orange-500">
            Sign ups with a limited quantity are only secured once checkout is
            complete.
          </WebUI.Text>
          <WebUI.Separator variant="primary" />
        </>
      )}

      <div className="flex flex-col gap-4">
        {cart?.items.map((cartItem) => (
          <CartOverviewItemRow
            key={cartItem.id}
            // biome-ignore lint/style/noNonNullAssertion:
            collectionSlug={urlParams.tabSlug!}
            cartItem={cartItem}
            cart={cart}
          />
        ))}
        {cart &&
          getVisibleCartForms(cart).map((cartForm) => (
            <CartOverviewFormRow
              key={cartForm.id}
              // biome-ignore lint/style/noNonNullAssertion:
              collectionSlug={urlParams.tabSlug!}
              cartUuid={cart.uuid}
              cartForm={cartForm}
            />
          ))}
        {!!cart &&
          cartSignUps.map((cartSignUp) => (
            <CartOverviewSignUpRow
              key={cartSignUp.signUp.id}
              // biome-ignore lint/style/noNonNullAssertion:
              collectionSlug={urlParams.tabSlug!}
              cartUuid={cart.uuid}
              cartSignUp={cartSignUp}
            />
          ))}
      </div>
    </WebUI.Panel>
  )
}

// MARK: – CartOverviewItemRow

interface CartOverviewItemRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  cart: Api.Cart
  cartItem: Api.CheddarUpCartItem
  collectionSlug: string
}

const CartOverviewItemRow = React.forwardRef<
  HTMLDivElement,
  CartOverviewItemRowProps
>(({collectionSlug, cart, cartItem, className, ...restProps}, forwardedRef) => (
  <WebUI.Card
    ref={forwardedRef}
    className={WebUI.cn('gap-3 p-4', className)}
    as={WebUI.VStack}
    {...restProps}
  >
    <WebUI.VStack className="gap-1">
      <WebUI.Heading className="font-accent" as="h5">
        {cartItem.tab_item.name}
      </WebUI.Heading>
      {cartItem.tab_item.required && (
        <WebUI.Text className="text-ds-sm text-orange-50">Required</WebUI.Text>
      )}

      <WebUI.HStack className="items-center justify-between gap-3 font-normal text-ds-sm">
        <WebUI.Text className="text-ds-sm">Qty: {cartItem.quantity}</WebUI.Text>
        <WebUI.Text className="text-right text-ds-sm">
          {cartItem.tab_item.amount_type === 'fixed' &&
            !!cartItem.tab_item.retailPrice &&
            cartItem.amount !== cartItem.tab_item.retailPrice && (
              <WebUI.Text className="mr-2 font-light text-gray400 line-through">
                {Util.formatAmount(
                  cartItem.tab_item.retailPrice * cartItem.quantity,
                )}
              </WebUI.Text>
            )}
          <span>{Util.formatAmount(cartItem.total)}</span>
        </WebUI.Text>
      </WebUI.HStack>
    </WebUI.VStack>

    <CartObjectDetailsDisclosure
      collectionSlug={collectionSlug}
      cartObject={cartItem}
    />

    {cartItem.tab_item.images.length > 0 && (
      <SharpImage
        className="h-auto w-auto"
        alt={cartItem.tab_item.name}
        width={70}
        height={70}
        errorFallback={null}
        image={ImagesUtils.getMainImage(
          cartItem.tab_item.images,
          cartItem.detail?.variant?.imageId,
        )}
      />
    )}
    {cartItem.tab_item.options.recurring?.enabled && (
      <RecurringPaymentIndicator />
    )}
    <RemoveCartItemButton
      cart={cart}
      cartItemId={cartItem.id}
      collectionSlug={collectionSlug}
      disabled={cartItem.tab_item.required}
    >
      {cartItem.tab_item.required ? 'Required' : 'Remove'}
    </RemoveCartItemButton>
  </WebUI.Card>
))

// MARK: – CartOverviewFormRow

interface CartOverviewFormRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionSlug: string
  cartUuid: string
  cartForm: Api.CheddarUpCartForm
}

export const CartOverviewFormRow = React.forwardRef<
  HTMLDivElement,
  CartOverviewFormRowProps
>(
  (
    {collectionSlug, cartUuid, cartForm, className, ...restProps},
    forwardedRef,
  ) => {
    const deleteCartFormMutation = useDeleteCartFormMutation()
    const isFormRequired =
      cartForm.tab_form.required || cartForm.detail.linked_item_id

    return (
      <WebUI.Card
        ref={forwardedRef}
        className={WebUI.cn('gap-3 bg-gray100 p-4', className)}
        as={WebUI.VStack}
        {...restProps}
      >
        <WebUI.Heading as="h4">
          {cartForm.detail.name ?? cartForm.tab_form.name}
        </WebUI.Heading>
        <CartObjectDetailsDisclosure
          collectionSlug={collectionSlug}
          cartObject={cartForm}
        />
        <WebUI.Button
          className="self-start"
          size="compact"
          variant="outlined"
          loading={deleteCartFormMutation.isPending}
          disabled={isFormRequired}
          onClick={() =>
            deleteCartFormMutation.mutate({
              pathParams: {
                tabId: collectionSlug,
                cartUuid,
                formId: cartForm.id,
              },
            })
          }
        >
          {isFormRequired ? 'Required' : 'Remove'}
        </WebUI.Button>
      </WebUI.Card>
    )
  },
)

interface CartOverviewSignUpRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionSlug: string
  cartUuid: string
  cartSignUp: CartSignUp
}

export const CartOverviewSignUpRow = React.forwardRef<
  HTMLDivElement,
  CartOverviewSignUpRowProps
>(
  (
    {collectionSlug, cartUuid, cartSignUp, className, ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Card
      ref={forwardedRef}
      className={WebUI.cn('gap-3 bg-gray100 p-4', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.Heading as="h4">{cartSignUp.signUp.name}</WebUI.Heading>

      <CartSignUpDisclosure
        collectionSlug={collectionSlug}
        cartSignUp={cartSignUp}
      />
    </WebUI.Card>
  ),
)

// MARK: – CartObjectDetailsDisclosure

export interface CartObjectDetailsDisclosureProps
  extends Omit<WebUI.DisclosureProps, 'children'> {
  collectionSlug: string
  maxVisibleFieldsCount?: number
  cartObject:
    | Api.CheddarUpCartItem
    | Api.CheddarUpCartForm
    | Api.CheddarUpCartTimeSlot
}

export const CartObjectDetailsDisclosure = React.forwardRef<
  WebUI.DisclosureInstance,
  CartObjectDetailsDisclosureProps
>(
  (
    {
      collectionSlug,
      maxVisibleFieldsCount = 10,
      cartObject,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const type =
      'tab_item' in cartObject
        ? 'item'
        : 'time_slot' in cartObject
          ? 'signup'
          : cartObject.cart_field_views.some(
                (cfv) => cfv.metadata.isWaiverField,
              )
            ? 'waiver'
            : 'form'
    const variantOptionValues =
      'tab_item' in cartObject
        ? Util.arrayFromObject(
            cartObject.detail.variant?.optionValues ?? {},
            (key, value) => ({
              key,
              value,
            }),
          )
        : []
    const timeSlotAdditionalValues =
      'time_slot' in cartObject
        ? cartObject.time_slot.spot.signup.options.signupType === 'schedule'
          ? [
              {
                key: 'Spot',
                value: cartObject.time_slot.spot.name,
              },
              {
                key: 'Date',
                value: Util.formatDate(
                  new Date(cartObject.time_slot.options.startTime ?? ''),
                  'EEE, MMM d yyyy',
                ),
              },
              {
                key: 'Time',
                value: `${Util.formatDate(
                  new Date(cartObject.time_slot.options.startTime ?? ''),
                  'hh:mm aaa',
                )} - ${Util.formatDate(
                  new Date(cartObject.time_slot.options.endTime ?? ''),
                  'hh:mm aaa',
                )}`,
              },
            ]
          : [
              {
                key: 'Spot',
                value: cartObject.time_slot.spot.name,
              },
            ]
        : []
    const totalFieldsCount =
      variantOptionValues.length +
      timeSlotAdditionalValues.length +
      cartObject.cart_field_views.length

    if (totalFieldsCount === 0) {
      return null
    }

    const visibleVariantOptionValues = variantOptionValues.slice(
      0,
      maxVisibleFieldsCount,
    )
    const visibleTimeSlotAdditionalValues = timeSlotAdditionalValues.slice(
      0,
      maxVisibleFieldsCount,
    )
    const visibleCartFieldViews = cartObject.cart_field_views.slice(
      0,
      maxVisibleFieldsCount,
    )

    const isTicket =
      'detail' in cartObject &&
      'itemType' in cartObject.detail &&
      cartObject.detail.itemType === 'ticket'

    return (
      <WebUI.Disclosure
        ref={forwardedRef}
        className={WebUI.cn('gap-3', className)}
        {...restProps}
      >
        <WebUI.DisclosureButton
          className="text-ds-sm"
          variant="text"
          iconBefore={
            <WebUI.PhosphorIcon
              className={`text-teal-600 transition-transform duration-100 ease-linear [[aria-expanded="true"]_.Button-iconBefore_>&]:rotate-90`}
              icon="caret-right-fill"
            />
          }
        >
          {
            {
              item: isTicket ? 'Ticket Details' : 'Item Details',
              form: 'Form Responses',
              signup: 'Signup Details',
              waiver: 'Waiver Responses',
            }[type]
          }
        </WebUI.DisclosureButton>
        <WebUI.DisclosureContent>
          <WebUI.VStack className="gap-3">
            <WebUI.VStack className="gap-1">
              {visibleVariantOptionValues.map((vov) => (
                <DefinitionList key={vov.key} title={vov.key}>
                  {vov.value}
                </DefinitionList>
              ))}
              {visibleTimeSlotAdditionalValues.map((vov) => (
                <DefinitionList key={vov.key} title={vov.key}>
                  {vov.value}
                </DefinitionList>
              ))}
              {isTicket && (
                <>
                  <DefinitionList title="Attendee">
                    {getAttendeeName(
                      cartObject.cart_field_views as Api.TabObjectFieldView[],
                    )}
                    ,{' '}
                    {getAttendeeEmail(
                      cartObject.cart_field_views as Api.TabObjectFieldView[],
                    )}
                  </DefinitionList>
                  {Util.sort(visibleCartFieldViews)
                    .asc((v) => v.item_field_id)
                    .filter(
                      (field) =>
                        field.field_type !== 'email' &&
                        field.metadata.fieldTypeMetadata?.fieldIdentifier !==
                          'first_name' &&
                        field.metadata.fieldTypeMetadata?.fieldIdentifier !==
                          'last_name',
                    )
                    .map((cartFieldView) => (
                      <DefinitionList
                        key={cartFieldView.item_field_id}
                        title={cartFieldView.name}
                      >
                        {cartFieldView.field_type === 'signature' ||
                        cartFieldView.field_type === 'image' ? (
                          <img
                            className="h-16"
                            alt={cartFieldView.name}
                            src={cartFieldView.value}
                          />
                        ) : (
                          getCollectionFieldValueFormatted(cartFieldView)
                        )}
                      </DefinitionList>
                    ))}
                </>
              )}
              {!isTicket &&
                Util.sort(visibleCartFieldViews)
                  .asc((v) => v.item_field_id)
                  .map((cartFieldView) => (
                    <DefinitionList
                      key={cartFieldView.item_field_id}
                      title={cartFieldView.name}
                    >
                      {cartFieldView.field_type === 'signature' ||
                      cartFieldView.field_type === 'image' ? (
                        <img
                          className="h-16"
                          alt={cartFieldView.name}
                          src={cartFieldView.value}
                        />
                      ) : (
                        getCollectionFieldValueFormatted(cartFieldView)
                      )}
                    </DefinitionList>
                  ))}
            </WebUI.VStack>
            {type !== 'signup' && (
              <Link
                className="text-ds-sm"
                variant="primary"
                iconBefore={
                  <WebUI.PhosphorIcon
                    className="text-ds-lg"
                    icon="arrow-square-up-right"
                  />
                }
                preserveSearch
                to={{
                  pathname:
                    'tab_item' in cartObject
                      ? `../items/item/${cartObject.tab_item.id}`
                      : 'tab_form' in cartObject
                        ? `../forms/form/${cartObject.tab_form.id}`
                        : 'time_slot' in cartObject
                          ? `../forms/signups/${cartObject.time_slot.spot.signup.id}`
                          : '',
                  search:
                    'tab_item' in cartObject ? `ciId=${cartObject.id}` : '',
                }}
                relative="nonContextualPath"
              >
                View
              </Link>
            )}
          </WebUI.VStack>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    )
  },
)

// MARK: - CartObjectDetailsDisclosure
export interface CartSignUpDisclosureProps
  extends Omit<WebUI.DisclosureProps, 'children'> {
  collectionSlug: string
  maxVisibleFieldsCount?: number
  cartSignUp: CartSignUp
}

export const CartSignUpDisclosure = React.forwardRef<
  WebUI.DisclosureInstance,
  CartSignUpDisclosureProps
>(
  (
    {
      collectionSlug,
      maxVisibleFieldsCount = 10,
      cartSignUp,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Disclosure
      ref={forwardedRef}
      className={WebUI.cn('gap-3', className)}
      {...restProps}
    >
      <WebUI.DisclosureButton
        className="text-ds-sm"
        variant="text"
        iconBefore={
          <WebUI.PhosphorIcon
            className={`text-teal-600 transition-transform [[aria-expanded="true"]_>_.Button-iconBefore_>_&]:rotate-90`}
            icon="caret-right-fill"
          />
        }
      >
        Signup Details
      </WebUI.DisclosureButton>
      <WebUI.DisclosureContent>
        <WebUI.VStack className="gap-3">
          {cartSignUp.time_slots.map((timeSlot, index) => (
            <React.Fragment key={timeSlot.id}>
              <TimeSlotDisclosureContent timeSlot={timeSlot} />
              {index < cartSignUp.time_slots.length - 1 && (
                <WebUI.Separator variant="black" />
              )}
            </React.Fragment>
          ))}

          <Link
            className="text-ds-sm"
            variant="primary"
            iconBefore={
              <WebUI.PhosphorIcon
                className="text-ds-lg"
                icon="arrow-square-up-right"
              />
            }
            to={`../forms/signups/${cartSignUp.signUp.id}`}
          >
            View
          </Link>
        </WebUI.VStack>
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>
  ),
)

// MARK - TimeSlotDisclosureContent
export interface TimeSlotDisclosureContentProps {
  timeSlot: Api.CheddarUpCartTimeSlot
}
const TimeSlotDisclosureContent: React.FC<TimeSlotDisclosureContentProps> = ({
  timeSlot,
}) => {
  const signUpType = timeSlot.time_slot.spot.signup.options.signupType
  const comment = timeSlot.cart_field_views.find(
    (cfv) => cfv.metadata.timeSlotFieldType === 'comment',
  )
  return (
    <WebUI.VStack className="gap-1">
      <DefinitionList title="Spot">
        {timeSlot.time_slot.spot.name}
      </DefinitionList>
      {signUpType === 'schedule' && (
        <DefinitionList title="Date">
          {Util.formatDate(
            new Date(timeSlot.time_slot.options.startTime ?? ''),
            'EEE, MMM d yyyy',
          )}
        </DefinitionList>
      )}
      {signUpType === 'schedule' && (
        <DefinitionList title="Time">
          {Util.formatDate(
            new Date(timeSlot.time_slot.options.startTime ?? ''),
            'hh:mm aaa',
          )}{' '}
          -{' '}
          {Util.formatDate(
            new Date(timeSlot.time_slot.options.endTime ?? ''),
            'hh:mm aaa',
          )}
        </DefinitionList>
      )}
      <DefinitionList title="Qty">{timeSlot.quantity}</DefinitionList>
      {comment && (
        <DefinitionList title={comment.name}>{comment.value}</DefinitionList>
      )}
    </WebUI.VStack>
  )
}

// MARK: – DefinitionList

interface DefinitionListProps
  extends Omit<React.ComponentPropsWithoutRef<'dl'>, 'title'> {
  title: React.ReactNode
}

const DefinitionList = ({
  title,
  className,
  children,
  ...restProps
}: DefinitionListProps) => (
  <dl className={WebUI.cn('font-normal text-ds-xs', className)} {...restProps}>
    <dt className="inline-block text-gray400 leading-compact">{title}:</dt>
    <dd className="ml-1 inline-block">{children}</dd>
  </dl>
)

export default CartOverview
