import {ForwardRefComponent} from '@cheddarup/react-util'
import React, {useState} from 'react'

import {useGenericMedia} from '../hooks'
import {VStack} from './Stack'
import {PhosphorIcon} from '../icons'
import type {DraggableSyntheticListeners} from './DragAndDrop'
import {cn} from '../utils'

export interface CardProps {
  dragHandleVisible?: boolean
  dragHandle?: React.ReactNode
  dragListeners?: DraggableSyntheticListeners
  accessoryView?: React.ReactNode
}

export const Card = React.forwardRef(
  (
    {
      dragHandleVisible: dragHandleVisibleProp = false,
      dragHandle,
      dragListeners,
      accessoryView,
      as: Comp = 'div',
      className,
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const isTouchDevice = useGenericMedia('(hover: none)')

    const dragHandleVisible =
      !isTouchDevice && dragHandleVisibleProp && isHovered
    const accessoryViewVisible = isTouchDevice || isFocused || isHovered

    return (
      <Comp
        ref={forwardedRef}
        className={cn(
          'Card relative overflow-hidden rounded border bg-trueWhite focus:outline-none',
          className,
        )}
        onFocus={(event) => {
          onFocus?.(event)

          if (!event.defaultPrevented) {
            setIsFocused(true)
          }
        }}
        onBlur={(event) => {
          onBlur?.(event)

          if (!event.defaultPrevented) {
            setIsFocused(false)
          }
        }}
        onMouseEnter={(event) => {
          onMouseEnter?.(event)

          if (!event.defaultPrevented) {
            setIsHovered(true)
          }
        }}
        onMouseLeave={(event) => {
          onMouseLeave?.(event)

          if (!event.defaultPrevented) {
            setIsHovered(false)
          }
        }}
        {...restProps}
      >
        {children}
        {dragHandleVisible &&
          (dragHandle ?? (
            <CardDragHandle
              className="Card-dragHandle !m-0 absolute top-0 bottom-0 left-0"
              {...dragListeners}
            />
          ))}
        {!!accessoryView && accessoryViewVisible && (
          <div
            data-no-dnd="true"
            className="Card-accessoryView !m-0 absolute top-3 right-3"
          >
            {accessoryView}
          </div>
        )}
      </Comp>
    )
  },
) as ForwardRefComponent<'div', CardProps>

// MARK: – CardDragHandle

export interface CardDragHandleProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const CardDragHandle = React.forwardRef<
  HTMLDivElement,
  CardDragHandleProps
>(({className, ...restProps}, forwardedRef) => (
  <VStack
    ref={forwardedRef}
    className={cn(
      'CardDragHandle w-6 cursor-grab items-center justify-center bg-teal-90 text-ds-2xl text-teal-600',
      className,
    )}
    {...restProps}
  >
    <PhosphorIcon icon="dots-six-vertical-bold" />
  </VStack>
))
