import * as WebUI from '@cheddarup/web-ui'
import {useState} from 'react'
import {SearchModal as CommonSearchModal} from 'src/components'
import {SearchModalProps as CommonSearchModalProps} from 'src/components/SearchModal'
import {useFolderId} from 'src/components/FolderProvider'

import CreateFolderFormPrompt from './CreateFolderFormPrompt'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

export interface FoldersNavHeaderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  folderTitle?: string
  canCreate?: boolean
}

const FoldersNavHeader = ({
  folderTitle = 'My Folders',
  canCreate = true,
  className,
  ...restProps
}: FoldersNavHeaderProps) => {
  const [, setFolderId] = useFolderId()
  return (
    <WebUI.HStack
      className={WebUI.cn('items-center gap-1', className)}
      {...restProps}
    >
      <WebUI.Text className="grow text-ds-sm uppercase">
        {folderTitle}
      </WebUI.Text>

      {canCreate && (
        <CreateFolderFormPrompt
          disclosure={
            <WebUI.DeprecatedTooltip label="New Folder">
              <WebUI.DialogDisclosure
                className="text-ds-lg"
                as={WebUI.IconButton}
                size="default_alt"
              >
                <WebUI.PhosphorIcon icon="plus" />
              </WebUI.DialogDisclosure>
            </WebUI.DeprecatedTooltip>
          }
        />
      )}

      <WebUI.DeprecatedTooltip label="Search">
        <SearchModal
          initialVisible={false}
          onFolderChange={(newFolder) => setFolderId(newFolder?.id ?? null)}
          disclosure={
            <WebUI.DialogDisclosure
              className="text-ds-lg"
              as={WebUI.IconButton}
              size="default_alt"
            >
              <WebUI.PhosphorIcon icon="magnifying-glass" />
            </WebUI.DialogDisclosure>
          }
        />
      </WebUI.DeprecatedTooltip>
    </WebUI.HStack>
  )
}

// MARK: – SearchModal

interface SearchModalProps
  extends Omit<CommonSearchModalProps, 'renderResults' | 'search' | 'onClear'> {
  onFolderChange: (folder: Api.TabFolder) => void
}

const SearchModal = ({onFolderChange, ...restProps}: SearchModalProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)

  const foldersQuery = api.tabFolders.list.useQuery()
  const tabsQuery = api.tabs.list.useQuery()

  return (
    <CommonSearchModal
      renderResults={() => {
        if (!searchKeyword) {
          return null
        }
        const matched = searchKeyword.match(/^[!#$%&()*+.=@^_-]+$/g)
        if (matched && matched.length > 0) {
          return null
        }
        const collections =
          tabsQuery.data?.filter(
            Util.fuzzyFilterIterator(searchKeyword, {
              iterator: (tab) => tab.name,
            }),
          ) ?? []
        const folders =
          foldersQuery.data?.filter(
            Util.fuzzyFilterIterator(searchKeyword, {
              iterator: (folder) => folder.name,
            }),
          ) ?? []

        return folders.length > 0 || collections.length > 0 ? (
          <div className="border-grey-200 border-t">
            {collections.length > 0 && (
              <div className="font-normal">
                <div className="px-[2.25rem] py-[0.5rem] text-ds-xs text-gray400 uppercase">
                  Collections
                </div>
                {collections
                  .sort((a, b) =>
                    a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
                  )
                  .map((collection) => (
                    <a
                      key={collection.id}
                      className={
                        'mb-[0.375rem] block cursor-pointer px-[2.25rem] py-[10px] text-ds-sm text-gray800 hover:bg-teal-80'
                      }
                      href={`/collection/${collection.id}/manage`}
                    >
                      {collection.name}
                    </a>
                  ))}
              </div>
            )}
            {folders.length > 0 && (
              <div className="font-normal">
                <div className="px-[2.25rem] py-[0.5rem] text-ds-xs text-gray400 uppercase">
                  Folders
                </div>
                {folders
                  .sort((a, b) =>
                    a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
                  )
                  .map((folder) => (
                    <div
                      key={folder.id}
                      className={
                        'mb-[0.375rem] block cursor-pointer px-[2.25rem] py-[10px] text-ds-sm text-gray800 hover:bg-teal-80'
                      }
                      onClick={() => onFolderChange(folder)}
                    >
                      {folder.name}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ) : (
          <div
            className={
              'border-0 border-t border-t-grey-200 border-solid px-[2.25rem] py-[0.5rem] font-normal text-ds-sm text-gray800'
            }
          >
            No results found
          </div>
        )
      }}
      search={async (keyword) => setSearchKeyword(keyword)}
      onClear={() => setSearchKeyword(null)}
      {...restProps}
    />
  )
}

export default FoldersNavHeader
