import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useState} from 'react'
import {LinkButton} from 'src/components/LinkButton'
import {Navigate} from 'react-router-dom'
import {useInterval} from '@cheddarup/react-util'

import usePublicCollection from './hooks/usePublicCollection'
import {
  PayerContentContainerLayout,
  PayerContentLayout,
  PayerMainBlock,
} from './components/PayerLayouts'
import {CollectionOverview} from './components/CollectionOverview'

const PrePayerTimingPage: React.FC = () => {
  const {publicCollection} = usePublicCollection()

  if (publicCollection.unavailable !== 'timing_out_of_range') {
    return <Navigate to="../.." />
  }

  return (
    <PayerContentContainerLayout>
      <PayerContentLayout className="justify-center">
        <PayerMainBlock className="grow">
          <CollectionOverview detailsHidden />

          {publicCollection.allowPayments ||
          publicCollection.userManagesCollection ? (
            new Date() >
            new Date(publicCollection.timing?.closes ?? new Date()) ? (
              <div className="rounded-extended bg-teal-600 px-8 py-4 text-center text-ds-xl text-trueWhite">
                This collection has ended
              </div>
            ) : (
              <CollectionTimingBarrier
                className="mt-4"
                publicCollection={publicCollection}
              />
            )
          ) : null}
        </PayerMainBlock>
      </PayerContentLayout>
    </PayerContentContainerLayout>
  )
}

// MARK: – CollectionTimingBarrier

interface CollectionTimingBarrierProps {
  className?: string
  publicCollection: Api.PublicTab
}

const CollectionTimingBarrier: React.FC<CollectionTimingBarrierProps> = ({
  publicCollection,
}) => {
  const opensDate = useMemo(
    () =>
      publicCollection.timing?.opens
        ? new Date(publicCollection.timing?.opens)
        : new Date(),
    [publicCollection.timing?.opens],
  )

  const sLeft = useTimer(Util.differenceInSeconds(opensDate, new Date()))

  const duration = Util.normalizeDuration({seconds: Math.ceil(Number(sLeft))})

  return (
    <WebUI.VStack
      className="items-center gap-8 p-8"
      as={WebUI.Panel}
      variant="capsule"
    >
      <WebUI.Text className="text-center font-light text-ds-base sm:text-ds-md">
        Starts:{' '}
        {Util.isSameDay(opensDate, new Date())
          ? 'Today'
          : Util.nativeFormatDate(opensDate, {
              weekday: 'long',
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZoneName: 'short',
            })}
      </WebUI.Text>
      <WebUI.HStack className="w-fit justify-between gap-4">
        {duration.days > 0 && (
          <CoundownCell title="Days" value={duration.days} />
        )}
        {duration.hours > 0 && (
          <CoundownCell title="Hours" value={duration.hours} />
        )}
        <CoundownCell title="Minutes" value={duration.minutes} />
        <CoundownCell title="Seconds" value={duration.seconds} />
      </WebUI.HStack>
      {publicCollection.allowPreview && (
        <LinkButton
          size="large"
          variant="primary"
          roundness="capsule"
          to={`/c/${publicCollection.slug}?preview=1`}
        >
          Preview
        </LinkButton>
      )}
    </WebUI.VStack>
  )
}

// MARK: – CoundownCell

interface CoundownCellProps {
  className?: string
  title: string
  value: number
}

const CoundownCell = ({className, title, value}: CoundownCellProps) => (
  <WebUI.VStack
    className={WebUI.cn(
      'h-[76px] w-[76px] items-center justify-center overflow-hidden rounded-extended border text-center sm:h-[96px] sm:w-[96px]',
      className,
    )}
  >
    <WebUI.Text className="font-bold text-[29px] text-orange-500 leading-compact sm:text-ds-3xl">
      {formatAsTwoDigits(value)}
    </WebUI.Text>
    <WebUI.Ellipsis className="font-light text-ds-xs uppercase sm:text-ds-sm">
      {title}
    </WebUI.Ellipsis>
  </WebUI.VStack>
)

// MARK: – Helpers

const useTimer = (initialSecnods: number, delayInMs = 1000) => {
  const [seconds, setSeconds] = useState(initialSecnods)

  useInterval(
    () =>
      setSeconds((prevSeconds) =>
        prevSeconds > 0 ? prevSeconds - 1 : prevSeconds,
      ),
    delayInMs,
  )

  return seconds
}

const formatAsTwoDigits = (num: number) =>
  num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
    maximumFractionDigits: 0,
  })

export default PrePayerTimingPage
