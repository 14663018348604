import {useEffect, useRef} from 'react'
import canvasConfetti from 'canvas-confetti'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import * as Util from '@cheddarup/util'

const GroupPagePublishSuccessConfetti = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (canvasRef.current) {
      const fireConfettiAnim = canvasConfetti.create(canvasRef.current, {
        disableForReducedMotion: true,
      })

      const timeouts = Array.from({length: 5}).map((_, idx, arr) =>
        setTimeout(
          () =>
            fireConfettiAnim({
              colors: [
                tailwindConfig.theme.colors.teal[600],
                tailwindConfig.theme.colors.teal[80],
                tailwindConfig.theme.colors.accent300,
                tailwindConfig.theme.colors.teal[70],
                tailwindConfig.theme.colors.teal[90],
              ],
              startVelocity: 16 - idx,
              particleCount: Util.randomInteger(50, 100),
              spread: 45,
              angle: 45 + idx * 10,
              gravity: 0.7 - (arr.length - idx) * 0.25,
              // decay: 0.9,
              origin: {
                x: idx * 0.2,
                y: 1,
              },
            }),
          500 + idx * 250,
        ),
      )

      return () => timeouts.forEach((timeout) => clearTimeout(timeout))
    }

    return () => {}
  }, [])
  return (
    <canvas
      ref={canvasRef}
      className="pointer-events-none absolute top-0 right-0 bottom-0"
    />
  )
}

export default GroupPagePublishSuccessConfetti
