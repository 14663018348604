import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import CollectionEmptyImage from 'src/images/CollectionEmptyImage.svg'
import * as Util from '@cheddarup/util'

import {ConvertTemplateToTabButton} from './ConvertTemplateToTabButton'
import {SharpImage} from './SharpImage'
import {LinkButton} from './LinkButton'

export interface TabTemplateCardProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  width?: number
  imageHeight?: number
  template: Api.TabTemplate | null
  buttonSize?: WebUI.ButtonProps['size']
}

export const TabTemplateCard = React.forwardRef<
  HTMLDivElement,
  TabTemplateCardProps
>(
  (
    {
      width = 320,
      imageHeight,
      buttonSize = 'big',
      template,
      className,
      style,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.VStack
      ref={forwardedRef}
      className={WebUI.cn(
        'TemplateCard group relative rounded-[16px]',
        className,
      )}
      style={{width, ...style}}
      variant="shadowed"
      as={WebUI.Panel}
      {...restProps}
    >
      {template ? (
        <SharpImage
          width={width}
          height={imageHeight ?? width / 2}
          alt={`${template.name} banner`}
          image={template.featured_image}
          errorFallback={
            <WebUI.VStack
              className={WebUI.cn(
                'shrink-0 items-center justify-center bg-grey-300 text-trueWhite',
                className,
              )}
              style={{
                width,
                height: imageHeight ?? width / 2,
                fontSize: imageHeight ? imageHeight / 2 : width / 3,
                ...style,
              }}
              {...restProps}
            >
              <img className="w-[1em]" alt="" src={CollectionEmptyImage} />
            </WebUI.VStack>
          }
        />
      ) : (
        <WebUI.Skeleton
          className="[&.Skeleton]:leading-[unset]"
          width={width}
          height={imageHeight ?? width / 2}
        />
      )}

      <WebUI.VStack className="TemplateCard-bottom h-[140px] justify-between gap-3 px-6 py-5">
        {template ? (
          <WebUI.Heading className="line-clamp-2" as="h4">
            {template.name}
          </WebUI.Heading>
        ) : (
          <WebUI.Skeleton width={Util.randomInteger(140, 200)} height={24} />
        )}

        <div className="flex items-center justify-between">
          <WebUI.Text className="text-ds-sm">
            <span className="text-gray400">By:</span>{' '}
            {template ? (
              <span>
                {template.options.template.presentedBy ||
                  template.user.full_name}
              </span>
            ) : (
              <WebUI.Skeleton width={Util.randomInteger(60, 100)} height={12} />
            )}
          </WebUI.Text>
          {(template?.requires_pro || template?.requires_team) && (
            <WebUI.PhosphorIcon icon="star-fill" color="#F9C442" />
          )}
        </div>
      </WebUI.VStack>

      {template && (
        <WebUI.VStack
          className={
            'TemplateCard-Overlay absolute top-0 left-0 h-full w-full translate-y-full justify-between gap-3 bg-trueWhite px-8 py-11 transition-transform duration-[250ms] ease-out group-hover:translate-y-0'
          }
        >
          {template && template?.options.template.categories.length > 0 && (
            <WebUI.VStack className="gap-2">
              <WebUI.VStack>
                {template.options.template.categories.find(
                  (cat) => cat.type === 'Staff Picks',
                ) && (
                  <WebUI.Text className="font-bold">
                    <span className="text-lg text-orange-50">Staff Pick!</span>
                  </WebUI.Text>
                )}
                <WebUI.Text className="font-light">
                  <span className="font-bold">Great for:</span>{' '}
                  {template.options.template.categories
                    .filter((cat) => cat.type !== 'Staff Picks')
                    .map((cat) => cat.type)
                    .join(', ')}
                </WebUI.Text>
              </WebUI.VStack>
              {(template.requires_pro || template.requires_team) && (
                <WebUI.HStack className="flex flex-row justify-start gap-2">
                  <WebUI.PhosphorIcon icon="star-fill" color="#F9C442" />
                  <span className="font-normal text-ds-xs">
                    This template uses premium features that can be removed if
                    desired.
                  </span>
                </WebUI.HStack>
              )}
            </WebUI.VStack>
          )}
          <WebUI.VStack className="gap-3">
            <WebUI.RoundedButton
              size={buttonSize}
              as={LinkButton}
              to={`c/${template.slug}`}
            >
              Preview
            </WebUI.RoundedButton>
            <ConvertTemplateToTabButton
              size={buttonSize}
              templateId={template.id}
            />
          </WebUI.VStack>
        </WebUI.VStack>
      )}
    </WebUI.VStack>
  ),
)
