import * as Ariakit from '@ariakit/react'
import React from 'react'

import {cn} from '../utils'

export interface WellProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Ariakit.RoleOptions {}

export const Well = React.forwardRef<HTMLDivElement, WellProps>(
  ({className, ...restProps}, forwardedRef) => (
    <Ariakit.Role
      ref={forwardedRef}
      className={cn('rounded-default bg-grey-100 px-8 py-7', className)}
      {...restProps}
    />
  ),
)
