import React, {useEffect, useImperativeHandle, useRef} from 'react'
import {
  Portal,
  Dialog as ReakitDialog,
  DialogDisclosure as ReakitDialogDisclosure,
  DialogStateReturn as ReakitDialogStateReturn,
  useDialogState as useReakitDialogState,
} from 'reakit'
import {getClosestFocusable} from 'reakit-utils'
import {
  genericForwardRef,
  useForkRef,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import {Merge} from '@cheddarup/util'

import {Ellipsis} from './Ellipsis'
import type {InputProps} from './Input'
import {HStack} from './Stack'
import {cn} from '../utils'

export interface InlineEditDialogInstance extends ReakitDialogStateReturn {}

export type InlineEditProps<P> = _InlineEditProps<P> &
  Omit<P, 'variant' | 'placeholder'>

export interface _InlineEditProps<P>
  extends Pick<
    React.AllHTMLAttributes<HTMLElement>,
    | 'autoFocus'
    | 'className'
    | 'disabled'
    | 'id'
    | 'placeholder'
    | 'aria-describedby'
    | 'aria-invalid'
    | 'aria-labelledby'
  > {
  InputComponent: React.ComponentType<P>
  DisplayComponent: React.ComponentType<{
    DefaultDisplayComponent: React.ComponentType<
      Merge<
        React.ComponentPropsWithoutRef<'span'>,
        {
          placeholder?: string
          content?: React.ReactNode
          children?: never
        }
      >
    >
  }>
  dialogRef?: React.Ref<InlineEditDialogInstance>
  disclosureRef?: React.Ref<HTMLDivElement>
  inputClassName?: string
  inputSize?: InputProps['size']
  inputVariant?: InputProps['variant']
  inputOffset?: [number, number]
  inputAlign?: 'left' | 'right'
  inputPortal?: boolean
  displayPadded?: boolean
  fixedMode?: 'input' | 'display'
  onVisibleChange?: (newVisible: boolean) => void
  onDidShow?: () => void
  onDidHide?: () => void
}

export const InlineEdit = genericForwardRef(
  <T, P>(
    {
      autoFocus,
      className,
      disabled,
      id,
      placeholder = '—',
      'aria-describedby': ariaDescribedBy,
      'aria-invalid': ariaInvalid,
      'aria-labelledby': ariaLabelledBy,
      InputComponent,
      DisplayComponent,
      dialogRef: dialogRefProp,
      disclosureRef: disclosureRefProp,
      inputClassName,
      inputSize = 'default',
      inputVariant = 'default',
      inputOffset = [0, 0],
      inputAlign = 'left',
      inputPortal,
      displayPadded,
      fixedMode,
      onVisibleChange,
      onDidShow,
      onDidHide,
      ...inputRestProps
    }: InlineEditProps<P> & {ref?: React.Ref<T>},
    forwardedRef: React.Ref<T>,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const ownDisclosureRef = useRef<HTMLDivElement>(null)
    const disclosureRef = useForkRef(ownDisclosureRef, disclosureRefProp)
    const ownInputRef = useRef<T>(null)
    const inputRef = useForkRef(ownInputRef, forwardedRef)

    const dialog = useReakitDialogState({modal: false})
    const dialogRef = useLiveRef(dialog)
    const onVisibleChangeRef = useLiveRef(onVisibleChange)
    const onDidShowRef = useLiveRef(onDidShow)
    const onDidHideRef = useLiveRef(onDidHide)

    useImperativeHandle(dialogRefProp, () => dialog, [dialog])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (autoFocus) {
        dialogRef.current?.show()
      }
    }, [])

    useUpdateEffect(() => {
      if (!dialog.animating) {
        onVisibleChangeRef.current?.(dialog.visible)
        if (dialog.visible) {
          onDidShowRef.current?.()
        } else {
          onDidHideRef.current?.()
        }
      }
    }, [dialog.visible, dialog.animating])

    const inputEl = (
      <InputComponent
        ref={inputRef}
        className={inputClassName}
        size={inputSize}
        variant={inputVariant}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        {...(inputRestProps as P)}
      />
    )
    if (fixedMode === 'input') {
      return inputEl
    }

    const valueEl = (
      <DisplayComponent
        DefaultDisplayComponent={({
          className: displayClassName,
          style: displayStyle,
          placeholder: displayPlaceholder,
          content: displayContent,
          ...displayRestProps
        }) => {
          if (
            displayContent == null ||
            (typeof displayContent === 'boolean' && displayContent === false) ||
            displayContent === ''
          ) {
            return (
              <Ellipsis
                className={cn(
                  'InlineEdit-display',
                  'InlineEdit-display--placeholder',
                  'flex-[1] text-inputPlaceholder italic',
                  displayClassName,
                )}
                style={{
                  textAlign: inputAlign,
                  ...displayStyle,
                }}
                {...displayRestProps}
              >
                {displayPlaceholder ?? placeholder}
              </Ellipsis>
            )
          }
          if (typeof displayContent === 'string') {
            return (
              <Ellipsis
                className={cn(
                  'InlineEdit-display',
                  'flex-[1]',
                  displayClassName,
                )}
                style={{
                  textAlign: inputAlign,
                  ...displayStyle,
                }}
                {...displayRestProps}
              >
                {displayContent}
              </Ellipsis>
            )
          }

          return (
            <HStack
              className={cn(
                'InlineEdit-display',
                'min-w-0 max-w-full flex-[1] items-center gap-1',
                inputAlign === 'left' && 'justify-start',
                inputAlign === 'right' && 'justify-end',
                displayClassName,
              )}
              {...displayRestProps}
            >
              {displayContent}
            </HStack>
          )
        }}
      />
    )
    if (fixedMode === 'display') {
      return valueEl
    }

    return (
      <HStack
        ref={containerRef}
        className={cn('InlineEdit', 'relative min-w-0 items-center', className)}
      >
        <ReakitDialogDisclosure
          ref={disclosureRef}
          as="div"
          id={id}
          className={cn(
            'InlineEdit-disclosure',
            'flex h-full min-h-full w-full min-w-full cursor-text select-none flex-row items-center whitespace-nowrap font-body leading-compact',
            'aria-disabled:cursor-not-allowed aria-disabled:select-auto',
            'focus:outline-none',
            'focus:focus-visible:shadow-[inset_0_0_0_2px_theme(colors.teal.600)]',
            'before:content-[​]',
            inputAlign === 'left' && 'justify-start',
            inputAlign === 'right' && 'justify-end',
          )}
          style={{
            // Prevent `pointerEvents: 'disabled'`
            ...(disabled && {pointerEvents: 'auto'}),
            ...(displayPadded && {
              paddingX: Math.abs(inputOffset[0]),
              paddingY: Math.abs(inputOffset[1]),
            }),
          }}
          aria-describedby={ariaDescribedBy}
          aria-disabled={disabled}
          aria-invalid={ariaInvalid}
          aria-labelledby={ariaLabelledBy}
          tabIndex={disabled ? -1 : 0}
          onFocus={(event: React.FocusEvent<HTMLDivElement>) => {
            if (disabled) {
              event.preventDefault()
              event.stopPropagation()

              if (containerRef.current) {
                getClosestFocusable(containerRef.current)?.focus()
              }
            } else {
              event.stopPropagation()
              if (event.target === event.currentTarget) {
                dialogRef.current?.show()
              }
            }
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            // Prevent default disclosure behaviour
            event.preventDefault()
          }}
          {...dialog}
        >
          {valueEl}
        </ReakitDialogDisclosure>

        <ReakitDialog
          aria-label="Edit value"
          hideOnClickOutside={false}
          hideOnEsc={false}
          unstable_autoFocusOnHide={false}
          {...dialog}
        >
          {({
            ref: contentRef,
            className: contentClassName,
            style: contentStyle,
            onFocus: contentOnFocus,
            onBlur: contentOnBlur,
            ...contentRestProps
          }) => {
            const rect = containerRef.current?.getBoundingClientRect()
            if (!rect || (!dialog.visible && !dialog.animating)) {
              return <>{null}</>
            }

            const content = (
              <div
                ref={contentRef}
                className={cn(
                  'InlineEdit-input',
                  'absolute flex flex-row',
                  'focus:outline-none',
                  inputPortal && 'fixed',
                  inputAlign === 'left' &&
                    'right-auto [&_>_.Input[class]]:text-left',
                  inputAlign === 'right' &&
                    'left-auto [&_>_.Input[class]]:text-right',
                  displayPadded &&
                    cn(
                      'top-0 min-h-full min-w-full',
                      inputAlign === 'left' && 'left-0',
                      inputAlign === 'right' && 'right-0',
                    ),
                  contentClassName,
                )}
                style={{
                  top: inputOffset[1],
                  left: inputOffset[0],
                  right: inputOffset[0],
                  minWidth: `calc(100% + ${Math.abs(inputOffset[0]) * 2}px)`,
                  minHeight: `calc(100% + ${Math.abs(inputOffset[1]) * 2}px)`,

                  ...(inputPortal && {
                    top: rect.top + inputOffset[1],
                    left: rect.left + inputOffset[0],
                    right: `calc(100% - ${rect.right - inputOffset[0]})`,
                    minWidth: rect.width + Math.abs(inputOffset[0]) * 2,
                    minHeight: rect.height + Math.abs(inputOffset[1]) * 2,
                  }),

                  ...(displayPadded &&
                    inputPortal && {
                      minWidth: rect.width,
                      minHeight: rect.height,
                      top: rect.top,
                      ...(inputAlign === 'left' && {left: rect.left}),
                      ...(inputAlign === 'right' && {right: rect.right}),
                    }),

                  ...contentStyle,
                }}
                onFocus={(event) => {
                  if (event.currentTarget === event.target) {
                    event.preventDefault()
                    setTimeout(() => dialog.hide(), 0)
                  }

                  contentOnFocus?.(event)
                }}
                onBlur={(event) => {
                  let focusingContainedEl = false
                  const nextFocusedEl =
                    event.relatedTarget as HTMLElement | null
                  if (nextFocusedEl) {
                    focusingContainedEl =
                      event.currentTarget.contains(nextFocusedEl)
                    if (!focusingContainedEl) {
                      // Dialog
                      const disclosureEl = event.currentTarget.querySelector(
                        '[aria-haspopup="dialog"]',
                      )
                      if (disclosureEl) {
                        const dialogId =
                          disclosureEl.getAttribute('aria-controls')
                        const dialogEl = document.querySelector(
                          `#${dialogId}[role="dialog"]`,
                        )
                        if (dialogEl) {
                          focusingContainedEl = dialogEl.contains(nextFocusedEl)
                        }
                      }
                    }
                  }

                  if (!focusingContainedEl) {
                    event.preventDefault()
                    setTimeout(() => dialog.hide(), 0)
                  }

                  contentOnBlur?.(event)
                }}
                {...contentRestProps}
              >
                {inputEl}
              </div>
            )
            return inputPortal ? <Portal>{content}</Portal> : content
          }}
        </ReakitDialog>
      </HStack>
    )
  },
)
