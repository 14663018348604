import {useDebounceCallback, useFormik} from '@cheddarup/react-util'
import * as Yup from 'yup'
import {
  Anchor,
  Button,
  cn,
  copyToClipboard,
  DialogInstance,
  FormField,
  Heading,
  Input,
  Modal,
  ModalCloseButton,
  ModalProps,
  PhosphorIcon,
  Skeleton,
  Text,
  useGrowlActions,
} from '@cheddarup/web-ui'
import React, {useEffect, useRef, useState} from 'react'
import config from 'src/config'
import TaxToPayLady from 'src/images/TaxToPayLady.png'
import {memoize} from '@cheddarup/util'
import {
  api,
  useCreateTabDonationCodeMutation,
  useUpdateTabDonationCodeMutation,
} from '@cheddarup/api-client'
import {guessError} from 'src/helpers/error-utils'
import CopyToClipboard from './CopyToClipboard'
import DonationCodeConfetti from 'src/images/DonationCodeConfetti.svg'

export const TEXT_TO_PAY_CONTACT = '234-324-3332'

export interface TextToPayModalProps extends ModalProps {
  collection: Api.Tab
}

const TextToPayModal = React.forwardRef<DialogInstance, TextToPayModalProps>(
  (
    {className, collection, initialVisible = false, ...restProps},
    forwardedRef,
  ) => {
    const [step, setStep] = useState<'initial' | 'final'>('initial')
    const [keywordCopied, setKeywordCopied] = useState(false)
    return (
      <Modal
        ref={forwardedRef}
        className={cn(
          '[&_>_.ModalContentView]:max-w-screen-xl sm:[&_>_.ModalContentView]:max-h-[668px] sm:[&_>_.ModalContentView]:rounded-[16px]',
          className,
        )}
        initialVisible={initialVisible}
        onDidShow={() => setStep('initial')}
        {...restProps}
      >
        <div className="flex">
          <div className="relative flex flex-col gap-9 p-8 sm:p-16">
            {step === 'initial' ? (
              <>
                <div className="flex flex-col gap-2">
                  <Heading
                    as="h3"
                    className="text-grey-800 text-h-4 leading-snug"
                  >
                    Text-to-Pay
                  </Heading>
                  <Text className="font-light text-ds-md text-grey-700">
                    Text-to-pay is ideal for in-person events and when you want
                    to engage your group without having to scan a code or type
                    in a URL.{' '}
                    <Anchor
                      href={config.links.shareCollection}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </Anchor>
                  </Text>
                </div>
                <div className="flex flex-col gap-1">
                  <Text className="font-medium text-ds-lg text-grey-800">
                    All you need is a keyword
                  </Text>
                  <Text className="font-light text-ds-md text-grey-700">
                    A link to your collection page will automatically be sent to
                    participants when they text your unique keyword to{' '}
                    {TEXT_TO_PAY_CONTACT}.
                  </Text>
                </div>
                <DonationCodeForm
                  collection={collection}
                  onDidSubmit={() => setStep('final')}
                />
              </>
            ) : (
              <>
                <div className="flex flex-col gap-2">
                  <Button
                    iconBefore={<PhosphorIcon icon="arrow-left" />}
                    variant="text"
                    size="compact"
                    className="text-ds-sm"
                    onClick={() => setStep('initial')}
                  >
                    Back
                  </Button>
                  <Heading
                    as="h3"
                    className="text-grey-800 text-h-4 leading-snug"
                  >
                    Your keyword is ready! 🎉
                  </Heading>
                  <Text className="font-light text-ds-md text-grey-700">
                    A link to your collection page will automatically be sent to
                    participants when they text your unique keyword to{' '}
                    {TEXT_TO_PAY_CONTACT}.
                  </Text>
                </div>
                <div className="flex max-w-[320px] flex-col gap-6">
                  <CopyToClipboard
                    label="Your Text-to-Pay keyword"
                    text={collection.donationCode?.code ?? ''}
                  />
                  <Button
                    size="large"
                    variant="primary"
                    onClick={() => {
                      copyToClipboard(collection.donationCode?.code ?? '')
                      setKeywordCopied(true)
                      setTimeout(() => {
                        setKeywordCopied(false)
                      }, 2000)
                    }}
                  >
                    {keywordCopied ? 'Copied!' : 'Copy Your Keyword'}
                  </Button>
                </div>
                <Text className="mt-auto mb-6 font-light text-ds-md [&_>_.Anchor]:underline [&_>_.Anchor_>_.Text]:font-light">
                  Need Help? Learn more{' '}
                  <Anchor href={config.links.shareCollection} target="_blank">
                    here
                  </Anchor>{' '}
                  or{' '}
                  <Anchor href="/contact" target="_blank">
                    contact us
                  </Anchor>
                  .
                </Text>
              </>
            )}
            <div className="absolute bottom-8 left-1/2 flex gap-3">
              <StepperDot selected={step === 'initial'} />
              <StepperDot selected={step === 'final'} />
            </div>
            {step === 'final' && (
              <img
                className="-right-16 absolute top-[30%]"
                height={150}
                width={150}
                src={DonationCodeConfetti}
                alt=""
              />
            )}
          </div>
          <img className="hidden sm:block" src={TaxToPayLady} alt="" />
        </div>
        <ModalCloseButton className="text-ds-4xl sm:text-grey-50" />
      </Modal>
    )
  },
)

// MARK: - StepperDot

interface StepperDotProps extends React.ComponentPropsWithoutRef<'span'> {
  selected: boolean
}

const StepperDot: React.FC<StepperDotProps> = ({
  selected,
  className,
  ...restProps
}) => (
  <span
    className={cn(
      'h-3 w-3 rounded-full bg-grey-300',
      selected && 'bg-orange-500',
      className,
    )}
    {...restProps}
  />
)

// MARK: - DonationCodeForm

interface DonationCodeFormProps {
  collection: Api.Tab
  onDidSubmit: () => void
}

interface DonationCodeFormValues {
  code: string
}

const DonationCodeForm: React.FC<DonationCodeFormProps> = ({
  collection,
  onDidSubmit,
}) => {
  const [keywordStatus, setKeywordStatus] = useState<
    'available' | 'unavailable' | null
  >(null)
  const [availableKeywords, setAvailableKeywords] = useState<string[]>([])
  const [validating, setValidating] = useState(false)
  const abortControllerRef = useRef<AbortController | null>(null)

  const createTabDonationCodeMutation = useCreateTabDonationCodeMutation()
  const updateTabDonationCodeMutation = useUpdateTabDonationCodeMutation()

  const growlActions = useGrowlActions()

  const validateDonationCode = useDebounceCallback(
    async (code: string) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }

      if (code.length === 0) {
        setKeywordStatus(null)
        return
      }

      try {
        if (code.length > 2 && /^[a-zA-Z0-9]+$/.test(code)) {
          setValidating(true)
          abortControllerRef.current = new AbortController()
          const res = await memoizedValidateDonationCode({
            pathParams: {tabId: collection.id},
            queryParams: {code},
            signal: abortControllerRef.current.signal,
          })

          setKeywordStatus(res.status)
          setAvailableKeywords(res.available_codes)
        }
      } catch (error) {
        if ((error as any).name !== 'CanceledError') {
          setKeywordStatus(null)
          setAvailableKeywords([])
        }
      } finally {
        setValidating(false)
      }
    },
    200,
    true,
  )

  const formik = useFormik<DonationCodeFormValues>({
    enableReinitialize: true,
    initialValues: {
      code: collection.donationCode?.code ?? '',
    },
    validationSchema: Yup.object().shape({
      code: Yup.string()
        .min(3, 'Code must be at least ${min} characters long')
        .max(12, 'Code must be at most ${max} characters long')
        .required('Code is required'),
    }),
    onSubmit: async (values) => {
      try {
        if (collection.donationCode?.code) {
          await updateTabDonationCodeMutation.mutateAsync({
            pathParams: {tabId: collection.id},
            body: values,
          })
        } else {
          await createTabDonationCodeMutation.mutateAsync({
            pathParams: {tabId: collection.id},
            body: values,
          })
        }
        onDidSubmit()
      } catch (err) {
        growlActions.show('error', {
          title: 'Error!',
          body: guessError(err).message,
        })
      }
    },
  })

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  return (
    <form
      className="flex max-w-[320px] flex-col gap-4"
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
    >
      <FormField
        className="[&_>_.FormField-caption]:inline [&_>_.FormField-caption]:text-sm [&_>_.FormField-caption]:text-teal-600 [&_>_.FormField-label]:text-ds-base"
        label="Enter your keyword"
        error={
          keywordStatus === 'unavailable'
            ? 'Keyword unavailable. Suggested alternatives:'
            : formik.errors.code
        }
        caption={
          keywordStatus === 'available' &&
          !validating &&
          "Keyword available! You're all set."
        }
      >
        <Input
          name="code"
          className="max-w-[320px]"
          placeholder="Enter your keyword"
          value={formik.values.code}
          onChange={(event) => {
            formik.handleChange(event)
            const keyword = event.target.value
            if (keyword !== formik.initialValues.code) {
              validateDonationCode(keyword)
            }
          }}
          onBlur={formik.handleBlur}
        />
      </FormField>
      <div className="flex flex-row gap-3">
        {validating
          ? Array.from({length: 3}).map((_, idx) => (
              <Skeleton key={idx} width={80} />
            ))
          : keywordStatus === 'unavailable' &&
            availableKeywords.map((keyword) => (
              <Button
                type="button"
                key={keyword}
                variant="secondaryAlt"
                className={cn(
                  formik.values.code === keyword
                    ? 'bg-teal-70 text-teal-40'
                    : 'bg-teal-80 text-teal-600',
                )}
                size="compact"
                onClick={() => {
                  formik.setFieldValue('code', keyword)
                  setKeywordStatus('available')
                }}
              >
                {keyword}
              </Button>
            ))}
      </div>
      <Button
        variant="primary"
        size="large"
        type="submit"
        disabled={keywordStatus !== 'available'}
        loading={formik.isSubmitting}
      >
        {collection.donationCode?.code
          ? 'Update Keyword'
          : 'Create New Keyword'}
      </Button>
    </form>
  )
}

// MARK:- Helpers

export const memoizedValidateDonationCode = memoize(
  api.tabDonationCodes.validate.fetch,
  {
    isMatchingKey: ([{queryParams: paramsA}], [{queryParams: paramsB}]) =>
      paramsA.code === paramsB.code,
    isPromise: true,
    maxSize: Number.POSITIVE_INFINITY,
  },
)

export default TextToPayModal
