import {
  Checkbox as ReakitCheckbox,
  CheckboxInitialState as ReakitCheckboxInitialState,
  CheckboxOptions as ReakitCheckboxOptions,
  useCheckboxState as useReakitCheckboxState,
} from 'reakit'
import {
  ForwardRefComponent,
  useForkRef,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import flattenChildren from 'react-keyed-flatten-children'
import React, {useRef} from 'react'

import {PhosphorIcon} from '../icons'
import {HStack, VStack} from './Stack'
import {Text} from './Text'
import {cn} from '../utils'

export type CheckboxSize = 'default' | 'compact'
export type CheckboxVariant = 'default'

export interface CheckboxProps
  extends ReakitCheckboxInitialState,
    ReakitCheckboxOptions {
  size?: CheckboxSize
  variant?: CheckboxVariant

  _isInCheckboxGroup?: boolean
}

export const Checkbox = React.forwardRef(
  (
    {
      variant = 'default',
      size = 'default',
      _isInCheckboxGroup,
      state,
      onMouseDown,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkbox = useReakitCheckboxState({state})
    const checkboxRef = useLiveRef(checkbox)
    const ownRef = useRef<HTMLInputElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    useUpdateEffect(() => {
      if (state != null) {
        checkboxRef.current.setState(state)
      }
    }, [state])

    return (
      <HStack
        className={cn(
          `Checkbox Checkbox--${variant}group relative inline-flex cursor-pointer items-start text-ds-sm`,
          size === 'compact' ? 'gap-2' : 'gap-3',
          className,
        )}
        onMouseDown={onMouseDown}
        onClick={() => {
          // Only handle click if component is not controlled
          if (_isInCheckboxGroup || state == null || restProps.onChange) {
            ownRef.current?.click()
          }
        }}
      >
        <ReakitCheckbox
          ref={ref}
          className="Checkbox-input peer/input absolute z-[-1] h-px w-px overflow-hidden opacity-0"
          {...checkbox}
          {...restProps}
        />

        <div
          className={cn(
            'Checkbox-icon relative h-[1.14em] w-[1.14em] shrink-0 rounded bg-grey-50 shadow-[inset_0_0_0_1px_theme(colors.grey.300)] transition-colors duration-100 ease-in-out',
            '[&[class]]:ml-0',
            'peer-disabled/input:cursor-not-allowed peer-disabled/input:opacity-50',
            'peer-focus/input:focus:focus-visible:shadow-[inset_0_0_0_1px_theme(colors.teal.600)]',
            'peer-aria-checked/input:bg-teal-600 peer-aria-checked/input:shadow-none',
            'peer-aria-[checked=mixed]/input:bg-teal-600 peer-aria-[checked=mixed]/input:shadow-none',
            'group-hover:bg-inputHoverBackground',
          )}
        >
          <PhosphorIcon
            aria-hidden="true"
            className={`-translate-x-1/2 -translate-y-1/2 invisible absolute top-1/2 left-1/2 text-[0.8em] text-trueWhite [.Checkbox-input[aria-checked="true"]_~_.Checkbox-icon_>_&]:visible`}
            icon="check-bold"
          />
          <PhosphorIcon
            aria-hidden="true"
            className={cn(
              '-translate-x-1/2 -translate-y-1/2 invisible absolute top-1/2 left-1/2 text-[0.8em] text-trueWhite',
              'peer-aria-[checked=mixed]/input:[.Checkbox-icon_>_&]:visible',
            )}
            icon="minus-bold"
          />
        </div>

        {typeof children === 'string' ? (
          <Text
            className={cn(
              'Checkbox-text',
              'first-line:leading-[1.14em]',
              'peer-disabled/input:cursor-not-allowed peer-aria-disabled/input:cursor-not-allowed',
              size === 'compact' && 'font-light',
            )}
          >
            {children}
          </Text>
        ) : (
          children
        )}
      </HStack>
    )
  },
) as ForwardRefComponent<'input', CheckboxProps>

// MARK: – CheckboxGroup

export interface CheckboxGroupProps {
  name?: string
  defaultState?: Array<number | string>
  state?: Array<number | string>
  size?: CheckboxSize
  variant?: CheckboxVariant
  onStateChange?: (state: Array<number | string>) => void
}

export const CheckboxGroup = React.forwardRef(
  (
    {
      className,
      as: Comp = VStack,
      name,
      size,
      variant,
      defaultState,
      state,
      onStateChange,
      children: childrenProp,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkbox = useReakitCheckboxState({
      state: state ?? defaultState,
    })
    const checkboxRef = useLiveRef(checkbox)
    const onStateChangeRef = useLiveRef(onStateChange)

    useUpdateEffect(() => {
      if (state != null) {
        checkboxRef.current.setState(state)
      }
    }, [state])

    useUpdateEffect(() => {
      onStateChangeRef.current?.(checkbox.state as any)
    }, [checkbox.state])

    const validChildren = flattenChildren(childrenProp).filter(
      (child): child is React.ReactElement => React.isValidElement(child),
    )

    return (
      <Comp
        ref={forwardedRef}
        className={cn('CheckboxGroup', 'gap-3', className)}
        {...restProps}
      >
        {validChildren.map((child) =>
          React.cloneElement(child, {
            size,
            variant,
            name,
            _isInCheckboxGroup: true,
            ...checkbox,
            ...child.props,
          }),
        )}
      </Comp>
    )
  },
) as ForwardRefComponent<typeof VStack, CheckboxGroupProps>
