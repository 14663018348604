import React from 'react'
import {
  PinturaComponentEvents,
  PinturaEditor as PrimitivePinturaEditor,
} from '@pqina/react-pintura'
import {
  createDefaultColorOptions,
  createDefaultFontFamilyOptions,
  createDefaultImageOrienter,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  createMarkupEditorShapeStyleControls,
  createNode,
  locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  PinturaDefaultImageWriterResult,
  PinturaEditorDefaultOptions,
  PinturaImageState,
  PinturaNodeOptions,
  plugin_crop_locale_en_gb,
  plugin_crop,
  plugin_decorate_locale_en_gb,
  plugin_decorate,
  plugin_fill_locale_en_gb,
  plugin_fill,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_filter,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_finetune,
  plugin_frame_defaults,
  plugin_frame_locale_en_gb,
  plugin_frame,
  plugin_sticker_locale_en_gb,
  plugin_sticker,
  setPlugins,
  StickerGroup,
} from '@pqina/pintura'
import '@pqina/pintura/pintura.css'

import StickerOne from '../assets/sticker-one.svg'
import StickerTwo from '../assets/sticker-two.svg'
import StickerThree from '../assets/sticker-three.svg'
import {cn} from '../utils'

export type {PinturaDefaultImageWriterResult, PinturaImageState}

setPlugins(
  plugin_crop,
  plugin_filter,
  plugin_finetune,
  plugin_sticker,
  plugin_fill,
  plugin_decorate,
  plugin_frame,
)

const editorConfig = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter({
    mimeType: 'image/webp',
  }),
  imageOrienter: createDefaultImageOrienter(),
  // The markup editor default options, tools, shape style controls
  ...markup_editor_defaults,
  ...plugin_filter_defaults,
  ...plugin_finetune_defaults,
  ...plugin_frame_defaults,
  // This handles complex shapes like arrows / frames
  shapePreprocessor: createDefaultShapePreprocessor(),
  // The icons and labels to use in the user interface (required)
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_sticker_locale_en_gb,
    ...plugin_fill_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...plugin_frame_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
}

export type PinturaEditorInstance = PrimitivePinturaEditor

export interface PinturaEditorProps
  extends PinturaEditorDefaultOptions,
    PinturaComponentEvents {
  className?: string
  defaultImageState?: PinturaEditorDefaultOptions['imageState']
  stickers?: StickerGroup[]
  circularCrop?: boolean
}

export const PinturaEditor = React.forwardRef<
  PinturaEditorInstance,
  PinturaEditorProps
>(
  (
    {
      defaultImageState,
      enableButtonExport = false,
      fillOptions,
      markupEditorShapeStyleControls,
      willRenderCanvas,
      stickers,
      circularCrop,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <PrimitivePinturaEditor
      ref={forwardedRef}
      {...editorConfig}
      className={cn('PinturaEditor', className)}
      enableButtonExport={enableButtonExport}
      fillOptions={[
        ...Object.values(createDefaultColorOptions()),
        ...(fillOptions ?? []),
      ]}
      markupEditorShapeStyleControls={{
        ...createMarkupEditorShapeStyleControls({
          fontFamilyOptions: [
            ['avenir-light', 'Avenir Light'],
            ['avenir-roman', 'Avenir Roman'],
            ["'Merriweather', serif", 'Merriweather'],
            ...createDefaultFontFamilyOptions(),
          ],
        }),
        ...markupEditorShapeStyleControls,
      }}
      stickers={[
        ['Emoji', ['🎉', '😄', '👍', '👎', '🍕']],
        ['Markers', [StickerOne, StickerTwo, StickerThree]],
        ...(stickers ?? []),
      ]}
      {...(circularCrop
        ? {
            willRenderCanvas: (shapes, state) => {
              let res: ReturnType<
                Required<PinturaEditorProps>['willRenderCanvas']
              > = shapes

              // Seehttps://pqina.nl/pintura/docs/v8/examples/circular-crop-overlay/
              if (circularCrop && state.utilVisibility.crop > 0) {
                // Get variable shortcuts to the crop selection rect
                const {x, y, width, height} = state.selectionRect

                res = {
                  ...res,
                  // Now we add an inverted ellipse shape to the interface shapes array
                  interfaceShapes: [
                    {
                      x: x + width * 0.5,
                      y: y + height * 0.5,
                      rx: width * 0.5,
                      ry: height * 0.5,
                      opacity: state.utilVisibility.crop,
                      inverted: true,
                      backgroundColor: [...state.backgroundColor, 0.5],
                      strokeWidth: 1,
                      strokeColor: [...state.lineColor],
                    } as any,
                    // Spread all existing interface shapes onto the array
                    ...shapes.interfaceShapes,
                  ],
                }
              }

              // res = willRenderCanvas?.({...shapes, ...res}, state) ?? res

              return res
            },
          }
        : willRenderCanvas
          ? {willRenderCanvas}
          : undefined)}
      {...restProps}
    />
  ),
)

// MARK: – Node utils

export function createButtonPinturaNode(id: string, props: PinturaNodeOptions) {
  return createNode('Button', id, {
    id,
    ...props,
    class: cn(
      '!bg-orange-500 !text-grey-50 !rounded-default !active:bg-orange-500 !hover:bg-orange-500',
      '!font-body !leading-compact',
      '!transition-colors !duration-100 !ease-in-out',
      '!h-[2.25rem] !px-5 [&_.PinturaButtonLabel]:!text-ds-sm',
      props.class,
    ),
  })
}

export function createDropdownPinturaNode(
  id: string,
  props: PinturaNodeOptions,
) {
  return createNode('Dropdown', id, {
    id,
    ...props,
    class: cn(
      '!bg-orange-500 !text-grey-50 !rounded-default !active:bg-orange-500 !hover:bg-orange-500',
      '!font-body !leading-compact',
      '!transition-colors !duration-100 !ease-in-out',
      '!h-[2.25rem] !px-5 [&_.PinturaButtonLabel]:!text-ds-sm',
      props.class,
    ),
  })
}
