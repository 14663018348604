import * as Yup from 'yup'
import {FormikHandlers, FormikHelpers, FormikState} from 'formik'
import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useRef, useState} from 'react'
import {
  api,
  useCreateTabDiscountMutation,
  useDeleteTabDiscountMutation,
  useUpdateTabDiscountMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {SearchForm} from 'src/components'
import {useFormik, useLiveRef} from '@cheddarup/react-util'

export interface DiscountProps {
  discounts: Api.TabDiscount[]
}

const Discount = ({discounts}: DiscountProps) => {
  const [discountToEdit, setDiscountToEdit] = useState<Api.TabDiscount | null>(
    null,
  )

  return (
    <div className="py-4 text-ds-sm">
      <WebUI.VStack className="max-w-screen-sm gap-4">
        <WebUI.VStack className="gap-3">
          {discounts.map((discount) => (
            <DiscountItem
              key={discount.id}
              discount={discount}
              onEdit={(d) => setDiscountToEdit(d)}
            />
          ))}
        </WebUI.VStack>

        {discounts.length > 1 && <AllowMultipleDiscount />}

        <WebUI.HStack>
          <DiscountCodeModal
            key={discountToEdit?.id ?? 'new'}
            discount={discountToEdit}
            visible={discountToEdit !== null}
            disclosure={
              <WebUI.DialogDisclosure>Add Code</WebUI.DialogDisclosure>
            }
            onDidHide={() => setDiscountToEdit(null)}
          />
        </WebUI.HStack>
      </WebUI.VStack>
    </div>
  )
}

// MARK: – DiscountItem

interface DiscountItemProps extends React.ComponentPropsWithoutRef<'div'> {
  discount: Api.TabDiscount
  onEdit: (discount: Api.TabDiscount) => void
}

const DiscountItem = ({discount, onEdit, ...restProps}: DiscountItemProps) => {
  const urlParams = useParams()
  const deleteDiscountMutation = useDeleteTabDiscountMutation()

  const collectionId = Number(urlParams.collection)

  return (
    <div {...restProps}>
      <WebUI.HStack className={'items-center gap-3 rounded border px-4 py-3'}>
        <WebUI.VStack className="grow gap-2">
          <WebUI.Button variant="link" onClick={() => onEdit(discount)}>
            {discount.code}
          </WebUI.Button>
          <WebUI.Text className="font-light text-ds-sm">
            {discount.options.calculationMethod === 'fixed'
              ? Util.formatAmount(discount.options.fixedAmount)
              : `${discount.options.percentage}%`}{' '}
            off{' '}
            {discount.options.restrictToSpecificItems
              ? 'specific items'
              : discount.options.appliesTo === 'one_item_most_expensive'
                ? 'most expensive item'
                : 'all items'}
            {discount.options.appliesTo === 'total_order_with_minimum' &&
            discount.options.minimumPurchase
              ? ` with purchase of at least ${Util.formatAmount(
                  discount.options.minimumPurchase,
                )}`
              : ' with no minimum purchase'}
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.DeprecatedTooltip label="Delete">
          <WebUI.IconButton
            size="default_alt"
            onClick={() => {
              deleteDiscountMutation.mutate({
                pathParams: {
                  tabId: collectionId,
                  discountId: discount.id,
                },
              })
            }}
          >
            <WebUI.PhosphorIcon icon="x" />
          </WebUI.IconButton>
        </WebUI.DeprecatedTooltip>
      </WebUI.HStack>
    </div>
  )
}

// MARK: – AllowMultipleDiscount

const AllowMultipleDiscount = (props: WebUI.CheckboxProps) => {
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)

  const allowMultipleDiscountAlertRef = useRef<WebUI.DialogInstance>(null)
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const updateCollectionMutation = useUpdateTabMutation()

  return (
    <>
      <WebUI.Checkbox
        size="compact"
        checked={!collection?.options?.onlyAllowOneDiscountPerPurchase}
        onChange={(event) => {
          if (event.target.checked) {
            allowMultipleDiscountAlertRef.current?.show()
          } else {
            updateCollectionMutation.mutate({
              pathParams: {
                tabId: collectionId,
              },
              body: {
                options: {
                  onlyAllowOneDiscountPerPurchase: true,
                },
              },
            })
          }
        }}
        {...props}
      >
        Allow more than one discount code to be used at checkout.
      </WebUI.Checkbox>

      <WebUI.Alert
        ref={allowMultipleDiscountAlertRef}
        aria-label="Allow multiple discount codes."
        initialVisible={false}
      >
        <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <>
              An item could have a deeper discount than intented if multiple
              codes are allowed. Want to test ?
              <WebUI.Anchor
                className="[&_>_.Anchor-content]:font-light"
                href="#"
              >
                Preview your collection.
              </WebUI.Anchor>
            </>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                execute={async () => {
                  await updateCollectionMutation.mutateAsync({
                    pathParams: {
                      tabId: collectionId,
                    },
                    body: {
                      options: {
                        onlyAllowOneDiscountPerPurchase: false,
                      },
                    },
                  })
                }}
              >
                Yes, I'm sure
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton>Cancel</WebUI.AlertCancelButton>
            </>
          }
        />
      </WebUI.Alert>
    </>
  )
}

// MARK: - DiscountCodeModal

interface DiscountCodeModalProps extends WebUI.ModalProps {
  discount: Api.TabDiscount | null
}

interface DiscountValues {
  fixedAmount: string
  percentage: string
  appliesTo: Api.TabDiscount['options']['appliesTo']
  calculationMethod: Api.TabDiscount['options']['calculationMethod']
  code: string
  minimumPurchase: string | number
  restrictToSpecificItems: boolean
  eligibleItemIds: number[]
}

const DiscountCodeModal = ({
  discount,
  ...restProps
}: DiscountCodeModalProps) => {
  const discountModalRef = useRef<WebUI.DialogInstance>(null)
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)

  const createDiscountMutation = useCreateTabDiscountMutation()
  const updateDiscountMutation = useUpdateTabDiscountMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik<DiscountValues>({
    validationSchema: Yup.object().shape({
      calculationMethod: Yup.string()
        .required('Required')
        .matches(/^(fixed|percentage)*/, 'not available value'),
      fixedAmount: Yup.number().when('calculationMethod', {
        is: 'fixed',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      percentage: Yup.number().when('calculationMethod', {
        is: 'percentage',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      code: Yup.string()
        .required('Required')
        .matches(
          /^((?![!"#$%&'()*+,./:;<=>?@[\\\]^_{|}-]).)*$/,
          'cannot include (%, &, ", #, etc.)',
        ),
      appliesTo: Yup.string().required('Required'),
      minimumPurchase: Yup.number().when('appliesTo', {
        is: 'total_order_with_minimum',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      restrictToSpecificItems: Yup.boolean(),
      eligibleItemIds: Yup.array().of(Yup.number()),
    }),
    initialValues: {
      fixedAmount: discount?.options.fixedAmount
        ? String(discount?.options.fixedAmount)
        : '',
      percentage: discount?.options.percentage
        ? String(discount?.options.percentage)
        : '',
      appliesTo: discount?.options.appliesTo ?? 'total_order',
      calculationMethod: discount?.options.calculationMethod ?? 'fixed',
      code: discount?.code ?? '',
      minimumPurchase: discount?.options.minimumPurchase ?? '',
      restrictToSpecificItems:
        discount?.options.restrictToSpecificItems ?? false,
      eligibleItemIds: discount?.options.eligibleItemIds ?? [],
    },
    onSubmit: async (values, formikHelpers) => {
      const body = {
        code: values.code,
        options: {
          fixedAmount: values.fixedAmount ? Number(values.fixedAmount) : 0,
          percentage: values.percentage ? Number(values.percentage) : 0,
          appliesTo: values.appliesTo,
          calculationMethod: values.calculationMethod,
          minimumPurchase: values.minimumPurchase
            ? Number(values.minimumPurchase)
            : 0,
          restrictToSpecificItems: values.restrictToSpecificItems,
          eligibleItemIds: values.restrictToSpecificItems
            ? values.eligibleItemIds
            : [],
        },
      }

      if (discount) {
        try {
          await updateDiscountMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              discountId: discount.id,
            },
            body,
          })
          growlActions.show('success', {
            body: `Discount "${values.code}" was updated successfully`,
            title: 'Success!',
          })
          discountModalRef.current?.hide()
        } catch (err: any) {
          growlActions.show('error', {body: err, title: 'Sorry!'})
        }
      } else {
        try {
          await createDiscountMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body,
          })
          growlActions.show('success', {
            body: `Discount "${values.code}" was added successfully`,
            title: 'Success!',
          })
          discountModalRef.current?.hide()
          formikHelpers.resetForm()
        } catch (err: any) {
          const errCode = err.response?.data?.errors?.code ?? []
          let errBody = ''
          switch (errCode[0]) {
            case 'has already been taken':
              errBody = `The code "${err.response.data?.code}" is already taken. Please use another one.`
              break
            default:
              errBody = 'Error happened. Please try again'
          }
          growlActions.show('error', {body: errBody, title: 'Sorry!'})
        }
      }
    },
  })

  return (
    <WebUI.Modal
      aria-label="Discount Code Form"
      ref={discountModalRef}
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg [&_>_.ModalContentView]:overflow-hidden"
      initialVisible={false}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader variant="compact">Discount Code</WebUI.ModalHeader>
      <WebUI.Form
        className="h-full min-h-0 justify-between [&_>_.Form-inner]:h-full [&_>_.Form-inner]:gap-0"
        onSubmit={formik.handleSubmit}
      >
        <WebUI.VStack className="min-h-0 grow justify-between gap-8 overflow-y-auto px-8 py-4">
          <WebUI.VStack className="max-w-[540px] gap-4">
            <WebUI.Heading as="h3">Details</WebUI.Heading>

            <WebUI.FormField label="Code Name" error={formik.errors.code}>
              <WebUI.Input
                name="code"
                placeholder="(e.g. VIP123)"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            <WebUI.FormFieldGroup>
              <WebUI.FormField
                label="Discount Type"
                error={formik.errors.calculationMethod}
              >
                <WebUI.DropdownSelect
                  className="h-[38px]"
                  name="calculationMethod"
                  placeholder="Discount Method"
                  value={formik.values.calculationMethod}
                  onValueChange={(value) =>
                    formik.setFieldValue('calculationMethod', value)
                  }
                  onBlur={formik.handleBlur}
                >
                  <WebUI.DropdownSelectOption value="fixed">
                    Fixed amount off
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value="percentage">
                    Percentage off
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
              </WebUI.FormField>
              {formik.values.calculationMethod === 'fixed' && (
                <WebUI.FormField
                  label="Amount"
                  error={formik.errors.fixedAmount}
                >
                  <WebUI.AmountInput
                    name="fixedAmount"
                    value={formik.values.fixedAmount}
                    onValueChange={(newFixedAmount) =>
                      formik.setFieldValue(
                        'fixedAmount',
                        String(newFixedAmount),
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              )}
              {formik.values.calculationMethod === 'percentage' && (
                <WebUI.FormField
                  label="Percentage"
                  error={formik.errors.percentage}
                >
                  <WebUI.PercentInput
                    name="percentage"
                    value={formik.values.percentage}
                    onValueChange={(newPercent) =>
                      formik.setFieldValue('percentage', newPercent)
                    }
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              )}
            </WebUI.FormFieldGroup>
            <WebUI.FormField label="Applicable Items">
              <WebUI.DropdownSelect<'all' | 'specific'>
                value={
                  formik.values.restrictToSpecificItems ? 'specific' : 'all'
                }
                onValueChange={(value) => {
                  formik.setFieldValue(
                    'restrictToSpecificItems',
                    value === 'specific',
                  )
                  if (
                    value === 'specific' &&
                    formik.values.appliesTo === 'one_item_most_expensive'
                  ) {
                    formik.setFieldValue('appliesTo', null)
                  }
                }}
                onBlur={formik.handleBlur}
              >
                <WebUI.DropdownSelectOption value="all">
                  Apply to all items
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="specific">
                  Apply to specific items
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelect>
            </WebUI.FormField>
            <WebUI.FormField error={formik.errors.appliesTo}>
              <WebUI.CheckboxGroup
                name="appliesTo"
                aria-label="Discount Applies To"
              >
                {!formik.values.restrictToSpecificItems && (
                  <WebUI.Checkbox
                    checked={
                      formik.values.appliesTo === 'one_item_most_expensive'
                    }
                    value="one_item_most_expensive"
                    onChange={(e) =>
                      formik.setFieldValue(
                        'appliesTo',
                        formik.values.appliesTo === e.target.value
                          ? 'total_order'
                          : e.target.value,
                      )
                    }
                  >
                    Apply discount to one item (most expensive)
                  </WebUI.Checkbox>
                )}
                <WebUI.Checkbox
                  checked={
                    formik.values.appliesTo === 'total_order_with_minimum'
                  }
                  value="total_order_with_minimum"
                  onChange={(e) =>
                    formik.setFieldValue(
                      'appliesTo',
                      formik.values.appliesTo === e.target.value
                        ? 'total_order'
                        : e.target.value,
                    )
                  }
                >
                  Require minimum purchase to receive discount
                </WebUI.Checkbox>
              </WebUI.CheckboxGroup>
            </WebUI.FormField>
            {formik.values.appliesTo === 'total_order_with_minimum' && (
              <WebUI.FormField
                label="Minimum Purchase"
                error={formik.errors.minimumPurchase}
              >
                <WebUI.AmountInput
                  className="max-w-[160px]"
                  name="minimumPurchase"
                  autoFocus
                  value={formik.values.minimumPurchase}
                  onValueChange={(newMinimumPurchase) =>
                    formik.setFieldValue('minimumPurchase', newMinimumPurchase)
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            )}
          </WebUI.VStack>

          {formik.values.restrictToSpecificItems && (
            <MinimumPurchaseDiscountTable
              collectionId={collectionId}
              formik={formik}
            />
          )}
        </WebUI.VStack>

        <WebUI.HStack
          className={'items-center justify-end border-t bg-trueWhite px-4 py-5'}
        >
          <WebUI.Button
            type="submit"
            variant="primary"
            size="large"
            loading={formik.isSubmitting}
          >
            Save
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.Form>
    </WebUI.Modal>
  )
}

// MARK: - MinimumPurchaseDiscountTable

interface MinimumPurchaseDiscountTableProps
  extends Omit<WebUI.TableViewProps<Partial<Api.TabItem>>, 'data' | 'columns'> {
  collectionId: number
  formik: FormikState<DiscountValues> &
    FormikHelpers<DiscountValues> &
    FormikHandlers
}

const MinimumPurchaseDiscountTable: React.FC<
  MinimumPurchaseDiscountTableProps
> = ({collectionId, formik, ...restProps}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const {data: tabItems, isPending} = api.tabItems.list.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
    },
    {
      select: (items) =>
        items
          .filter((item) => item.amount_type !== 'open')
          .filter(
            Util.fuzzyFilterIterator(searchTerm, {
              iterator: (item) => item.name,
            }),
          ),
    },
  )

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Partial<Api.TabItem>>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((item) => item.name, {
        id: 'name',
      }),
    ],
    [columnHelper],
  )

  const selectionState = useMemo(
    () =>
      formik.values.eligibleItemIds.reduce(
        (o, key) => Object.assign(o, {[key]: true}),
        {},
      ),
    [formik.values.eligibleItemIds],
  )
  const selectionStateRef = useLiveRef(selectionState)

  return (
    <WebUI.VStack>
      <WebUI.TableView<Partial<Api.TabItem>>
        className={
          'min-h-[300px] overflow-y-auto pb-3 [&_.TableViewCell]:min-h-[30px] [&_.TableViewCell]:items-center [&_.TableViewCell]:px-1 [&_.TableViewRow]:px-0'
        }
        enableRowSelection
        selectAllVisible={false}
        loading={isPending}
        columns={columns}
        data={tabItems ?? []}
        getRowId={(row) => String(row.id)}
        state={{rowSelection: selectionState}}
        onRowSelectionChange={(updater) => {
          const newRowSelection =
            typeof updater === 'function'
              ? updater(selectionStateRef.current)
              : updater
          const newSelectedItemIdsIds = Object.keys(
            Util.pickBy(newRowSelection, (v) => v === true),
          ).map(Number)
          formik.setFieldValue('eligibleItemIds', newSelectedItemIdsIds)
        }}
        {...restProps}
      >
        {(table) => (
          <WebUI.VStack className="-order-1 gap-4">
            <WebUI.Heading as="h3">Select Items</WebUI.Heading>
            <WebUI.HStack className="mb-4 items-center justify-between">
              <WebUI.Checkbox
                state={
                  table.getIsSomeRowsSelected()
                    ? 'indeterminate'
                    : table.getIsAllRowsSelected()
                }
                onChange={table.getToggleAllRowsSelectedHandler()}
              >
                Select All
              </WebUI.Checkbox>
              <SearchForm
                size="compact"
                iconClassName="text-gray400"
                className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
                onTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
                initialValues={{term: ''}}
                placeholder="Search by item name"
              />
            </WebUI.HStack>
          </WebUI.VStack>
        )}
      </WebUI.TableView>
    </WebUI.VStack>
  )
}

export default Discount
