// Inspired by https://github.com/primer/react/blob/main/packages/react/src/NavList/NavList.tsx

import React, {useContext, useId, useMemo, useState} from 'react'
import * as AriaKit from '@ariakit/react'
import {motion} from 'framer-motion'
import {Button, ButtonProps} from './Button'
import {Merge} from '@cheddarup/util'
import {PhosphorIcon} from '../icons'
import {cn} from '../utils'
import {Anchor} from './Anchor'
import {genericForwardRef} from '@cheddarup/react-util'

export interface NavListProps
  extends Omit<
      AriaKit.CompositeStoreProps,
      'items' | 'defaultItems' | 'orientation' | 'setActiveId' | 'setItems'
    >,
    AriaKit.RoleProps<'nav'> {
  onActiveIdChange?: AriaKit.CompositeStoreProps['setActiveId']
}

export const NavList = React.forwardRef<HTMLElement, NavListProps>(
  (
    {
      defaultActiveId,
      activeId,
      onActiveIdChange,
      includesBaseElement = false,
      focusWrap = true,
      focusLoop = true,
      focusShift = true,
      virtualFocus = false,
      store,
      rtl,
      ...restProps
    },
    forwardedRef,
  ) => {
    const composieStore = AriaKit.useCompositeStore({
      defaultActiveId,
      activeId,
      setActiveId: onActiveIdChange,
      includesBaseElement,
      focusWrap,
      focusLoop,
      focusShift,
      virtualFocus,
      store,
      rtl,
      orientation: 'vertical',
    })

    return (
      <AriaKit.CompositeProvider store={composieStore}>
        <AriaKit.Composite
          render={<AriaKit.Role.nav ref={forwardedRef} {...restProps} />}
        />
        <AriaKit.CompositeTypeahead />
      </AriaKit.CompositeProvider>
    )
  },
)

// MARK: – NavListContent

export interface NavListContentProps extends AriaKit.RoleProps<'ul'> {}

export const NavListContent = React.forwardRef<
  HTMLUListElement,
  NavListContentProps
>((props, forwardedRef) => (
  <AriaKit.CompositeGroup
    render={<AriaKit.Role.ul ref={forwardedRef} {...props} />}
  />
))

// MARK: – NavListItem

export interface NavListItemProps extends AriaKit.RoleProps<'li'> {}

export const NavListItem = React.forwardRef<HTMLLIElement, NavListItemProps>(
  ({className, ...restProps}, forwardedRef) => (
    <AriaKit.Role.li
      ref={forwardedRef}
      className={cn('h-10', className)}
      {...restProps}
    />
  ),
)

// MARK: – NavListItemContent

type NavListItemContentProps<T extends React.ElementType = 'button'> =
  AriaKit.CompositeItemProps<T>

const NavListItemContent = genericForwardRef(
  <T extends React.ElementType = 'button'>(
    {className, id: idProp, disabled, ...restProps}: NavListItemContentProps<T>,
    forwardedRef: React.Ref<T>,
  ) => {
    const id = idProp ?? useId()
    const navListSubNav = useContext(InternalNavListSubNavContext)

    return (
      <AriaKit.CompositeItem
        ref={forwardedRef as any}
        className={cn(
          'rounded-default px-1 py-2 font-medium outline-offset-2 transition-colors data-[focus-visible]:bg-trueWhite',
          {1: 'pl-7'}[navListSubNav?.depth ?? 0],
          className,
        )}
        id={id}
        disabled={disabled ?? (!!navListSubNav && !navListSubNav.visible)}
        {...restProps}
      />
    )
  },
)

// MARK: – InternalNavListSubNavContext

interface InternalNavListSubNavContextValue {
  depth: number
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const InternalNavListSubNavContext = React.createContext(
  null as InternalNavListSubNavContextValue | null,
)

// MARK: – NavListSubNav

export interface NavListSubNavProps extends NavListContentProps {
  defaultVisible?: boolean
  disclosure: React.ReactNode
}

export const NavListSub = React.forwardRef<
  HTMLUListElement,
  NavListSubNavProps
>(
  (
    {defaultVisible, disclosure, className, style, children, ...restProps},
    forwardedRef,
  ) => {
    const [visible, setVisible] = useState(defaultVisible ?? false)
    const navListSubNav = useContext(InternalNavListSubNavContext)

    const contextValue: InternalNavListSubNavContextValue = useMemo(
      () => ({
        depth: navListSubNav?.depth ? navListSubNav.depth + 1 : 1,
        visible,
        setVisible,
      }),
      [visible, navListSubNav?.depth],
    )

    return (
      <InternalNavListSubNavContext.Provider value={contextValue}>
        {disclosure}
        <NavListContent
          data-visible={visible}
          ref={forwardedRef}
          className={cn('relative', className)}
          render={
            <motion.ul
              initial={{
                opacity: 0,
                height: 0,
                scale: 0.9,
              }}
              animate={
                visible
                  ? {
                      display: 'block',
                      opacity: 1,
                      height: 'auto',
                      scale: 1,
                      transition: {duration: 0.15},
                    }
                  : {
                      opacity: 0,
                      transition: {duration: 0.15},
                      transitionEnd: {display: 'none'},
                      height: 0,
                      scale: 0.9,
                    }
              }
            />
          }
          {...restProps}
        >
          {children}
        </NavListContent>
      </InternalNavListSubNavContext.Provider>
    )
  },
)

export function useNavListSubNav() {
  return useContext(InternalNavListSubNavContext)
}

// MARK: – NavListSubDisclosure

export interface NavListSubDisclosureProps
  extends Merge<React.ComponentPropsWithoutRef<'button'>, ButtonProps> {}

export const NavListSubDisclosure = React.forwardRef<
  HTMLButtonElement,
  NavListSubDisclosureProps
>(
  (
    {
      'aria-expanded': ariaExpanded,
      variant = 'text',
      className,
      onClick,
      disabled,
      ...restProps
    },
    forwardedRef,
  ) => {
    const navListSub = useContext(InternalNavListSubNavContext)

    return (
      <NavListItemContent
        disabled={disabled ?? navListSub?.depth !== 1}
        render={
          <Button
            aria-expanded={ariaExpanded ?? navListSub?.visible}
            ref={forwardedRef}
            className={cn(
              '!px-1 h-full w-full focus:focus-visible:shadow-none',
              {1: 'pl-0'}[navListSub?.depth ?? 0],
              className,
            )}
            variant={variant}
            iconBefore={
              <PhosphorIcon
                className={`text-ds-base transition-transform [.Button[aria-expanded="true"]_>_.Button-iconBefore_>_&]:rotate-90`}
                icon="caret-right"
              />
            }
            onClick={(event) => {
              onClick?.(event)

              if (event.defaultPrevented) {
                return
              }

              navListSub?.setVisible((prevVisible) => !prevVisible)
            }}
            {...restProps}
          />
        }
      />
    )
  },
)

// MARK: – NavListItemAnchor

export interface NavListItemAnchorProps
  extends Merge<NavListItemContentProps, React.ComponentPropsWithoutRef<'a'>> {}

export const NavListItemAnchor = React.forwardRef<
  HTMLAnchorElement,
  NavListItemAnchorProps
>(({className, ...restProps}, forwardedRef) => (
  <NavListItemContent<'a'>
    ref={forwardedRef as any}
    className={cn('h-full w-full aria-current-page:text-orange-500', className)}
    render={<Anchor iconBefore={<div className="h-4 w-4" />} />}
    {...restProps}
  />
))
