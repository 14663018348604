import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import {useLocation, useNavigate} from 'react-router-dom'
import React, {useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCheckSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from '@cheddarup/api-client'
import {trackUserSubscriptionChangeEvent} from 'src/helpers/analytics'

const PlansPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {data: session} = api.auth.session.useSuspenseQuery()
  const {data: planName} = api.subscriptions.invoices.useQuery(undefined, {
    select: ({plan}) => plan.name,
  })
  const [recommendedPlan] = useQueryParam(
    'recommendedPlan',
    withDefault(StringParam, ''),
  )
  const checkSubscriptionMutation = useCheckSubscriptionMutation()
  const updateSubscriptionMutation = useUpdateSubscriptionMutation()
  const growlActions = WebUI.useGrowlActions()

  const isPause = session.capabilities.plan === 'pause'
  const isProUser = !!session.capabilities.subscribed_to_pro
  const isTeamUser = !!session.capabilities.subscribed_to_team
  const isFree = !isPause && !isProUser && !isTeamUser
  const hasPro = isProUser && !isTeamUser
  const recommendedEnabled = !!recommendedPlan

  const onGoProClick = async () => {
    if (isTeamUser) {
      const checkResults = await checkSubscriptionMutation.mutateAsync({
        body: {plan: 'free'},
      })
      const results = checkResults?.results ?? []
      if (results.some((r) => !!r.error)) {
        navigate('basic/error')
      } else if (
        results.some((r) => r.warning === 'managers_will_be_deleted')
      ) {
        navigate('pro/warning')
      } else {
        navigate('downgrade/pro')
      }
    } else {
      navigate(
        {pathname: 'pro', search: location.search},
        {state: location.state},
      )
    }
  }

  return (
    <WebUI.Modal
      className={
        '[&_>_.ModalContentView]:max-w-screen-lg [&_>_.ModalContentView]:overflow-y-auto [&_>_.ModalContentView]:bg-gray100'
      }
      onDidHide={() => {
        // hack for react-native WebView to detect url change
        window.location.hash = Util.makeShortId()
        navigate({pathname: '../..', search: location.search})
      }}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader
        className="bg-trueWhite pt-16 pb-[120px]"
        variant="compact"
      >
        <WebUI.VStack className="gap-2">
          <WebUI.Heading className="text-ds-2xl">
            Choose the plan that’s right for you.
          </WebUI.Heading>
          <WebUI.Text className="text-ds-md">
            {recommendedEnabled ? (
              <>
                Based on the paid features you’ve chosen, we recommend the{' '}
                <span className="font-bold text-orange-500">
                  {Util.capitalize(recommendedPlan)} Plan
                </span>
                .
              </>
            ) : (
              'We offer simple, affordable plans that you can start and stop at any time.'
            )}
          </WebUI.Text>
        </WebUI.VStack>
      </WebUI.ModalHeader>

      <WebUI.VStack className="mt-[-104px] gap-4 p-8 sm:flex-row *:sm:flex-[1_1_0px]">
        <SubscriptionPlanPanel
          current={isFree}
          title={{
            children: 'Basic',
            className: 'text-gray800',
          }}
          subtitle="Free for all"
          monthlyCost="$0"
          features={basicPanelFeatures}
          ctaBtn={{
            disabled: isFree || updateSubscriptionMutation.isPending,
            children: isFree ? 'Your Current Plan' : 'Downgrade to Basic',
            onClick: async () => {
              const checkResults = await checkSubscriptionMutation.mutateAsync({
                body: {plan: 'free'},
              })
              const results = checkResults?.results ?? []
              const errors = results.filter((result) => result.error)
              const warnings = results.filter((result) => result.warning)
              if (errors.length > 0) {
                navigate('basic/error')
              } else if (
                warnings.length !== 0 ||
                session.capabilities.plan === 'team'
              ) {
                navigate('downgrade/basic')
              } else if (session.capabilities.plan === 'pro') {
                navigate(`pause/${isTeamUser ? 'basic' : 'pro'}`)
              } else {
                try {
                  const newPlan = await updateSubscriptionMutation.mutateAsync({
                    body: {plan: 'free', source_id: ''},
                  })
                  trackUserSubscriptionChangeEvent(newPlan)
                  growlActions.show('success', {
                    body: "You've been downgraded",
                    title: 'Back to Basics',
                  })
                } catch (err: any) {
                  let errMsg = err.message || err.response?.data?.error
                  if (errMsg === 'tabs_have_balances') {
                    errMsg =
                      'You must withdraw your balance prior to downgrading'
                  }

                  if (errMsg) {
                    growlActions.show('error', {
                      title: 'Error',
                      body: errMsg,
                    })
                  }
                }
              }
            },
          }}
        />
        <SubscriptionPlanPanel
          recommended={recommendedPlan === 'pro'}
          recommendedEnabled={recommendedEnabled}
          isPause={isPause}
          current={hasPro}
          title={{
            className: recommendedEnabled ? 'text-gray800' : 'text-orange-500',
            children: 'Pro',
          }}
          subtitle="Made for individuals"
          monthlyCost="$10"
          monthlyCostLabel={
            <span>
              / month billed
              <br />
              annually
            </span>
          }
          ctaBtn={
            planName === 'PRO (Monthly)'
              ? () => (
                  <>
                    <WebUI.Button
                      className="w-full"
                      size="large"
                      variant="secondary"
                      disabled
                    >
                      Your Plan: Pro (Monthly)
                    </WebUI.Button>
                    <WebUI.Button
                      className="mt-4 w-full"
                      size="large"
                      variant="primary"
                      onClick={() => onGoProClick()}
                    >
                      Save with PRO (Annual)
                    </WebUI.Button>
                  </>
                )
              : {
                  children:
                    hasPro || isPause
                      ? 'Your Current Plan'
                      : isFree
                        ? 'Upgrade to Pro'
                        : 'Downgrade to Pro',
                  disabled:
                    hasPro || isPause || updateSubscriptionMutation.isPending,
                  onClick: hasPro ? () => {} : () => onGoProClick(),
                }
          }
          features={proPanelFeatures}
        />
        <SubscriptionPlanPanel
          recommended={recommendedPlan === 'team'}
          recommendedEnabled={recommendedEnabled}
          current={isTeamUser}
          title={{
            children: 'Team',
            className: recommendedEnabled ? 'text-gray800' : 'text-teal-600',
          }}
          subtitle="Made for groups"
          monthlyCost="$30"
          monthlyCostLabel={
            <span>
              / month billed
              <br />
              annually
            </span>
          }
          ctaBtn={
            planName === 'Team (Monthly)'
              ? () => (
                  <>
                    <WebUI.Button
                      className="w-full"
                      size="large"
                      variant="secondary"
                      disabled
                    >
                      Your Plan: Team (Monthly)
                    </WebUI.Button>
                    <WebUI.Button
                      className="mt-4 w-full"
                      size="large"
                      variant="primary"
                      onClick={() =>
                        navigate(
                          {pathname: 'team-upgrade', search: location.search},
                          {state: location.state},
                        )
                      }
                    >
                      Save with Team (Annual)
                    </WebUI.Button>
                  </>
                )
              : {
                  children: isTeamUser
                    ? 'Your Current Plan'
                    : 'Upgrade to Team',
                  disabled: isTeamUser || updateSubscriptionMutation.isPending,
                  onClick: () => {
                    if (!isTeamUser) {
                      navigate(
                        {pathname: 'team-upgrade', search: location.search},
                        {state: location.state},
                      )
                    }
                  },
                }
          }
          features={teamPanelFeatures}
        />
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

// MARK: FeesTooltip

interface FeesTooltipProps {
  plan: string
  creditCardFee: string
  eChecksFee?: string
}

const FeesTooltip: React.FC<FeesTooltipProps> = ({
  plan,
  creditCardFee,
  eChecksFee,
}) => (
  <WebUI.Tooltip>
    <WebUI.TooltipAnchor className="inline text-teal-600">
      fees
    </WebUI.TooltipAnchor>
    <WebUI.TooltipContent className="p-5" variant="light">
      <WebUI.VStack className="items-start">
        <WebUI.Text className="font-bold">{plan} Plan Fees</WebUI.Text>
        <WebUI.Text className="font-light">
          Credit Cards: {creditCardFee}
        </WebUI.Text>
        {eChecksFee && (
          <WebUI.Text className="font-light">eChecks: {eChecksFee}</WebUI.Text>
        )}
      </WebUI.VStack>
    </WebUI.TooltipContent>
  </WebUI.Tooltip>
)

const basicPanelFeatures = [
  {
    children: 'Free forever',
    check: false,
    className: 'font-normal text-ds-base',
  },
  {children: 'Unlimited custom pages', check: true},
  {children: 'Up to 5 items per collection', check: true},
  {
    children: 'Up to 1 form or sign up per page',
    check: true,
  },
  {children: 'Track online and offline payments', check: true},
  {children: 'One-click spreadsheet export', check: true},
  {children: 'Share with QR Codes', check: true},
  {children: 'Share with “Text to Pay”', check: true},
  {children: 'Point of Sale with Tap on Mobile', check: true},
  {children: 'Event Codes for volunteers', check: true},
  {children: 'Customize your URL', check: true},
  {children: 'Free and unlimited withdrawals', check: true},
  {
    children: (
      <span>
        Payments by credit card, cash, Link, Apple Pay and Google Pay with
        industry-low <FeesTooltip plan="Basic" creditCardFee="3.95% + $0.95" />
      </span>
    ),
    check: true,
  },
]

const proPanelFeatures = [
  {
    children: 'Everything in Basic, plus:',
    check: false,
    className: 'font-normal text-ds-base',
  },
  {children: 'Unlimited items', check: true},
  {
    children: 'Unlimited forms with file upload and e-signature question types',
    check: true,
  },
  {children: 'Unlimited sign ups per page', check: true},
  {children: 'Unlimited waivers per page', check: true},
  {children: 'Multiple banner images or video', check: true},
  {children: 'Item variations', check: true},
  {children: 'Fundraising goal marker', check: true},
  {children: 'Set start and stop times', check: true},
  {children: 'Count-down timer', check: true},
  {children: 'Discount codes', check: true},
  {children: 'Access codes', check: true},
  {children: 'Built-in shipping', check: true},
  {children: 'Visitor reporting', check: true},
  {children: 'Customized receipts', check: true},
  {children: 'Include taxes', check: true},
  {children: 'Multiple item images', check: true},
  {children: 'Payments by eChecks', check: true},
  {
    children: (
      <span>
        Lower{' '}
        <FeesTooltip
          plan="Pro"
          creditCardFee="3.59% + $0.59"
          eChecksFee="0.75% (min $1)"
        />
      </span>
    ),
    check: true,
  },
]

const teamPanelFeatures = [
  {
    children: 'Everything in Pro, plus:',
    check: false,
    className: 'font-normal text-ds-base',
  },
  {
    children: 'Add managers and share data while keeping banking info private',
    check: true,
  },
  {
    children:
      'Group Page — a custom landing page featuring your collections and more',
    check: true,
  },
  {children: 'Account-wide reporting and reconciliation', check: true},
  {children: 'Recurring payments', check: true},
  {children: 'Waitlists', check: true},
  {children: 'Ticketing', check: true},
  {children: 'Integrations', check: true},
  {
    children: 'Robust page details: FAQs, time, date, and physical address',
    check: true,
  },
  {children: 'Custom branding', check: true},
  {
    children: (
      <span>
        Lowest{' '}
        <FeesTooltip
          plan="Team"
          creditCardFee="3.59% + $0.59"
          eChecksFee="Flat $0.59"
        />
      </span>
    ),
    check: true,
  },
]

// MARK: – SubscriptionPlanPanel

interface SubscriptionPlanPanelProps {
  isPause?: boolean
  current?: boolean
  title: React.ComponentPropsWithoutRef<'div'>
  subtitle: React.ReactNode
  monthlyCost: string
  monthlyCostLabel?: React.ReactNode
  ctaBtn?: (() => React.ReactNode) | React.ComponentPropsWithoutRef<'button'>
  features: Array<React.ComponentPropsWithoutRef<'div'> & {check?: boolean}>
  recommended?: boolean
  recommendedEnabled?: boolean
}

const SubscriptionPlanPanel = ({
  isPause,
  current,
  title,
  subtitle,
  monthlyCost,
  monthlyCostLabel,
  ctaBtn,
  features,
  recommended = false,
  recommendedEnabled = false,
}: SubscriptionPlanPanelProps) => {
  const media = WebUI.useMedia()
  const [featuresVisible, setFeaturesVisible] = useState(false)

  return (
    <WebUI.Panel
      className={WebUI.cn(
        'p-4 pb-6',
        recommended && 'border-[2px] border-tint bg-teal-80',
      )}
      variant="shadowed"
    >
      <div className="sm:text-center">
        <div
          {...title}
          className={WebUI.cn('font-normal text-ds-3xl', title.className)}
        >
          {title.children}
          {!media.sm && <span> {monthlyCost}</span>}
        </div>
        <p className="mb-4 font-normal text-ds-base text-gray800">
          {media.sm
            ? subtitle
            : Number(monthlyCost.slice(1)) === 0
              ? ''
              : '/ month billed annually'}
        </p>
        {media.sm && (
          <WebUI.HStack className="items-center justify-center gap-3">
            <WebUI.Text className="font-light text-ds-5xl">
              {monthlyCost}
            </WebUI.Text>
            <WebUI.Text className="text-left text-ds-sm">
              {monthlyCostLabel}
            </WebUI.Text>
          </WebUI.HStack>
        )}
        {typeof ctaBtn === 'function' ? (
          <div className="mt-4">{ctaBtn()}</div>
        ) : (
          ctaBtn && (
            <WebUI.Button
              {...ctaBtn}
              className="mt-4 w-full"
              size="large"
              variant={
                recommended
                  ? 'primary'
                  : current || isPause
                    ? 'secondary'
                    : title.children === 'Pro' && !recommendedEnabled
                      ? 'primary'
                      : 'default'
              }
            />
          )
        )}
        {title.children === 'Pro' && isPause && (
          <WebUI.Button
            className="mt-4 w-full"
            size="large"
            variant="primary"
            onClick={(ctaBtn as any)?.onClick}
          >
            Reactivate Pro
          </WebUI.Button>
        )}
      </div>
      <WebUI.Separator className="my-4 sm:my-8" variant="primary" />
      {!media.sm && (
        <WebUI.HStack className="justify-between gap-3 font-normal text-base">
          <div>{subtitle}</div>
          <div
            className="cursor-pointer text-teal-600"
            onClick={() =>
              setFeaturesVisible((prevFeaturesVisible) => !prevFeaturesVisible)
            }
          >
            {featuresVisible ? 'Hide Features' : 'View Features'}
          </div>
        </WebUI.HStack>
      )}
      {(media.sm || featuresVisible) && (
        <WebUI.VStack className="mt-8 gap-5 text-gray800 sm:mt-0">
          {features.map(({check, ...feature}, key) => (
            <WebUI.HStack
              key={key}
              {...feature}
              className={WebUI.cn(
                'items-baseline gap-2 font-normal text-ds-sm leading-[18px]',
                feature.className,
              )}
            >
              <span>
                {check && (
                  <WebUI.PhosphorIcon
                    className="text-teal-600"
                    icon="check-bold"
                  />
                )}
              </span>
              <span>{feature.children}</span>
            </WebUI.HStack>
          ))}
        </WebUI.VStack>
      )}
    </WebUI.Panel>
  )
}

export default PlansPage
