import {cn, Heading, PhosphorIcon, PhosphorIconName} from '@cheddarup/web-ui'

export interface SharePanelHeadingProps
  extends React.ComponentPropsWithoutRef<'div'> {
  iconName: PhosphorIconName
  children: React.ReactNode
}

export const SharePanelHeading = ({
  iconName,
  className,
  children,
  ...restProps
}: SharePanelHeadingProps) => (
  <div
    className={cn('SharePanelHeading flex', 'items-center gap-3', className)}
    {...restProps}
  >
    <PhosphorIcon
      icon={iconName}
      className="SharePanelHeading-icon text-ds-xl text-teal-600"
    />
    <Heading className="SharePanelHeading-heading text-ds-md" as="h2">
      {children}
    </Heading>
  </div>
)
