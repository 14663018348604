import {useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useResetVerificationMutation,
  useStartCodeVerificationMutation,
} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {read2FAError} from 'src/helpers/error-formatting'
import {BooleanParam, useQueryParam} from 'use-query-params'

const RESEND_SECONDS_INTERVAL = 45

const TwoFactorAuthenticationPage = () => {
  const navigate = useNavigate()
  const [secondaryEmail] = useQueryParam('secondaryEmail', BooleanParam)
  const [verificationCode, setVerificationCode] = useState('')
  const [password, setPassword] = useState('')
  const [secondsLeftToResend, setSecondsLeftToResend] = useState(
    RESEND_SECONDS_INTERVAL,
  )
  const {data: lastTwoNumbers} = api.auth.session.useSuspenseQuery(undefined, {
    select: (session) => session.user.profile.phone?.lastTwoNumbers,
  })
  const {data: canRequestSecurityCode} = api.auth.session.useSuspenseQuery(
    undefined,
    {
      select: (session) => session.user.canRequestSecurityCode,
    },
  )
  const startCodeVerificationMutation = useStartCodeVerificationMutation()
  const resetVerificationMutation = useResetVerificationMutation()
  const growlActions = WebUI.useGrowlActions()

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeftToResend((prev) => Math.max(prev - 1, 0))
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const codeReadyToResend = secondsLeftToResend < 1

  return (
    <WebUI.Alert
      aria-label="Reset two-factor authentication"
      className={
        'sm:[&_>_.ModalContentView]:max-w-screen-md sm:[&_>_.ModalContentView]:overflow-y-auto'
      }
      initialVisible
      onDidHide={() => navigate('../')}
    >
      <WebUI.AlertHeader>Reset Two-Factor Authentication</WebUI.AlertHeader>

      <WebUI.AlertContentView
        text={
          <WebUI.VStack className="gap-4">
            <WebUI.Text className="border-grey-300 border-b pb-4 font-light">
              <span className="font-semibold text-orange-500">IMPORTANT:</span>{' '}
              Taking the actions below will clear out the two-factor
              authentication on your account, which puts any current or future
              balance at risk should an unauthorized person gain access to your
              account. If you proceed with removing the current device, you are
              choosing to accept these risks. In addition, upon removing the
              current device, it is crucial to immediately add a new two-factor
              authentication device to keep your account protected.
            </WebUI.Text>
            <WebUI.Text className="font-light">
              To proceed with your two-factor device reset, enter your account
              password and the code sent to the device on file (***) ***-**{' '}
              {lastTwoNumbers ?? ''} below.
            </WebUI.Text>

            <WebUI.FormField className="w-3/5" label="Account Password">
              <WebUI.Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </WebUI.FormField>
            <WebUI.FormField
              className="w-3/5"
              label="Enter Code Sent to Device"
            >
              <WebUI.Input
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </WebUI.FormField>
            <WebUI.Button
              disabled={!codeReadyToResend}
              variant={codeReadyToResend ? 'link' : 'text'}
              onClick={async () => {
                if (!codeReadyToResend) {
                  return
                }

                setSecondsLeftToResend(RESEND_SECONDS_INTERVAL)
                await startCodeVerificationMutation.mutateAsync()
                growlActions.show('success', {
                  title: 'Code Sent',
                  body: 'Verification code sent to your phone number',
                })
              }}
            >
              {codeReadyToResend
                ? 'Resend a new code'
                : `Seconds left to resend code: ${secondsLeftToResend}`}
            </WebUI.Button>

            <WebUI.Separator variant="primary" />

            <span className="font-normal">
              Don't have access to the current device on file?
            </span>

            <div>
              Two-factor authentication is in place to keep your account safe.
              As a result, there are limited means for resetting it. The easiest
              method for resetting two-factor authentication is to make a
              concerted effort to locate the current device.{' '}
              <Link
                variant="primary"
                replace
                to="../about-mailed-security-code"
              >
                Learn more.
              </Link>{' '}
              If that's not possible, see the alternative(s) for resetting
              two-factor authentication below:
            </div>

            <ul className="list-inside">
              <li>
                Use your{' '}
                <Link
                  variant="primary"
                  replace
                  to="../enter-backup-security-code"
                  className="underline"
                >
                  backup security code.
                </Link>{' '}
                <Link
                  variant="primary"
                  replace
                  to="../about-backup-security-code"
                >
                  Learn more.
                </Link>
              </li>
              {canRequestSecurityCode ? (
                <li>
                  Recieved a{' '}
                  <Link
                    variant="primary"
                    replace
                    to="../request-mailed-security-code"
                    className="underline"
                  >
                    mailed security code to your physical address
                  </Link>{' '}
                  on file.{' '}
                  <Link
                    variant="primary"
                    replace
                    to="../about-mailed-security-code"
                  >
                    Learn more.
                  </Link>
                </li>
              ) : null}
            </ul>
          </WebUI.VStack>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              disabled={
                verificationCode.length < 4 ||
                password.length === 0 ||
                resetVerificationMutation.isPending
              }
              loading={resetVerificationMutation.isPending}
              onClick={async () => {
                try {
                  await resetVerificationMutation.mutateAsync({
                    body: {
                      security: {token: verificationCode},
                      password,
                      resetSecondaryEmail: secondaryEmail || false,
                    },
                  })
                  growlActions.clear()
                  growlActions.show('success', {
                    title: 'Success',
                    body: 'Your two-factor authentication phone number has been reset',
                  })
                  navigate(-1)
                } catch (err) {
                  growlActions.show('error', {
                    title: 'Error',
                    body:
                      read2FAError(err) ||
                      'Something went wrong. Failed to reset your two-factor authentication phone number',
                  })
                }
              }}
            >
              Continue
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}

export default TwoFactorAuthenticationPage
