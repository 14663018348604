import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useMemo, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {SearchForm} from 'src/components'
import FooterBig from 'src/components/FooterBig'
import {LinkButton} from 'src/components/LinkButton'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'

export interface TemplatesLayoutProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const TemplatesLayout: React.FC<TemplatesLayoutProps> = ({
  className,
  children,
  ...restProps
}) => {
  useEffect(() => {
    window.zE?.('webWidget', 'hide')

    return () => {
      window.zE?.('webWidget', 'show')
    }
  }, [])

  const [searchTerm, setSearchTerm] = useQueryParam(
    'q',
    withDefault(StringParam, ''),
  )
  const templatesQuery = api.templates.list.useQuery({
    queryParams: {
      orderBy: {
        key: 'categories',
      },
    },
  })

  const groupTypes = Util.unique(
    templatesQuery.data?.flatMap((template) =>
      (template?.options?.template?.groups ?? []).map((g) => g.type),
    ) ?? [],
  )
  const categories = Util.unique(
    templatesQuery.data?.flatMap((template) =>
      (template?.options?.template?.categories ?? []).map((c) => c.type),
    ) ?? [],
  )

  return (
    <>
      <WebUI.VStack
        className={WebUI.cn('shrink-0 grow basis-auto bg-grey-200', className)}
        {...restProps}
      >
        <WebUI.VStack
          className={
            'w-full max-w-[calc(1080px+2*theme(spacing.4))] items-center gap-9 self-center py-16 *:px-4'
          }
        >
          <WebUI.VStack className="items-center gap-2 text-center">
            <WebUI.Heading className="font-accentAlt text-h-1 leading-normal sm:leading-[unset]">
              Explore our Template Library
            </WebUI.Heading>
            <WebUI.Text className="max-w-[720px] text-balance text-ds-lg">
              Tap into some inspiration with our collection templates from
              Cheddar Up as well as other group organizers. See something you
              like? Make it your own!
            </WebUI.Text>
          </WebUI.VStack>

          <SearchForm
            containerClassName="max-w-[800px] w-full"
            className={
              'w-full [&_.Input]:rounded-xl [&_.Input]:bg-trueWhite [&_.Input]:px-7 [&_.Search-cancelButton]:right-6'
            }
            placeholder="Search by keyword"
            values={useMemo(() => ({term: searchTerm}), [searchTerm])}
            onTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
          />

          {(groupTypes.length > 0 || categories.length > 0) && (
            <TabTemplatesFilters
              className="self-stretch"
              groupTypes={groupTypes}
              categories={categories}
            />
          )}
        </WebUI.VStack>
        {children}
      </WebUI.VStack>
      <FooterBig />
    </>
  )
}

// MARK: – TabTemplatesFilter

interface TabTemplatesFiltersProps
  extends React.ComponentPropsWithoutRef<'div'> {
  groupTypes: Api.TabTemplateGroupType[]
  categories: Api.TemplateCollectionCategory[]
}

const TabTemplatesFilters: React.FC<TabTemplatesFiltersProps> = ({
  groupTypes,
  categories,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const categoriesContainerRef = useRef<HTMLDivElement>(null)
  const visibleCategoriesCount = WebUI.useSizeSelector(
    categoriesContainerRef,
    ([width]) => Math.round(width / 150) - 1,
  )

  const selectedCategory =
    urlParams.filterType === 'category' ? urlParams.filterValue : null
  const selectedGroupType =
    urlParams.filterType === 'group-type' ? urlParams.filterValue : null

  const categoriesOrdered = Util.sort(categories).asc((cat) => {
    const index = templateCategoriesPickerOrder.indexOf(cat)
    return index > -1 ? index : Number.MAX_SAFE_INTEGER
  })
  const visibleCategories = categoriesOrdered.slice(0, visibleCategoriesCount)
  const collapsedCategories = categoriesOrdered.slice(visibleCategoriesCount)

  const isCollapsedCategorySelected =
    selectedCategory &&
    (collapsedCategories as string[]).includes(selectedCategory)

  return (
    <WebUI.HStack
      className={WebUI.cn('max-w-full gap-3', className)}
      {...restProps}
    >
      {groupTypes.length > 0 && (
        <>
          <WebUI.DropdownSelect
            popoverClassName="[&_.PopoverContent-body]:rounded-[10px] [&_.DropdownSelectOption]:rounded [&_.DropdownSelectOption]:px-4"
            size="compact"
            placeholder="Group Type"
            value={selectedGroupType}
            disclosure={
              <WebUI.DropdownSelectButton
                as={WebUI.RoundedButton}
                size="default"
                variant={
                  selectedGroupType == null ? 'outlined' : 'secondaryAlt'
                }
                iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
              />
            }
          >
            {groupTypes.map((groupType) => (
              <WebUI.DropdownSelectOption
                key={groupType}
                value={groupType}
                as={LinkButton}
                variant={
                  groupType === selectedGroupType ? 'secondaryAlt' : 'text'
                }
                to={`group-type/${groupType}`}
              >
                {groupType || 'Group Type'}
              </WebUI.DropdownSelectOption>
            ))}
          </WebUI.DropdownSelect>

          <WebUI.Separator orientation="vertical" variant="black" />
        </>
      )}

      <WebUI.HStack
        ref={categoriesContainerRef}
        className="flex-auto gap-3 overflow-hidden"
      >
        {visibleCategories.map((categoryTitle, idx) => {
          const isSelected = categoryTitle === selectedCategory

          return (
            <WebUI.RoundedButton
              key={`${categoryTitle}-${idx}`}
              className={
                'w-[140px] transition-colors duration-100 ease-in-out [&_.Button-content]:ml-0 [&_.Checkbox-icon]:hidden'
              }
              size="default"
              as={LinkButton}
              variant={isSelected ? 'secondaryAlt' : 'outlined'}
              to={isSelected ? '.' : `category/${categoryTitle}`}
            >
              {categoryTitle}
            </WebUI.RoundedButton>
          )
        })}

        {collapsedCategories.length > 0 && (
          <WebUI.DropdownSelect
            popoverClassName="[&_.PopoverContent-body]:rounded-[10px] [&_.DropdownSelectOption]:rounded [&_.DropdownSelectOption]:px-4"
            size="compact"
            placeholder="More"
            value={selectedCategory}
            disclosure={
              <WebUI.DropdownSelectButton
                as={WebUI.RoundedButton}
                size="default"
                variant={
                  isCollapsedCategorySelected ? 'secondaryAlt' : 'outlined'
                }
                iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
              />
            }
          >
            {collapsedCategories.map((categoryTitle) => (
              <WebUI.DropdownSelectOption
                key={categoryTitle}
                value={categoryTitle}
                as={LinkButton}
                variant={
                  categoryTitle === selectedCategory ? 'secondaryAlt' : 'text'
                }
                to={`category/${categoryTitle}`}
              >
                {categoryTitle}
              </WebUI.DropdownSelectOption>
            ))}
          </WebUI.DropdownSelect>
        )}
      </WebUI.HStack>
    </WebUI.HStack>
  )
}

// MARK: – Helpers

export const templateCategoriesPickerOrder: Api.TemplateCollectionCategory[] = [
  'Fundraisers',
  'Events',
  'Online Stores',
  'Dues & Fees',
  'Registrations',
  'Sign Ups',
  'Camps & Classes',
  'Group Gifts',
  'Group Travel',
  'Staff Picks',
  'Seasonal Collections',
]
