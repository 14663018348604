import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import * as Util from '@cheddarup/util'
import {api, useDeleteAlertMutation} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import config from 'src/config'

import {alertCategoryToTitleMap} from './AlertDetailsPage'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'

const AlertsPage = () => {
  const alertsQuery = api.alerts.list.useQuery(undefined, {
    select: (alerts) => Util.sort(alerts).desc((a) => a.created_at),
  })
  return (
    <AccountSettingsContentLayout
      heading="Alerts"
      body={
        <WebUI.Text className="font-light">
          Have questions?{' '}
          <WebUI.Anchor
            className="[&_>_.Anchor-content]:font-light"
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${config.supportEmail}?subject=Contact My Cheddar Up Support`}
          >
            Contact our support team
          </WebUI.Anchor>
        </WebUI.Text>
      }
    >
      <AccountSettingsContentCard className="gap-5 py-7">
        {alertsQuery.data?.map((alert) => (
          <Alert key={alert.id} alert={alert} />
        ))}
      </AccountSettingsContentCard>
    </AccountSettingsContentLayout>
  )
}

// MARK: – Alert

interface AlertProps extends React.ComponentPropsWithRef<'div'> {
  alert: Api.Alert
}

const Alert = ({alert, className, ...restProps}: AlertProps) => {
  const deleteAlertMutation = useDeleteAlertMutation()
  return (
    <WebUI.HStack
      className={WebUI.cn(
        'h-10 items-center gap-4 rounded bg-grey-200 px-4 font-normal text-ds-sm',
        className,
      )}
      {...restProps}
    >
      <WebUI.PhosphorIcon
        className="shrink-0 text-ds-md text-orange-500"
        icon="warning-circle-fill"
      />
      <span>{Util.formatDateAs(alert.created_at)}</span>
      <WebUI.Ellipsis className="grow">
        {alert.detail.header ?? alertCategoryToTitleMap[alert.category]}
      </WebUI.Ellipsis>
      <Link variant="primary" to={String(alert.id)}>
        Details
      </Link>
      <WebUI.IconButton
        size="default_alt"
        variant="ghost"
        onClick={() =>
          deleteAlertMutation.mutate({
            pathParams: {
              alertId: alert.id,
            },
          })
        }
      >
        <WebUI.PhosphorIcon icon="x" />
      </WebUI.IconButton>
    </WebUI.HStack>
  )
}

export default AlertsPage
