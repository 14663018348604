import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface AccountInputLockProps
  extends Util.SetOptional<WebUI.LockProps, 'content'>,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'content' | 'children'> {}

export const AccountInputLock = React.forwardRef<
  HTMLDivElement,
  AccountInputLockProps
>(
  (
    {className, content = <AccountInputLockContent />, locked, ...restProps},
    forwardedRef,
  ) => {
    const lock = (
      <WebUI.Lock
        ref={forwardedRef}
        className={WebUI.cn(
          'AccountInputLock inline-flex items-center',
          className,
        )}
        locked={locked}
        content={content}
        {...restProps}
      />
    )

    if (locked) {
      return (
        <WebUI.Tooltip>
          <WebUI.TooltipAnchor>{lock}</WebUI.TooltipAnchor>

          <WebUI.TooltipContent variant="light">
            This information is hidden for security purposes. To edit it after
            your account is verified, contact{' '}
            <WebUI.Anchor
              href="https://support.cheddarup.com/hc/en-us/requests/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cheddar Up Support
            </WebUI.Anchor>{' '}
            for assistance.
          </WebUI.TooltipContent>
        </WebUI.Tooltip>
      )
    }

    return lock
  },
)

// MARK: – AccountInputLockContent

export interface AccountInputLockContentProps
  extends WebUI.TextProps,
    React.ComponentPropsWithoutRef<'span'> {}

export const AccountInputLockContent = React.forwardRef<
  HTMLSpanElement,
  AccountInputLockContentProps
>(({className, ...restProps}, forwardedRef) => (
  <WebUI.Text
    ref={forwardedRef}
    className={WebUI.cn('AccountInputLockContent text-teal-600', className)}
    {...restProps}
  >
    Provided
  </WebUI.Text>
))
