import {HTMLMotionProps, motion} from 'framer-motion'
import React, {
  useContext,
  useId,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  Menu as ReakitMenu,
  MenuArrow as ReakitMenuArrow,
  MenuButton as ReakitMenuButton,
  MenuButtonOptions as ReakitMenuButtonOptions,
  MenuGroup as ReakitMenuGroup,
  MenuGroupOptions as ReakitMenuGroupOptions,
  MenuInitialState as ReakitMenuInitialState,
  MenuItem as ReakitMenuItem,
  MenuItemOptions as ReakitMenuItemOptions,
  MenuOptions as ReakitMenuOptions,
  MenuSeparator as ReakitMenuSeparator,
  MenuSeparatorOptions as ReakitMenuSeparatorOptions,
  MenuStateReturn as ReakitMenuStateReturn,
  useMenuState as useReakitMenuState,
} from 'reakit'
import {
  ForwardRefComponent,
  useForkRef,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'

import {Button} from './Button'
import {usePreventBodyScroll} from '../hooks'
import {cn} from '../utils'

interface InternalMenuContextValue extends ReakitMenuStateReturn {}

const InternalMenuContext = React.createContext({} as InternalMenuContextValue)

// MARK: - Menu

export interface MenuInstance extends ReakitMenuStateReturn {}
export interface MenuProps
  extends ReakitMenuInitialState,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  children?: React.ReactNode | ((menu: MenuInstance) => React.ReactNode)
  initialVisible?: boolean
  onVisibleChange?: (newVisible: boolean) => void
  onValuesChange?: (newValues: Record<string, unknown>) => void
  onDidShow?: () => void
  onDidHide?: () => void
}

export const Menu = React.forwardRef<MenuInstance, MenuProps>(
  (
    {
      children,
      initialVisible,
      onVisibleChange,
      onValuesChange,
      onDidShow,
      onDidHide,
      baseId,
      unstable_virtual,
      rtl,
      orientation,
      currentId,
      loop,
      wrap,
      unstable_values,
      visible,
      animated = true,
      modal = true,
      placement,
      unstable_fixed,
      unstable_flip,
      unstable_offset,
      gutter = 8,
      unstable_preventOverflow,
    },
    forwardedRef,
  ) => {
    const menu = useReakitMenuState({
      baseId,
      unstable_virtual,
      rtl,
      orientation,
      currentId,
      loop,
      wrap,
      unstable_values,
      visible: visible ?? initialVisible,
      animated,
      modal,
      placement,
      unstable_fixed,
      unstable_flip,
      unstable_offset,
      gutter,
      unstable_preventOverflow,
    })
    const menuRef = useLiveRef(menu)
    const onVisibleChangeRef = useLiveRef(onVisibleChange)
    const onValuesChangeRef = useLiveRef(onValuesChange)
    const onDidShowRef = useLiveRef(onDidShow)
    const onDidHideRef = useLiveRef(onDidHide)

    useImperativeHandle(forwardedRef, () => menu, [menu])

    useUpdateEffect(() => {
      if (visible != null) {
        menuRef.current.setVisible(visible)
      }
    }, [visible])

    const values = useMemo(() => unstable_values, [unstable_values])
    useUpdateEffect(() => {
      if (values != null) {
        for (const [key, val] of Object.entries(values)) {
          menuRef.current.unstable_setValue(key, val)
        }
      }
    }, [values])

    useUpdateEffect(() => {
      if (!menu.animating) {
        onVisibleChangeRef.current?.(menu.visible)
        if (menu.visible) {
          onDidShowRef.current?.()
        } else {
          onDidHideRef.current?.()
        }
      }
    }, [menu.visible, menu.animating])

    useUpdateEffect(() => {
      onValuesChangeRef.current?.(menu.unstable_values)
    }, [menu.unstable_values])

    return (
      <InternalMenuContext.Provider value={menu}>
        {typeof children === 'function' ? children(menu) : children}
      </InternalMenuContext.Provider>
    )
  },
)

// MARK: - MenuButton

export interface MenuButtonProps
  extends Omit<ReakitMenuButtonOptions, keyof ReakitMenuStateReturn> {}

export const MenuButton = React.forwardRef(
  ({as: Comp = Button, children, className, ...restProps}, forwardedRef) => {
    const menu = useContext(InternalMenuContext)
    return (
      <ReakitMenuButton
        ref={forwardedRef}
        className={cn('MenuButton', className)}
        {...menu}
        {...restProps}
      >
        {(props: any) => <Comp {...props}>{children}</Comp>}
      </ReakitMenuButton>
    )
  },
) as ForwardRefComponent<typeof Button, MenuButtonProps>

// MARK: - MenuList

// TODO: Get rid of warning: "Can't determine if the element is a native tabbable element because `ref` wasn't passed to the component."

export interface MenuListProps
  extends Omit<ReakitMenuOptions, keyof ReakitMenuStateReturn> {
  children?: React.ReactNode | ((menu: MenuInstance) => React.ReactNode)
  arrow?: boolean
  shrinkable?: boolean
  strictWidth?: boolean
  fullSize?: boolean
  portal?: boolean
  variant?: 'default' | 'nav'
}

export const MenuList = React.forwardRef(
  (
    {
      as: Comp = 'div',
      shrinkable = true,
      strictWidth = false,
      fullSize = false,
      children,
      id: idProp,
      className,
      style,
      arrow,
      preventBodyScroll = true,
      variant = 'default',
      ...restProps
    },
    forwardedRef,
  ) => {
    const menu = useContext(InternalMenuContext)
    const [ownElement, setOwnElement] = useState<HTMLElement | null>(null)
    const ref = useForkRef(forwardedRef, setOwnElement)

    const id = idProp ?? useId()

    usePreventBodyScroll(ownElement, id, preventBodyScroll && menu.visible)

    return (
      <ReakitMenu
        ref={ref}
        aria-label="Menu"
        id={id}
        className={cn('MenuList', 'focus:outline-none', className)}
        style={{
          ...(fullSize && {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            transform: 'none',
          }),
          ...style,
        }}
        unstable_autoFocusOnShow={false}
        unstable_autoFocusOnHide={false}
        preventBodyScroll={false}
        {...menu}
        {...restProps}
      >
        {(props) => {
          if (!menu.visible && !menu.animating) {
            return <>{null}</>
          }

          const referenceRect =
            menu.unstable_referenceRef.current?.getBoundingClientRect()

          return (
            <Comp {...props}>
              <MenuListInnerView
                className={cn('MenuList-inner', fullSize && 'h-full w-full')}
              >
                {arrow && (
                  <ReakitMenuArrow
                    className={
                      'MenuList-arrow bg-transparent leading-compact [&_.fill]:fill-trueWhite [&_.stroke]:fill-transparent'
                    }
                    {...menu}
                  />
                )}
                <div
                  className={cn(
                    'MenuList-body',
                    'flex flex-col overflow-auto bg-trueWhite shadow-z4 *:flex-0',
                    fullSize && 'h-full',
                    {
                      default: 'rounded p-1',
                      nav: 'rounded-extended p-4',
                    }[variant],
                  )}
                  style={{
                    ...(referenceRect && {
                      width: strictWidth ? referenceRect.width : undefined,
                      minWidth: referenceRect.width,
                      maxWidth: (() => {
                        if (menu.placement.startsWith('left')) {
                          return `${referenceRect.left - 16}px`
                        }
                        if (menu.placement.startsWith('right')) {
                          return `calc(100vw - ${referenceRect.right + 16}px)`
                        }
                        return 'calc(100vw - 32px)'
                      })(),
                      maxHeight: (() => {
                        if (shrinkable && menu.placement.startsWith('top')) {
                          return `${referenceRect.top - 16}px`
                        }
                        if (shrinkable && menu.placement.startsWith('bottom')) {
                          return `calc(${
                            CSS.supports('max-height: 100dvh')
                              ? '100dvh'
                              : '100vh'
                          } - ${referenceRect.bottom + 16}px)`
                        }
                        return `calc(${
                          CSS.supports('max-height: 100dvh')
                            ? '100dvh'
                            : '100vh'
                        } - 32px)`
                      })(),
                    }),
                    ...(fullSize && {
                      width: '100%',
                      minWidth: '100%',
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }),
                  }}
                >
                  {typeof children === 'function' ? children(menu) : children}
                </div>
              </MenuListInnerView>
            </Comp>
          )
        }}
      </ReakitMenu>
    )
  },
) as ForwardRefComponent<'div', MenuListProps>

const MenuListInnerView = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, forwardedRef) => {
  const menu = useContext(InternalMenuContext)
  return menu.animated ? (
    <motion.div
      ref={forwardedRef}
      initial={{scale: 0.9, y: '-1rem', opacity: 0}}
      animate={
        menu.visible
          ? {
              scale: 1,
              y: 0,
              opacity: 1,
              transition: {duration: 0.15},
            }
          : {
              scale: 0.9,
              y: '-1rem',
              opacity: 0,
              transition: {duration: 0.15},
              transitionEnd: {display: 'none'},
            }
      }
      onAnimationComplete={() => setTimeout(() => menu.stopAnimation(), 0)}
      {...(props as HTMLMotionProps<'div'>)}
    />
  ) : (
    <div ref={forwardedRef} {...props} />
  )
})

// MARK: - MenuGroup

export interface MenuGroupProps
  extends Omit<ReakitMenuGroupOptions, keyof ReakitMenuStateReturn> {}

export const MenuGroup = React.forwardRef(
  ({className, ...restProps}, forwardedRef) => {
    const menu = useContext(InternalMenuContext)
    return (
      <ReakitMenuGroup
        ref={forwardedRef}
        className={cn(
          'MenuGroup',
          '[display:inherit] [flex-direction:inherit]',
          className,
        )}
        {...menu}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'div', MenuGroupProps>

// MARK: - MenuItem

export interface MenuItemProps
  extends Omit<ReakitMenuItemOptions, keyof ReakitMenuStateReturn> {
  hideOnClick?: boolean
}

export const MenuItem = React.forwardRef(
  (
    {
      hideOnClick = true,
      as = Button,
      variant = 'ghost',
      className,
      onClick,
      ...restProps
    },
    forwardedRef,
  ) => {
    const menu = useContext(InternalMenuContext)
    return (
      <ReakitMenuItem
        ref={forwardedRef}
        as={as}
        className={cn(
          'MenuItem',
          'min-h-[2.5rem] justify-start rounded-none px-[1em] text-left',
          'focus:focus-visible:bg-buttonGhostHoverBackground focus:focus-visible:shadow-none',
          'hover:bg-buttonGhostHoverBackground hover:shadow-none',
          '[&>_.Button-iconBefore]:text-[1.2em] [&_>_.Button-iconBefore]:mr-[2.6em]',
          className,
        )}
        variant={variant}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          onClick?.(event)
          if (hideOnClick && !event.defaultPrevented) {
            menu.hide()
          }
        }}
        {...menu}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<typeof Button, MenuItemProps>

// MARK: - MenuSeparator

export interface MenuSeparatorProps
  extends Omit<ReakitMenuSeparatorOptions, keyof ReakitMenuStateReturn> {}

export const MenuSeparator = React.forwardRef(
  ({className, ...restProps}, forwardedRef) => {
    const menu = useContext(InternalMenuContext)
    return (
      <ReakitMenuSeparator
        ref={forwardedRef}
        aria-orientation={menu.orientation}
        className={cn(
          'MenuSeparator',
          'mx-0 my-2 p-0',
          'aria-orientation-vertical:h-0 aria-orientation-vertical:w-auto aria-orientation-vertical:border-b',
          'aria-orientation-horizontal:h-auto aria-orientation-horizontal:w-0 aria-orientation-horizontal:border-r',
          className,
        )}
        {...menu}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'hr', MenuSeparatorProps>
