import * as Yup from 'yup'
import React, {useEffect, useRef, useState} from 'react'
import {CalendarDateTime} from '@internationalized/date'
import {useFormik} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {
  api,
  endpoints,
  getEndpointKey,
  useCreateTabFaqMutation,
  useDeleteTabFaqMutation,
  useQueryClient,
  useUpdateTabFaqMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {yupCalendarDateTimeSchema} from 'src/helpers/YupInternationalizedDateSchema'
import {
  FeatureKey,
  PlanUpgradeButton,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'
import {
  RoutableTab,
  RoutableTabPanel,
  RoutableTabs,
} from 'src/components/RoutableTabs'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'
import {PageContainer} from 'src/components/PageContainer'

import {type TabFaqValue, TabFaqsEdit, makeTabFaqValue} from './TabFaqsEdit'
import {SettingDisclosureSwitch} from '../CollectionSettingsPage/SettingDisclosureSwitch'
import {CollectionSettingsUpgradePlanButton} from '../CollectionSettingsPage/CollectionSettingsUpgradePlanButton'

const CUSTOM_CONTRIBUTOR_NAME_MAX_LEN = 40

export interface CollectionAdditionalInformationFormValues {
  contact_manager_id: number | null
  options: {
    paymentGoal: {
      enabled: boolean
      value: string
    }
    groupPage?: {
      shared: boolean
      order: number
    }
    infoBlockSettings: {
      faqs: {
        enabled: boolean
        order: number
      }
      time: {
        enabled: boolean
        startTime: CalendarDateTime | null
        endTime: CalendarDateTime | null
        timeZone: string
        order: number
      }
      location: {
        enabled: boolean
        address: string
        longitude: number | null
        latitude: number | null
        order: number
      }
      nonProfitStatus: {
        enabled: boolean
        order: number
      }
      promoteSharing: {
        enabled: boolean
        order: number
      }
      totalCollected: {
        enabled: boolean
        order: number
      }
      payerList: {
        enabled: boolean
        customContributorName: string
        order: number
      }
    }
  }
  faqs: TabFaqValue[]
}

export type CollectionAdditionalInformationFormik = ReturnType<
  typeof useFormik<CollectionAdditionalInformationFormValues>
>

Yup.setLocale({mixed: {notType: 'Must be a number'}})

export interface CollectionAdditionalInformationFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  collectionId: number
  onDidSave?: () => void
}

export const CollectionAdditionalInformationForm: React.FC<
  CollectionAdditionalInformationFormProps
> = ({collectionId, onDidSave, className, ...restProps}) => {
  const [enforcedPremiumMeta, setEnforcedPremiumMeta] = useState<
    Util.PartialDeep<Api.TabPremiumMeta>
  >({})

  const [selectedTabId, setSelectedTabId] = useState('participation')
  const queryClient = useQueryClient()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const {data: acceptedManagers} = api.managers.list.useQuery(undefined, {
    select: (managers) => managers.filter((m) => m.accepted_at) ?? [],
  })
  const tabsRef = useRef<WebUI.TabsInstance>(null)
  const growlActions = WebUI.useGrowlActions()
  const updateCollectionMutation = useUpdateTabMutation()
  const createTabFaqMutation = useCreateTabFaqMutation()
  const updateTabFaqMutation = useUpdateTabFaqMutation()
  const deleteTabFaqMutation = useDeleteTabFaqMutation()

  const faqAnswerMaxChars = 200
  const isBasic = !collection?.is_pro && !collection?.is_team
  const isPublished = collection && collection.status !== 'draft'
  const enforceAddlGatFeatures = !collection?.options.doNotEnforceAddlGated
  // const orgVerified = false // TODO: waiting for api

  const formik = useFormik<CollectionAdditionalInformationFormValues>({
    validationSchema: Yup.object().shape({
      options: Yup.object().shape({
        paymentGoal: Yup.object().shape({
          enabled: Yup.boolean(),
          value: Yup.number().when('enabled', {
            is: true,
            // biome-ignore lint/suspicious/noThenProperty:
            then: (schema) => schema.required('Required'),
          }),
        }),
        infoBlockSettings: Yup.object().shape({
          time: Yup.object().shape({
            enabled: Yup.boolean(),
            startTime: yupCalendarDateTimeSchema().when(
              'enabled',
              ([enabled], schema) =>
                enabled ? schema.required('Required') : schema.nullable(),
            ),
            endTime: Yup.lazy(() =>
              yupCalendarDateTimeSchema().when(
                'startTime',
                ([startTime], schema) =>
                  startTime
                    ? schema
                        .min(startTime, "Can't be earlier than start time")
                        .nullable()
                    : schema.nullable(),
              ),
            ),
            timeZone: Yup.string().when(['enabled'], ([enabled], schema) =>
              enabled ? schema.required() : schema.nullable(),
            ),
          }),
          location: Yup.object().shape({
            enabled: Yup.boolean(),
            address: Yup.string().when('enabled', ([enabled], schema) =>
              enabled ? schema.required('Required') : schema.nullable(),
            ),
          }),
        }),
      }),
      faqs: Yup.array().of(
        Yup.object({
          question: Yup.string().required('Required'),
          answer: Yup.string()
            .required('Required')
            .max(faqAnswerMaxChars, "Can't exceed ${max}"),
        }),
      ),
    }),
    initialValues: {
      contact_manager_id: collection?.contact_manager_id ?? null,
      options: {
        paymentGoal: {
          enabled: collection?.options.paymentGoal?.enabled ?? false,
          value: String(collection?.options.paymentGoal?.value || ''),
        },
        groupPage: {
          shared: collection?.options?.groupPage?.shared ?? false,
          order: collection?.options?.groupPage?.order ?? 0,
        },
        infoBlockSettings: {
          faqs: {
            enabled:
              collection?.options.infoBlockSettings?.faqs?.enabled ?? false,
            order: collection?.options.infoBlockSettings?.faqs?.order ?? 0,
          },
          promoteSharing: {
            enabled:
              collection?.options?.infoBlockSettings?.promoteSharing?.enabled ??
              false,
            order:
              collection?.options?.infoBlockSettings?.promoteSharing?.order ??
              0,
          },
          nonProfitStatus: {
            enabled:
              collection?.options?.infoBlockSettings?.nonProfitStatus
                ?.enabled ?? false,
            order:
              collection?.options?.infoBlockSettings?.nonProfitStatus?.order ??
              0,
          },
          time: {
            enabled:
              collection?.options?.infoBlockSettings?.time?.enabled ?? false,
            order: collection?.options?.infoBlockSettings?.time?.order ?? 0,
            startTime: Util.parseCalendarDateTime(
              collection?.options?.infoBlockSettings?.time?.startTime ?? '',
            ),
            endTime: Util.parseCalendarDateTime(
              collection?.options?.infoBlockSettings?.time?.endTime ?? '',
            ),
            timeZone:
              collection?.options?.infoBlockSettings?.time?.timeZone ??
              Util.getDefaultCuTimeZone() ??
              '',
          },
          location: {
            enabled:
              collection?.options.infoBlockSettings?.location?.enabled ?? false,
            address:
              collection?.options.infoBlockSettings?.location?.address ?? '',
            longitude:
              collection?.options.infoBlockSettings?.location?.longitude ??
              null,
            latitude:
              collection?.options.infoBlockSettings?.location?.latitude ?? null,
            order: collection?.options.infoBlockSettings?.location?.order ?? 0,
          },
          totalCollected: {
            enabled:
              collection?.options?.infoBlockSettings?.totalCollected?.enabled ??
              false,
            order:
              collection?.options?.infoBlockSettings?.totalCollected?.order ??
              0,
          },
          payerList: {
            enabled:
              collection?.options?.infoBlockSettings?.payerList?.enabled ??
              false,
            order:
              collection?.options?.infoBlockSettings?.payerList?.order ?? 0,
            customContributorName:
              collection?.options?.infoBlockSettings?.payerList
                ?.customContributorName ?? '',
          },
        },
      },
      faqs: collection?.faqs ?? [],
    },
    onSubmit: async (values) => {
      const {faqs: _faqs, ...formValues} = values
      const faqs = _faqs.map((faq, idx) => ({
        ...faq,
        position: idx + 1,
      }))

      try {
        await updateCollectionMutation.mutateAsync({
          pathParams: {
            tabId: collectionId,
          },
          body: {
            ...formValues,
            options: {
              ...values.options,
              paymentGoal: {
                ...values.options.paymentGoal,
                value: values.options.paymentGoal.value
                  ? Number(values.options.paymentGoal.value)
                  : 0,
              },
              infoBlockSettings: {
                ...values.options.infoBlockSettings,
                time: {
                  ...values.options.infoBlockSettings.time,
                  startTime: values.options.infoBlockSettings.time?.startTime
                    ?.toDate(values.options.infoBlockSettings.time.timeZone)
                    .toISOString(),
                  endTime: values.options.infoBlockSettings.time?.endTime
                    ?.toDate(values.options.infoBlockSettings.time.timeZone)
                    .toISOString(),
                },
                faqs: {
                  enabled:
                    faqs.length > 0 &&
                    values.options.infoBlockSettings.faqs.enabled,
                  order: values.options.infoBlockSettings.faqs.order,
                },
              },
            },
          },
        })

        const faqIds = faqs.map((faq) => faq.id)
        const faqIdsToDelete =
          collection?.faqs
            ?.map((eFaq) => eFaq.id)
            .filter((eFaqId) => !faqIds.includes(eFaqId)) ?? []

        const createOrUpdateFaqsPromises = faqs
          .filter(
            (faq) =>
              typeof faq.id === 'string' ||
              !Util.deepEqual(
                faq,
                collection?.faqs?.find((eFaq) => eFaq.id === faq.id),
              ),
          )
          .map((faq) => {
            const body = {
              question: faq.question,
              answer: faq.answer,
              position: faq.position,
            }

            return typeof faq.id === 'string'
              ? createTabFaqMutation.mutateAsync({
                  pathParams: {
                    tabId: collectionId,
                  },
                  body,
                })
              : updateTabFaqMutation.mutateAsync({
                  pathParams: {
                    tabId: collectionId,
                    faqId: faq.id,
                  },
                  body,
                })
          })
        const deleteFaqsPromises = faqIdsToDelete.map((faqId) =>
          deleteTabFaqMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              faqId,
            },
          }),
        )

        await Promise.all(deleteFaqsPromises)
        await Promise.all(createOrUpdateFaqsPromises)

        // promise all can result in a race condition
        // the query cache may end up in an old state
        const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, {
          pathParams: {tabId: collectionId},
        })
        queryClient.invalidateQueries({queryKey: tabDetailQueryKey})

        onDidSave?.()
      } catch (err: any) {
        growlActions.show('error', {
          title: 'Error',
          body: err.message,
        })
      }
    },
  })

  const isCollectionDefined = collection != null
  const resetFormik = formik.resetForm
  useEffect(() => {
    if (isCollectionDefined) {
      resetFormik()
    }
  }, [resetFormik, isCollectionDefined])

  return (
    <PremiumFeaturesSideSheet
      tabId={collectionId}
      enforcedPremiumMeta={enforcedPremiumMeta}
    >
      <WebUI.Form
        className={WebUI.cn(
          'min-h-0 [&_>_.Form-inner]:h-full [&_>_.Form-inner]:gap-0',
          className,
        )}
        noValidate
        onSubmit={async (event) => {
          const errors = await formik.validateForm()
          if (errors.options?.paymentGoal) {
            tabsRef.current?.select('participation')
          } else if (
            errors.options?.infoBlockSettings?.time ||
            errors.options?.infoBlockSettings?.location
          ) {
            tabsRef.current?.select('date-and-location')
          } else if (errors.faqs) {
            tabsRef.current?.select('faqs')
          }

          formik.handleSubmit(event)
        }}
        onReset={formik.handleReset}
        {...restProps}
      >
        <RoutableTabs
          ref={tabsRef}
          className="min-h-0 grow [&_>_.TabPanel]:grow [&_>_.TabPanel]:overflow-y-auto [&_>_.TabPanel]:p-6 sm:[&_>_.TabPanel]:p-9"
          variant="underlined"
          onChangeSelectedId={(newSelectedId) => {
            if (newSelectedId != null) {
              setSelectedTabId(newSelectedId)
            }
          }}
        >
          <WebUI.TabList
            aria-label="Collection Additional Information form navigation"
            className="mx-6 flex-0 border-b-0 sm:mx-9 [&_>_.RoutableTab]:text-ds-sm sm:[&_>_.RoutableTab]:text-ds-md [&_>_.TabList-underline]:bg-orange-500"
          >
            <RoutableTab to="participation">Participation</RoutableTab>
            <RoutableTab to="date-and-location">Date and Location</RoutableTab>
            <RoutableTab to="faqs">FAQ</RoutableTab>
            <RoutableTab to="contact">Contact</RoutableTab>
          </WebUI.TabList>

          <WebUI.Separator variant="primary" />

          <RoutableTabPanel>
            <Routes>
              <Route
                path="participation/*"
                element={
                  <PageContainer>
                    <WebUI.VStack
                      className={WebUI.cn(
                        '[&_>_.Separator]:-mx-2 gap-6',
                        className,
                      )}
                    >
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Promote sharing"
                        content="Participants can share your collection in an email, social post, or text."
                        visible={
                          formik.values.options.infoBlockSettings
                            ?.promoteSharing?.enabled
                        }
                        onVisibleChange={(isEnabled) =>
                          formik.setFieldValue(
                            'options.infoBlockSettings.promoteSharing.enabled',
                            isEnabled,
                          )
                        }
                      />
                      <WebUI.Separator variant="primary" />
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Make total collected public"
                        content="The total collected to date will be shown on your collection page."
                        visible={
                          !!formik.values.options.infoBlockSettings
                            .totalCollected.enabled
                        }
                        onVisibleChange={(isEnabled) =>
                          formik.setFieldValue(
                            'options.infoBlockSettings.totalCollected.enabled',
                            isEnabled,
                          )
                        }
                      />
                      <WebUI.Separator variant="primary" />
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Set a fundraising goal for this collection"
                        visible={formik.values.options.paymentGoal.enabled}
                        onVisibleChange={(newPaymentGoalEnabled) => {
                          formik.setFieldValue(
                            'options.paymentGoal.enabled',
                            newPaymentGoalEnabled,
                          )
                          setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
                            ...prevEnforcedPremiumMeta,
                            pro: {
                              ...prevEnforcedPremiumMeta?.pro,
                              fundraisingGoal: newPaymentGoalEnabled,
                            },
                          }))
                        }}
                        requirePlan={
                          isBasic && isPublished && enforceAddlGatFeatures
                            ? 'pro'
                            : undefined
                        }
                        featureKey={
                          isBasic && enforceAddlGatFeatures
                            ? 'fundraisingGoal'
                            : undefined
                        }
                        content={
                          <WebUI.VStack className="gap-2">
                            <span>
                              Encourage current and potential donors with a
                              progress bar on your collection page.
                            </span>
                            <WebUI.FormField
                              size="compact"
                              required
                              label="Goal Amount"
                              error={formik.errors.options?.paymentGoal?.value}
                            >
                              <WebUI.AmountInput
                                className="max-w-[160px]"
                                name="options.paymentGoal.value"
                                placeholder="$0"
                                value={formik.values.options.paymentGoal.value}
                                onValueChange={(newValue) =>
                                  formik.setFieldValue(
                                    'options.paymentGoal.value',
                                    newValue,
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </WebUI.FormField>
                          </WebUI.VStack>
                        }
                      />
                      <WebUI.Separator variant="primary" />
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Make participant list public"
                        visible={
                          !!formik.values.options.infoBlockSettings.payerList
                            .enabled
                        }
                        onVisibleChange={(isEnabled) => {
                          formik.setFieldValue(
                            'options.infoBlockSettings.payerList.enabled',
                            isEnabled,
                          )
                        }}
                        content={
                          <WebUI.VStack className="gap-2">
                            <span>
                              List will include first and last names of
                              participants (no amounts or emails). You can
                              customize the list header below.
                            </span>
                            <WebUI.FormField
                              className="[&_>_.FormField-inputWrapper]:w-1/3"
                              label="List header:"
                              required
                            >
                              <WebUI.Input
                                type="text"
                                name="options.infoBlockSettings.payerList.customContributorName"
                                placeholder="e.g. Contributors"
                                value={
                                  formik.values.options.infoBlockSettings
                                    .payerList.customContributorName
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                maxLength={CUSTOM_CONTRIBUTOR_NAME_MAX_LEN}
                              />
                            </WebUI.FormField>
                          </WebUI.VStack>
                        }
                      />
                      {/* TODO: [MAIN-2332] Uncomment when api ready */}
                      {/* <WebUI.Separator variant="primary" />
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Show Non-Profit Status"
                        visible={
                          formik.values.options.infoBlockSettings
                            ?.nonProfitStatus?.enabled
                        }
                        onVisibleChange={(newnonProfitStatus) => {
                          formik.setFieldValue(
                            'options.infoBlockSettings.nonProfitStatus.enabled',
                            newnonProfitStatus,
                          )
                          setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
                            ...prevEnforcedPremiumMeta,
                            team: {
                              ...prevEnforcedPremiumMeta?.team,
                              nonProfitStatus: newnonProfitStatus,
                            },
                          }))
                        }}
                        requirePlan={
                          !collection?.is_team && isPublished
                            ? 'team'
                            : undefined
                        }
                        featureKey={
                          collection?.is_team ? undefined : 'nonProfitStatus'
                        }
                        content={
                          <WebUI.VStack className="gap-6">
                            <span>
                              Give your community peace of mind by displaying
                              your 501(c)(3) status with the IRS. You must have
                              an organizational account and have completed our
                              non-profit verification process.{' '}
                              <WebUI.Anchor href="#">
                                Learn more about non-profit verification.
                              </WebUI.Anchor>
                            </span>
                            {collection?.is_team && !orgVerified && (
                              <WebUI.Button
                                className="self-start"
                                variant="secondary"
                              >
                                Get Verified
                              </WebUI.Button>
                            )}
                          </WebUI.VStack>
                        }
                      /> */}
                    </WebUI.VStack>
                    <Outlet />
                  </PageContainer>
                }
              />
              <Route
                path="date-and-location/*"
                element={
                  <PageContainer>
                    <WebUI.VStack
                      className={WebUI.cn(
                        '[&_>_.Separator]:-mx-2 gap-6',
                        className,
                      )}
                    >
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Add date and time"
                        visible={
                          formik.values.options.infoBlockSettings.time?.enabled
                        }
                        onVisibleChange={(isEnabled) => {
                          formik.setFieldValue(
                            'options.infoBlockSettings.time.enabled',
                            isEnabled,
                          )
                          setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
                            ...prevEnforcedPremiumMeta,
                            team: {
                              ...prevEnforcedPremiumMeta?.team,
                              dateTime: isEnabled,
                            },
                          }))
                        }}
                        requirePlan={
                          !collection?.is_team && isPublished
                            ? 'team'
                            : undefined
                        }
                        featureKey={
                          collection?.is_team ? undefined : 'dateTime'
                        }
                        content={
                          <WebUI.VStack className="max-w-[380px] gap-4">
                            <WebUI.FormField
                              label="Start Date and Time:"
                              error={
                                formik.errors.options?.infoBlockSettings?.time
                                  ?.startTime
                              }
                              required
                            >
                              <WebUI.DatePicker
                                granularity="minute"
                                value={
                                  formik.values.options.infoBlockSettings.time
                                    ?.startTime
                                }
                                onValueChange={(newDate) =>
                                  formik.setFieldValue(
                                    'options.infoBlockSettings.time.startTime',
                                    newDate,
                                  )
                                }
                              />
                            </WebUI.FormField>
                            <WebUI.FormField
                              label="End Date and Time (optional):"
                              error={
                                formik.errors.options?.infoBlockSettings?.time
                                  ?.endTime
                              }
                            >
                              <WebUI.DatePicker
                                granularity="minute"
                                value={
                                  formik.values.options.infoBlockSettings.time
                                    ?.endTime
                                }
                                onValueChange={(newDate) =>
                                  formik.setFieldValue(
                                    'options.infoBlockSettings.time.endTime',
                                    newDate,
                                  )
                                }
                              />
                            </WebUI.FormField>

                            <WebUI.FormField
                              required
                              label="Time Zone"
                              error={
                                formik.errors.options?.infoBlockSettings?.time
                                  ?.timeZone
                              }
                            >
                              <WebUI.SupportedTimeZoneCombobox
                                className="max-w-[400px]"
                                value={
                                  formik.values.options.infoBlockSettings.time
                                    ?.timeZone
                                }
                                onValueChange={(newTimeZoneValue) =>
                                  formik.setFieldValue(
                                    'options.infoBlockSettings.time.timeZone',
                                    newTimeZoneValue,
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </WebUI.FormField>
                          </WebUI.VStack>
                        }
                      />
                      <WebUI.Separator variant="primary" />
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Add an in-person location"
                        visible={
                          formik.values.options.infoBlockSettings.location
                            ?.enabled
                        }
                        requirePlan={
                          !collection?.is_team && isPublished
                            ? 'team'
                            : undefined
                        }
                        featureKey={
                          collection?.is_team ? undefined : 'location'
                        }
                        onVisibleChange={(isEnabled) => {
                          formik.setFieldValue(
                            'options.infoBlockSettings.location.enabled',
                            isEnabled,
                          )
                          setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
                            ...prevEnforcedPremiumMeta,
                            team: {
                              ...prevEnforcedPremiumMeta?.team,
                              location: isEnabled,
                            },
                          }))
                        }}
                        content={
                          <WebUI.VStack className="gap-6">
                            <span>
                              In addition to the address you provide below,
                              we’ll include Google and Apple map links for your
                              participants to easily find your event.
                            </span>
                            <WebUI.FormField
                              label="Location:"
                              error={
                                formik.errors.options?.infoBlockSettings
                                  ?.location?.address
                              }
                              required
                            >
                              <WebUI.AddressCombobox
                                className="max-w-[400px]"
                                placement="top"
                                defaultAddress={
                                  formik.values.options.infoBlockSettings
                                    .location?.address
                                }
                                creatable
                                withDetails
                                onAddressChange={(location) =>
                                  formik.setFieldValue(
                                    'options.infoBlockSettings.location',
                                    {
                                      ...formik.values.options.infoBlockSettings
                                        .location,
                                      address: location.address,
                                      latitude:
                                        location.details?.geometry?.location?.lat() ??
                                        null,
                                      longitude:
                                        location.details?.geometry?.location?.lng() ??
                                        null,
                                    },
                                  )
                                }
                              />
                            </WebUI.FormField>
                          </WebUI.VStack>
                        }
                      />
                      <WebUI.Separator variant="primary" />
                    </WebUI.VStack>
                    <Outlet />
                  </PageContainer>
                }
              />
              <Route
                path="faqs/*"
                element={
                  <PageContainer>
                    <WebUI.VStack
                      className={WebUI.cn(
                        '[&_>_.Separator]:-mx-2 gap-6',
                        className,
                      )}
                    >
                      <CollectionAdditionalInformationSettingsSwitch
                        title="Include an FAQ"
                        visible={
                          formik.values.options.infoBlockSettings.faqs.enabled
                        }
                        onVisibleChange={(isEnabled) => {
                          formik.setFieldValue(
                            'options.infoBlockSettings.faqs.enabled',
                            isEnabled,
                          )

                          if (formik.values.faqs.length === 0) {
                            formik.setFieldValue('faqs', [makeTabFaqValue()])
                          }

                          setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
                            ...prevEnforcedPremiumMeta,
                            team: {
                              ...prevEnforcedPremiumMeta?.team,
                              faqs: isEnabled,
                            },
                          }))
                        }}
                        requirePlan={
                          !collection?.is_team && isPublished
                            ? 'team'
                            : undefined
                        }
                        featureKey={collection?.is_team ? undefined : 'faqs'}
                        content={
                          <WebUI.VStack className="gap-4">
                            <span>
                              Include up to four questions participants may have
                              about this collection (e.g. refund policies,
                              parking, etc.)
                            </span>

                            <TabFaqsEdit
                              formik={formik}
                              maxAnswerChars={faqAnswerMaxChars}
                            />
                          </WebUI.VStack>
                        }
                      />
                      <WebUI.Separator variant="primary" />
                    </WebUI.VStack>
                    <Outlet />
                  </PageContainer>
                }
              />
              <Route
                path="contact/*"
                element={
                  <PageContainer>
                    <WebUI.VStack
                      className={WebUI.cn(
                        '[&_>_.Separator]:-mx-2 gap-6 text-gray800',
                        className,
                      )}
                    >
                      <WebUI.VStack className="max-w-2xl gap-1">
                        <WebUI.Text>
                          Contact name and profile picture
                        </WebUI.Text>
                        <WebUI.Text className="font-light text-ds-sm">
                          By default, your account display name and profile
                          picture is shown on all collections. All questions
                          from participants will be sent to the account owner
                          email unless a manager is specified below. You can
                          update your profile in your{' '}
                          <Link
                            variant="primary"
                            to="my-account/display-name"
                            className="[&_>_.Anchor-content]:font-light"
                          >
                            account settings
                          </Link>
                          .
                        </WebUI.Text>
                      </WebUI.VStack>
                      <LinkButton
                        className="self-start"
                        variant="secondary"
                        to="my-account/display-name"
                      >
                        Update Profile
                      </LinkButton>
                      <WebUI.Separator />
                      <CollectionContactSettingsSwitch
                        title={
                          <WebUI.VStack className="gap-1">
                            <WebUI.Text>
                              Assign a manager to be the main contact for this
                              collection
                            </WebUI.Text>
                            <WebUI.Text className="font-light text-ds-sm">
                              All questions from participants will be forwarded
                              to the Manager’s email and not the main account
                              holder.
                            </WebUI.Text>
                          </WebUI.VStack>
                        }
                        visible={!!formik.values.contact_manager_id}
                        onVisibleChange={(isEnabled) => {
                          if (!isEnabled) {
                            formik.setFieldValue('contact_manager_id', null)
                          }
                        }}
                        requireUpgrage={!collection?.is_team}
                        content={
                          acceptedManagers && acceptedManagers.length > 0 ? (
                            <WebUI.DropdownSelect
                              className="max-w-[180px]"
                              variant="secondary"
                              size="compact"
                              placeholder="Main Contact"
                              value={formik.values.contact_manager_id}
                              onValueChange={(newManagerId) => {
                                formik.setFieldValue(
                                  'contact_manager_id',
                                  newManagerId == null
                                    ? null
                                    : Number(newManagerId),
                                )
                              }}
                            >
                              <WebUI.DropdownSelectOption
                                value="addNewManager"
                                as={Link}
                                iconAfter={
                                  <WebUI.PhosphorIcon
                                    icon="arrow-square-out-fill"
                                    width={16}
                                  />
                                }
                                target="_blank"
                                to="/managers"
                              >
                                Add New Manager
                              </WebUI.DropdownSelectOption>
                              <WebUI.DropdownSelectOption value={null}>
                                {collection?.organizer.name}
                              </WebUI.DropdownSelectOption>
                              {acceptedManagers.map((m) => (
                                <WebUI.DropdownSelectOption
                                  key={m.id}
                                  value={m.id}
                                >
                                  {m.name}
                                </WebUI.DropdownSelectOption>
                              ))}
                            </WebUI.DropdownSelect>
                          ) : (
                            <LinkButton
                              className="self-start"
                              variant="secondary"
                              size="compact"
                              iconAfter={
                                <WebUI.PhosphorIcon
                                  icon="arrow-square-out-fill"
                                  width={16}
                                />
                              }
                              to="/managers"
                            >
                              Add Manager
                            </LinkButton>
                          )
                        }
                      />
                      <WebUI.Separator />
                      <CollectionContactSettingsSwitch
                        title={
                          <WebUI.VStack className="max-w-2xl gap-1">
                            <WebUI.Text>
                              Include a button linking to your Group Page
                            </WebUI.Text>
                            <WebUI.Text className="font-light text-ds-sm">
                              If you’ve added collections to your Group Page,
                              we’ll display a button in your contact block for
                              participants to view any other relevant
                              collections that you’ve chosen.{' '}
                              <WebUI.Anchor
                                className="inline [&_>_.Anchor-content]:whitespace-break-spaces [&_>_.Anchor-content]:font-light"
                                href="https://www.cheddarup.com/group-page/"
                                target="_blank"
                              >
                                Learn more about creating a one-stop shop for
                                your group
                              </WebUI.Anchor>
                              .
                            </WebUI.Text>
                          </WebUI.VStack>
                        }
                        visible={
                          formik.values.options.groupPage?.shared &&
                          !!collection?.is_team
                        }
                        onVisibleChange={(isEnabled) => {
                          formik.setFieldValue(
                            'options.groupPage.shared',
                            isEnabled,
                          )
                        }}
                        requireUpgrage={!collection?.is_team}
                        content={
                          <LinkButton
                            className="self-start"
                            variant="secondary"
                            size="compact"
                            iconAfter={
                              <WebUI.PhosphorIcon
                                icon="arrow-square-out-fill"
                                width={16}
                              />
                            }
                            to="/group"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Update Group Page
                          </LinkButton>
                        }
                      />
                    </WebUI.VStack>
                    <Outlet />
                  </PageContainer>
                }
              />
            </Routes>
          </RoutableTabPanel>
        </RoutableTabs>

        <WebUI.Separator />
        <WebUI.HStack className="justify-end bg-trueWhite px-4 py-5">
          {!collectionId && selectedTabId !== 'contact' ? (
            <WebUI.Button
              variant="default"
              size="large"
              onClick={() => tabsRef.current?.next()}
            >
              Continue
            </WebUI.Button>
          ) : (
            <WebUI.Button
              type="submit"
              variant="primary"
              size="large"
              loading={formik.isSubmitting}
            >
              Save
            </WebUI.Button>
          )}
        </WebUI.HStack>
      </WebUI.Form>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – CollectionAdditionalInformationSettingsSwitch

export interface CollectionAdditionalInformationSettingsSwitchProps
  extends Omit<WebUI.DisclosureProps, 'title' | 'content' | 'children'> {
  title: React.ReactNode
  content?: React.ReactNode
  featureKey?: FeatureKey
  requirePlan?: 'team' | 'pro'
  disabled?: boolean
  enforcePaidBadge?: boolean
}

export const CollectionAdditionalInformationSettingsSwitch: React.FC<
  CollectionAdditionalInformationSettingsSwitchProps
> = ({
  title,
  content,
  featureKey,
  requirePlan,
  disabled = false,
  enforcePaidBadge = false,
  className,
  ...restProps
}) => (
  <WebUI.Disclosure className={WebUI.cn(className, 'gap-2')} {...restProps}>
    {requirePlan ? (
      <CollectionSettingsUpgradePlanButton plan={requirePlan}>
        {title}
      </CollectionSettingsUpgradePlanButton>
    ) : (
      <SettingDisclosureSwitch
        featureKey={featureKey}
        disabled={disabled}
        enforcePaidBadge={enforcePaidBadge}
      >
        <WebUI.Text className="text-gray800">{title}</WebUI.Text>
      </SettingDisclosureSwitch>
    )}
    <WebUI.DisclosureContent className="text-ds-sm text-gray800">
      {content}
    </WebUI.DisclosureContent>
  </WebUI.Disclosure>
)

// MARK: – CollectionContactSettingsSettingsSwitch

export interface CollectionContactSettingsSwitchProps
  extends Omit<
    CollectionAdditionalInformationSettingsSwitchProps,
    'featureKey' | 'requirePlan' | 'disabled' | 'enforcePaidBadge'
  > {
  requireUpgrage: boolean
}

export const CollectionContactSettingsSwitch: React.FC<
  CollectionContactSettingsSwitchProps
> = ({title, content, requireUpgrage = false, className, ...restProps}) => (
  <WebUI.Disclosure className={WebUI.cn(className, 'gap-2')} {...restProps}>
    {requireUpgrage ? (
      <WebUI.HStack className="justify-between">
        {title}
        <PlanUpgradeButton upgradeTo="team" />
      </WebUI.HStack>
    ) : (
      <WebUI.DisclosureSwitch>{title}</WebUI.DisclosureSwitch>
    )}
    <WebUI.DisclosureContent className="pt-5 text-ds-sm text-gray800">
      {content}
    </WebUI.DisclosureContent>
  </WebUI.Disclosure>
)
