import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {FormikHandlers, FormikHelpers, FormikState, useFormik} from 'formik'
import React, {useRef, useState} from 'react'
import * as Yup from 'yup'
import {
  api,
  useResetUserAccountMutation,
  useStartCodeVerificationMutation,
  useUpdateUserMutation,
} from '@cheddarup/api-client'
import {AccountSettingsContentLayout} from './AccountSettingsContentLayouts'

export type AccountTransferStep = 'step1' | 'step2' | 'steps-completed'
export interface NewOwnerValues {
  firstName: string
  lastName: string
  email?: string
}
export type NewOwnerFormik = FormikState<NewOwnerValues> &
  FormikHelpers<NewOwnerValues> &
  FormikHandlers

export interface AccountTransferProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onGoBack: () => void
}

export const AccountTransfer = ({
  onGoBack,
  ...restProps
}: AccountTransferProps) => {
  const transferAccountConfirmationAlertRef = useRef<WebUI.DialogInstance>(null)
  const navigate = useNavigate()
  const growlActions = WebUI.useGrowlActions()
  const startCodeVerificationMutation = useStartCodeVerificationMutation()
  const resetUserAccountMutation = useResetUserAccountMutation()
  const updateUserMutation = useUpdateUserMutation()
  const {data: lastTwoNumbers} = api.auth.session.useSuspenseQuery(undefined, {
    select: (session) => session.user.profile.phone?.lastTwoNumbers,
  })
  const {data: phoneVerified} = api.auth.session.useSuspenseQuery(undefined, {
    select: (session) => session.user.profile.phone?.verified,
  })
  const [step, setStep] = useState<AccountTransferStep>(
    phoneVerified ? 'step1' : 'step2',
  )

  const formik = useFormik<NewOwnerValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid format'),
    }),
    onSubmit: () => {
      setStep('steps-completed')
    },
  })

  return (
    <AccountSettingsContentLayout
      heading="Transfer Account"
      body={
        <>
          <WebUI.Button variant="link" onClick={onGoBack}>
            Back to Account Details
          </WebUI.Button>

          <WebUI.Text className="font-light text-ds-base">
            Transferring an account allows you to remove your personal details
            so you can pass the account to a new account owner. It is important
            to take the following into consideration.
          </WebUI.Text>

          <ul className="flex list-inside list-disc flex-col gap-2 font-normal text-ds-base">
            <li>
              Your organization details (org name, tax ID, etc.) and login
              credentials will remain intact.
            </li>
            <li>
              Payment and/or withdrawls may be disabled until the new account
              owner enters their personal information and sets up two-factor
              authentication.
            </li>
          </ul>
        </>
      }
      {...restProps}
    >
      <AccountTransferStepDisclosure
        heading="Step 1: Clear your personal details"
        active={step === 'step1'}
        completed={step === 'step2' || step === 'steps-completed'}
      >
        <WebUI.VStack className="gap-4">
          <span>
            To clear your personal details from the account, reset your
            two-factor authentication code using your mobile phone (***) ***-**{' '}
            {lastTwoNumbers ?? ''}.
          </span>

          <span className="text-ds-sm">Send verification code via:</span>

          <WebUI.HStack>
            <WebUI.Button
              onClick={async () => {
                await startCodeVerificationMutation.mutateAsync()
                navigate('two-factor-authentication')
              }}
            >
              Text Message
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      </AccountTransferStepDisclosure>

      <AccountTransferStepDisclosure
        heading="Step 2: Designate a new account holder"
        active={step === 'step2'}
        completed={step === 'steps-completed'}
      >
        <WebUI.VStack className="gap-4">
          <span>
            Enter the name of the person who will be taking over the account.
            They will fill in their personal details later.
          </span>

          <WebUI.FormFieldGroup>
            <WebUI.FormField
              required
              label="First Name"
              error={formik.errors.firstName}
            >
              <WebUI.Input
                name="firstName"
                placeholder="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            <WebUI.FormField
              required
              label="Last Name"
              error={formik.errors.lastName}
            >
              <WebUI.Input
                name="lastName"
                placeholder="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          </WebUI.FormFieldGroup>

          <WebUI.Separator />

          <span>
            Optional: Send a message to the new account holder to let them know
            that the account has been cleared and is ready for them to take
            over.
          </span>

          <WebUI.FormField label="Email Address" error={formik.errors.email}>
            <WebUI.Input
              name="email"
              placeholder="Email address (optional)"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>

          <WebUI.HStack>
            <WebUI.Button onClick={() => formik.handleSubmit()}>
              Save
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      </AccountTransferStepDisclosure>

      <WebUI.HStack>
        <WebUI.Button
          loading={
            resetUserAccountMutation.isPending || updateUserMutation.isPending
          }
          disabled={step !== 'steps-completed'}
          onClick={async () => {
            try {
              await resetUserAccountMutation.mutateAsync({
                body: {
                  first_name: formik.values.firstName,
                  last_name: formik.values.lastName,
                  ...(formik.values.email && {email: formik.values.email}),
                },
              })
              await updateUserMutation.mutateAsync({
                body: {
                  first_name: formik.values.firstName,
                  last_name: formik.values.lastName,
                },
              })
              transferAccountConfirmationAlertRef.current?.show()
            } catch (err: any) {
              growlActions.clear()
              growlActions.show('error', {
                title: "Couldn't transfer account",
                body:
                  err.response.data.errors[0].details ??
                  err.response?.data?.err ??
                  err.message,
              })
            }
          }}
        >
          Confirm
        </WebUI.Button>
      </WebUI.HStack>

      <WebUI.Alert
        aria-label="Transfer account completed"
        ref={transferAccountConfirmationAlertRef}
      >
        <WebUI.AlertHeader>
          Your account is ready for a new owner
        </WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <WebUI.VStack className="gap-8">
              <span>
                Nice work! Your personal details have been removed from your
                account, and it is now ready for a new account owner.
              </span>

              <span className="font-bold">
                Important: The new account owner must do the following in order
                to accept payments:
              </span>

              <WebUI.VStack className="list-inside gap-4" as="ol">
                <li>
                  Log in using the email and password for the account. Once
                  done, we recommend they reset their account password. They can
                  also change the email address for the account if desired.
                </li>
                <li>Enter their personal details and set up authentication.</li>
              </WebUI.VStack>
            </WebUI.VStack>
          }
          actions={
            <WebUI.AlertActionButton onClick={onGoBack} variant="primary">
              Got it
            </WebUI.AlertActionButton>
          }
        />
      </WebUI.Alert>
    </AccountSettingsContentLayout>
  )
}

// MARK: - AccountTransferStepDisclosure

interface AccountTransferStepDisclosureProps extends React.PropsWithChildren {
  heading: string
  active?: boolean
  completed?: boolean
}

const AccountTransferStepDisclosure = ({
  heading,
  active = false,
  completed = false,
  children,
}: AccountTransferStepDisclosureProps) => (
  <WebUI.Disclosure visible={active}>
    {({visible}) => (
      <WebUI.Panel
        className={WebUI.cn('p-4', visible ? 'bg-grey-50' : 'bg-trueWhite')}
      >
        <WebUI.HStack className="items-center gap-2">
          {!visible && (
            <span>
              {completed ? (
                <WebUI.PhosphorIcon
                  className="mt-2 text-ds-lg text-orange-500"
                  icon="check-circle-fill"
                />
              ) : (
                <WebUI.PhosphorIcon
                  className="mt-2 text-ds-lg text-grey-300"
                  icon="circle"
                />
              )}
            </span>
          )}
          <span
            className={WebUI.cn(
              'font-medium',
              visible || completed ? 'text-black' : 'text-gray400',
            )}
          >
            {heading}
          </span>
        </WebUI.HStack>

        <WebUI.DisclosureContent className="pt-2">
          {children}
        </WebUI.DisclosureContent>
      </WebUI.Panel>
    )}
  </WebUI.Disclosure>
)
