import {api, usePublishTabMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import React, {useEffect, useRef} from 'react'
import canvasConfetti from 'canvas-confetti'
import {LinkButton} from 'src/components/LinkButton'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {usePremiumFeaturesSideSheetContext} from 'src/components/PremiumFeaturesSideSheet'
import {AddPhoneNumberModal} from 'src/components/AddPhoneNumberModal'
import {useManagerRole} from 'src/components/ManageRoleProvider'

export interface PublishTabPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  tab: Api.Tab
  onDidPublish?: () => void
}

export const PublishTabPanel: React.FC<PublishTabPanelProps> = ({
  tab,
  onDidPublish,
  className,
  ...restProps
}) => {
  const {data: session, refetch: refetchSession} = api.auth.session.useQuery()
  const [managerRole] = useManagerRole()
  const publishTabMutation = usePublishTabMutation()
  const {setPremiumFeatureSheetVisible} = usePremiumFeaturesSideSheetContext()
  const addPhoneNumberVerificationModalRef = useRef<WebUI.DialogInstance>(null)
  const phoneNumberRequiredAlertRef = useRef<WebUI.DialogInstance>(null)

  const requiresPlanUpgrade =
    (!tab.is_pro && tab.requires_pro) || (!tab.is_team && tab.requires_team)

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-5 bg-teal-80 p-7', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.Heading className="text-ds-md" as="h2">
        <WebUI.HStack className="items-center gap-4">
          <WebUI.LogoGraphicsIcon className="text-ds-xl" />

          <span>
            {requiresPlanUpgrade
              ? 'Publish your collection'
              : 'Publish your collection to start sharing'}
          </span>
        </WebUI.HStack>
      </WebUI.Heading>

      <WebUI.Text>
        {requiresPlanUpgrade ? (
          <>
            This collection uses features that are not in your current plan.
            Upgrade your plan and access our most powerful features to publish
            this collection and share it with others.
          </>
        ) : (
          'Once you publish your collection, you’ll be able to share it with a URL, email invitation, QR code, or embedded button. You can continue to edit your page after you’ve published it.'
        )}
      </WebUI.Text>

      <WebUI.HStack className="gap-4">
        {requiresPlanUpgrade ? (
          <WebUI.Button
            variant="primary"
            onClick={() => setPremiumFeatureSheetVisible(true)}
          >
            View your paid features
          </WebUI.Button>
        ) : (
          <WebUI.Button
            variant="primary"
            loading={publishTabMutation.isPending}
            onClick={async () => {
              if (managerRole && !managerRole.profile.phone?.verified) {
                phoneNumberRequiredAlertRef.current?.show()
              } else if (managerRole || session?.user.profile.phone?.verified) {
                await publishTabMutation.mutateAsync({
                  pathParams: {
                    tabId: tab.id,
                  },
                })
                onDidPublish?.()
              } else {
                addPhoneNumberVerificationModalRef.current?.show()
              }
            }}
          >
            Publish
          </WebUI.Button>
        )}

        <LinkButton variant="default" to={`c/${tab.slug}`}>
          Preview
        </LinkButton>
      </WebUI.HStack>

      <AddPhoneNumberModal
        ref={addPhoneNumberVerificationModalRef}
        onDidVerify={() => {
          refetchSession()
          addPhoneNumberVerificationModalRef.current?.hide()
        }}
      />
      <PhoneNumberRequiredAlert
        ref={phoneNumberRequiredAlertRef}
        accountOwnerEmail={managerRole?.email}
      />
    </WebUI.VStack>
  )
}

// MARK: - PhoneNumberRequiredAlert

interface PhoneNumberRequiredAlertProps extends WebUI.AlertProps {
  accountOwnerEmail?: string
}

const PhoneNumberRequiredAlert = React.forwardRef<
  WebUI.DialogInstance,
  PhoneNumberRequiredAlertProps
>(({accountOwnerEmail, ...restProps}, forwardedRef) => (
  <WebUI.Alert ref={forwardedRef} aria-label="Upgrade required" {...restProps}>
    <WebUI.AlertContentView
      text={
        <WebUI.Text>
          You’re so close! Prior to publishing collections, your account owner
          needs to add a two-factor authentication number. Contact{' '}
          <WebUI.Anchor
            rel="noopener noreferrer"
            target="_blank"
            href={`mailto:${accountOwnerEmail}`}
          >
            {accountOwnerEmail}
          </WebUI.Anchor>{' '}
          to make this request
        </WebUI.Text>
      }
    />
  </WebUI.Alert>
))

// MARK: – PublishTabSuccessPanel

export interface PublishTabSuccessPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  tab: Api.Tab
}

export const PublishTabSuccessPanel: React.FC<PublishTabSuccessPanelProps> = ({
  tab,
  className,
  ...restProps
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (canvasRef.current) {
      const fireConfettiAnim = canvasConfetti.create(canvasRef.current, {
        disableForReducedMotion: true,
      })

      const timeouts = Array.from({length: 5}).map((_, idx, arr) =>
        setTimeout(
          () =>
            fireConfettiAnim({
              colors: [
                tailwindConfig.theme.colors.teal[600],
                tailwindConfig.theme.colors.teal[80],
                tailwindConfig.theme.colors.accent300,
                tailwindConfig.theme.colors.teal[70],
                tailwindConfig.theme.colors.teal[90],
              ],
              startVelocity: 16 - idx,
              particleCount: Util.randomInteger(50, 100),
              spread: 45,
              angle: 45 + idx * 10,
              gravity: 0.7 - (arr.length - idx) * 0.25,
              // decay: 0.9,
              origin: {
                x: idx * 0.2,
                y: 1,
              },
            }),
          500 + idx * 250,
        ),
      )

      return () => timeouts.forEach((timeout) => clearTimeout(timeout))
    }

    return () => {}
  }, [])

  return (
    <WebUI.Panel className={WebUI.cn('relative p-7', className)} {...restProps}>
      <WebUI.VStack className="gap-5">
        <WebUI.Heading className="text-ds-md" as="h2">
          Your page is live — start sharing!
        </WebUI.Heading>

        <WebUI.Text>
          <Link variant="primary" target="_blank" to={`/c/${tab.slug}`}>
            {tab.name}
          </Link>{' '}
          has been published and is ready to be shared. You can update your page
          at any time.
        </WebUI.Text>
      </WebUI.VStack>
      <canvas
        ref={canvasRef}
        className="pointer-events-none absolute top-0 right-0 bottom-0"
      />
    </WebUI.Panel>
  )
}
