import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import Papa from 'papaparse'
import React, {useMemo, useState} from 'react'
import {api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {SearchForm} from 'src/components'
import {useParams} from 'react-router-dom'
import {useManagerRole} from 'src/components/ManageRoleProvider'

const ITEMS_PER_PAGE = 25

const CollectionManageItems = (
  props: React.ComponentPropsWithoutRef<'div'>,
) => {
  interface TabItemDatum {
    object: Api.TabItemWithPaymentInfo
    type: 'item' | 'ticket'
    name: string
    price: string
    quantity_sold: number
    available_quantity: string | number
    net_amount: string
    waitlist_count: number
  }
  interface ListingDatum {
    id: number
    object: Api.VariantListing | null
    type: 'listing'
    name: string
    price: string
    quantity_sold: number
    available_quantity: string | number
    net_amount: string
  }
  interface DecorDatum {
    type: 'subtableHeader'
    name: string
  }

  type Datum = TabItemDatum | ListingDatum | DecorDatum

  const urlParams = useParams()
  const [downloadingItems, setDownloadingItems] = useState(false)
  const [query, setQuery] = useState<{
    page: number
    perPage: number
    sort: string
    direction: 'asc' | 'desc'
    search: string
  }>({
    page: 1,
    perPage: ITEMS_PER_PAGE,
    sort: 'id',
    direction: 'asc',
    search: '',
  })
  const searchItemsQuery = api.tabItems.search.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
      queryParams: query,
    },
    {
      placeholderData: (prevData) => prevData,
    },
  )

  const items = useMemo(
    () => searchItemsQuery.data?.data ?? [],
    [searchItemsQuery.data],
  )

  const qtyRemainingColumnVisible = items.some(
    (item) => typeof item.available_quantity === 'number',
  )

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Datum>(), [])

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor((ti) => ti.name, {
          id: 'name',
          size: 200,
          meta: {
            subtle: false,
          },
          header: 'Name',
          cell: ({cell, row: {original: item}}) =>
            item.type === 'item' || item.type === 'ticket' ? (
              <Link variant="primary" to={`${item.type}/${item.object.id}`}>
                <WebUI.Ellipsis className="max-w-[280px]">
                  {cell.getValue()}
                </WebUI.Ellipsis>
              </Link>
            ) : (
              <WebUI.Text className="font-light">{cell.getValue()}</WebUI.Text>
            ),
        }),
        columnHelper.accessor(
          (data) => (['item', 'ticket'].includes(data.type) ? data.type : ''),
          {
            id: 'type',
            maxSize: 80,
            header: 'Type',
            cell: ({cell}) => <>{Util.capitalize(cell.getValue())}</>,
          },
        ),
        columnHelper.accessor((ti) => ('price' in ti ? ti.price : ''), {
          id: 'amount',
          size: 120,
          meta: {
            align: 'right',
          },
          header: 'Price',
          cell: ({cell}) => cell.getValue(),
        }),
        columnHelper.accessor(
          (ti) => ('quantity_sold' in ti ? ti.quantity_sold : ''),
          {
            id: 'quantity_sold',
            maxSize: 120,
            size: 100,
            meta: {
              align: 'right',
            },
            header: 'Sold',
            cell: ({cell}) => cell.getValue(),
          },
        ),
        qtyRemainingColumnVisible
          ? columnHelper.accessor(
              (ti) => ('available_quantity' in ti ? ti.available_quantity : ''),
              {
                id: 'available_quantity',
                maxSize: 120,
                size: 120,
                enableSorting: false,
                meta: {
                  align: 'right',
                },
                header: 'Remaining',
                cell: ({cell}) => cell.getValue(),
              },
            )
          : undefined,
        columnHelper.accessor(
          (ti) => ('waitlist_count' in ti ? ti.waitlist_count : ''),
          {
            id: 'waitlist_count',
            maxSize: 160,
            size: 100,
            meta: {
              align: 'right',
            },
            header: 'Waitlist',
            cell: ({cell, row: {original: item}}) => {
              if (item.type === 'subtableHeader') {
                return null
              }

              const hasVariations =
                item.type === 'item' &&
                item.object?.options?.variants?.enabled &&
                item.object?.options?.variants?.listings &&
                item.object?.options?.variants?.listings.length > 0

              return !hasVariations && Number(cell.getValue()) > 0 ? (
                <Link
                  className="font-light"
                  variant="primary"
                  to={
                    item.type === 'listing'
                      ? `item/${item.id}/waitlist?uuid=${item.object?.uuid}`
                      : `item/${item.object?.id}/waitlist`
                  }
                >
                  {cell.getValue()}
                </Link>
              ) : (
                cell.getValue()
              )
            },
          },
        ),
        columnHelper.accessor(
          (ti) => ('net_amount' in ti ? ti.net_amount : ''),
          {
            id: 'net_amount',
            size: 120,
            meta: {
              align: 'right',
              subtle: false,
            },
            header: 'Collected',
            cell: ({cell}) => (
              <WebUI.Text className="font-light">{cell.getValue()}</WebUI.Text>
            ),
          },
        ),
        columnHelper.display({
          id: 'actions',
          minSize: 60,
          size: 60,
          enableSorting: false,
          meta: {
            align: 'center',
          },
          cell: ({row: {original: item}}) => {
            if (item.type === 'subtableHeader' || item.type === 'listing') {
              return null
            }

            return (
              <ItemActionsDropdown
                className="ml-8 text-ds-sm"
                item={item.object}
              />
            )
          },
        }),
      ].filter((col) => !!col),
    [qtyRemainingColumnVisible, columnHelper],
  )

  const tableData = useMemo(
    () =>
      items.flatMap((item) => {
        const listings = item.options?.variants?.enabled
          ? (item.options.variants.listings ?? [])
          : []

        const getItemPrice = () => {
          const lowestAmountListing = Util.firstBy(listings, (l) => l.amount)
          const highestAmountListing = Util.firstBy(listings, [
            (l) => l.amount,
            'desc',
          ])
          if (
            lowestAmountListing &&
            highestAmountListing &&
            lowestAmountListing.amount !== highestAmountListing.amount
          ) {
            return `${Util.formatAmount(lowestAmountListing.amount)}-${Util.formatAmount(
              highestAmountListing.amount,
            )}`
          }
          return `${Util.formatAmount(
            lowestAmountListing?.amount ?? item.amount ?? 0,
          )}`
        }

        const itemRowData: TabItemDatum = {
          object: item,
          type: item.options.itemSubType === 'ticket' ? 'ticket' : 'item',
          name: item.name,
          price: getItemPrice(),
          quantity_sold: item.quantity_sold - item.quantity_refunded,
          waitlist_count: item.waitlist_count,
          available_quantity:
            (item.options?.variants?.enabled
              ? item.available_variant_quantity
              : item.available_quantity) == null
              ? 'N/A'
              : (item.options?.variants?.enabled
                  ? item.available_variant_quantity
                  : item.available_quantity) || 'Sold Out',
          net_amount: Util.formatAmount(item.net_amount || 0),
        }

        const listingRowsData: ListingDatum[] = (item.variant_sales ?? [])
          .map((vs) => {
            let optionValues = {}
            try {
              if (vs.optionvalues) {
                optionValues = JSON.parse(vs.optionvalues)
              }
            } catch {
              // noop
            }
            const listing = listings.find(
              (l) =>
                l.uuid === vs.listing ||
                Util.deepEqual(l.optionValues, optionValues),
            )

            return {
              id: item.id,
              object: listing ?? null,
              type: 'listing' as const,
              name: Object.values(optionValues).join(', '),
              price: listing ? Util.formatAmount(listing.amount ?? 0) : 'N/A',
              quantity_sold: vs.quantity_sold,
              available_quantity: listing
                ? listing.available_quantity === null
                  ? 'N/A'
                  : listing.available_quantity || 'Sold Out'
                : 'N/A',
              net_amount: Util.formatAmount(vs.total_sold),
              waitlist_count: listing?.waitlist_count,
            }
          })
          .filter((lrd) => lrd.object && lrd.name && lrd.quantity_sold > 0)

        return listingRowsData.length > 0
          ? [
              itemRowData,
              {type: 'subtableHeader', name: 'Variations:'},
              ...listingRowsData,
            ]
          : itemRowData
      }),
    [items],
  )

  const paginationState = useMemo(
    () => ({pageSize: ITEMS_PER_PAGE, pageIndex: query.page - 1}),
    [query.page],
  )
  const sortingState = useMemo(
    () => [{id: query.sort, desc: query.direction === 'desc'}],
    [query.sort, query.direction],
  )

  const pageCount = Math.ceil(
    (searchItemsQuery.data?.pagination.total ?? 0) / ITEMS_PER_PAGE,
  )

  return (
    <WebUI.Panel {...props}>
      <WebUI.VStack>
        <WebUI.TableView<Datum>
          className={
            '[&_.TableView-headerGroup]:px-4 [&_.TableViewCell_>_.Stack]:py-0 [&_.TableViewRow]:items-start [&_.TableViewRow]:px-4'
          }
          columns={columns as any}
          data={tableData as any as Datum[]}
          state={{pagination: paginationState, sorting: sortingState}}
          pageCount={pageCount}
          manualPagination
          onPaginationChange={(updater) => {
            const newPagination =
              typeof updater === 'function' ? updater(paginationState) : updater

            setQuery((prevQuery) => ({
              ...prevQuery,
              page: newPagination.pageIndex + 1,
            }))
          }}
          getRowProps={(row, instance) => {
            const nextRow = instance.getRowModel().rows[row.index + 1]
            if (nextRow?.original.type === 'subtableHeader') {
              return {
                className: 'min-w-full py-4',
              }
            }
            if (
              row.original.type === 'subtableHeader' ||
              row.original.type === 'listing'
            ) {
              return {
                className: WebUI.cn(
                  '!w-auto py-1',
                  nextRow?.original.type === 'item' && 'border-b pb-4',
                ),
              }
            }

            return {
              className: WebUI.cn(
                (nextRow?.original.type === 'item' ||
                  nextRow?.original.type === 'ticket') &&
                  'border-b pb-4',
              ),
            }
          }}
          getCellProps={({row}) => {
            if (
              row.original.type === 'listing' ||
              row.original.type === 'subtableHeader'
            ) {
              return {
                className: WebUI.cn(
                  'text-ds-sm data-[columnid=name]:pl-5',
                  row.original.type === 'listing' ? 'font-light' : 'font-bold',
                ),
              }
            }

            return {}
          }}
          sortable
          sortByTogglesVisible
          manualSortBy
          disableSortRemove
          onSortingChange={(updater) => {
            const [newSorting] =
              typeof updater === 'function' ? updater(sortingState) : updater

            if (newSorting) {
              setQuery((prevQuery) => ({
                ...prevQuery,
                direction: newSorting.desc ? 'desc' : 'asc',
                sort: newSorting.id,
              }))
            }
          }}
        >
          <WebUI.VStack
            className={
              '-order-1 items-stretch justify-center gap-4 border-b px-8 py-4 sm:flex-row sm:items-center sm:justify-between'
            }
          >
            <WebUI.HStack className="items-center gap-4">
              <WebUI.Text className="text-ds-sm">
                Total:{' '}
                {searchItemsQuery.isLoading ? (
                  <WebUI.Skeleton width={60} height={12} />
                ) : (
                  searchItemsQuery.data?.pagination.total
                )}
              </WebUI.Text>
              <SearchForm
                className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
                iconClassName="text-gray400"
                size="compact"
                initialValues={{term: query.search}}
                onSubmit={(values) => {
                  if (values.term !== query.search) {
                    setQuery((prevQuery) => ({
                      ...prevQuery,
                      page: 1,
                      search: values.term,
                    }))
                  }
                }}
                noResult={query.search.length > 0 && items.length === 0}
                placeholder="Search by item name"
              />
            </WebUI.HStack>
            <WebUI.DeprecatedTooltip label="Download Item Summary (.csv)">
              <WebUI.Button
                size="compact"
                aria-label="Download Item Summary (.csv)"
                variant="secondary"
                disabled={downloadingItems}
                loading={downloadingItems}
                iconAfter={
                  <WebUI.PhosphorIcon icon="download-simple" width={16} />
                }
                onClick={async () => {
                  setDownloadingItems(true)

                  try {
                    const res = await memoizedFetchItems({
                      pathParams: {
                        // biome-ignore lint/style/noNonNullAssertion:
                        tabId: urlParams.collection!,
                      },
                      queryParams: {
                        ...query,
                        page: 1,
                        perPage: searchItemsQuery.data?.pagination.total,
                      },
                    })

                    WebUI.downloadFile(
                      new Blob([makeItemsCSV(res.data)], {
                        type: 'data:text/csv;charset=utf-8',
                      }),
                      'items.csv',
                    )
                  } finally {
                    setDownloadingItems(false)
                  }
                }}
              >
                Items Summary
              </WebUI.Button>
            </WebUI.DeprecatedTooltip>
          </WebUI.VStack>

          {pageCount > 1 && (
            <WebUI.HStack className="justify-end px-8 py-4">
              <WebUI.TablePaginator />
            </WebUI.HStack>
          )}
        </WebUI.TableView>
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

// MARK: – ItemActionsDropdown

interface ItemActionsDropdownProps
  extends WebUI.MenuButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  item: Api.TabItemWithPaymentInfo
}

const ItemActionsDropdown = React.forwardRef<
  HTMLButtonElement,
  ItemActionsDropdownProps
>(({item, className, ...restProps}, forwardedRef) => {
  const [managerRole] = useManagerRole()
  const isTicket = item.options.itemSubType === 'ticket'
  const itemType = isTicket ? 'ticket' : 'item'
  const hasVariations =
    item.options.variants?.listings &&
    item.options.variants?.listings.length > 0

  return (
    <WebUI.Menu placement="bottom-start">
      <WebUI.MenuButton
        ref={forwardedRef}
        className={WebUI.cn('rounded-full bg-grey-200 text-gray400', className)}
        as={WebUI.IconButton}
        {...restProps}
      >
        <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
      </WebUI.MenuButton>

      <WebUI.MenuList className="text-ds-sm">
        {managerRole?.permissions?.role !== 'viewer' && (
          <WebUI.MenuItem
            as={Link}
            variant="default"
            to={`${itemType}/${item.id}/edit`}
          >
            {isTicket ? 'Edit Ticket' : 'Edit Item'}
          </WebUI.MenuItem>
        )}
        <WebUI.MenuItem
          as={Link}
          variant="default"
          to={`${itemType}/${item.id}`}
        >
          {isTicket ? 'View Attendees' : 'View Payments'}
        </WebUI.MenuItem>
        <WebUI.MenuItem
          as={Link}
          variant="default"
          to={`${itemType}/${item.id}?t=responses-table&viewBy=questions`}
        >
          View Responses
        </WebUI.MenuItem>
        <WebUI.MenuItem
          as={Link}
          variant="default"
          to={`item/${item.id}/report`}
        >
          {isTicket ? 'Ticket Report' : 'Item Report'}
        </WebUI.MenuItem>
        {item.options.waitlist?.enabled && !hasVariations && (
          <WebUI.MenuItem
            as={Link}
            variant="default"
            to={`item/${item.id}/waitlist`}
          >
            View Waitlist
          </WebUI.MenuItem>
        )}
      </WebUI.MenuList>
    </WebUI.Menu>
  )
})

// MARK: – Helpers

const memoizedFetchItems = Util.memoize(api.tabItems.search.fetch, {
  isMatchingKey: Util.deepEqual,
})

const makeItemsCSV = (items: Api.TabItemWithPaymentInfo[]) => {
  const getAvailableQuantityLabel = (quantity: number | null) =>
    quantity == null ? 'N/A' : quantity || 'Sold Out'

  const qtyRemainingColumnVisible = items.some(
    (item) => typeof item.available_quantity === 'number',
  )
  const skuColumnVisible = items.some(
    (item) =>
      item.options?.variants?.enabled &&
      item.options.variants.listings.some((l) => !!l.sku),
  )

  const rows = items.flatMap((item) => {
    const listingRowsData = (
      item.options?.variants?.enabled ? (item.variant_sales ?? []) : []
    )
      .map((vs) => {
        let optionValues = {}
        try {
          if (vs.optionvalues) {
            optionValues = JSON.parse(vs.optionvalues)
          }
        } catch {
          // noop
        }
        const matchingVariantSale = (
          item.options?.variants?.enabled
            ? (item.options.variants.listings ?? [])
            : []
        ).find(
          (l) =>
            l.uuid === vs.listing ||
            Util.deepEqual(l.optionValues, optionValues),
        )
        return {
          name: Object.values(optionValues ?? {}).join(', '),
          amount: matchingVariantSale?.amount ?? 0,
          quantitySold: vs?.quantity_sold ?? 0,
          amountSold: vs?.total_sold ?? 0,
          available_quantity: getAvailableQuantityLabel(
            matchingVariantSale?.available_quantity ?? null,
          ),
          sku: matchingVariantSale?.sku,
        }
      })
      .filter((lrd) => lrd.quantitySold > 0)

    return {
      name: item.name,
      amount: item.amount,
      quantitySold: item.quantity_sold,
      quantityRefunded: item.quantity_refunded,
      amountSold: item.amount_sold,
      amountRefunded: item.amount_refunded,
      netAmountSold: item.net_amount,
      listings: listingRowsData,
      available_quantity: getAvailableQuantityLabel(
        item.available_quantity ?? null,
      ),
    }
  })

  const csvRows = rows.flatMap((row) => {
    if (row.listings.length > 0) {
      return row.listings.map((l) =>
        [
          row.name,
          l.name,
          Util.formatAmount(l.amount),
          l.quantitySold,
          qtyRemainingColumnVisible ? l.available_quantity : null,
          '',
          Util.formatAmount(l.amountSold),
          '',
          Util.formatAmount(l.amountSold),
          skuColumnVisible ? l.sku : null,
        ].filter((rd) => rd != null),
      )
    }

    return [
      [
        row.name,
        '',
        typeof row.amount === 'number' ? Util.formatAmount(row.amount) : 'Open',
        row.quantitySold,
        qtyRemainingColumnVisible ? row.available_quantity : null,
        row.quantityRefunded || '',
        Util.formatAmount(row.amountSold),
        Util.formatAmount(row.amountRefunded),
        Util.formatAmount(row.netAmountSold),
        skuColumnVisible ? '' : null,
      ].filter((rd) => rd != null),
    ]
  })

  const fields = [
    'Item Name',
    'Variation',
    'Price',
    'Quantity Sold',
    qtyRemainingColumnVisible ? 'Quantity Remaining' : null,
    'Quantity Refunded',
    'Amount Sold',
    'Refunded Amount',
    'Net Amount Sold',
    skuColumnVisible ? 'SKU' : null,
  ].filter((f) => f != null)

  return Papa.unparse({
    fields,
    data: csvRows,
  })
}

export default CollectionManageItems
