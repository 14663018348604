import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import * as Util from '@cheddarup/util'

export interface BalanceSummaryProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.TabDetailed
}

const BalanceSummary = ({
  collection,
  className,
  ...restProps
}: BalanceSummaryProps) => (
  <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
    <WebUI.VStack as={WebUI.Panel}>
      <WebUI.HStack className="items-center gap-4 px-6 py-4 text-ds-md">
        <WebUI.PhosphorIcon
          className="rounded bg-teal-600 p-1 text-ds-2xl text-trueWhite"
          icon="list-bullets"
        />
        <span className="font-semibold">Balance Summary</span>
      </WebUI.HStack>
      <WebUI.Separator variant="primary" />
      <WebUI.HStack className="[&_>_.Stack]:flex-[0_0_50%] [&_>_.Stack]:p-8">
        <WebUI.VStack>
          <div className="font-semibold text-ds-xs text-gray400">
            TOTAL COLLECTED
          </div>
          <div className="font-normal text-ds-xl">
            {Util.formatAmount(collection.payments_total)}
          </div>
        </WebUI.VStack>
        <WebUI.Separator orientation="vertical" variant="primary" />
        <WebUI.VStack>
          <div className="font-semibold text-ds-xs text-gray400">
            TOTAL BALANCE
          </div>
          <div className="font-normal text-ds-xl">
            {Util.formatAmount(collection.withdrawal_balance_available)}
          </div>
        </WebUI.VStack>
      </WebUI.HStack>
    </WebUI.VStack>
    <WebUI.VStack as={WebUI.Panel}>
      <WebUI.HStack className="px-6 py-4">
        <span className="font-semibold text-ds-md">Collected</span>
      </WebUI.HStack>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="gap-2 p-6 font-normal [&_>_.Stack_>_span]:flex-[0_0_160px]">
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Total Collected</span>
          <span className="font-normal">
            {Util.formatAmount(collection.payments_total)}
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Cash or Check</span>
          <span className="font-normal">
            {Util.formatAmount(collection.offline_total ?? 0)}
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Past payouts</span>
          <span className="font-normal">
            {Util.formatAmount(collection.approved_withdrawals ?? 0)}
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Fees</span>
          <span className="font-normal">
            {Util.formatAmount(collection.total_user_fees)}
          </span>
        </WebUI.HStack>
      </WebUI.VStack>
    </WebUI.VStack>
    <WebUI.VStack as={WebUI.Panel}>
      <WebUI.HStack className="px-6 py-4">
        <span className="font-semibold text-ds-md">Balance</span>
      </WebUI.HStack>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="gap-2 p-6 [&_>_.Stack_>_span]:flex-[0_0_160px]">
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Balance</span>
          <span className="font-normal">
            {Util.formatAmount(collection.withdrawal_balance_available)}
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Available to Pay Out</span>
          <span className="font-normal">
            {Util.formatAmount(collection.withdrawal_balance_available)}
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Available Soon</span>
          <span className="font-normal">
            {Util.formatAmount(collection.online_pending_total)}
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">
            In transit to bank ending in 3456
          </span>
          <span className="font-normal">
            {Util.formatAmount(collection.pending_withdrawals ?? 0)}
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="gap-4">
          <span className="text-gray400">Withdrawn Amount</span>
          <span className="font-normal">
            {Util.formatAmount(collection.approved_withdrawals ?? 0)}
          </span>
        </WebUI.HStack>
      </WebUI.VStack>
    </WebUI.VStack>
  </WebUI.VStack>
)

export default BalanceSummary
