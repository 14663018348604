import {useParams} from 'react-router-dom'
import React, {useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {genericForwardRef} from '@cheddarup/react-util'
import {QueryDataSectionList} from 'src/components/QueryDataList'
import {
  api,
  useCloneFormMutation,
  useCloneTabSignupMutation,
  useDeleteFormMutation,
  useDeleteTabSignupMutation,
  useSortCollectionObjectsMutation,
  useUpdateFormMutation,
  useUpdateTabSignupMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {LinkButton, LinkButtonProps} from 'src/components/LinkButton'
import ChecklistBox from 'src/images/ChecklistBox.svg'
import CalendarChecked from 'src/images/CalendarChecked.svg'
import {
  TabObjectLimitExceededLabel,
  TabObjectLimitMeter,
  TabObjectLimitMeterProps,
} from 'src/components/TabObjectLimitMeter'
import FormBuilderIcon from 'src/images/FormBuilderIcon.svg'
import WaiverFormIcon from 'src/images/WaiverFormIcon.svg'
import AddSignupIcon from 'src/images/AddSignupIcon.svg'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'

import {FormListToolbar} from './FormListToolbar'
import {MiniBadge} from '../items/ItemsPage/components/ItemsNav'
import {MoveTabObjectsToAnotherCollectionOrCategoryModal} from 'src/components'

const FormsPage = () => {
  const media = WebUI.useMedia()
  const urlParams = useParams()
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  return (
    <WebUI.HStack className="relative shrink-0 grow-0 basis-full pb-18">
      {media.sm && <FormsNav className="bg-teal-80" />}
      <PremiumFeaturesSideSheet tabId={Number(urlParams.collection)}>
        <FormList
          className="max-h-full min-w-0 flex-auto self-start"
          collection={tabQuery.data}
        />
      </PremiumFeaturesSideSheet>

      <WebUI.PageToolbar className="fixed right-0 bottom-0 left-0">
        {!media.sm && tabQuery.data && tabQuery.data.formLimit != null && (
          <FormCountLimitMeter
            maxValue={1}
            value={tabQuery.data.reportsAvailable.activeFormsCount}
          />
        )}

        <WebUI.PageToolbarSubmitButton as={Link} to="../settings">
          Continue
        </WebUI.PageToolbarSubmitButton>
      </WebUI.PageToolbar>
    </WebUI.HStack>
  )
}

// MARK: – FormList

type FormItemType = 'form' | 'signup'

interface FormListSectionData {
  id: FormItemType
  name: string
}

type FormItem<TType extends FormItemType = FormItemType> = TType extends 'form'
  ? Api.TabForm & {formType: TType}
  : TType extends 'signup'
    ? Api.SignUp & {formType: TType}
    : never

interface FormListProps extends React.ComponentPropsWithoutRef<'div'> {
  collection?: Api.Tab
}

const FormList = ({collection, className, ...restProps}: FormListProps) => {
  const urlParams = useParams()
  const signupsQuery = api.tabSignups.list.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const formsQuery = api.tabForms.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (
        items,
      ): Array<WebUI.SectionListData<FormListSectionData, FormItem>> =>
        [
          items.length > 0
            ? {
                sectionData: {
                  id: 'form' as const,
                  name: 'Forms and Waivers',
                },
                rowsData: items.map((f) => ({
                  ...f,
                  formType: 'form' as const,
                })),
              }
            : undefined,
          signupsQuery.data && signupsQuery.data.length > 0
            ? {
                sectionData: {
                  id: 'signup' as const,
                  name: 'Sign Ups',
                },
                rowsData: Util.sort(
                  signupsQuery.data?.map((d) => ({
                    ...d,
                    formType: 'signup' as const,
                  })) ?? [],
                ).asc((d) => d.position),
              }
            : undefined,
        ].filter((s) => s != null),
    },
  )
  const sortCollectionObjectsMutation = useSortCollectionObjectsMutation()
  const deleteTabSignupMutation = useDeleteTabSignupMutation()
  const growlActions = WebUI.useGrowlActions()
  const upgradeRequiredAlertRef = useRef<WebUI.DialogInstance>(null)
  const [formIdToDelete, setFormIdToDelete] = useState<number | null>(null)
  const [formToMove, setFormToMove] = useState<{
    id: number
    type: FormItemType
  } | null>(null)
  const [signupIdToDelete, setSignupIdToDelete] = useState<number | null>(null)

  const sortDisabledSectionIds: FormItemType[] = useMemo(
    () => ['form', 'signup'],
    [],
  )

  const FormListItemWithHandlers: WebUI.DataRowComponentType<FormItem> =
    useMemo(
      () =>
        React.forwardRef((rowProps, forwardedRef) => (
          <FormListItem
            ref={forwardedRef}
            collection={collection}
            onMoveToAnotherCollection={() =>
              setFormToMove(
                rowProps.row?.original.id
                  ? {
                      id: rowProps.row?.original.id,
                      type: rowProps.row.original.formType,
                    }
                  : null,
              )
            }
            onUpgrade={() => upgradeRequiredAlertRef.current?.show()}
            onDelete={async () => {
              if (rowProps.row?.original.formType === 'form') {
                setFormIdToDelete(rowProps.row.original.id)
              } else if (rowProps.row?.original.formType === 'signup') {
                const activeParticipants = rowProps.row.original.spots.reduce(
                  (a, b) => a + (b.active_participant_count ?? 0),
                  0,
                )
                if (activeParticipants > 0) {
                  setSignupIdToDelete(rowProps.row.original.id)
                } else {
                  try {
                    await deleteTabSignupMutation.mutateAsync({
                      pathParams: {
                        // biome-ignore lint/style/noNonNullAssertion:
                        tabId: urlParams.collection!,
                        signupId: rowProps.row.original.id,
                      },
                    })
                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Your signup was successfully deleted',
                    })
                  } catch {
                    growlActions.show('error', {
                      title: 'Error',
                      body: 'Something went wrong while trying to delete your signup. Please try again.',
                    })
                  }
                }
              }
            }}
            {...rowProps}
          />
        )),
      [deleteTabSignupMutation, growlActions, urlParams.collection, collection],
    )

  return (
    <WebUI.VStack
      className={WebUI.cn(
        '[&_>_.DataListToolbar_>_.DataListToolbar-inner]:mx-auto [&_>_.DataListToolbar_>_.DataListToolbar-inner]:h-16 [&_>_.DataListToolbar_>_.DataListToolbar-inner]:min-w-max [&_>_.DataListToolbar_>_.DataListToolbar-inner]:flex-[0_0_min(1200px,96%)] [&_>_.DataListToolbar_>_.DataListToolbar-inner]:px-2 sm:[&_>_.DataListToolbar_>_.DataListToolbar-inner]:px-8',
        className,
      )}
    >
      <FormListToolbar />
      <WebUI.VStack
        className="mx-auto w-full max-w-[min(1200px,100%)] gap-4 py-8"
        {...restProps}
      >
        <QueryDataSectionList
          className={WebUI.cn(
            'w-full gap-4',
            '[&_.SectionContainer]:gap-2',
            className,
          )}
          query={formsQuery}
          estimateRowSize={76}
          sortDisabledSectionIds={sortDisabledSectionIds}
          SectionListComponent={WebUI.SortableSectionList}
          EmptyStateViewComponent={AddFormCTAPanel}
          DataSectionComponent={FormListSection}
          DataRowComponent={FormListItemWithHandlers}
          sortableBetweenSections={false}
          onOrderChange={(newOrder) =>
            sortCollectionObjectsMutation.mutate({
              pathParams: {
                // biome-ignore lint/style/noNonNullAssertion:
                tabId: urlParams.collection!,
              },
              body: {
                type: newOrder.sectionId === 'form' ? 'forms' : 'signups',
                order: newOrder.orderedRowIds.map(Number),
              },
            })
          }
        />

        <MoveTabObjectsToAnotherCollectionOrCategoryModal
          visible={!!formToMove}
          objectType={formToMove?.type ?? 'form'}
          collectionId={Number(urlParams.collection)}
          tabObjectIds={formToMove ? [formToMove.id] : []}
          onDidHide={() => setFormToMove(null)}
        />
        <FormDeleteAlert
          visible={!!formIdToDelete}
          formId={formIdToDelete ?? undefined}
          onDidHide={() => setFormIdToDelete(null)}
        />
        <SignupDeleteAlert
          visible={!!signupIdToDelete}
          signupId={signupIdToDelete ?? undefined}
          onDidHide={() => setSignupIdToDelete(null)}
        />
      </WebUI.VStack>

      <UpgradeRequiredAlert ref={upgradeRequiredAlertRef} />
    </WebUI.VStack>
  )
}

// MARK: – FormListItem

interface FormListItemProps<TType extends FormItemType>
  extends WebUI.DataRowComponentProps<FormItem<TType>> {
  collection?: Api.Tab
  onMoveToAnotherCollection: () => void
  onDelete: () => void
  onUpgrade: () => void
}

const FormListItem = genericForwardRef(
  <TType extends FormItemType>(
    {
      collection,
      onMoveToAnotherCollection,
      onUpgrade,
      onDelete,
      row,
      setSelected,
      ...restProps
    }: FormListItemProps<TType> & {ref?: React.Ref<HTMLDivElement>},
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const updateFormMutation = useUpdateFormMutation()
    const cloneFormMutation = useCloneFormMutation()
    const updateTabSignupMutation = useUpdateTabSignupMutation()
    const cloneTabSignupMutation = useCloneTabSignupMutation()

    const form = row?.original
    const toEdit = form
      ? {
          form: `${form.id}/edit`,
          signup: `sign-up/${form.id}?type=${
            (form as Api.SignUp).options.signupType
          }`,
        }[form.formType]
      : '#'
    const showPaidBadge =
      ((form?.requires_pro && !form?.is_pro && !collection?.is_pro) ||
        (form?.requires_team && !form?.is_team && !collection?.is_team)) &&
      !collection?.options?.doNotEnforceAddlGated

    return (
      <WebUI.Card
        ref={forwardedRef}
        dragHandleVisible
        accessoryView={
          !!form && (
            <WebUI.ActionGroup>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="pencil" />}
                as={LinkButton}
                to={toEdit}
              >
                Edit
              </WebUI.Action>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="copy" />}
                execute={() => {
                  if (
                    collection &&
                    collection.status !== 'draft' &&
                    collection.formLimit != null &&
                    !collection.is_pro &&
                    collection.reportsAvailable &&
                    collection.reportsAvailable.activeFormsCount +
                      collection.reportsAvailable.activeSignupsCount >=
                      collection.formLimit
                  ) {
                    return onUpgrade()
                  }

                  if (form.formType === 'signup') {
                    return cloneTabSignupMutation.mutateAsync({
                      pathParams: {
                        tabId: form.tab_id,
                        signupId: form.id,
                      },
                    })
                  }
                  return cloneFormMutation.mutateAsync({
                    pathParams: {
                      tabId: form.tab_id,
                      formId: form.id,
                    },
                    body: {name: `${form.name} (Copy)`},
                  })
                }}
              >
                Replicate
              </WebUI.Action>
              <WebUI.Action
                icon={
                  form.hidden ? (
                    <WebUI.PhosphorIcon icon="eye" />
                  ) : (
                    <WebUI.PhosphorIcon icon="eye-slash" />
                  )
                }
                execute={() => {
                  if (form.formType === 'signup') {
                    return updateTabSignupMutation.mutateAsync({
                      pathParams: {
                        tabId: form.tab_id,
                        signupId: form.id,
                      },
                      body: {hidden: !form.hidden},
                    })
                  }
                  return updateFormMutation.mutateAsync({
                    pathParams: {
                      tabId: form.tab_id,
                      formId: form.id,
                    },
                    body: {hidden: !form.hidden},
                  })
                }}
              >
                {form.hidden ? 'Show' : 'Hide'}
              </WebUI.Action>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="folder-plus" />}
                onClick={() => onMoveToAnotherCollection()}
              >
                Move to
              </WebUI.Action>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="trash" />}
                onClick={() => onDelete()}
              >
                Delete
              </WebUI.Action>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="arrow-square-out" />}
                as={LinkButton}
                target="_blank"
                rel="noreferrer"
                to={`../../manage?p=signups&signupId=${form.id}&t=responses&viewBy=date`}
              >
                View Responses
              </WebUI.Action>
            </WebUI.ActionGroup>
          )
        }
        {...restProps}
      >
        <WebUI.HStack className="w-[85%] items-center">
          <Link
            className="h-[82px] w-full items-stretch justify-start"
            to={toEdit}
          >
            <WebUI.HStack className="items-center gap-2">
              {form?.hidden ? (
                <WebUI.VStack
                  className={
                    'w-[82px] flex-0 items-center justify-center self-stretch bg-[#ADADAD] text-center text-trueWhite'
                  }
                >
                  <WebUI.PhosphorIcon icon="eye-slash" width={28} />
                  <WebUI.Text className="font-light">Hidden</WebUI.Text>
                </WebUI.VStack>
              ) : (
                <WebUI.VStack className="w-[82px] flex-0 items-center justify-center text-[42px] text-teal-70">
                  {form?.formType === 'signup' ? (
                    <WebUI.PhosphorIcon icon="users-four" />
                  ) : form?.options?.isWaiver ? (
                    <WebUI.PhosphorIcon icon="pen" />
                  ) : (
                    <WebUI.PhosphorIcon icon="clipboard-text" />
                  )}
                </WebUI.VStack>
              )}
              {form ? (
                <WebUI.Ellipsis className="min-w-0 grow">
                  {form.name}
                </WebUI.Ellipsis>
              ) : (
                <WebUI.Skeleton width={220} height={12} />
              )}
            </WebUI.HStack>
          </Link>

          {showPaidBadge && (
            <PremiumFeatureSideSheetDisclosure tabId={collection?.id} />
          )}
        </WebUI.HStack>
      </WebUI.Card>
    )
  },
)

// MARK: - FormListSection

const FormListSection: WebUI.DataSectionComponentType<FormListSectionData> = ({
  setSelected,
  section,
  children,
  ...restProps
}) => (
  <WebUI.Card {...restProps}>
    <WebUI.SectionListHeader className="h-17 items-center overflow-hidden px-4">
      <WebUI.Ellipsis className="text-ds-md">
        {section?.original.name}
      </WebUI.Ellipsis>
    </WebUI.SectionListHeader>

    <WebUI.Separator />

    <div className="p-3">{children}</div>
  </WebUI.Card>
)

// MARK: – FormsNav

interface FormsNavProps extends React.ComponentPropsWithoutRef<'div'> {
  forceAddForm?: boolean
}

const FormsNav = ({
  forceAddForm = false,
  className,
  ...restProps
}: FormsNavProps) => {
  const urlParams = useParams()
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const sellersQuery = api.formTemplates.list.useQuery(undefined, {
    select: (templates) =>
      Util.uniqueBy(
        templates.map((t) => ({
          id: t.user.id,
          name: t.user.name,
        })),
        (t) => t.id,
      ),
  })

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'w-[200px] justify-between gap-3 overflow-y-auto px-6 py-8',
        className,
      )}
      {...restProps}
    >
      <WebUI.VStack className="gap-10">
        {forceAddForm ? (
          <LinkButton variant="primary" to="add-form">
            New Form
          </LinkButton>
        ) : (
          <AddFormMenu variant="primary" />
        )}

        {!!sellersQuery.data && sellersQuery.data.length > 0 && (
          <WebUI.VStack className="gap-2">
            <WebUI.Text className="text-ds-xs uppercase">
              My Form Libraries
            </WebUI.Text>
            {sellersQuery.data.map((seller, idx) => (
              <Link
                key={idx}
                className="max-w-[180px] text-ds-sm"
                variant="primary"
                to={`seller/${seller.id}/forms`}
              >
                {seller.name}
              </Link>
            ))}
          </WebUI.VStack>
        )}
      </WebUI.VStack>

      {tabQuery.data && !tabQuery.data.is_pro && (
        <FormCountLimitMeter
          maxValue={1}
          value={
            tabQuery.data.reportsAvailable.activeFormsCount +
            tabQuery.data.reportsAvailable.activeSignupsCount
          }
        />
      )}
    </WebUI.VStack>
  )
}

// MARK: – FormCountLimitMeter

interface FormCountLimitProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<TabObjectLimitMeterProps, 'value' | 'maxValue'> {}

const FormCountLimitMeter = ({
  maxValue,
  value,
  className,
  ...restProps
}: FormCountLimitProps) => {
  const media = WebUI.useMedia()
  return (
    <WebUI.VStack className={WebUI.cn('gap-1', className)} {...restProps}>
      {media.sm ? (
        <TabObjectLimitMeter
          tabObjectType="form"
          value={value}
          maxValue={maxValue}
        />
      ) : (
        <TabObjectLimitExceededLabel
          className="text-ds-xs"
          tabObjectType="item"
        />
      )}

      {value > maxValue && (
        <Link
          className="text-ds-sm [&_>_.Anchor-content]:whitespace-normal"
          variant="primary"
          to="i/plans"
        >
          Upgrade for unlimited forms
        </Link>
      )}
    </WebUI.VStack>
  )
}

// MARK: – AddFormMenu

export const AddFormMenu = ({
  children,
  ...restProps
}: WebUI.MenuButtonProps &
  WebUI.ButtonProps &
  React.ComponentPropsWithoutRef<'button'>) => {
  const urlParams = useParams()
  const {data: tab} = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (_tab) => ({status: _tab.status, is_pro: _tab.is_pro}),
    },
  )
  const media = WebUI.useMedia()
  const hasSellersQuery = api.formTemplates.list.useQuery(undefined, {
    enabled: !media.sm,
    select: (templates) => templates.length > 0,
  })

  const isBasic = !tab?.is_pro
  const isPublished = tab?.status !== 'draft'

  return (
    <WebUI.Menu>
      <WebUI.MenuButton
        disabled={hasSellersQuery.isPending}
        iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
        {...restProps}
      >
        {children ?? 'Add'}
      </WebUI.MenuButton>

      <WebUI.MenuList>
        {!media.sm && hasSellersQuery.data ? (
          <WebUI.Drawer
            disclosure={
              <WebUI.MenuItem
                iconBefore={<WebUI.PhosphorIcon icon="clipboard-text" />}
                hideOnClick={false}
                as={WebUI.DialogDisclosure}
              >
                Form
              </WebUI.MenuItem>
            }
          >
            <FormsNav forceAddForm />
          </WebUI.Drawer>
        ) : (
          <WebUI.MenuItem
            as={LinkButton}
            iconBefore={<WebUI.PhosphorIcon icon="clipboard-text" />}
            to="add-form"
          >
            Form
          </WebUI.MenuItem>
        )}

        <SignUpTypePickerModal
          disclosure={
            <WebUI.MenuItem
              hideOnClick={false}
              as={WebUI.DialogDisclosure}
              iconBefore={<WebUI.PhosphorIcon icon="users-four" />}
            >
              <span>Sign Up</span>
            </WebUI.MenuItem>
          }
        />

        <WebUI.MenuItem
          as={LinkButton}
          iconBefore={
            !isBasic || isPublished ? (
              <WebUI.PhosphorIcon icon="pen" />
            ) : (
              <MiniBadge />
            )
          }
          to="add-waiver"
        >
          <WebUI.HStack className="gap-3">
            <WebUI.Text>Waiver</WebUI.Text>
            {isBasic && isPublished && (
              <WebUI.Text className="text-teal-600">PRO</WebUI.Text>
            )}
          </WebUI.HStack>
        </WebUI.MenuItem>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

// MARK: – AddFormCTAPanel

const AddFormCTAPanel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.VStack
    className={WebUI.cn('items-center gap-6 p-8 text-center', className)}
    {...restProps}
  >
    <WebUI.Heading className="leading-heading" as="h2">
      Need to collect information?
      <br />
      Add a form, waiver or sign up to your collection page
    </WebUI.Heading>
    <WebUI.VStack className="flex-wrap justify-center gap-8 sm:flex-row [&_>_.Button]:w-[214px] [&_>_.Button]:gap-3 [&_>_.Button]:text-wrap [&_>_.Button]:rounded-[10px] [&_>_.Button]:bg-trueWhite [&_>_.Button]:shadow-z4">
      <LinkButton
        variant="secondary"
        orientation="vertical"
        iconBefore={<img className="h-[5.5em]" src={FormBuilderIcon} alt="" />}
        to="add-form"
      >
        Add Form
      </LinkButton>
      <LinkButton
        variant="secondary"
        orientation="vertical"
        iconBefore={<img className="h-[5.5em]" src={WaiverFormIcon} alt="" />}
        to="add-waiver"
      >
        Add Waiver
      </LinkButton>
      <SignUpTypePickerModal
        disclosure={
          <WebUI.DialogDisclosure
            variant="secondary"
            orientation="vertical"
            iconBefore={
              <img className="h-[5.5em]" src={AddSignupIcon} alt="" />
            }
          >
            Add Sign Up
          </WebUI.DialogDisclosure>
        }
      />
    </WebUI.VStack>
  </WebUI.VStack>
)

// MARK: – FormDeleteAlert

interface FormDeleteAlertProps extends WebUI.AlertProps {
  formId?: number
}

const FormDeleteAlert = React.forwardRef<
  WebUI.DialogInstance,
  FormDeleteAlertProps
>(({formId, ...restProps}, forwardedRef) => {
  const urlParams = useParams()
  const deleteFormMutation = useDeleteFormMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete form confirmation"
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>
            Are you sure you want to delete this form?
          </WebUI.AlertHeader>
          <WebUI.AlertContentView
            text="This action can not be undone."
            actions={
              <>
                <WebUI.AlertActionButton
                  execute={async () => {
                    if (!formId) {
                      return
                    }

                    try {
                      await deleteFormMutation.mutateAsync({
                        pathParams: {
                          // biome-ignore lint/style/noNonNullAssertion:
                          tabId: urlParams.collection!,
                          formId,
                        },
                      })
                      growlActions.show('success', {
                        title: 'Success',
                        body: 'Your form was successfully deleted',
                      })
                      dialog.hide()
                    } catch {
                      growlActions.show('error', {
                        title: 'Error',
                        body: 'Something went wrong while trying to delete your form. Please try again.',
                      })
                    }
                  }}
                >
                  Delete
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </>
      )}
    </WebUI.Alert>
  )
})

// MARK: – SignupDeleteAlert

interface SignupDeleteAlertProps extends WebUI.AlertProps {
  signupId?: number
}

const SignupDeleteAlert = React.forwardRef<
  WebUI.DialogInstance,
  SignupDeleteAlertProps
>(({signupId, ...restProps}, forwardedRef) => {
  const urlParams = useParams()
  const deleteTabSignupMutation = useDeleteTabSignupMutation()
  const growlActions = WebUI.useGrowlActions()
  const [sendEmail, setSendEmail] = useState(true)
  const signupQuery = api.tabSignups.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: signupId!,
      },
    },
    {
      enabled: signupId != null,
    },
  )

  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete signup confirmation"
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text={
              <WebUI.VStack className="gap-4">
                <span>
                  There are{' '}
                  {Util.pluralize(
                    'participant',
                    signupQuery.data?.active_participant_count ?? 0,
                    true,
                  )}{' '}
                  signed up for this signup. Their information will be removed.
                </span>
                <WebUI.Checkbox
                  size="compact"
                  state={sendEmail}
                  onChange={(event) => setSendEmail(event.target.checked)}
                >
                  Send participants an automatic email that signup has been
                  deleted
                </WebUI.Checkbox>
              </WebUI.VStack>
            }
            actions={
              <>
                <WebUI.AlertActionButton
                  execute={async () => {
                    if (!signupId) {
                      return
                    }

                    try {
                      await deleteTabSignupMutation.mutateAsync({
                        pathParams: {
                          // biome-ignore lint/style/noNonNullAssertion:
                          tabId: urlParams.collection!,
                          signupId,
                        },
                        queryParams: {
                          sendEmail,
                        },
                      })
                      growlActions.show('success', {
                        title: 'Success',
                        body: 'Your signup was successfully deleted',
                      })
                      dialog.hide()
                    } catch {
                      growlActions.show('error', {
                        title: 'Error',
                        body: 'Something went wrong while trying to delete your signup. Please try again.',
                      })
                    }
                  }}
                >
                  Delete
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </>
      )}
    </WebUI.Alert>
  )
})

// MARK: – SignUpTypePickerModal

const SignUpTypePickerModal = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.ModalProps
>(({initialVisible = false, className, ...restProps}, forwardedRef) => (
  <WebUI.Modal
    aria-label="Sign up type picker"
    ref={forwardedRef}
    className={WebUI.cn('sm:[&_>_.ModalContentView]:max-w-[420px]', className)}
    initialVisible={initialVisible}
    {...restProps}
  >
    <WebUI.ModalCloseButton />
    <WebUI.ModalHeader variant="compact">Choose Sign Up Type</WebUI.ModalHeader>
    <WebUI.VStack className="gap-3 p-8 pt-0">
      <SignUpTypePickerItem
        iconBefore={<img src={ChecklistBox} alt="sign up list" />}
        heading="Sign Up List"
        detail="No individual time spots needed (e.g. food items, wish lists)"
        to={{pathname: 'sign-up', search: 'type=list'}}
      />
      <SignUpTypePickerItem
        variant="secondaryAlt"
        iconBefore={<img src={CalendarChecked} alt="sign up schedule" />}
        heading="Sign Up Schedule"
        detail="Event is on a specific date and/or time (e.g. class party, tournaments)"
        to={{pathname: 'sign-up', search: 'type=schedule'}}
      />
    </WebUI.VStack>
  </WebUI.Modal>
))

// MARK: – SignUpTypePickerItem

interface SignUpTypePickerItemProps
  extends LinkButtonProps,
    React.ComponentPropsWithoutRef<'a'> {
  heading: string
  detail: string
}

const SignUpTypePickerItem = ({
  heading,
  detail,
  iconBefore,
  className,
  ...restProps
}: SignUpTypePickerItemProps) => (
  <LinkButton
    className={WebUI.cn(
      '[&_>_.Button-iconBefore]:!mr-4 h-[88px] whitespace-normal px-3 text-start',
      className,
    )}
    variant="secondary"
    iconBefore={
      <WebUI.VStack
        className={
          'h-16 w-14 items-center justify-center rounded bg-trueWhite text-8xl text-teal-600'
        }
      >
        {iconBefore}
      </WebUI.VStack>
    }
    {...restProps}
  >
    <WebUI.VStack className="min-w-0 items-start gap-1">
      <WebUI.Ellipsis className="text-ds-sm text-teal-600">
        {heading}
      </WebUI.Ellipsis>
      <WebUI.Text className="overflow-visible font-light text-ds-sm [text-overflow:initial] [word-wrap:normal]">
        {detail}
      </WebUI.Text>
    </WebUI.VStack>
  </LinkButton>
)

export default FormsPage
