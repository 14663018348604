import {forwardRef, useEffect, useMemo} from 'react'

import {PhosphorIcon} from '../icons'
import {IconButton} from './IconButton'
import {Image, ImageProps} from './Image'
import {cn} from '../utils'

export interface ImageFilePreviewProps extends ImageProps {
  imageFile: File
  onClear?: () => void
}

export const ImageFilePreview = forwardRef<
  HTMLImageElement,
  ImageFilePreviewProps
>(({imageFile, onClear, className, ...restProps}, forwardedRef) => {
  const imageFileUrl = useMemo(
    () => URL.createObjectURL(imageFile),
    [imageFile],
  )

  useEffect(
    () => () => {
      if (imageFileUrl) {
        URL.revokeObjectURL(imageFileUrl)
      }
    },
    [imageFileUrl],
  )

  return (
    <div className={cn('ImageFilePreview', 'relative', className)}>
      <Image
        ref={forwardedRef}
        className="ImageFilePreview-image"
        src={imageFileUrl}
        {...restProps}
      />
      {!!onClear && (
        <IconButton
          className="ImageFilePreview-clearButton absolute top-2 right-2 text-ds-lg text-teal-600"
          size="default_alt"
          onClick={() => onClear()}
        >
          <PhosphorIcon
            icon="x-circle-fill"
            className="ImageFilePreview-clearButton-icon"
          />
        </IconButton>
      )}
    </div>
  )
})
