import React, {useRef} from 'react'
import {useControlledState, useForkRef} from '@cheddarup/react-util'

import {HStack, VStack} from './Stack'
import {NumberInput} from './NumberInput'
import {IconButton} from './IconButton'
import {Separator} from './Separator'
import {PhosphorIcon} from '../icons'
import {cn} from '../utils'

export interface NumberStepperProps
  extends React.ComponentPropsWithoutRef<'div'> {
  value?: string
  defaultValue?: string
  onValueChange?: (newValue: string) => void
}

export const NumberStepper = React.forwardRef<
  HTMLDivElement,
  NumberStepperProps
>(
  (
    {
      className,
      defaultValue = '',
      value: valueProp,
      onValueChange,
      ...restProps
    },
    forwardedRef,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const [value, setValue] = useControlledState(
      valueProp,
      defaultValue,
      onValueChange,
    )

    return (
      <HStack
        ref={ref}
        className={cn(
          'NumberStepper',
          'relative overflow-hidden rounded bg-trueWhite text-gray800 shadow-[inset_0_0_0_1px_theme(colors.grey.300)]',
          'aria-invalid:shadow-[inset_0_0_0_1px_theme(colors.orange.50)] aria-invalid:[&_.Separator]:border-orange-50',
          'focus-within::shadow-[inset_0_0_0_1px_theme(colors.teal.600)] focus:shadow-[inset_0_0_0_1px_theme(colors.teal.600)]',
          'focus-within::[&_.Separator]:border-teal-600 focus:[&_.Separator]:border-teal-600',
          `hover:[&:not(:focus):not([aria-invalid="true"]):not(:disabled):not([aria-disabled="true"])]:bg-inputHoverBackground`,
          className,
        )}
        {...restProps}
      >
        <NumberInput
          className="NumberStepper-input min-w-[2rem] bg-transparent px-2"
          variant="headless"
          value={value}
          onValueChange={(newValues) => setValue(newValues.value)}
          onFocus={() => ownRef.current?.focus()}
          onBlur={() => ownRef.current?.blur()}
        />
        <Separator className="my-px" orientation="vertical" variant="primary" />
        <VStack
          className={
            'NumberStepper-steps h-10 flex-0 [&_>_.IconButton]:h-1/2 [&_>_.IconButton]:rounded-none [&_>_.IconButton]:text-ds-sm'
          }
        >
          <IconButton
            className="NumberStepper-stepPlus"
            tabIndex={-1}
            size="small"
            onClick={() => setValue(String(Number(value || '0') + 1))}
          >
            <PhosphorIcon icon="plus" />
          </IconButton>
          <Separator className="mr-px" variant="primary" />
          <IconButton
            className="NumberStepper-stepMinus"
            tabIndex={-1}
            size="small"
            onClick={() => setValue(String(Number(value || '0') - 1))}
          >
            <PhosphorIcon icon="minus" />
          </IconButton>
        </VStack>
      </HStack>
    )
  },
)
