import * as Yup from 'yup'
import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ForwardRefComponent, useFormik} from '@cheddarup/react-util'
import {
  InferResponse,
  api,
  endpoints,
  useUpdateTabPaymentMutation,
} from '@cheddarup/api-client'

import {SharpAvatar} from './SharpAvatar'

export interface PayerOverviewProps {
  payment?: Api.TabPayment
  tabMember?: Api.PaymentToOthers['tab_member']
  avatarHidden?: boolean
  editable?: boolean
  footer?: React.ReactNode
}

const PayerOverview = React.forwardRef(
  (
    {
      as: Comp = WebUI.HStack,
      payment,
      tabMember,
      avatarHidden = false,
      editable,
      footer,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <Comp
      ref={forwardedRef}
      className={WebUI.cn('gap-4', className)}
      {...restProps}
    >
      {!avatarHidden && (
        <SharpAvatar
          size={48}
          image={tabMember?.profile_pic}
          name={tabMember?.name}
        />
      )}
      <WebUI.VStack className="min-w-0 gap-3">
        {editable && payment ? (
          <PayerOverviewPopover payment={payment} tabMember={tabMember}>
            <WebUI.VStack>
              <WebUI.Text>
                {tabMember?.name ?? <WebUI.Skeleton width={220} height={12} />}
              </WebUI.Text>
              <WebUI.Text className="font-light text-ds-sm text-teal-600">
                {tabMember?.email}
              </WebUI.Text>
            </WebUI.VStack>
          </PayerOverviewPopover>
        ) : (
          <WebUI.VStack>
            <WebUI.Text>
              {tabMember?.name ?? <WebUI.Skeleton width={220} height={12} />}
            </WebUI.Text>
            {tabMember ? (
              <WebUI.Anchor
                className="font-light text-ds-sm"
                rel="noopener noreferrer"
                target="_blank"
                href={`mailto:${tabMember?.email}`}
              >
                {tabMember?.email}
              </WebUI.Anchor>
            ) : (
              <WebUI.Skeleton width={160} height={12} />
            )}
          </WebUI.VStack>
        )}
        {footer && <div>{footer}</div>}
      </WebUI.VStack>
    </Comp>
  ),
) as ForwardRefComponent<'div', PayerOverviewProps>

interface PayerOverviewPopoverProps extends WebUI.PopoverDisclosureProps {
  payment: Api.TabPayment
  tabMember?: Api.PaymentToOthers['tab_member']
  children: React.ReactNode
}

const PayerOverviewPopover: React.FC<PayerOverviewPopoverProps> = ({
  tabMember,
  payment,
  children,
  ...restProps
}) => (
  <WebUI.Popover placement="bottom-start">
    {(popover) => (
      <>
        <WebUI.PopoverDisclosure {...restProps} variant="text">
          {children}
        </WebUI.PopoverDisclosure>
        <WebUI.PopoverContent aria-label="Payer overview form">
          <PayerOverviewPopoverForm
            className="p-4"
            payment={payment}
            tabMember={tabMember}
            onCancel={() => popover.hide()}
            onDidSubmit={() => popover.hide()}
          />
        </WebUI.PopoverContent>
      </>
    )}
  </WebUI.Popover>
)

// MARK: – PayerOverviewPopoverFormProps

interface PayerOverviewPopoverFormProps
  extends React.ComponentPropsWithoutRef<'form'> {
  payment: Api.TabPayment
  tabMember?: Api.PaymentToOthers['tab_member']
  onCancel: () => void
  onDidSubmit?: () => void
}

const PayerOverviewPopoverForm: React.FC<PayerOverviewPopoverFormProps> = ({
  payment,
  tabMember,
  onCancel,
  onDidSubmit,
  className,
  ...restProps
}) => {
  const updateTabMemberConfirmationAlertRef = useRef<WebUI.DialogInstance>(null)
  const updatePaymentMutation = useUpdateTabPaymentMutation()
  const [newTabMember, setNewTabMember] =
    useState<InferResponse<typeof endpoints.tabMembers.list>[number]>()
  const otherMembersQuery = api.tabMembers.list.useQuery(
    {
      pathParams: {
        tabId: payment.tab_id,
      },
    },
    {
      select: (members) =>
        members.filter((member) => member.email !== tabMember?.email),
    },
  )

  const formik = useFormik({
    initialValues: {
      name: tabMember?.name ?? '',
      email: tabMember?.email ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid format'),
    }),
    onSubmit: async (values) => {
      const existingTabMember = otherMembersQuery.data?.find(
        (member) => member.email === values.email,
      )
      if (existingTabMember) {
        setNewTabMember(existingTabMember)
        updateTabMemberConfirmationAlertRef.current?.show()
      } else {
        const nameChanged = formik.initialValues.name !== values.name
        const emailChanged = formik.initialValues.email !== values.email
        await updatePaymentMutation.mutateAsync({
          pathParams: {
            tabId: payment.tab_id,
            paymentId: payment.id,
          },
          body: {
            ...(nameChanged && {name: values.name}),
            ...(emailChanged && {email: values.email || null}),
          },
        })
        onDidSubmit?.()
      }
    },
  })

  return (
    <>
      <WebUI.Form
        className={WebUI.cn('[&_>_.Form-inner]:gap-3', className)}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
        {...restProps}
      >
        <WebUI.FormField label="Name" error={formik.errors.name}>
          <WebUI.Input
            className="w-[280px]"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField label="Email" error={formik.errors.email}>
          <WebUI.Input
            className="w-[280px]"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        <WebUI.HStack className="items-center gap-3">
          {formik.dirty && (
            <WebUI.Button type="submit" loading={formik.isSubmitting}>
              Save
            </WebUI.Button>
          )}
          <WebUI.Button variant="secondary" onClick={onCancel}>
            Cancel
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.Form>
      <UpdateTabMemberConfirmationAlert
        ref={updateTabMemberConfirmationAlertRef}
        payment={payment}
        tabMember={newTabMember}
        onDidHide={() => onDidSubmit?.()}
      />
    </>
  )
}

// MARK: – UpdateTabMemberConfirmationAlert

interface UpdateTabMemberConfirmationAlertProps extends WebUI.AlertProps {
  payment: Api.TabPayment
  tabMember?: InferResponse<typeof endpoints.tabMembers.list>[number]
}

const UpdateTabMemberConfirmationAlert = React.forwardRef<
  WebUI.DialogInstance,
  UpdateTabMemberConfirmationAlertProps
>(({payment, tabMember, ...restProps}, forwardedRef) => {
  const updatePaymentMutation = useUpdateTabPaymentMutation()

  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Update tabmember confirmation"
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text={
              <WebUI.VStack className="gap-4">
                <span>
                  This email address has been used on a previous order. Using
                  this email again will change the name on this order to:{' '}
                  <strong>{tabMember?.name}</strong>.
                </span>
                <span>
                  If this order is for a different user, use a different email
                  or leave blank.
                </span>
              </WebUI.VStack>
            }
            actions={
              <>
                <WebUI.AlertActionButton
                  execute={async () => {
                    if (!tabMember) {
                      return
                    }
                    await updatePaymentMutation.mutateAsync({
                      pathParams: {
                        tabId: payment.tab_id,
                        paymentId: payment.id,
                      },
                      body: {email: tabMember.email},
                    })
                    dialog.hide()
                  }}
                >
                  Save and Change Name
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </>
      )}
    </WebUI.Alert>
  )
})

export default PayerOverview
