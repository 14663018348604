import * as Yup from 'yup'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateUserMutation} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import {CurrentUserAvatar} from 'src/components/CurrentUserAvatar'
import {UploadPinturaImageFormModal} from 'src/components/UploadPinturaImageForm'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import {guessError} from 'src/helpers/error-utils'

const DisplayNamePage = () => {
  return (
    <AccountSettingsContentLayout
      heading="Display Name"
      body="Your display name will appear in a contact section on all collections.
        Including a small profile picture builds trust and encourages
        participation."
    >
      <DisplayNameForm />
    </AccountSettingsContentLayout>
  )
}

// MARK: – DisplayNameForm

const DISPLAY_NAME_MAX_CHARS = 56

const DisplayNameForm = (props: React.ComponentPropsWithoutRef<'form'>) => {
  const sessionQuery = api.auth.session.useQuery()
  const updateUserMutation = useUpdateUserMutation()
  const growlActions = WebUI.useGrowlActions()
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      displayName: Yup.string()
        .trim()
        .required('Required')
        .max(
          DISPLAY_NAME_MAX_CHARS,
          `Can't be more than ${DISPLAY_NAME_MAX_CHARS} characters`,
        ),
    }),
    initialValues: {
      displayName: sessionQuery.data?.user.display_name ?? '',
    },
    onSubmit: async (values) => {
      try {
        await updateUserMutation.mutateAsync({
          body: {
            display_name: values.displayName,
          },
        })
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <AccountSettingsContentCard
      as="form"
      heading="Display Name"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <div className="flex flex-row items-center gap-5">
        <UploadProfilePic />

        <WebUI.FormField
          required
          label="Display Name"
          className="grow"
          error={formik.errors.displayName}
          caption={`Characters remaining: ${DISPLAY_NAME_MAX_CHARS - formik.values.displayName.length}`}
        >
          <WebUI.Input
            name="displayName"
            maxLength={56}
            value={formik.values.displayName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </div>

      <WebUI.Disclosure visible={formik.dirty}>
        <WebUI.DisclosureContent>
          <WebUI.Button type="submit" loading={formik.isSubmitting}>
            Save
          </WebUI.Button>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </AccountSettingsContentCard>
  )
}

// MARK: – UploadProfilePic

const UploadProfilePic = () => {
  const profilePicQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.profile_pic,
  })
  const updateUserMutation = useUpdateUserMutation()
  const [profilePicBlob, setProfilePicBlob] = useState<Blob | null>(null)

  const content = (
    <>
      <CurrentUserAvatar size={72} />
      <WebUI.PhosphorIcon
        className="absolute right-1 bottom-1 rounded-full bg-teal-600 p-0_5 text-trueWhite"
        icon="camera-fill"
      />
    </>
  )

  if (profilePicQuery.isPending) {
    return content
  }

  if (!profilePicQuery.data && !profilePicBlob) {
    return (
      <WebUI.FileUploader
        onDropAccepted={([newProfilePicBlob]) => {
          if (newProfilePicBlob) {
            setProfilePicBlob(newProfilePicBlob)
          }
        }}
      >
        <WebUI.FileUploaderButton className="relative" variant="text">
          {content}
        </WebUI.FileUploaderButton>
        <WebUI.FileUploaderInput />
      </WebUI.FileUploader>
    )
  }

  return (
    <UploadPinturaImageFormModal
      key={profilePicBlob?.size}
      initialVisible={!!profilePicBlob}
      initialImage={profilePicBlob ?? profilePicQuery.data}
      parentPath="user"
      imageCropAspectRatio={1 / 1}
      uploaderOptions={['upload', 'camera']}
      circularCrop
      noOriginalImage
      utils={['crop', 'filter', 'finetune']}
      disclosure={
        <div className="relative">
          <WebUI.DialogDisclosure variant="text">
            {content}
          </WebUI.DialogDisclosure>
          <WebUI.IconButton
            className="absolute top-1 right-1 rounded-full"
            size="small"
            variant="default"
            onClick={() =>
              updateUserMutation.mutate({body: {profile_pic_id: null}})
            }
          >
            <WebUI.PhosphorIcon icon="trash" />
          </WebUI.IconButton>
        </div>
      }
      onImageSubmit={(newProfilePic) =>
        updateUserMutation.mutate({
          body: {profile_pic_id: newProfilePic.id},
        })
      }
      onDidHide={() => setProfilePicBlob(null)}
    />
  )
}

export default DisplayNamePage
