import {Link} from 'react-router-dom'
import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import ImagePlaceholder from 'src/images/ImagePlaceholder.svg'
import ImagesUtils from 'src/helpers/ImagesUtils'

export interface MarketplaceItemsGalleryProps
  extends React.ComponentPropsWithoutRef<'div'> {
  items: Api.MarketplaceItem[]
}

const MarketplaceItemsGallery = ({
  items,
  className,
  ...restProps
}: MarketplaceItemsGalleryProps) => (
  <div
    className={WebUI.cn(
      'grid grid-cols-2 gap-x-3 gap-y-3 sm:grid-cols-[repeat(2,1fr)] sm:gap-x-7 sm:gap-y-7 lg:grid-cols-3 xl:grid-cols-4',
      className,
    )}
    {...restProps}
  >
    {items
      .filter((item) => !!item.collection?.[0]?.organizer)
      .map((item) => (
        <div key={item.id} className="h-[18rem] w-full">
          <MarketplaceItemsGalleryItem item={item} />
        </div>
      ))}
  </div>
)

// MARK: – MarketplaceItemsGalleryItem

interface MarketplaceItemsGalleryItemProps
  extends React.ComponentPropsWithoutRef<'a'> {
  item: Api.MarketplaceItem
}

const MarketplaceItemsGalleryItem = React.memo(
  ({item, className, ...restProps}: MarketplaceItemsGalleryItemProps) => {
    const organizer = item.collection[0]?.organizer
    const hasImage = item.images && item.images.length !== 0

    const imageSrc = useMemo(() => {
      if (!hasImage) {
        return ImagePlaceholder
      }
      return (
        ImagesUtils.getItemMainThumbnailUrl(item.images, {
          width: 300,
        }) ?? ImagePlaceholder
      )
    }, [hasImage, item.images])

    return (
      <Link
        className={WebUI.cn(
          'flex h-full w-full flex-col rounded bg-trueWhite shadow-z2',
          className,
        )}
        target="_blank"
        to={`/c/${item.collection[0]?.slug}?access-code=${item.collection[0]?.access_code}`}
        {...restProps}
      >
        <div
          className={WebUI.cn(
            !hasImage && 'bg-teal-80',
            'relative flex h-[70%] w-full items-center justify-center',
          )}
        >
          <img
            className={WebUI.cn(
              hasImage ? 'h-full w-full' : 'h-auto w-[6rem]',
              'block object-cover object-center',
            )}
            alt={item.name}
            src={imageSrc}
          />
          <MarketplaceItemsGalleryItemPriceBlock
            className="absolute top-0 right-0 [border-top-right-radius:theme(borderRadius.default)]"
            item={item}
          />
        </div>
        <div className="flex h-[30%] flex-col justify-between p-4">
          <div className="overflow-hidden text-ellipsis whitespace-nowrap text-ds-sm">
            {item.name}
          </div>
          <div
            className={
              'overflow-hidden text-ellipsis whitespace-nowrap font-accent text-ds-sm text-gray400 italic'
            }
          >
            {organizer?.display_name || organizer?.name}
          </div>
        </div>
      </Link>
    )
  },
)

// MARK: – MarketplaceItemsGalleryItemPriceBlock

interface MarketplaceItemsGalleryItemPriceBlockProps
  extends React.ComponentPropsWithoutRef<'div'> {
  item: Api.MarketplaceItem
}

const MarketplaceItemsGalleryItemPriceBlock = React.memo(
  ({
    item,
    className,
    ...restProps
  }: MarketplaceItemsGalleryItemPriceBlockProps) => (
    <div
      className={WebUI.cn('bg-teal-600 px-4 py-2', className)}
      {...restProps}
    >
      {typeof item.retailPrice === 'number' && item.retailPrice > 0 && (
        <div className="font-normal text-ds-sm text-trueWhite line-through opacity-50">
          {Util.formatAmount(item.retailPrice)}
        </div>
      )}
      <div className="font-normal text-ds-sm text-trueWhite">
        {item.amount === null ? '$Open' : Util.formatAmount(item.amount)}
      </div>
    </div>
  ),
)

export default MarketplaceItemsGallery
