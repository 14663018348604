import React from 'react'
import {SharpAvatar, SharpAvatarProps} from './SharpAvatar'
import {api} from '@cheddarup/api-client'
import {useIsAuthed} from 'src/hooks/useAuthToken'
import {useManagerRole} from './ManageRoleProvider'
import * as WebUI from '@cheddarup/web-ui'

export interface CurrentUserAvatarProps extends SharpAvatarProps {}

export const CurrentUserAvatar = React.forwardRef<
  HTMLImageElement,
  CurrentUserAvatarProps
>(({name, image, className, ...restProps}, forwardedRef) => {
  const isAuthed = useIsAuthed()
  const [managerRole] = useManagerRole()
  const userQuery = api.auth.session.useQuery(undefined, {
    enabled: isAuthed,
    select: (session) => session.user,
  })

  return (
    <SharpAvatar
      ref={forwardedRef}
      className={WebUI.cn(
        !!managerRole && '[&_.Avatar-label]:bg-teal-600',
        className,
      )}
      image={image ?? managerRole?.profile_pic ?? userQuery.data?.profile_pic}
      name={name ?? managerRole?.name ?? userQuery.data?.display_name ?? ''}
      {...restProps}
    />
  )
})
