import {useForkRef, useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import React, {useRef, useState} from 'react'

import {HStack} from './Stack'
import {Input} from './Input'
import {Separator} from './Separator'
import {cn} from '../utils'

export interface InitialsInputProps
  extends React.ComponentPropsWithoutRef<'div'> {
  disabled?: boolean
  placeholder?: string
  defaultValue?: string
  value?: string
  onValueChange?: (value: string) => void
}

export const InitialsInput = React.forwardRef<
  HTMLDivElement,
  InitialsInputProps
>(
  (
    {
      disabled,
      placeholder,
      defaultValue,
      value: valueProp,
      onValueChange,
      className,
      tabIndex = 0,
      ...restProps
    },
    forwardedRef,
  ) => {
    const initialValues = makeStringTuple(defaultValue ?? valueProp ?? '')
    const [values, setValues] = useState(initialValues)
    const onValueChangeRef = useLiveRef(onValueChange)
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(forwardedRef, ownRef)
    const inputRefs = useRef<
      [HTMLInputElement | null, HTMLInputElement | null]
    >([null, null])

    const placeholders = placeholder
      ? makeStringTuple(placeholder)
      : ([undefined, undefined] as const)

    useUpdateEffect(() => {
      onValueChangeRef.current?.(values.join(''))
    }, [values])

    return (
      <HStack
        ref={ref}
        className={cn(
          'InitialsInput',
          'relative cursor-text overflow-hidden border border-grey-300',
          'transition-colors duration-100 ease-in-out',
          'aria-invalid:border aria-invalid:border-orange-50',
          'focus-within:border focus-within:border-teal-600',
          'hover:[&:not(:focus-within):not([aria-invalid=true]):not(:disabled):not([aria-disabled=true])]:bg-inputHoverBackground',
          'disabled:cursor-not-allowed disabled:opacity-50 aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
          '[&_>_.Input]:w-[calc(50%-1px/2)] [&_>_.Input]:min-w-[unset] [&_>_.Input]:max-w-[unset] [&_>_.Input]:flex-[1_0_calc(50%-1px/2)] [&_>_.Input]:text-center [&_>_.Input]:font-accent',
          className,
        )}
        {...restProps}
      >
        <Input
          ref={(input) => {
            inputRefs.current = [input, inputRefs.current[1]]
          }}
          className="InitialsInput-firstLetter"
          variant="headless"
          tabIndex={-1}
          disabled={disabled}
          maxLength={1}
          placeholder={placeholders[0]}
          value={values[0]}
          onChange={(event) => {
            const uppercaseChar = event.target.value.slice(0, 1).toUpperCase()
            setValues((prevValues) => [uppercaseChar, prevValues[1]])
            if (uppercaseChar !== '') {
              inputRefs.current[1]?.focus()
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'ArrowRight' || event.key === 'Enter') {
              inputRefs.current[1]?.focus()
              requestAnimationFrame(() =>
                inputRefs.current[1]?.setSelectionRange(
                  0,
                  inputRefs.current[1].value.length,
                ),
              )
            }
          }}
        />
        <Separator orientation="vertical" variant="primary" />
        <Input
          ref={(input) => {
            inputRefs.current = [inputRefs.current[0], input]
          }}
          className="InitialsInput-secondLetter"
          variant="headless"
          tabIndex={-1}
          disabled={disabled}
          maxLength={1}
          placeholder={placeholders[1]}
          value={values[1]}
          onChange={(event) => {
            const uppercaseChar = event.target.value.slice(0, 1).toUpperCase()
            setValues((prevValues) => [prevValues[0], uppercaseChar])
            if (uppercaseChar !== '') {
              requestAnimationFrame(() => event.target.setSelectionRange(0, 1))
            }
          }}
          onKeyDown={(event) => {
            if (
              event.key === 'Clear' ||
              event.key === 'Delete' ||
              event.key === 'ArrowLeft' ||
              (event.key === 'Backspace' && values[1] === '')
            ) {
              inputRefs.current[0]?.focus()
              requestAnimationFrame(() =>
                inputRefs.current[0]?.setSelectionRange(0, values[1].length),
              )
            }
          }}
        />
      </HStack>
    )
  },
)

// MARK: – Helpers

const makeStringTuple = (str: string): [string, string] => {
  const splitted = str.split('')

  if (splitted[0] && splitted[1]) {
    return [splitted[0], splitted[1]]
  }
  if (splitted[0]) {
    return [splitted[0], '']
  }

  return ['', '']
}
