import {api} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import React, {useState} from 'react'
import {useUpdateEffect} from '@cheddarup/react-util'

const CollectionAppBanner = () => {
  const {data} = api.global.activeBanner.useQuery()
  const [bannerShownSession] = WebUI.useLocalStorage(
    'banner_shown_session',
    false,
  )

  const initialVisible = data?.status === 'active' && !bannerShownSession

  return (
    <AppBanner
      initialVisible={initialVisible}
      iconType={data?.icon}
      href={data?.link_url}
      linkContent={data?.link_text}
      variant={data?.bg_color}
    >
      {data?.banner_text}
    </AppBanner>
  )
}

// MARK: – AppBanner

type AppBannerVariant = 'default' | 'light' | 'dark'
type AppBannerIconType = 'warning' | 'informative' | 'marketing'

interface AppBannerProps extends React.ComponentPropsWithoutRef<'div'> {
  initialVisible?: boolean
  variant?: AppBannerVariant
  iconType?: AppBannerIconType
  href?: string
  linkContent?: React.ReactNode
}

const AppBanner = ({
  initialVisible = false,
  iconType,
  href,
  linkContent = 'Learn More',
  variant = 'default',
  className,
  children,
  ...restProps
}: AppBannerProps) => {
  const [, setBannerShown] = WebUI.useLocalStorage(
    'banner_shown_session',
    false,
  )
  const [visible, setVisible] = useState(initialVisible)

  useUpdateEffect(() => setVisible(initialVisible), [initialVisible])

  const icon = iconType
    ? {
        warning: (
          <WebUI.PhosphorIcon
            className="text-ds-lg text-orange-500"
            icon="warning-fill"
          />
        ),
        informative: (
          <WebUI.PhosphorIcon
            className="text-ds-lg text-orange-500"
            icon="info-fill"
          />
        ),
        marketing: (
          <WebUI.PhosphorIcon
            className="text-ds-lg text-orange-500"
            icon="arrow-fat-right-fill"
          />
        ),
      }[iconType]
    : null

  return (
    <>
      {visible && (
        <WebUI.HStack
          className={WebUI.cn(
            'relative h-[54px] items-center justify-center',
            {
              default: 'bg-teal-600',
              light: 'bg-trueWhite',
              dark: 'bg-gray750',
            }[variant],
            className,
          )}
          {...restProps}
        >
          {icon}
          <WebUI.Ellipsis
            className={WebUI.cn(
              'mr-10 ml-5 font-bold text-ds-sm',
              {
                default: 'text-trueWhite',
                light: 'text-gray800',
                dark: 'text-trueWhite',
              }[variant],
            )}
          >
            {children}
          </WebUI.Ellipsis>
          {href && (
            <WebUI.Anchor
              className={WebUI.cn(
                'text-ds-sm underline',
                {
                  default: 'text-trueWhite [&.Anchor:hover]:text-grey-300',
                  light: 'text-gray800 [&.Anchor:hover]:text-gray700',
                  dark: 'text-trueWhite [&.Anchor:hover]:text-grey-300',
                }[variant],
              )}
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              {linkContent}
            </WebUI.Anchor>
          )}
          <WebUI.IconButton
            className={WebUI.cn(
              '-translate-y-1/2 absolute top-1/2 right-2',
              {
                default: 'text-trueWhite',
                light: 'text-gray400',
                dark: 'text-trueWhite',
              }[variant],
            )}
            onClick={() => {
              setVisible(false)
              setBannerShown(true)
            }}
          >
            <WebUI.PhosphorIcon icon="x" width={22} />
          </WebUI.IconButton>
        </WebUI.HStack>
      )}
    </>
  )
}

export default CollectionAppBanner
