import React, {useState} from 'react'

import {PhosphorIcon} from '../icons'
import {FormField, FormFieldProps} from './Form'
import {HStack} from './Stack'
import {IconButton} from './IconButton'
import {Input, InputProps} from './Input'
import {
  FloatingLabelFormTextField,
  type FloatingLabelFormTextFieldProps,
} from './FloatingLabelFormTextField'

export interface PasswordFormFieldInputProps
  extends InputProps,
    Pick<FormFieldProps, 'label' | 'error' | 'caption'>,
    Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> {}

export const PasswordFormFieldInput = React.forwardRef<
  HTMLFieldSetElement,
  PasswordFormFieldInputProps
>(
  (
    {
      placeholder = 'Password',
      label = 'Password',
      caption,
      error,
      required,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [passwordRevealed, setPasswordRevealed] = useState(false)

    return (
      <FormField
        ref={forwardedRef}
        className={className}
        label={
          <HStack className="items-center justify-between gap-4">
            <span>
              {label} {required && <span className="text-orange-500"> *</span>}
            </span>
            <IconButton
              className="h-auto w-auto p-0 text-ds-md text-gray400"
              size="default_alt"
              tabIndex={-1}
              onClick={() =>
                setPasswordRevealed(
                  (prevPasswordRevealed) => !prevPasswordRevealed,
                )
              }
            >
              {passwordRevealed ? (
                <PhosphorIcon icon="eye-slash" />
              ) : (
                <PhosphorIcon icon="eye" />
              )}
            </IconButton>
          </HStack>
        }
        error={error}
        caption={caption}
      >
        <Input
          name="password"
          type={passwordRevealed ? 'text' : 'password'}
          required={required}
          placeholder={placeholder}
          {...restProps}
        />
      </FormField>
    )
  },
)

// MARK: – PasswordFloatingLabelFormTextFieldInput

export interface PasswordFloatingLabelFormTextFieldInputProps
  extends InputProps,
    Pick<FloatingLabelFormTextFieldProps, 'label' | 'caption' | 'error'>,
    Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> {}

export const PasswordFloatingLabelFormTextFieldInput = React.forwardRef<
  HTMLFieldSetElement,
  PasswordFloatingLabelFormTextFieldInputProps
>(
  (
    {label = 'Password', error, required, className, ...restProps},
    forwardedRef,
  ) => {
    const [passwordRevealed, setPasswordRevealed] = useState(false)
    return (
      <FloatingLabelFormTextField
        ref={forwardedRef}
        className={className}
        required={required}
        label={label}
        error={error}
        suffix={
          <IconButton
            className="h-auto w-auto p-0 text-ds-lg text-teal-600"
            size="default_alt"
            tabIndex={-1}
            onClick={() =>
              setPasswordRevealed(
                (prevPasswordRevealed) => !prevPasswordRevealed,
              )
            }
          >
            {passwordRevealed ? (
              <PhosphorIcon icon="eye-slash" />
            ) : (
              <PhosphorIcon icon="eye" />
            )}
          </IconButton>
        }
      >
        <Input
          type={passwordRevealed ? 'text' : 'password'}
          size="xl"
          roundness="capsule"
          autoComplete="current-password"
          required={required}
          {...restProps}
        />
      </FloatingLabelFormTextField>
    )
  },
)
