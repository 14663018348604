import {Link, useParams} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'
import FooterBig from 'src/components/FooterBig'

import {
  AboutUs,
  CollectionSpotlight,
  CollectionsSection,
  MeetTheTeam,
  NavBar,
  Sponsors,
  TopBanner,
  TotalCollected,
} from './components'
import {GroupPageHelmet as MePageHelmet} from '../group/GroupPage/components'
import {getBrandKitColors} from '@cheddarup/core'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import config from 'src/config'
import {bottomLinks} from 'src/components/FooterBig/data'
import FacebookIcon from 'src/images/FacebookIcon.svg'
import YoutubeIcon from 'src/images/YouTubeIcon.svg'
import InstagramIcon from 'src/images/InstagramIcon.svg'
import XIcon from 'src/images/XIcon.svg'
import {SmartAnchor} from 'src/components/SmartAnchor'

const MePage = () => {
  const urlParams = useParams()
  const {data: session} = api.auth.session.useQuery()
  const {data: groupPage} = api.groupPages.home.useQuery({
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    pathParams: {slug: urlParams.user!},
  })

  const groupPageSections = Util.sort(
    Object.values(groupPage?.sections ?? {})
      .map((section) => section as Api.GroupPageSection)
      .filter((section) => section && section.type !== 'TopBanner'),
  ).asc((section) => section.position)
  const brandKitColors = groupPage
    ? getBrandKitColors(groupPage.branding?.color_palette.groupPage)
    : null

  const isNotTeamUser =
    (session?.user.slug === urlParams.user ||
      session?.user.id.toString() === urlParams.user) &&
    session?.capabilities.plan !== 'team'

  const isPublished = groupPage?.status === 'published'
  const brandFooter = groupPage?.branding?.footer
  const brandedFooterEnabled =
    !!groupPage?.branding?.enable_brand_footer && brandFooter

  return (
    <div className="bg-trueWhite">
      <MePageHelmet groupPage={groupPage} />
      <NavBar published={isPublished} branding={groupPage?.branding} />
      {isNotTeamUser && !isPublished && (
        <div className="h-15 bg-teal-600 p-2 text-center text-ds-md text-trueWhite leading-[44px]">
          Preview only:{' '}
          <Link
            className="text-trueWhite underline"
            to="/collections/i/plans?recommendedPlan=team"
          >
            Upgrade to Team
          </Link>{' '}
          to share this page publicly and allow your payers to browse all active
          collections in one spot.
        </div>
      )}

      <div className="flex flex-col">
        {groupPage?.sections?.top_banner && brandKitColors && (
          <TopBanner
            topBanner={groupPage.sections.top_banner}
            brandKitColors={brandKitColors}
          />
        )}

        {brandKitColors &&
          groupPageSections.map((section) => {
            switch (section.type) {
              case 'CollectionSection':
                return (
                  <CollectionsSection
                    key={section.id}
                    brandKitColors={brandKitColors}
                    collectionsSection={section}
                  />
                )
              case 'CollectionSpotlight':
                return (
                  <CollectionSpotlight
                    key={section.id}
                    brandKitColors={brandKitColors}
                    collectionSpotlight={section}
                  />
                )
              case 'TotalCollected':
                return (
                  <TotalCollected
                    key={section.id}
                    totalCollected={section}
                    brandKitColors={brandKitColors}
                  />
                )
              case 'AboutUsSection':
                return (
                  <AboutUs
                    key={section.id}
                    brandKitColors={brandKitColors}
                    aboutUs={section}
                  />
                )
              case 'MeetTheTeam':
                return (
                  <MeetTheTeam
                    key={section.id}
                    brandKitColors={brandKitColors}
                    team={section}
                  />
                )
              case 'Sponsor':
                return (
                  <Sponsors
                    key={section.id}
                    sponsors={section}
                    brandKitColors={brandKitColors}
                  />
                )
              default:
                return null
            }
          })}
      </div>
      {brandedFooterEnabled ? (
        <BrandedFooter footer={brandFooter} />
      ) : (
        <FooterBig className="z-0" />
      )}
    </div>
  )
}

// MARK: - BrandedFooter

interface BrandedFooterProps extends React.ComponentPropsWithoutRef<'div'> {
  footer: Api.UserBrandingFooter
}

export const BrandedFooter: React.FC<BrandedFooterProps> = ({
  footer,
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'bg-gray800 text-trueWhite [&_>_div]:mx-auto [&_>_div]:flex [&_>_div]:max-w-[1100px] [&_>_div]:justify-between',
      className,
    )}
    {...restProps}
  >
    <div className="flex-col items-start gap-5 px-8 py-10 sm:flex-row xl:px-6">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <WebUI.Text className="font-extrabold">
            {footer.contact?.orgName}
          </WebUI.Text>
          {footer.contact?.streetAddress && (
            <WebUI.Text>{footer.contact?.streetAddress}</WebUI.Text>
          )}
          {footer.contact?.phoneNumber && (
            <WebUI.Text>
              {WebUI.parsePhoneNumber(
                footer.contact?.phoneNumber,
              )?.formatNational()}
            </WebUI.Text>
          )}
        </div>
        {/* TODO: Uncomment when api is ready */}
        {/* {footer.verifiedNonProfitBadge?.enabled && (
          <div className="flex items-center gap-3">
            <WebUI.PhosphorIcon
              className="text-ds-2xl"
              color="#F9C442"
              icon="seal-check-fill"
            />
            <WebUI.Text className="font-extrabold text-[#F9C442]">
              Verified 501(c)(3)
            </WebUI.Text>
          </div>
        )} */}
      </div>
      <div className="flex flex-col gap-4">
        {footer.mainWebsiteLink?.text && (
          <SmartAnchor
            href={footer.mainWebsiteLink.link}
            target="_blank"
            className="text-trueWhite [&_>_.Text]:font-extrabold"
            normalizeLink
          >
            {footer.mainWebsiteLink.text}
          </SmartAnchor>
        )}
        <div className="flex gap-4 text-8xl [&_>_.Anchor]:text-trueWhite">
          {footer.socialAccounts?.youtube && (
            <WebUI.Anchor target="_blank" href={footer.socialAccounts.youtube}>
              <img src={YoutubeIcon} alt="youtube" />
            </WebUI.Anchor>
          )}
          {footer.socialAccounts?.instagram && (
            <WebUI.Anchor
              target="_blank"
              href={footer.socialAccounts.instagram}
            >
              <img src={InstagramIcon} alt="instagram" />
            </WebUI.Anchor>
          )}
          {footer.socialAccounts?.twitter && (
            <WebUI.Anchor target="_blank" href={footer.socialAccounts.twitter}>
              <img src={XIcon} alt="x" />
            </WebUI.Anchor>
          )}
          {footer.socialAccounts?.facebook && (
            <WebUI.Anchor target="_blank" href={footer.socialAccounts.facebook}>
              <img src={FacebookIcon} alt="facebook" />
            </WebUI.Anchor>
          )}
        </div>
      </div>
    </div>
    <WebUI.Separator className="border-[#707070]" />
    <div className="flex-col items-start gap-4 px-8 py-7 sm:flex-row xl:px-0">
      <WebUI.Anchor
        href={config.links.marketingPage}
        className="flex items-center gap-3 text-trueWhite"
      >
        <WebUI.LogoGraphicsIcon fontSize={25} />
        <WebUI.Text className="text-[13px]">Powered by Cheddar Up</WebUI.Text>
      </WebUI.Anchor>
      <div className="flex flex-wrap items-center gap-4 text-ds-md [&_>_.Anchor]:text-[13px] [&_>_.Anchor]:text-trueWhite">
        {bottomLinks.map((bl) =>
          bl.url ? (
            <WebUI.Anchor
              key={bl.label}
              href={bl.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {bl.label}
            </WebUI.Anchor>
          ) : (
            <WebUI.Text className="text-[13px]" key={bl.label}>
              {bl.label}
            </WebUI.Text>
          ),
        )}
      </div>
    </div>
  </div>
)

export default MePage
