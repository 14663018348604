import * as WebUI from '@cheddarup/web-ui'
import {useParams} from 'react-router-dom'
import {useMemo, useState} from 'react'
import * as Util from '@cheddarup/util'
import {UseQueryResult, api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {SharpAvatar} from 'src/components/SharpAvatar'

import {PaymentOverviewSideSheet} from './PaymentOverviewSideSheet'
import CollectorPayerDropdown from '../components/CollectorPayerDropdown'

const PayerPage = () => {
  const {id: email} = useParams()
  const clientUserQuery = api.clients.userDetailByEmail.useQuery(
    {
      queryParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        email: email!,
        showCustomer: true,
      },
    },
    {
      enabled: !!email,
    },
  )
  const [selectedCustomerPayment, setSelectedCustomerPayment] =
    useState<Api.CustomerByEmailPayment | null>(null)

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <WebUI.HStack className="items-center gap-2">
          <SharpAvatar
            size={36}
            image={clientUserQuery.data?.user?.profile_pic}
          />
          <div className="font-semibold text-ds-md">
            {clientUserQuery.data?.user?.full_name}
          </div>{' '}
          <div className="font-normal text-ds-sm text-gray400">{email}</div>
        </WebUI.HStack>
        <CollectorPayerDropdown
          isPayerVisible
          isCollector={false}
          isCollectorVisible={!!clientUserQuery.data?.user}
          distributorLink={
            clientUserQuery.data?.user
              ? `/reporting/collectors/${clientUserQuery.data?.user?.uuid}`
              : ''
          }
        />
      </WebUI.HStack>

      <WebUI.HStack className="*:flex-[1_0_0px] *:p-6" as={WebUI.Panel}>
        <div>
          <div className="font-semibold text-ds-xs">TOTAL SPENT</div>
          <div className="font-normal text-5xl">
            {Util.formatAmount(
              clientUserQuery.data?.customer?.total_spent ?? 0,
            )}
          </div>
        </div>
        <WebUI.Separator orientation="vertical" variant="primary" />
        <div>
          <div className="font-semibold text-ds-xs"># OF ITEMS PURCHASED</div>
          <div className="font-normal text-5xl">
            {clientUserQuery.data?.customer?.total_items_purchased ?? 0}
          </div>
        </div>
        <WebUI.Separator orientation="vertical" variant="primary" />
        <div>
          <div className="font-semibold text-ds-xs"># OF PAYMENTS</div>
          <div className="font-normal text-5xl">
            {clientUserQuery.data?.customer?.payments?.length ?? 0}
          </div>
        </div>
        <WebUI.Separator orientation="vertical" variant="primary" />
        <div>
          <div className="font-semibold text-ds-xs">COLLECTION PAGE VISITS</div>
          <div className="font-normal text-5xl">
            {clientUserQuery.data?.customer?.visits?.length || 0}
          </div>
        </div>
      </WebUI.HStack>

      <WebUI.VStack className="gap-4">
        <WebUI.HStack className="mx-3 items-center gap-4">
          <WebUI.PhosphorIcon
            className="rounded bg-teal-600 p-1 text-ds-2xl text-trueWhite"
            icon="currency-dollar"
          />
          <div className="font-semibold text-ds-md">Payments</div>
        </WebUI.HStack>

        <WebUI.Panel>
          <CustomerPaymentsTable
            clientUserQuery={clientUserQuery}
            onSelect={(customerPayment) =>
              setSelectedCustomerPayment(customerPayment)
            }
          />
        </WebUI.Panel>
      </WebUI.VStack>

      <WebUI.VStack className="gap-4">
        <WebUI.HStack className="mx-3 items-center gap-4">
          <WebUI.PhosphorIcon
            className="rounded bg-aqua p-1 text-ds-2xl text-trueWhite"
            icon="eye"
          />
          <div className="font-semibold text-ds-md">Visits</div>
        </WebUI.HStack>
        <WebUI.Panel>
          <CustomerVisitsTable clientUserQuery={clientUserQuery} />
        </WebUI.Panel>
      </WebUI.VStack>
      <PaymentOverviewSideSheet
        customerPayment={selectedCustomerPayment}
        visible={!!selectedCustomerPayment}
        onDidHide={() => setSelectedCustomerPayment(null)}
      />
    </WebUI.VStack>
  )
}

// MARK: – CustomerPaymentsTable

interface CustomerPaymentsTableProps
  extends Omit<
    WebUI.TableViewProps<Api.CustomerByEmailPayment>,
    'data' | 'columns' | 'onSelect'
  > {
  clientUserQuery: UseQueryResult<Api.ClientUserByEmail, Error>
  onSelect: (customerPayment: Api.CustomerByEmailPayment) => void
}

const CustomerPaymentsTable = ({
  clientUserQuery,
  onSelect,
  ...restProps
}: CustomerPaymentsTableProps) => {
  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.CustomerByEmailPayment>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((payment) => payment.collection.name, {
        id: 'collectionName',
        header: 'Collection Page',
      }),
      columnHelper.accessor((payment) => payment.organizer.name, {
        id: 'amount',
        meta: {
          align: 'right',
        },
        header: 'Organizer',
      }),
      columnHelper.accessor((payment) => payment.date, {
        id: 'date',
        meta: {
          align: 'right',
        },
        header: 'Date',
        cell: ({cell}) => Util.formatDateAs(cell.getValue(), 'date_tabular'),
      }),
      columnHelper.accessor((payment) => payment.items, {
        id: 'itemsCount',
        meta: {
          align: 'right',
        },
        header: 'Items',
        cell: ({cell}) => (
          <WebUI.Tag className="text-ds-xs">{cell.getValue()}</WebUI.Tag>
        ),
      }),
      columnHelper.accessor((payment) => payment.total, {
        id: 'total',
        meta: {
          align: 'right',
        },
        header: 'Amount',
        cell: ({cell}) => Util.formatAmount(cell.getValue()),
      }),
    ],
    [columnHelper],
  )

  return (
    <WebUI.TableView
      className="[&_.TableView-headerGroup]:px-2 [&_.TableViewRow]:px-2"
      sortable
      sortByTogglesVisible
      loading={clientUserQuery.isPending}
      data={clientUserQuery.data?.customer.payments ?? []}
      columns={columns}
      getRowProps={(row) =>
        ({
          as: WebUI.Button,
          variant: 'text',
          onClick: () => onSelect(row.original),
        }) as any
      }
      {...restProps}
    />
  )
}

// MARK: – CustomerVisitsTable

interface CustomerVisitsTableProps
  extends Omit<
    WebUI.TableViewProps<Api.CustomerByEmailVisit>,
    'data' | 'columns'
  > {
  clientUserQuery: UseQueryResult<Api.ClientUserByEmail, Error>
}

const CustomerVisitsTable = ({
  clientUserQuery,
  ...restProps
}: CustomerVisitsTableProps) => {
  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.CustomerByEmailVisit>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((visit) => visit.collection.name, {
        id: 'collectionName',
        header: 'Collection Page',
      }),
      columnHelper.accessor((visit) => visit.organizer.name, {
        id: 'amount',
        meta: {
          align: 'right',
        },
        header: 'Organizer',
      }),
      columnHelper.accessor((visit) => visit.first_visit, {
        id: 'firstVisit',
        meta: {
          align: 'right',
        },
        header: 'Date',
        cell: ({cell}) => Util.formatDateAs(cell.getValue(), 'date_tabular'),
      }),
      columnHelper.accessor((visit) => visit.total_visits, {
        id: 'visitsCount',
        meta: {
          align: 'right',
        },
        header: 'Total Visits',
        cell: ({cell}) => (
          <WebUI.Tag className="text-ds-xs">{cell.getValue()}</WebUI.Tag>
        ),
      }),
      columnHelper.accessor((visit) => visit.total_spent, {
        id: 'totalSpent',
        meta: {
          align: 'right',
        },
        header: 'Total Spent',
        cell: ({cell}) => Util.formatAmount(cell.getValue()),
      }),
    ],
    [columnHelper],
  )

  return (
    <WebUI.TableView
      className="[&_.TableView-headerGroup]:px-2 [&_.TableViewRow]:px-2"
      sortable
      sortByTogglesVisible
      loading={clientUserQuery.isPending}
      data={clientUserQuery.data?.customer?.visits ?? []}
      columns={columns}
      getRowProps={(row) =>
        ({
          as: Link,
          className: 'px-0 h-auto [font-size:inherit] text-left',
          to: {
            pathname: `/reporting/collection-pages/${row.original.collection?.id}`,
          },
          state: {uuid: row.original.organizer?.uuid},
        }) as any
      }
      {...restProps}
    />
  )
}

export default PayerPage
