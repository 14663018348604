import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'

import ItemVariantOptionArrayInput from './ItemVariantOptionArrayInput'
import ItemVariantsListingTable from './ItemVariantsListingTable'
import type {FixedItemFormFormik} from '../../../containers/FixedItemForm/FixedItemForm'

export interface ItemVariantsFormSectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formik: FixedItemFormFormik
  itemImages: any[]
}

const ItemVariantsFormSection = ({
  collectionId,
  formik,
  itemImages,
  className,
  ...restProps
}: ItemVariantsFormSectionProps) => {
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })

  return (
    <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
      <WebUI.VStack className="gap-1">
        <WebUI.Heading className="text-ds-md" as="h4">
          Add Variants
        </WebUI.Heading>
        {tabQuery.data &&
        tabQuery.data.status !== 'draft' &&
        !tabQuery.data.is_pro ? (
          <ItemVariantsFormSectionUpgrade formik={formik} />
        ) : (
          <p className="text-ds-sm">
            Add an option name (e.g. size) and value (e.g. small, medium, large)
          </p>
        )}
      </WebUI.VStack>
      {tabQuery.data &&
        (tabQuery.data.status === 'draft' || tabQuery.data.is_pro) && (
          <ItemVariantOptionArrayInput
            collectionId={collectionId}
            formik={formik}
          />
        )}
      {formik.values.options.variants.options.some(
        (option) => option.values.length > 0,
      ) && <ItemVariantsListingTable formik={formik} itemImages={itemImages} />}
    </WebUI.VStack>
  )
}

// MARK: – ItemVariantsFormSectionUpgrade

interface ItemVariantsFormSectionUpgradeProps {
  formik: FixedItemFormFormik
}

const ItemVariantsFormSectionUpgrade = ({
  formik,
}: ItemVariantsFormSectionUpgradeProps) => (
  <WebUI.VStack className="gap-3">
    <div className="max-w-[30em] font-normal text-ds-sm">
      Upgrade to our <span className="font-bold text-teal-600">PRO Plan</span>{' '}
      to add variants (e.g. sizing) and to drive sales with discount codes and
      built-in shipping.
    </div>
    <WebUI.HStack className="items-center gap-3">
      <LinkButton variant="default" to="i/plans">
        Upgrade to PRO
      </LinkButton>
      <WebUI.Button
        type="button"
        variant="secondary"
        onClick={() => formik.setFieldValue('options.variants.enabled', false)}
      >
        Not now
      </WebUI.Button>
    </WebUI.HStack>
  </WebUI.VStack>
)

export default ItemVariantsFormSection
