import * as Sentry from '@sentry/react'
import * as WebUI from '@cheddarup/web-ui'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6'
import {useLocation} from 'react-router-dom'
import jsonp from 'jsonp'
import {Suspense, useEffect, useState} from 'react'
import {QueryProvider} from '@cheddarup/api-client'
import queryString from 'query-string'

import config from './config'
import {RecaptchaErrorHandler} from './components/RecaptchaErrorHandler'
import {apiClient} from './helpers/client'
import {ManagerRoleProvider} from './components/ManageRoleProvider'
import AppMaintenance from './views/AppMaintenance'
import {RootRoutes} from './routes/RootRoutes'
import {AuthErrorHandler} from './components/AuthErrorHandler'

const SentriedRootRoutes = Sentry.withSentryReactRouterV6Routing(RootRoutes)

export const RootLayout = () => {
  usePageTracking()

  const location = useLocation()
  const isMobileAppBannerVisible = useIsMobileAppBannerVisible()

  useEffect(() => {
    const page = location.pathname.split('/').pop()
    const label = `pagetag${page}`

    window.zE?.('webWidget', 'helpCenter:setSuggestions', {
      url: true,
      labels: [label],
    })
  }, [location.pathname])

  useEffect(() => {
    try {
      window.convertflow?.start()
    } catch {
      // noop
    }
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Collect Money Online. Collect from a Group for Free. Get Started Now.
        </title>
        {isMobileAppBannerVisible && (
          <meta
            name="apple-itunes-app"
            content={`app-id=${config.appStoreAppId}`}
          />
        )}
      </Helmet>
      <QueryProvider>
        <BrowserWarningBanner />
        <Suspense
          fallback={
            <div className="flex h-screen min-h-0 min-w-0 flex-auto items-center justify-center">
              <WebUI.Loader />
            </div>
          }
        >
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: queryString.parse,
              objectToSearchString: queryString.stringify,
              enableBatching: false,
            }}
          >
            <WebUI.UIProvider>
              <ManagerRoleProvider>
                <AppMaintenance>
                  <SentriedRootRoutes />
                </AppMaintenance>
              </ManagerRoleProvider>
              <AuthErrorHandler />
              <RecaptchaErrorHandler axios={apiClient} />
            </WebUI.UIProvider>
          </QueryParamProvider>
        </Suspense>
      </QueryProvider>
    </HelmetProvider>
  )
}

// MARK: – BrowserWarningBanner

const isIe10OrBelow = () => navigator.userAgent.includes('MSIE')
const isIe11 = () => navigator.userAgent.includes('Trident/')

const BrowserWarningBanner = () => {
  const [browserWarningBannerVisible, setBrowserWarningBannerVisible] =
    useState(isIe11() || isIe10OrBelow())

  return browserWarningBannerVisible ? (
    <div
      className={
        'flex cursor-pointer items-center justify-center bg-orange-500 p-4 text-center text-trueWhite leading-default'
      }
      onClick={() => setBrowserWarningBannerVisible(false)}
    >
      {isIe11()
        ? `You're using an outdated version of Internet Explorer that we do not support. We recommend using Chrome or Safari as your browser.`
        : 'Using Internet Explorer may provide a degraded experience on our platform. We recommend using Chrome or Safari as your browser.'}
    </div>
  ) : null
}

// MARK: – Helpers

const usePageTracking = () => {
  const location = useLocation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // TODO: get rid of jsonp
    jsonp(
      `https://trackcmp.net/visit?actid=798920561&e=${window.trackcmp_email}&r=${document.referrer}&u=${window.location.href}`,
    )
  }, [location.pathname])
}

const useIsMobileAppBannerVisible = () => {
  const location = useLocation()
  return (
    !queryString.parse(location.search).hideSmartBanner &&
    !location.pathname.startsWith('/c/') &&
    !location.pathname.startsWith('/marketplace') &&
    !location.pathname.includes('/i/') &&
    !location.pathname.includes('/me/') &&
    location.pathname.replace(/\//g, '') !== 'collections'
  )
}
