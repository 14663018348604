import * as WebUI from '@cheddarup/web-ui'
import {SharePanelHeading} from './SharePanelHeading'
import TextToPayModal, {TEXT_TO_PAY_CONTACT} from './TextToPayModal'
import React, {useRef} from 'react'
import {useDeleteTabDonationCodeMutation} from '@cheddarup/api-client'

export interface TextToPayPanelProps
  extends WebUI.PanelProps,
    React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

const TextToPayPanel = ({
  collection,
  className,
  ...restProps
}: TextToPayPanelProps) => {
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const deleteTabDonationCodeMutation = useDeleteTabDonationCodeMutation()
  const growActions = WebUI.useGrowlActions()

  const keyword = collection.donationCode?.code

  return (
    <WebUI.Panel
      className={WebUI.cn('relative flex flex-col gap-5 p-7', className)}
      {...restProps}
    >
      <SharePanelHeading iconName="chat-dots">Text-to-Pay</SharePanelHeading>
      <WebUI.Text className="grow font-light">
        Share a keyword for payers to text to {TEXT_TO_PAY_CONTACT} and we’ll
        reply with your link.
      </WebUI.Text>
      {keyword ? (
        <WebUI.Panel className="flex h-9 items-center justify-between px-3">
          <WebUI.Text className="font-light text-ds-sm">{keyword}</WebUI.Text>
          <WebUI.ActionGroup className="border-0" maxVisible={1}>
            <WebUI.Action onClick={() => modalRef.current?.show()}>
              Edit
            </WebUI.Action>
            <WebUI.Action
              execute={async () => {
                await deleteTabDonationCodeMutation.mutateAsync({
                  pathParams: {tabId: collection.id},
                })
                growActions.show('success', {
                  body: 'Code has been deleted successfully',
                })
              }}
            >
              Delete
            </WebUI.Action>
          </WebUI.ActionGroup>
        </WebUI.Panel>
      ) : (
        <>
          <WebUI.Button
            disabled={collection?.status === 'draft'}
            onClick={() => modalRef.current?.show()}
          >
            Create Your Keyword
          </WebUI.Button>
          <span
            className={WebUI.cn(
              'flex h-[28px] w-[120px] items-center justify-center font-accentAlt text-teal-600 uppercase',
              '-right-8 absolute top-4 rotate-45',
              'bg-gradient-to-b from-[#CBEBEF] via-[#E7F6F8] to-[#CBEBEF]',
            )}
          >
            NEW!
          </span>
        </>
      )}

      <TextToPayModal ref={modalRef} collection={collection} />
    </WebUI.Panel>
  )
}

export default TextToPayPanel
