import * as WebUI from '@cheddarup/web-ui'
import {CubeProvider} from '@cubejs-client/react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import React from 'react'
import {api} from '@cheddarup/api-client'

import CollectorsPage from './CollectorsPage'
import CollectorPage from './CollectorPage'
import SalesPagesPage from './SalesPagesPage'
import SalesPagePage from './SalesPagePage'
import PayersPage from './PayersPage'
import PayerPage from './PayerPage'
import HomePage from './HomePage'
import {LinkButton, NavLinkButton} from '../../components/LinkButton'
import logo from '../../images/partner-dashboard.svg'
import {useCubeApi} from '../../hooks/cube'
import {CurrentUserAvatar} from 'src/components/CurrentUserAvatar'

const PartnerDashboardRouter = () => {
  const cubeApi = useCubeApi()

  return (
    <CubeProvider cubeApi={cubeApi}>
      <WebUI.VStack className="shrink-0 grow basis-auto">
        <WebUI.HStack
          className={'h-17 items-center justify-between gap-4 border-b px-8'}
        >
          <img className="h-[1.8em]" alt="Partner Dashboard" src={logo} />
          <TopNavRight />
        </WebUI.HStack>

        <WebUI.HStack className="grow">
          <SideBar className="flex-[0_0_240px]" />
          <div className="grow p-8">
            <Routes>
              <Route path="" element={<Navigate to="dashboard" />} />
              <Route path="dashboard" element={<HomePage />} />
              <Route path="collectors">
                <Route path="" element={<Navigate to="active" />} />
                <Route path="active" element={<CollectorsPage />} />
                <Route path="invited" element={<CollectorsPage />} />
                <Route path=":collector" element={<CollectorPage />} />
              </Route>
              <Route path="collection-pages" element={<SalesPagesPage />} />
              <Route path="collection-pages/:id" element={<SalesPagePage />} />
              <Route path="payers" element={<PayersPage />} />
              <Route path="payers/:id" element={<PayerPage />} />
            </Routes>
          </div>
        </WebUI.HStack>
      </WebUI.VStack>
    </CubeProvider>
  )
}

// MARK: – SideBar

const SideBar = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const location = useLocation()
  const {data: canInviteCollectors} = api.auth.session.useQuery(undefined, {
    select: (session) => session.partner_admin?.org?.canInviteCollectors,
  })

  return (
    <WebUI.VStack
      className={WebUI.cn(
        `gap-2 bg-gray800 px-2 py-4 [&_>_.NavLinkButton[aria-current="page"]:bg-teal-600 [&_.NavLinkButton]:px-3 [&_.NavLinkButton]:text-left [&_.NavLinkButton]:text-trueWhite [&_.NavLinkButton_>_.Button-iconBefore]:mr-3 [&_.NavLinkButton_>_.Button-iconBefore]:text-ds-xl`,
        className,
      )}
      {...restProps}
    >
      <NavLinkButton
        size="large"
        iconBefore={<WebUI.PhosphorIcon icon="house-line" />}
        to="dashboard"
      >
        Home
      </NavLinkButton>

      <NavLinkButton
        size="large"
        iconBefore={<WebUI.PhosphorIcon icon="star" />}
        to="collectors"
      >
        Collectors
      </NavLinkButton>
      {canInviteCollectors && (
        <WebUI.Disclosure visible={location.pathname.includes('collectors')}>
          <WebUI.DisclosureContent>
            <WebUI.VStack
              className={`gap-2 [&_>_.NavLinkButton[aria-current="page"]]:text-trueWhite [&_>_.NavLinkButton]:ml-9 [&_>_.NavLinkButton]:text-gray400`}
            >
              <NavLinkButton size="large" to="collectors/active">
                Active
              </NavLinkButton>
              <NavLinkButton size="large" to="collectors/invited">
                Invited
              </NavLinkButton>
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
      )}

      <NavLinkButton
        size="large"
        iconBefore={<WebUI.PhosphorIcon icon="link" />}
        to="collection-pages"
      >
        Collection Pages
      </NavLinkButton>
      <NavLinkButton
        size="large"
        iconBefore={<WebUI.PhosphorIcon icon="address-book" />}
        to="payers"
      >
        Payers
      </NavLinkButton>
    </WebUI.VStack>
  )
}

// MARK: – TopNavRight

const TopNavRight = () => {
  const {data: session} = api.auth.session.useQuery()

  if (!session) {
    return null
  }

  return (
    <WebUI.Menu>
      <WebUI.MenuButton
        className="px-1"
        iconAfter={<WebUI.PhosphorIcon icon="caret-down" />}
        variant="text"
        size="large"
      >
        <CurrentUserAvatar size={36} />
      </WebUI.MenuButton>

      <WebUI.MenuList>
        <WebUI.VStack className="gap-1 px-[1.4em] py-1">
          <div className="font-semibold text-ds-sm">
            {session.user.display_name}
          </div>
          {!!session.auth?.isAdmin && (
            <div className="font-accent text-ds-xs italic">Administrator</div>
          )}
        </WebUI.VStack>
        <WebUI.MenuItem as={LinkButton} to="/collections">
          Back to Cheddar Up
        </WebUI.MenuItem>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

export default PartnerDashboardRouter
