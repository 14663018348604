import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect, useRef, useState} from 'react'
import {CheckoutSteppedPanel} from '../CheckoutPage/CheckoutSteppedPanel'
import {
  InferResponse,
  endpoints,
  useSendGuestLoginCodeMutation,
} from '@cheddarup/api-client'
import {
  useDebounceCallback,
  useForkRef,
  useFormik,
  useInterval,
} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {INVALID_EMAIL_STATUSES} from 'src/helpers/email-status-helpers'
import {useLogin, useSignup} from 'src/hooks/useAuth'
import usePublicCollection from '../hooks/usePublicCollection'
import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import config from 'src/config'
import {usePayForCart} from '../hooks/usePayForCart'
import CartHelpers from 'src/helpers/CartHelpers'
import {CheckoutButton, TermsText} from './CheckoutButton'
import {memoizedFetchCheckEmailExistence} from '../utils/payer-auth-utils'
import {readApiError} from 'src/helpers/error-formatting'
import {getVisibleCartForms, PayerBrandKitColors} from '@cheddarup/core'
import {getCUReadableColor} from 'src/helpers/color-utils'

export interface DefaultPayerAuthPanelProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<SmartEmailFieldProps, 'onDidLogin'> {
  onDidContinueAsGuest?: () => void
  onDidSignup?: () => void
  brandKitColors: PayerBrandKitColors
}

export const DefaultPayerAuth = ({
  onDidContinueAsGuest,
  onDidLogin,
  onDidSignup,
  brandKitColors,
  ...restProps
}: DefaultPayerAuthPanelProps) => {
  const {publicCollection, updatePublicTabPayload} = usePublicCollection()
  const {cart} = useCart()
  const recaptchaRef = useRef<WebUI.RecaptchaInstance>(null)
  const [, updateCartAsync] = useEnhancedUpdateCartMutation()
  const payForCart = usePayForCart()
  const [signup] = useSignup({recaptchaRef})
  const growlActions = WebUI.useGrowlActions()
  const [emailCheckResMap, setEmailCheckResMap] = useState<
    Record<string, InferResponse<typeof endpoints.auth.checkEmailExistence>>
  >({})

  const defaultName = cart?.member?.name ?? updatePublicTabPayload?.name
  const isAuthRequired = !!cart && cart.recurringTotal > 0
  const isQuickCheckout =
    !CartHelpers.isPaymentRequired({cart}) &&
    !publicCollection.shippingOptions.shipToEnabled

  const formik = useFormik({
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string().email().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        phoneNumber:
          isQuickCheckout && !isSignup
            ? Yup.mixed()
            : Yup.string().possiblePhoneNumber('Invalid format').required(),
        password: isSignup ? Yup.string().required() : Yup.mixed(),
      }),
    initialValues: {
      email: cart?.member?.email ?? updatePublicTabPayload?.email ?? '',
      firstName: defaultName?.split(' ')[0] ?? '',
      lastName: defaultName?.split(' ')[1] ?? '',
      phoneNumber: cart?.phoneNumber ?? '',
      password: '',
      dripOptIn: false,
    },
    onSubmit: async (values, formikHelpers) => {
      const checkEmailRes = await memoizedFetchCheckEmailExistence({
        body: {email: values.email},
      })
      if (INVALID_EMAIL_STATUSES.includes(checkEmailRes.status)) {
        formikHelpers.setFieldError(
          'email',
          'The email entered is invalid. Please enter a valid email address.',
        )
        return
      }

      const fullName = `${values.firstName} ${values.lastName}`.trim()

      if (isSignup) {
        try {
          const signupRes = await signup({
            email: values.email,
            password: values.password,
            first_name: values.firstName,
            last_name: values.lastName,
            display_name: fullName,
            profile: {
              signupSource: 'payment_page',
              uiClientFlags: {
                disabledEmailNotifications: {
                  marketingOptIn: true,
                },
              },
            },
          })

          if (!signupRes) {
            return
          }

          const cartUpdateBody = {
            phoneNumber: values.phoneNumber || undefined,
            email: values.email,
            name: fullName,
            dripOptIn: values.dripOptIn,
          }

          if (isQuickCheckout) {
            await payForCart(cartUpdateBody)
          } else {
            await updateCartAsync({body: cartUpdateBody})
          }

          onDidSignup?.()
        } catch (err: any) {
          growlActions.show('error', {
            title: 'Error!',
            body:
              (Object.values(err.response?.data?.errors ?? {})?.[0] as
                | string
                | undefined) ||
              err.message ||
              'Something went wrong!',
          })
        }
      } else {
        const cartUpdateBody = {
          email: values.email,
          name: fullName,
          phoneNumber: values.phoneNumber || undefined,
        }

        if (
          !isQuickCheckout &&
          (cart?.member?.name !== cartUpdateBody.name ||
            cart.member.email !== cartUpdateBody.email ||
            cart.phoneNumber !== cartUpdateBody.phoneNumber)
        ) {
          CartHelpers.trackEvent('payerNameSet', {payerName: fullName})
          CartHelpers.trackEvent('payerEmailSet', {payerEmail: values.email})

          await updateCartAsync({body: cartUpdateBody})
        }

        if (isQuickCheckout) {
          await payForCart(cartUpdateBody)
        }

        onDidContinueAsGuest?.()
      }
    },
  })

  const isEmailChecked = emailCheckResMap[formik.values.email] != null
  const doesEmailExist = !!emailCheckResMap[formik.values.email]?.exists

  const [_isSignup, setIsSignup] = useState(!doesEmailExist)
  const isSignup = _isSignup && !doesEmailExist

  return (
    <CheckoutSteppedPanel
      step="auth"
      heading={cart && cart.itemCount > 0 ? 'Checkout' : 'Submit'}
      {...restProps}
    >
      <form
        className="flex flex-col gap-4"
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        <SmartEmailField
          brandKitColors={brandKitColors}
          loginHidden={isAuthRequired}
          name="email"
          error={formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onEmailCheck={(checkRes, email) =>
            setEmailCheckResMap((prevEmailExistenceMap) => ({
              ...prevEmailExistenceMap,
              [email]: checkRes,
            }))
          }
          onDidLogin={onDidLogin}
        />

        {isEmailChecked && isAuthRequired && doesEmailExist && (
          <>
            <WebUI.Separator variant="primary" />

            <div className="flex flex-col gap-1">
              <WebUI.Text className="text-ds-lg">Welcome back!</WebUI.Text>
              <WebUI.Text className="font-light">
                Log in to save a payment method for your recurring payment.
              </WebUI.Text>
            </div>
            <LoginMethodPicker
              brandKitColors={brandKitColors}
              email={formik.values.email}
              onDidLogin={onDidLogin}
            />
          </>
        )}

        {isEmailChecked &&
          (!isAuthRequired || (isAuthRequired && !doesEmailExist)) && (
            <>
              <WebUI.Separator variant="primary" />

              {isAuthRequired && !doesEmailExist && (
                <div className="flex flex-col gap-1">
                  <WebUI.Text className="text-ds-lg">
                    Create an account
                  </WebUI.Text>
                  <WebUI.Text className="font-light">
                    Create an account to save a payment method for your
                    recurring payment.
                  </WebUI.Text>
                </div>
              )}

              <WebUI.FormFieldGroup>
                <WebUI.FormField
                  required
                  label="First Name"
                  error={formik.errors.firstName}
                >
                  <WebUI.Input
                    name="firstName"
                    placeholder="First Name"
                    autoComplete="given-name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                <WebUI.FormField
                  required
                  label="Last Name"
                  error={formik.errors.lastName}
                >
                  <WebUI.Input
                    name="lastName"
                    placeholder="Last Name"
                    autoComplete="family-name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              </WebUI.FormFieldGroup>

              {(!isQuickCheckout || isSignup) && (
                <WebUI.FormField
                  required
                  label="Phone"
                  error={formik.errors.phoneNumber}
                >
                  <WebUI.PhoneInput
                    name="phoneNumber"
                    countries={[...WebUI.ALLOWED_COUNTRY_CODES, 'IN']}
                    value={formik.values.phoneNumber}
                    onChange={(newPhoneNumber) =>
                      formik.setFieldValue('phoneNumber', newPhoneNumber)
                    }
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              )}

              {!doesEmailExist && !isAuthRequired && (
                <WebUI.Checkbox
                  checked={isSignup}
                  onChange={(event) => setIsSignup(event.target.checked)}
                >
                  Create an account?
                </WebUI.Checkbox>
              )}

              {isSignup && (
                <WebUI.PasswordFormFieldInput
                  className="[&_.FormField-caption]:[display:initial]"
                  required
                  label="Add a Password"
                  error={formik.errors.password}
                  name="password"
                  placeholder="Add a Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}

              {(isSignup || isQuickCheckout) && <TermsText />}
              {isQuickCheckout &&
                emailCheckResMap[formik.values.email]?.show_opt_in && (
                  <WebUI.Checkbox
                    name="dripOptIn"
                    state={formik.values.dripOptIn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    I want tips for collecting payments, sign ups, and forms
                  </WebUI.Checkbox>
                )}

              {isQuickCheckout ? (
                <CheckoutButton
                  className="self-start"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  {getCheckoutButtonLabel(cart)}
                </CheckoutButton>
              ) : (
                <WebUI.Button
                  className="self-start"
                  roundness="capsule"
                  type="submit"
                  loading={formik.isSubmitting}
                  style={{
                    backgroundColor: brandKitColors.primaryButton,
                    color: getCUReadableColor(brandKitColors.primaryButton),
                  }}
                >
                  Continue to Payment
                </WebUI.Button>
              )}
            </>
          )}
      </form>

      {config.isProd && <WebUI.Recaptcha ref={recaptchaRef} />}
    </CheckoutSteppedPanel>
  )
}

// MARK: – SmartEmailField

interface SmartEmailFieldProps
  extends Util.Merge<React.ComponentPropsWithoutRef<'input'>, WebUI.InputProps>,
    Pick<WebUI.FormFieldProps, 'error'>,
    Pick<LoginMethodPickerModalProps, 'onDidLogin'> {
  brandKitColors: PayerBrandKitColors
  loginHidden?: boolean
  defaultValue?: string
  value?: string
  onEmailCheck?: (
    checkResult: InferResponse<typeof endpoints.auth.checkEmailExistence>,
    email: string,
  ) => void
}

const SmartEmailField = ({
  brandKitColors,
  loginHidden,
  error,
  onDidLogin,
  onEmailCheck,
  defaultValue: defaultValueProp,
  value: valueProp,
  onChange,
  ...restProps
}: SmartEmailFieldProps) => {
  const [frozenDefaultValue] = useState(defaultValueProp)
  const [doesEmailExist, setDoesEmailExist] = useState(false)
  const abortControllerRef = useRef<AbortController | null>(null)

  const value = frozenDefaultValue ?? valueProp

  const checkEmailExistence = useDebounceCallback(
    async (email: string) => {
      abortControllerRef.current?.abort()

      const isValidEmail =
        email.length > 0 && Yup.string().email().isValidSync(email)

      if (!isValidEmail) {
        return
      }

      try {
        abortControllerRef.current = new AbortController()

        const res = await memoizedFetchCheckEmailExistence({
          body: {
            email,
          },
          signal: abortControllerRef.current.signal,
        })

        setDoesEmailExist(res.exists)

        onEmailCheck?.(res, email)
      } catch {
        // noop
      }
    },
    200,
    true,
  )

  useEffect(() => {
    if (value != null) {
      checkEmailExistence(value)
    }
  }, [value, checkEmailExistence])

  return (
    <WebUI.FormField
      required
      label="Email"
      error={error}
      suffix={
        !loginHidden &&
        (doesEmailExist ? (
          <LoginMethodPickerModal
            brandKitColors={brandKitColors}
            email={value ?? ''}
            disclosure={
              <WebUI.DialogDisclosure
                className="bg-trueWhite px-1"
                variant="link"
              >
                Login
              </WebUI.DialogDisclosure>
            }
            onDidLogin={onDidLogin}
          />
        ) : null)
      }
    >
      <WebUI.Input
        type="email"
        autoComplete="email"
        placeholder="Email"
        defaultValue={defaultValueProp}
        value={valueProp}
        onChange={(event) => {
          onChange?.(event)

          if (doesEmailExist) {
            setDoesEmailExist(false)
          }
        }}
        {...restProps}
      />
    </WebUI.FormField>
  )
}

// MARK: – LoginMethodPickerModal

interface LoginMethodPickerModalProps
  extends WebUI.PromptProps,
    Pick<LoginWithPasswordModalProps, 'onDidLogin'> {
  brandKitColors: PayerBrandKitColors
  email: string
}

const LoginMethodPickerModal = React.forwardRef<
  WebUI.DialogInstance,
  LoginMethodPickerModalProps
>(
  (
    {brandKitColors, email, onDidLogin, className, ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Prompt
      aria-label="Login method picker"
      ref={forwardedRef}
      className={WebUI.cn(
        'sm:[&_>_.ModalContentView]:rounded-extended',
        className,
      )}
      {...restProps}
    >
      {(dialog) => (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <WebUI.PromptHeader heading="Welcome back!" />
            <span>
              Log in to your existing account to make checkouts faster: {email}
            </span>
          </div>
          <LoginMethodPicker
            email={email}
            brandKitColors={brandKitColors}
            onDidLogin={(session) => {
              onDidLogin?.(session)
              dialog.hide()
            }}
            onDidHide={() => dialog.hide()}
          />
        </div>
      )}
    </WebUI.Prompt>
  ),
)

// MARK: – LoginMethodPicker

interface LoginMethodPickerProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<LoginWithPasswordModalProps, 'onDidLogin' | 'onDidHide'> {
  brandKitColors: PayerBrandKitColors
  email: string
}

const LoginMethodPicker = ({
  brandKitColors,
  email,
  onDidLogin,
  onDidHide,
  className,
  ...restProps
}: LoginMethodPickerProps) => (
  <div className={WebUI.cn('flex flex-row gap-3', className)} {...restProps}>
    <LoginCodeModal
      brandKitColors={brandKitColors}
      disclosure={
        <WebUI.DialogDisclosure
          roundness="capsule"
          style={{
            backgroundColor: brandKitColors.primaryButton,
            color: getCUReadableColor(brandKitColors.primaryButton),
          }}
        >
          Email me a login code
        </WebUI.DialogDisclosure>
      }
      email={email}
      onDidLogin={onDidLogin}
      onDidHide={onDidHide}
    />
    <LoginWithPasswordModal
      brandKitColors={brandKitColors}
      disclosure={
        <WebUI.DialogDisclosure variant="secondary" roundness="capsule">
          Log in with password
        </WebUI.DialogDisclosure>
      }
      email={email}
      onDidLogin={onDidLogin}
      onDidHide={onDidHide}
    />
  </div>
)

// MARK: – LoginCodeModal

interface LoginCodeModalProps
  extends WebUI.ModalProps,
    Pick<LoginWithPasswordModalProps, 'onDidLogin'> {
  brandKitColors: PayerBrandKitColors
  email: string
}

const LoginCodeModal = React.forwardRef<
  WebUI.DialogInstance,
  LoginCodeModalProps
>(
  (
    {brandKitColors, email, onDidLogin, onDidShow, className, ...restProps},
    forwardedRef,
  ) => {
    const resendTimeout = 60 * 5 // 5 minutes

    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const ownRef = useRef<WebUI.DialogInstance>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const recaptchaRef = useRef<WebUI.RecaptchaInstance>(null)
    const [login] = useLogin({recaptchaRef})
    const sendGuestLoginCodeMutation = useSendGuestLoginCodeMutation()
    const [secondsToResend, setSecondsToResend] = useState(resendTimeout)

    useInterval(
      () =>
        setSecondsToResend((prevSecondsToResend) =>
          Math.max(prevSecondsToResend - 1, 0),
        ),
      1000,
    )

    const formik = useFormik({
      validationSchema: Yup.object().shape({
        code: Yup.string().required(),
      }),
      initialValues: {
        code: '',
      },
      onSubmit: async (values) => {
        try {
          const session = await login({
            email,
            otp_code: values.code,
            forCheckout: true,
          })

          if (!session) {
            return
          }

          onDidLogin?.(session)

          ownRef.current?.hide()
        } catch (err) {
          const errMsg =
            err instanceof Error
              ? err.message
              : readApiError(
                  err,
                  {
                    invalid_security_token: 'Incorrect code',
                    two_factor_lockout:
                      'Your account is locked. Please try later.',
                    login_lockout: 'Your account is locked. Please try later.',
                  },
                  true,
                )
          setErrorMessage(errMsg)
        }
      },
    })

    return (
      <WebUI.Prompt
        aria-label="Request login code form"
        ref={ref}
        className={WebUI.cn(
          'sm:[&_>_.ModalContentView]:rounded-extended',
          className,
        )}
        onDidShow={() => {
          onDidShow?.()

          sendGuestLoginCodeMutation.mutate({body: {email}})
        }}
        {...restProps}
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <WebUI.PromptHeader heading="Check your inbox" />
            <span>Enter the login code we sent to {email}</span>
          </div>

          <form
            className="flex flex-col gap-4"
            onReset={formik.handleReset}
            onSubmit={formik.handleSubmit}
          >
            <div className="flex flex-col gap-2">
              <WebUI.Input
                aria-invalid={!!formik.errors.code}
                className="max-w-[380px]"
                name="code"
                autoComplete="one-time-code"
                autoCapitalize="characters"
                placeholder="Enter code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="text-ds-sm">
                {secondsToResend > 0 ? (
                  `Time left to resend code: ${Util.formatDuration(
                    Util.normalizeDuration({seconds: secondsToResend}),
                  )}`
                ) : (
                  <>
                    Didn't receive code?{' '}
                    <WebUI.Button
                      type="button"
                      variant="link"
                      disabled={sendGuestLoginCodeMutation.isPending}
                      onClick={async () => {
                        try {
                          await sendGuestLoginCodeMutation.mutateAsync({
                            body: {email},
                          })
                          setSecondsToResend(secondsToResend)
                        } catch {
                          // noop
                        }
                      }}
                    >
                      Resend
                    </WebUI.Button>
                  </>
                )}
              </span>
            </div>

            <WebUI.HStack className="items-center gap-8">
              <WebUI.Button
                className="self-start"
                type="submit"
                roundness="capsule"
                loading={formik.isSubmitting}
                style={{
                  backgroundColor: brandKitColors.primaryButton,
                  color: getCUReadableColor(brandKitColors.primaryButton),
                }}
              >
                Log in
              </WebUI.Button>
              {!!errorMessage && (
                <WebUI.Text className="text-ds-sm text-orange-500">
                  {errorMessage}
                </WebUI.Text>
              )}
            </WebUI.HStack>
          </form>
        </div>

        {config.isProd && <WebUI.Recaptcha ref={recaptchaRef} />}
      </WebUI.Prompt>
    )
  },
)

// MARK: – LoginWithPasswordModal

interface LoginWithPasswordModalProps extends WebUI.ModalProps {
  brandKitColors: PayerBrandKitColors
  email: string
  onDidLogin?: (session: Api.Session) => void
}

const LoginWithPasswordModal = React.forwardRef<
  WebUI.DialogInstance,
  LoginWithPasswordModalProps
>(
  (
    {brandKitColors, email, onDidLogin, className, ...restProps},
    forwardedRef,
  ) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const ownRef = useRef<WebUI.DialogInstance>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const recaptchaRef = useRef<WebUI.RecaptchaInstance>(null)
    const [login] = useLogin({recaptchaRef})

    const formik = useFormik({
      validationSchema: Yup.object().shape({
        password: Yup.string().required(),
      }),
      initialValues: {
        password: '',
      },
      onSubmit: async (values) => {
        try {
          const session = await login({
            email,
            password: values.password,
            forCheckout: true,
          })

          if (!session) {
            return
          }

          onDidLogin?.(session)

          ownRef.current?.hide()
        } catch (err) {
          if (err instanceof Error) {
            setErrorMessage(err.message)
          }
        }
      },
    })

    return (
      <WebUI.Prompt
        aria-label="Login form"
        ref={ref}
        className={WebUI.cn(
          'sm:[&_>_.ModalContentView]:rounded-extended',
          className,
        )}
        {...restProps}
      >
        <div className="flex flex-col gap-6">
          <WebUI.PromptHeader heading="Enter your password to log in" />

          <form
            className="flex flex-col gap-4"
            onReset={formik.handleReset}
            onSubmit={formik.handleSubmit}
          >
            <WebUI.Input
              className="max-w-[380px]"
              type="email"
              readOnly
              disabled
              value={email}
            />

            <PasswordInput
              wrapperClassName="max-w-[380px]"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <WebUI.HStack className="items-center gap-8">
              <WebUI.Button
                className="self-start"
                type="submit"
                roundness="capsule"
                loading={formik.isSubmitting}
                style={{
                  backgroundColor: brandKitColors.primaryButton,
                  color: getCUReadableColor(brandKitColors.primaryButton),
                }}
              >
                Log in
              </WebUI.Button>
              {!!errorMessage && (
                <WebUI.Text className="text-ds-sm text-orange-500">
                  {errorMessage}
                </WebUI.Text>
              )}
            </WebUI.HStack>
          </form>
        </div>
        {config.isProd && <WebUI.Recaptcha ref={recaptchaRef} />}
      </WebUI.Prompt>
    )
  },
)

// MARK: – PasswordInput

interface PasswordInputProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'input'>,
    WebUI.InputProps
  > {
  wrapperClassName?: string
}

const PasswordInput = ({
  wrapperClassName,
  className,
  ...restProps
}: PasswordInputProps) => {
  const [isRevealed, setIsRevealed] = useState(false)

  return (
    <div className={WebUI.cn('relative flex', wrapperClassName)}>
      <WebUI.Input
        className={WebUI.cn('w-full', className)}
        type={isRevealed ? 'text' : 'password'}
        {...restProps}
      />
      <WebUI.IconButton
        className="-translate-y-1/2 absolute top-1/2 right-3 h-auto w-auto p-0 text-ds-md text-teal-600"
        size="default_alt"
        tabIndex={-1}
        onClick={() =>
          setIsRevealed((prevPasswordRevealed) => !prevPasswordRevealed)
        }
      >
        {isRevealed ? (
          <WebUI.PhosphorIcon icon="eye-slash" />
        ) : (
          <WebUI.PhosphorIcon icon="eye" />
        )}
      </WebUI.IconButton>
    </div>
  )
}

// MARK: – Helpers

function getCheckoutButtonLabel(cart: Api.Cart | undefined) {
  const isCartFormsOnly = cart?.items.length === 0
  if (isCartFormsOnly) {
    const signupLabel =
      cart.time_slots.length > 0
        ? Util.pluralize('Sign Up', cart.time_slots.length)
        : ''
    const formLabel =
      getVisibleCartForms(cart).length > 0
        ? Util.pluralize('Form', cart.forms.length)
        : ''

    return `Submit My ${[signupLabel, formLabel]
      .filter((label) => label.length > 0)
      .join(' and ')}`
  }

  return 'Submit'
}
