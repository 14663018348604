import {Appearance} from '@stripe/stripe-js'
import {
  PaymentElement as StripePaymentElement,
  PaymentElementProps as StripePaymentElementProps,
} from '@stripe/react-stripe-js'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'

export interface PaymentElementProps extends StripePaymentElementProps {
  onLoadingChange?: (isLoading: boolean) => void
}

export const PaymentElement = ({
  onLoadingChange,
  onLoaderStart,
  onReady,
  ...restProps
}: PaymentElementProps) => {
  const [isLoaderDisplayed, setIsLoaderDisplayed] = useState(false)
  const [isPaymentElementReady, setIsPaymentElementReady] = useState(false)
  const onLoadingChangeRef = useLiveRef(onLoadingChange)

  useUpdateEffect(() => {
    onLoadingChangeRef.current?.(!isPaymentElementReady)
  }, [isPaymentElementReady])

  return (
    <>
      <StripePaymentElement
        onLoaderStart={(event) => {
          onLoaderStart?.(event)
          setIsLoaderDisplayed(true)
        }}
        onReady={(event) => {
          onReady?.(event)
          setIsPaymentElementReady(true)
        }}
        {...restProps}
      />
      {!isLoaderDisplayed && !isPaymentElementReady && <WebUI.Loader />}
    </>
  )
}

// See https://stripe.com/docs/elements/appearance-api?platform=web#rules
export const paymentElementAppearance: Appearance = {
  // HACK: they removed "none" theme, but it still works
  // https://github.com/stripe/stripe-js/pull/479
  theme: 'none' as any,
  variables: {
    colorPrimary: tailwindConfig.theme.colors.orange[500],
    colorText: tailwindConfig.theme.colors.gray800,
    colorBackground: '#ffffff',
    colorDanger: tailwindConfig.theme.colors.orange[500],
    fontFamily: 'avenir-light',
    fontWeightLight: 'avenir-light',
    fontWeightNormal: 'avenir-roman',
    fontWeightMedium: 'avenir-roman',
    fontWeightBold: 'avenir-roman',
    spacingUnit: tailwindConfig.theme.space[1],
    borderRadius: tailwindConfig.theme.borderRadius.default,
    fontSizeBase: tailwindConfig.theme.fontSize['ds-base'][0],
    fontLineHeight: tailwindConfig.theme.lineHeight.default,
    colorLogo: 'light',
    colorTextPlaceholder: tailwindConfig.theme.colors.inputPlaceholder,
    spacingGridRow: tailwindConfig.theme.space[4],
    spacingTab: tailwindConfig.theme.space[2],
    colorIconTabSelected: tailwindConfig.theme.colors.teal[600],
  },
  rules: {
    '.Tab': {
      backgroundColor: tailwindConfig.theme.colors.gray100,
      border: 'none',
    },
    '.Tab--selected': {
      border: `1px solid ${tailwindConfig.theme.colors.teal[600]}`,
      color: tailwindConfig.theme.colors.teal[600],
    },
    '.TabLabel': {
      fontFamily: 'avenir-roman',
      fontWeight: tailwindConfig.theme.fontWeight.normal,
    },

    '.Dropdown': {
      border: `1px solid ${tailwindConfig.theme.colors.grey[300]}`,
    },

    '.Label': {
      fontFamily: 'avenir-roman',
      fontSize: tailwindConfig.theme.fontSize['ds-sm'][0],
      marginBottom: tailwindConfig.theme.space[2] ?? '',
    },
    '.Error': {
      fontFamily: 'avenir-roman',
      fontSize: tailwindConfig.theme.fontSize['ds-xs'][0],
      color: tailwindConfig.theme.colors.orange[500],
    },

    '.Input': {
      paddingLeft: tailwindConfig.theme.space[2] ?? '',
      paddingRight: tailwindConfig.theme.space[2] ?? '',
      lineHeight: tailwindConfig.theme.lineHeight.compact,
      borderRadius: tailwindConfig.theme.borderRadius.default,
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.grey[300]}`,
      transition: 'background-color 100ms ease-in-out',
    },
    '.Input:hover': {
      backgroundColor: tailwindConfig.theme.colors.inputHoverBackground,
    },
    '.Input:focus': {
      outline: 'none',
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.teal[600]}`,
    },
    '.Input--invalid': {
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.orange[500]}`,
    },

    '.CheckboxInput': {
      borderRadius: tailwindConfig.theme.borderRadius.default,
      backgroundColor: '#ffffff',
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.grey[300]}`,
      transition: 'background-color 100ms ease-in-out',
    },
    '.CheckboxInput:focus': {
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.grey[300]}`,
    },
    '.CheckboxInput:hover': {
      backgroundColor: tailwindConfig.theme.colors.inputHoverBackground,
    },
    '.CheckboxInput--checked': {
      boxShadow: 'none',
    },

    '.CodeInput': {
      borderRadius: tailwindConfig.theme.borderRadius.default,
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.grey[300]}`,
      transition: 'box-shadow 100ms ease-in-out',
    },
    '.CodeInput:focus': {
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.teal[600]}`,
    },

    '.PickerItem--selected': {
      boxShadow: `inset 0 0 0 1px ${tailwindConfig.theme.colors.teal[600]}`,
    },
  },
}
