import React, {useRef} from 'react'
import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'

import {AddFormMenu} from './FormsPage'

export const FormListToolbar: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const media = WebUI.useMedia()
  const urlParams = useParams()

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'DataListToolbar relative flex-0 overflow-x-auto border-b bg-trueWhite',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="DataListToolbar-inner grow items-center justify-end gap-6">
        <WebUI.HStack
          className={
            'DataListToolbar-content items-center gap-2 [&_>_.Button_>_.Button-iconBefore_>_svg]:text-ds-lg'
          }
        >
          <WebUI.HStack className="gap-3">
            {!media.sm && (
              <>
                <AddFormMenu size="compact" variant="primary">
                  Add Form
                </AddFormMenu>
                <WebUI.Separator orientation="vertical" variant="primary" />
              </>
            )}

            <DisplayOptionsPopover
              collectionId={Number(urlParams.collection)}
            />
          </WebUI.HStack>
        </WebUI.HStack>
      </WebUI.HStack>
    </WebUI.HStack>
  )
}

// MARK: – DisplayOptionsPopover

interface DisplayOptionsPopoverProps extends WebUI.PopoverProps {
  collectionId: number
}

const DisplayOptionsPopover: React.FC<DisplayOptionsPopoverProps> = ({
  collectionId,
  ...restProps
}) => {
  const popoverRef = useRef<WebUI.PopoverInstance>(null)
  const collectionQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const isDynamicFormEnabledQuery = api.tabForms.list.useQuery(
    {pathParams: {tabId: collectionId}},
    {select: (forms) => forms.some((form) => !!form.linked_item_id)},
  )
  const updateCollectionMutation = useUpdateTabMutation()
  const isFormsFirst =
    collectionQuery.data?.options?.displayOrder?.[0] === 'forms'

  return (
    <WebUI.Popover ref={popoverRef} {...restProps}>
      <WebUI.PopoverDisclosure
        size="compact"
        variant="outlined"
        iconBefore={<WebUI.PhosphorIcon icon="squares-four" width={20} />}
      >
        Display Options
      </WebUI.PopoverDisclosure>
      <WebUI.PopoverContent
        className={
          '[&_>_.PopoverContent-inner_>_.PopoverContent-body]:w-[420px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:rounded-[10px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:px-8 [&_>_.PopoverContent-inner_>_.PopoverContent-body]:py-8'
        }
        unstable_autoFocusOnShow={false}
        arrow
      >
        <WebUI.VStack className="gap-6">
          <WebUI.Heading as="h2">Display Options</WebUI.Heading>
          <WebUI.IconButton
            className="absolute top-0 right-4 text-ds-xl"
            size="default_alt"
            onClick={() => popoverRef?.current?.setVisible(false)}
          >
            <WebUI.PhosphorIcon icon="x" />
          </WebUI.IconButton>
          <WebUI.Disclosure visible>
            <WebUI.Switch
              className={
                'gap-0 [&_>_.Switch-input]:order-1 [&_>_.Switch-label]:m-0 [&_>_.Switch-label]:grow [&_>_.Switch-label]:font-normal'
              }
              checked={isFormsFirst}
              disabled={
                isDynamicFormEnabledQuery.isLoading ||
                isDynamicFormEnabledQuery.data
              }
              onChange={(event) =>
                updateCollectionMutation.mutate({
                  pathParams: {
                    tabId: collectionId,
                  },
                  body: {
                    options: {
                      displayOrder: event.target.checked
                        ? ['forms', 'items']
                        : ['items', 'forms'],
                    },
                  },
                })
              }
            >
              Show Forms before Items
            </WebUI.Switch>
            <WebUI.DisclosureContent className="mt-6">
              <div className="flex max-w-[240px] flex-col gap-4 text-ds-sm">
                <span>
                  If your collection includes both items and forms, your payers
                  will see items first. Toggle this on if you'd prefer to see
                  your forms first.
                </span>
                {isDynamicFormEnabledQuery.data && (
                  <span className="text-orange-50">
                    This feature is not available because you've enabled dynamic
                    forms.
                  </span>
                )}
              </div>
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        </WebUI.VStack>
      </WebUI.PopoverContent>
    </WebUI.Popover>
  )
}
