import React, {useEffect, useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateUserMutation} from '@cheddarup/api-client'
import ArrowRight from 'src/images/arrow-right.png'
import ArrowRightOrange from 'src/images/arrow-right-orange.png'
import {Link, LinkProps} from 'src/components/Link'
import {useLiveRef} from '@cheddarup/react-util'

export interface FinishSetupPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onShowFinishSetup?: () => void
}

const FinishSetupPanel = ({
  onShowFinishSetup,
  ...restProps
}: FinishSetupPanelProps) => {
  const media = WebUI.useMedia()
  const updateUserMutation = useUpdateUserMutation()
  const onboardStepRadioLinkProps = useOnboardStepRadioLinkProps()

  const checkedCount = onboardStepRadioLinkProps.filter(
    (item) => !!item.checked,
  ).length
  const setupCompleted = checkedCount === onboardStepRadioLinkProps.length
  const percent = (checkedCount / onboardStepRadioLinkProps.length) * 100

  return (
    <WebUI.Panel {...restProps}>
      <WebUI.VStack className="gap-3 px-8 py-6">
        <WebUI.VStack className="gap-2">
          <WebUI.Text className="font-normal text-ds-xs" variant="subtle">
            FINISH SETUP
          </WebUI.Text>
          <WebUI.Text className="font-normal text-ds-md">
            Get ready to receive payments
          </WebUI.Text>
        </WebUI.VStack>

        <ProgressBar percent={percent} />
      </WebUI.VStack>

      <WebUI.Separator />

      <WebUI.VStack className="gap-1 px-8 py-6">
        {media.sm ? (
          <>
            {setupCompleted ? (
              <>
                <WebUI.Text>
                  You've finished your account set up. Time to start collecting!
                </WebUI.Text>
                <WebUI.Button
                  variant="link"
                  onClick={() =>
                    updateUserMutation.mutate({
                      body: {
                        profile: {
                          uiClientFlags: {onboardingChecklist: {show: false}},
                        },
                      },
                    })
                  }
                >
                  Close
                </WebUI.Button>
              </>
            ) : (
              <WebUI.RadioGroup size="compact">
                {onboardStepRadioLinkProps.map((radioLinkProps, idx) => (
                  <WebUI.Radio
                    key={idx}
                    className="[&_>_.Radio-input[aria-checked=false]_~_.Radio-text]:text-teal-600"
                    as={Link}
                    {...radioLinkProps}
                  />
                ))}
              </WebUI.RadioGroup>
            )}
          </>
        ) : (
          <WebUI.Button className="self-start" onClick={onShowFinishSetup}>
            Finish Setup
          </WebUI.Button>
        )}
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

// MARK: – ProgressBar

interface ProgressBarProps extends React.ComponentPropsWithoutRef<'div'> {
  percent: number
}

const ProgressBar = ({percent, className, ...restProps}: ProgressBarProps) => (
  <WebUI.HStack
    className={WebUI.cn('items-center gap-2', className)}
    {...restProps}
  >
    <WebUI.Text className="font-normal text-ds-xs" variant="subtle">
      {percent}%
    </WebUI.Text>
    <WebUI.HStack className="h-3 grow rounded-[6px] bg-grey-300">
      <div
        className="rounded-[inherit] bg-gradient-to-r from-yellow-500 to-orange-500"
        style={{width: `${percent}%`}}
      />
    </WebUI.HStack>
    <img
      className="h-4"
      alt="Progress arrow"
      src={percent < 100 ? ArrowRight : ArrowRightOrange}
    />
  </WebUI.HStack>
)

// MARK: – Helpers

export function useOnboardStepRadioLinkProps(): Array<
  WebUI.RadioProps & LinkProps
> {
  const sessionQuery = api.auth.session.useQuery()
  const hasExternalAccountsQuery = api.externalAccounts.list.useQuery(
    undefined,
    {
      select: (externalAccounts) =>
        externalAccounts.banks.length > 0 || externalAccounts.cards.length > 0,
    },
  )
  const updateUserMutation = useUpdateUserMutation()

  const isTwoFactorAuthSetUp = !!sessionQuery.data?.user.profile.phone.verified
  const isDetailsSetUp = !sessionQuery.data?.user.editable
  const isCustomCardDescriptorSetUp =
    !!sessionQuery.data?.user.profile.uiClientFlags?.onboardingChecklist
      ?.statementDescriptorComplete ||
    (!!sessionQuery.data?.user.profile.uiClientFlags?.customCardDescriptor &&
      sessionQuery.data?.user.profile.uiClientFlags?.customCardDescriptor !==
        sessionQuery.data?.user.display_name?.slice(0, 17))

  const radioLinkProps = useMemo(
    () => [
      {
        children: 'Create your account',
        to: '',
        checked: true,
      },
      {
        children: 'Set two-factor authentication',
        to: 'my-account/security',
        checked: isTwoFactorAuthSetUp,
      },
      {
        children: 'Confirm your details',
        to: 'my-account/account-details',
        checked: isDetailsSetUp,
      },
      {
        children: 'Create statement descriptor',
        to: 'my-account/statement-descriptor',
        checked: isCustomCardDescriptorSetUp,
      },
      {
        children: 'Prep for withdrawal',
        to: 'my-account/withdrawal-settings',
        checked: !!hasExternalAccountsQuery.data,
      },
    ],
    [
      hasExternalAccountsQuery.data,
      isTwoFactorAuthSetUp,
      isDetailsSetUp,
      isCustomCardDescriptorSetUp,
    ],
  )

  const isOnboardCheckListComplete = radioLinkProps.every((p) => !!p.checked)
  const isFinishSetupComplete =
    sessionQuery.data?.user.profile.uiClientFlags?.onboardingChecklist
      ?.finishSetupComplete
  const isUpdatingUserRef = useLiveRef(updateUserMutation.isPending)
  useEffect(() => {
    if (
      !isUpdatingUserRef.current &&
      isFinishSetupComplete === false &&
      isOnboardCheckListComplete
    ) {
      updateUserMutation.mutate({
        body: {
          profile: {
            uiClientFlags: {
              onboardingChecklist: {
                finishSetupComplete: true,
              },
            },
          },
        },
      })
    }
  }, [
    isFinishSetupComplete,
    isOnboardCheckListComplete,
    updateUserMutation.mutate,
  ])

  return radioLinkProps
}

export default FinishSetupPanel
