import {useNavigate, useParams} from 'react-router-dom'
import React, {useMemo, useRef} from 'react'
import {useReactToPrint} from 'react-to-print'
import Papa from 'papaparse'
import {StringParam, useQueryParam} from 'use-query-params'
import {api, useBulkDeleteFromWaitlisttMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {PaymentItemTableView} from 'src/components/PaymentItemTableView'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'

interface ItemWaitList extends Api.Waitlist {
  variant?: string
  variantItemAmount?: number
}

const ItemWaitlistPage: React.FC = () => {
  const [uuid] = useQueryParam('uuid', StringParam)
  const navigate = useNavigate()
  const urlParams = useParams()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const itemReportQuery = api.tabItems.report.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      itemId: urlParams.item!,
    },
  })
  const waitlistQuery = api.tabItems.listWaitlists.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      itemId: urlParams.item!,
    },
  })
  const deleteWaitlistMutation = useBulkDeleteFromWaitlisttMutation()
  const printDivRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    contentRef: printDivRef,
  })

  const itemVariation = (() => {
    const listing = itemReportQuery.data?.options.variants?.listings.find(
      (l) => l.uuid === uuid,
    )
    return Object.values(listing?.optionValues ?? {}).join(', ')
  })()

  const waitlistItems = useMemo(
    () =>
      waitlistQuery.data
        ?.map((waitlist) => {
          const listing = itemReportQuery.data?.options.variants?.listings.find(
            (l) => l.uuid === waitlist.variant_uuid,
          )
          return {
            ...waitlist,
            variant: Object.values(listing?.optionValues ?? {}).join(', '),
            variantItemAmount: listing?.amount,
          }
        })
        .filter((item) => (uuid ? item.variant_uuid === uuid : true)) ?? [],
    [itemReportQuery.data, uuid, waitlistQuery.data],
  )

  const itemPrice = (() => {
    const listings = itemReportQuery.data?.options?.variants?.enabled
      ? (itemReportQuery.data?.options.variants.listings ?? [])
      : []
    const lowestAmountListing = Util.firstBy(listings, (l) => l.amount)
    const highestAmountListing = Util.firstBy(listings, [
      (l) => l.amount,
      'desc',
    ])
    if (
      lowestAmountListing &&
      highestAmountListing &&
      lowestAmountListing.amount !== highestAmountListing.amount
    ) {
      return `${Util.formatAmount(lowestAmountListing.amount)}-${Util.formatAmount(
        highestAmountListing.amount,
      )}`
    }
    return `${Util.formatAmount(
      lowestAmountListing?.amount ?? itemReportQuery.data?.amount ?? 0,
    )}`
  })()

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<ItemWaitList>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((wi) => `${wi.first_name} ${wi.last_name}`, {
        id: 'walitlistName',
        header: 'Name on Waitlist',
        cell: ({row: {original: wi}}) => (
          <WebUI.VStack>
            <WebUI.Text>
              {wi.first_name} {wi.last_name}
            </WebUI.Text>
            <Link
              variant="primary"
              to={`message-center?to=${wi.email}-${wi.first_name} ${wi.last_name}`}
            >
              {wi.email}
            </Link>
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor((wi) => new Date(wi.created_at), {
        id: 'created_at',
        size: 100,
        sortingFn: 'datetime',
        header: 'Date and Time Added',
        cell: ({cell}) => Util.formatDateAs(cell.getValue(), 'datetime'),
      }),
      columnHelper.accessor((wi) => wi.quantity_desired, {
        id: 'qty',
        size: 60,
        meta: {
          align: 'right',
        },
        header: 'Desired Qty',
      }),
      columnHelper.accessor(
        (wi) =>
          wi.quantity_desired *
          (itemReportQuery.data?.amount ?? wi.variantItemAmount ?? 0),
        {
          id: 'value',
          size: 60,
          meta: {
            align: 'right',
          },
          header: 'Value',
          cell: ({cell}) => Util.formatAmount(cell.getValue()),
        },
      ),
    ],
    [columnHelper, itemReportQuery.data?.amount],
  )

  return (
    <WebUI.Modal
      aria-label="Item report"
      className={
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg sm:[&_>_.ModalContentView]:w-full'
      }
      preventBodyScroll
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.VStack className="min-h-0 flex-auto gap-6 p-4 sm:p-8">
        <WebUI.VStack className="gap-4">
          <WebUI.VStack className="gap-0_5">
            <WebUI.Heading as="h3">Waitlist</WebUI.Heading>
            {itemReportQuery.data && (
              <>
                <p className="font-light text-ds-sm">
                  {itemReportQuery.data?.name ?? ''}{' '}
                  {itemVariation ? `- ${itemVariation}` : ''}
                </p>
                <p className="font-light text-ds-sm">
                  {itemReportQuery.data.amount_type === 'fixed'
                    ? itemPrice
                    : '$Open'}
                </p>
                <br />
                <p className="text-ds-sm">
                  Quantity Sold: {itemReportQuery.data.quantity_sold}
                </p>
                <p className="text-ds-sm">Collection: {collection?.name}</p>
              </>
            )}
          </WebUI.VStack>
          <WebUI.HStack className="items-center gap-3">
            <WebUI.Button size="compact" onClick={() => handlePrint()}>
              Print
            </WebUI.Button>
            <WebUI.Button
              className="print:hidden"
              size="compact"
              variant="secondary"
              onClick={() => {
                if (!collection) {
                  return
                }
                WebUI.downloadFile(
                  new Blob(
                    [
                      makeItemWaitlistCSV(
                        itemReportQuery.data?.amount ?? 0,
                        waitlistQuery.data ?? [],
                      ),
                    ],
                    {
                      type: 'data:text/csv;charset=utf-8',
                    },
                  ),
                  'item-waitlist.csv',
                )
              }}
            >
              Export Spreadsheet
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
        <WebUI.VStack
          ref={printDivRef}
          className={
            'min-h-0 flex-auto flex-col-reverse border-t print:h-auto print:overflow-auto print:p-[2rem]'
          }
        >
          {!!collection && !!waitlistQuery.data && (
            <PaymentItemTableView
              className={
                'min-h-0 [&_>_.TableView-body]:min-h-0 [&_>_.TableView-body]:overflow-y-auto [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-grey-200'
              }
              enableRowSelection
              selectAllVisible={false}
              columns={columns}
              data={waitlistItems}
            >
              {(table) => {
                const selectedWaitlist = table
                  .getSelectedRowModel()
                  .flatRows.map((r) => r.original)
                return (
                  <WebUI.HStack className="h-15 items-center justify-between">
                    <WebUI.Checkbox
                      onChange={table.getToggleAllRowsSelectedHandler()}
                    >
                      Select All
                    </WebUI.Checkbox>
                    {selectedWaitlist.length > 0 && (
                      <WebUI.HStack className="gap-3">
                        <Link
                          to="message-center"
                          state={{
                            recipients: selectedWaitlist.map((w) => ({
                              uuid: Util.makeUuid(),
                              email: w.email,
                              name: `${w.first_name} ${w.last_name}`,
                            })),
                          }}
                        >
                          <WebUI.Button variant="secondaryAlt" size="compact">
                            Send Message
                          </WebUI.Button>
                        </Link>
                        <WebUI.Button
                          variant="secondaryAlt"
                          size="compact"
                          onClick={() => {
                            const ids = selectedWaitlist.map((w) => w.id)
                            deleteWaitlistMutation.mutate({
                              pathParams: {
                                tabId: Number(urlParams.collection),
                                itemId: Number(urlParams.item),
                              },
                              body: {waitlist_ids: ids},
                            })
                          }}
                        >
                          Delete
                        </WebUI.Button>
                      </WebUI.HStack>
                    )}
                  </WebUI.HStack>
                )
              }}
            </PaymentItemTableView>
          )}
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

// MARK: – Helpers

const makeItemWaitlistCSV = (amount: number, items: Api.Waitlist[]) => {
  const rows = items.flatMap((item) => ({
    name: `${item.first_name} ${item.last_name}`,
    email: item.email,
    date: Util.formatDateAs(item.created_at, 'datetime'),
    qty: item.quantity_desired,
    value: Util.formatAmount(item.quantity_desired * amount),
  }))

  const csvRows = rows.map((row) => [
    row.name,
    row.email,
    row.date,
    row.qty,
    row.value,
  ])

  const fields = [
    'Name on Waitlist',
    'Email',
    'Date and Time Added',
    'Desired Qty',
    'Value',
  ].filter((f) => f != null)

  return Papa.unparse({
    fields,
    data: csvRows,
  })
}

export default ItemWaitlistPage
