import React, {useRef} from 'react'
import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'
import {ForwardRefComponent, useFormik} from '@cheddarup/react-util'
import {api, useUpdateBrandingMutation} from '@cheddarup/api-client'
import {guessError} from 'src/helpers/error-utils'
import {useNavigate} from 'react-router-dom'

interface BrandPageFooterFormValues {
  enable_brand_footer: boolean
  contact: {
    orgName: string
    phoneNumber: string
    streetAddress: string
  }
  socialAccounts: {
    twitter: string
    youtube: string
    facebook: string
    instagram: string
  }
  mainWebsiteLink: {
    link: string
    text: string
  }
}

type BrandPageFooterFormFormik = ReturnType<
  typeof useFormik<BrandPageFooterFormValues>
>

const BrandKitFooterPage = () => {
  const disableCustomFooterAlertRef = useRef<WebUI.DialogInstance>(null)

  const {data: notSubscribedToTeam} = api.auth.session.useQuery(undefined, {
    select: (session) => !session.capabilities.subscribed_to_team,
  })
  const {data: branding} = api.userBrandings.detail.useQuery()

  const updateBrandingMutation = useUpdateBrandingMutation()
  const growlActions = WebUI.useGrowlActions()
  const navigate = useNavigate()

  const formik = useFormik<BrandPageFooterFormValues>({
    enableReinitialize: true,
    initialValues: {
      enable_brand_footer: !!branding?.enable_brand_footer,
      contact: {
        orgName: branding?.footer.contact?.orgName ?? '',
        phoneNumber: branding?.footer.contact?.phoneNumber ?? '',
        streetAddress: branding?.footer.contact?.streetAddress ?? '',
      },
      socialAccounts: {
        twitter: branding?.footer.socialAccounts?.twitter ?? '',
        youtube: branding?.footer.socialAccounts?.youtube ?? '',
        facebook: branding?.footer.socialAccounts?.facebook ?? '',
        instagram: branding?.footer.socialAccounts?.instagram ?? '',
      },
      mainWebsiteLink: {
        link: branding?.footer.mainWebsiteLink?.link ?? '',
        text: branding?.footer.mainWebsiteLink?.text ?? '',
      },
    },
    validationSchema: Yup.object().shape({
      enable_brand_footer: Yup.boolean(),
      contact: Yup.object().shape({
        orgName: Yup.string().when(
          '$enable_brand_footer',
          ([enable_brand_footer], schema) =>
            enable_brand_footer ? schema.required('Required') : schema,
        ),
      }),
      mainWebsiteLink: Yup.object().shape({
        link: Yup.string(),
        text: Yup.string().when('link', ([link], schema) =>
          link ? schema.required('Required') : schema,
        ),
      }),
    }),
    onSubmit: async (values) => {
      try {
        const {enable_brand_footer, ...restFooterValues} = values
        await updateBrandingMutation.mutateAsync({
          body: {
            enable_brand_footer,
            footer: {
              ...restFooterValues,
              mainWebsiteLink: {
                link: restFooterValues.mainWebsiteLink.link,
                text: restFooterValues.mainWebsiteLink.link
                  ? restFooterValues.mainWebsiteLink.text
                  : '',
              },
            },
          },
        })
        growlActions.show('success', {
          title: 'Success!',
          body: 'Footer has been updated',
        })
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <>
      <AccountSettingsContentLayout
        heading={
          <div className="flex items-center gap-4">
            <WebUI.Heading as="h2">Page Footer</WebUI.Heading>
            {notSubscribedToTeam && (
              <PlanUpgradeButton upgradeTo="team" asPaidBadge />
            )}
          </div>
        }
        body="Customize the footer that appears at the bottom of your collection pages and, if published, Group Page. Add contact information, a link to your main website and social accounts."
      >
        <WebUI.Panel className="px-6 py-5">
          <WebUI.Switch
            className="items-start gap-3"
            checked={formik.values.enable_brand_footer}
            onChange={(event) => {
              if (notSubscribedToTeam) {
                event.preventDefault()
                navigate('i/plans?recommendedPlan=team')
              } else {
                const isEnabled = event.target.checked
                if (isEnabled) {
                  formik.setFieldValue('enable_brand_footer', isEnabled)
                } else {
                  disableCustomFooterAlertRef.current?.show()
                }
              }
            }}
            size="compact"
          >
            <div className="flex flex-col gap-2 font-normal text-ds-sm">
              <WebUI.Text>Replace the Cheddar Up Footer</WebUI.Text>
              <WebUI.Text className="font-light">
                Replace the default Cheddar Up footer and make it your own.
              </WebUI.Text>
            </div>
          </WebUI.Switch>
        </WebUI.Panel>
        {formik.values.enable_brand_footer && (
          <WebUI.Panel
            as="form"
            onBlur={formik.handleBlur}
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <AccountSettingsContentCard
              className="[&_>_.Separator]:-mx-6 border-none"
              heading="Footer Elements"
            >
              <WebUI.FormField
                label="Organization Name"
                error={formik.errors.contact?.orgName}
                required
              >
                <WebUI.Input
                  name="contact.orgName"
                  value={formik.values.contact.orgName}
                  placeholder="Organization Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField label="Street Address">
                <WebUI.AddressCombobox
                  defaultAddress={formik.values.contact.streetAddress}
                  onAddressChange={(location) =>
                    formik.setFieldValue(
                      'contact.streetAddress',
                      location.address,
                    )
                  }
                />
              </WebUI.FormField>
              <WebUI.FormField label="Phone Number">
                <WebUI.PhoneInput
                  name="contact.phoneNumber"
                  placeholder="Phone Number"
                  value={formik.values.contact.phoneNumber}
                  countries={WebUI.ALLOWED_COUNTRY_CODES}
                  onChange={(newPhoneNumber) =>
                    formik.setFieldValue('contact.phoneNumber', newPhoneNumber)
                  }
                  onBlur={formik.handleBlur}
                  allowNull
                />
              </WebUI.FormField>
              <WebUI.Separator />
              <PageFooterSection
                label="Main Website URL"
                description="Provide a link to your home page or main website from the
                    page header and footer."
              >
                <WebUI.FormField label="URL">
                  <WebUI.Input
                    name="mainWebsiteLink.link"
                    value={formik.values.mainWebsiteLink.link}
                    placeholder="Enter a valid URL"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                {formik.values.mainWebsiteLink.link && (
                  <WebUI.FormField
                    label="Text"
                    error={formik.errors.mainWebsiteLink?.text}
                    required
                  >
                    <WebUI.Input
                      name="mainWebsiteLink.text"
                      value={formik.values.mainWebsiteLink.text}
                      placeholder="e.g. “Main Website” or “Home Page”"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )}
              </PageFooterSection>
              <WebUI.Separator />
              <PageFooterSection
                label="Social Accounts"
                description="Increase engagement by adding social accounts to your page
                  footer."
              >
                <InputWithIcon
                  name="socialAccounts.facebook"
                  value={formik.values.socialAccounts.facebook}
                  placeholder="Facebook URL"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  icon={
                    <WebUI.PhosphorIcon
                      icon="facebook-logo-fill"
                      className="text-ds-lg text-trueBlack"
                    />
                  }
                />
                <InputWithIcon
                  name="socialAccounts.instagram"
                  value={formik.values.socialAccounts.instagram}
                  placeholder="Instagram URL"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  icon={
                    <WebUI.PhosphorIcon
                      icon="instagram-logo"
                      className="text-ds-lg text-trueBlack"
                    />
                  }
                />
                <InputWithIcon
                  name="socialAccounts.twitter"
                  value={formik.values.socialAccounts.twitter}
                  placeholder="X URL"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  icon={
                    <WebUI.PhosphorIcon
                      icon="x-logo"
                      className="text-ds-lg text-trueBlack"
                    />
                  }
                />
                <InputWithIcon
                  name="socialAccounts.youtube"
                  value={formik.values.socialAccounts.youtube}
                  placeholder="YouTube URL"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  icon={
                    <WebUI.PhosphorIcon
                      icon="youtube-logo-fill"
                      className="text-ds-lg text-trueBlack"
                    />
                  }
                />
              </PageFooterSection>
              {formik.dirty && formik.values.enable_brand_footer && (
                <WebUI.Button
                  className="self-start"
                  loading={formik.isSubmitting}
                  type="submit"
                >
                  Save
                </WebUI.Button>
              )}
            </AccountSettingsContentCard>
          </WebUI.Panel>
        )}
      </AccountSettingsContentLayout>
      <DisableCustomFooterAlert
        ref={disableCustomFooterAlertRef}
        formik={formik}
      />
    </>
  )
}

// MARK: - PageFooterSection

export interface PageFooterSectionProps {
  label: React.ReactNode
  description?: string
}

export const PageFooterSection = React.forwardRef(
  ({label, description, className, children, ...restProps}, forwardedRef) => (
    <div
      ref={forwardedRef}
      className={WebUI.cn(
        'PageFooterSection flex flex-col gap-6 text-ds-sm',
        className,
      )}
      {...restProps}
    >
      <div className="flex flex-col gap-2">
        <WebUI.Text>{label}</WebUI.Text>
        {description && (
          <WebUI.Text className="font-light">{description}</WebUI.Text>
        )}
      </div>
      {children}
    </div>
  ),
) as ForwardRefComponent<'div', PageFooterSectionProps>

// MARK: - InputWithIcon

export interface InputWithIconProps extends WebUI.InputProps {
  icon: React.ReactNode
  position?: 'left' | 'right'
}

export const InputWithIcon = React.forwardRef(
  ({icon, className, position = 'left', ...restProps}, forwardedRef) => (
    <div className={WebUI.cn('Input-wrapper relative')}>
      <WebUI.Input
        ref={forwardedRef}
        className={WebUI.cn(
          'w-full px-4',
          position === 'left' ? 'pl-11' : 'pr-11',
          className,
        )}
        {...restProps}
      />
      <span
        className={WebUI.cn(
          '-translate-y-1/2 pointer-events-none absolute top-1/2 flex',
          position === 'right' ? 'right-3' : 'left-3',
        )}
      >
        {icon}
      </span>
    </div>
  ),
) as ForwardRefComponent<'input', InputWithIconProps>

// MARK: – DisableCustomFooterAlert

interface DisableCustomFooterAlertProps extends WebUI.AlertProps {
  formik: BrandPageFooterFormFormik
}

const DisableCustomFooterAlert = React.forwardRef<
  WebUI.DialogInstance,
  DisableCustomFooterAlertProps
>(({formik, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    aria-label="Disable Custom Footer alert"
    ref={forwardedRef}
    {...restProps}
  >
    <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text="You will no longer have a customized footer and the Cheddar Up footer will show on your pages."
      actions={
        <>
          <WebUI.AlertActionButton
            loading={formik.isSubmitting}
            execute={async () => {
              formik.setFieldValue('enable_brand_footer', false)
              await formik.submitForm()
            }}
          >
            Show Cheddar Up Footer
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton />
        </>
      }
    />
  </WebUI.Alert>
))

export default BrandKitFooterPage
