import * as Ariakit from '@ariakit/react'
import React from 'react'
import {cva} from 'class-variance-authority'

import {Loader} from '../Loader'
import {VariantsProps, cn} from '../../utils'

export const nextButton = cva(
  [
    'relative inline-flex cursor-pointer select-none appearance-none flex-row items-center justify-center overflow-hidden border-none transition-all',
    'text-ellipsis whitespace-nowrap text-center font-body',
    'aria-disabled:cursor-not-allowed',
  ],
  {
    variants: {
      variant: {
        teal: [
          'bg-teal-600',
          'font-normal text-trueWhite',
          'hover:bg-teal-40',
          'data-[focus-visible=true]:border-3 data-[focus-visible=true]:border-teal-90',
          'aria-disabled:bg-teal-300',
        ],
        orange: [
          'bg-orange-500',
          'font-normal text-trueWhite',
          'hover:bg-orange-600',
          'data-[focus-visible=true]:border-3 data-[focus-visible=true]:border-orange-300',
          'aria-disabled:bg-orange-300',
        ],
        outlined: [
          'border border-grey-400 bg-trueWhite',
          'font-normal text-grey-700',
          'hover:shadow-300',
          'data-[focus-visible=true]:border-grey-300 data-[focus-visible=true]:shadow-300',
          'aria-disabled:border-grey-200 aria-disabled:text-grey-200',
        ],
        gray: [
          'bg-grey-200 font-normal text-grey-700',
          'hover:bg-grey-100',
          'data-[focus-visible=true]:border-3 data-[focus-visible=true]:border-grey-100 data-[focus-visible=true]:bg-grey-50',
          'aria-disabled:bg-grey-100 aria-disabled:text-grey-200',
        ],
        transparent: [
          'bg-transparent',
          'font-normal text-grey-600',
          'hover:bg-grey-100',
          'aria-disabled:text-grey-200',
        ],
        headless: '',
      },
      roundness: {
        none: 'rounded-none',
        default: 'rounded',
        pill: 'rounded-full',
      },
      size: {
        xs: 'gap-2 px-3 py-2 text-ds-xs',
        sm: 'gap-2 px-4 py-2 text-ds-sm',
        md: 'gap-2 px-5 py-2 text-ds-base',
        lg: 'gap-3 px-6 py-3 text-ds-md',
        xl: 'gap-3 px-7 py-3 text-ds-lg',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'teal',
      size: 'sm',
    },
  },
)

export interface NextButtonProps
  extends VariantsProps<typeof nextButton>,
    Ariakit.ButtonOptions,
    React.ComponentPropsWithoutRef<'button'> {
  loading?: boolean
}

export const NextButton = React.forwardRef<HTMLButtonElement, NextButtonProps>(
  (
    {
      variant = 'teal',
      roundness = 'default',
      size = 'sm',
      loading,
      render,
      className,
      disabled,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    return (
      <Ariakit.Button
        ref={forwardedRef}
        data-loading={loading}
        className={cn(nextButton({variant, size, roundness}), className)}
        disabled={disabled || loading}
        render={render}
        {...restProps}
      >
        {loading && (
          <div className="absolute inset-0 flex flex-row items-center justify-center bg-grey-600/70">
            <Loader size="1.75em" variant="light" />
          </div>
        )}
        {children}
      </Ariakit.Button>
    )
  },
)

// MARK: AnchorButton

export interface NextAnchorButtonProps
  extends VariantsProps<typeof nextButton>,
    Ariakit.ButtonOptions,
    React.ComponentPropsWithoutRef<'a'> {}

export const NextAnchorButton = React.forwardRef<
  HTMLAnchorElement,
  NextAnchorButtonProps
>(
  (
    {
      variant,
      size,
      clickOnEnter,
      clickOnSpace,
      disabled,
      autoFocus,
      focusable,
      accessibleWhenDisabled,
      onFocusVisible,
      ...restProps
    },
    forwardedRef,
  ) => (
    <NextButton
      variant={variant}
      size={size}
      clickOnEnter={clickOnEnter}
      clickOnSpace={clickOnSpace}
      disabled={disabled}
      autoFocus={autoFocus}
      focusable={focusable}
      accessibleWhenDisabled={accessibleWhenDisabled}
      onFocusVisible={onFocusVisible}
      render={<Ariakit.Role.a ref={forwardedRef} {...restProps} />}
    />
  ),
)
