import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef, usePrevious, useUpdateEffect} from '@cheddarup/react-util'
import React, {useCallback, useContext, useMemo, useRef} from 'react'
import * as Util from '@cheddarup/util'

import {
  FieldGroup,
  FieldGroupType,
  FieldGroupValue,
  FieldsEditInnerContext,
} from '../FieldsEdit/FieldsEdit'
import {DividerFieldsEdit} from './FieldGroupFieldsEdit/DividerFieldsEdit'
import {FieldGroupCard} from './FieldGroupCard'
import {FieldGroupFieldsEdit} from './FieldGroupFieldsEdit/FieldGroupFieldsEdit'
import {FieldGroupEdit} from './FieldGroupEdit'
import {LayoutDescriptionFieldsEdit} from './FieldGroupFieldsEdit/LayoutDescriptionFieldsEdit'

export interface FieldGroupEditListProps
  extends Omit<
    WebUI.DataListProps<FieldGroup, typeof WebUI.List>,
    'initialState' | 'data' | 'DataRowComponent'
  > {}

export const FieldGroupEditList = ({
  className,
  ...restProps
}: FieldGroupEditListProps) => {
  const contextValue = useContext(FieldsEditInnerContext)
  const dataListRef = useRef<WebUI.DataListInstance>(null)
  const setFieldGroupsRef = useLiveRef(contextValue.setFieldGroups)

  const fieldGroupsLength = contextValue.fieldGroups.length
  const prevFieldGroupsLength = usePrevious(fieldGroupsLength)
  const isFieldGroupAdded =
    prevFieldGroupsLength != null && fieldGroupsLength > prevFieldGroupsLength

  const lastFieldGroupId = contextValue.fieldGroups[fieldGroupsLength - 1]?.id

  useUpdateEffect(() => {
    if (lastFieldGroupId && isFieldGroupAdded) {
      dataListRef.current?.dispatch({
        type: 'SET_ITEM_FOCUSED',
        key: lastFieldGroupId,
      })

      // HACK: `setTimeout` here is to make sure the row element exists
      setTimeout(() => dataListRef.current?.scrollToRow(lastFieldGroupId), 100)
    }
  }, [isFieldGroupAdded, lastFieldGroupId])

  const FieldGroupDataRowWithHandlers: WebUI.DataRowComponentType<FieldGroup> =
    useMemo(
      () =>
        React.forwardRef(
          ({row, tabIndex, onFocus, onBlur, ...restRowProps}, forwardedRef) => (
            <WebUI.Sortable
              className="grow"
              id={row?.id ?? Util.makeShortId()}
              draggable={false}
              tabIndex={tabIndex}
              onFocus={onFocus}
              onBlur={onBlur}
            >
              {({dragListeners}) => (
                <FieldGroupDataRow
                  ref={forwardedRef}
                  row={row}
                  dragListeners={dragListeners}
                  onReplicate={useCallback(() => {
                    setFieldGroupsRef.current((prevFieldGroups) => {
                      const currFieldGroupIdx = prevFieldGroups.findIndex(
                        (fg) => fg.id === row?.original.id,
                      )
                      const currFieldGroup = prevFieldGroups[currFieldGroupIdx]
                      if (!currFieldGroup) {
                        return prevFieldGroups
                      }

                      return [
                        ...prevFieldGroups.slice(0, currFieldGroupIdx),
                        {
                          ...currFieldGroup,
                          uuid: null,
                          id: Util.makeShortId(),
                        },
                        ...prevFieldGroups.slice(currFieldGroupIdx),
                      ]
                    })
                  }, [row?.original.id])}
                  onDelete={useCallback(
                    () =>
                      setFieldGroupsRef.current((prevFieldGroups) =>
                        prevFieldGroups.filter(
                          (fg) => fg.id !== row?.original.id,
                        ),
                      ),
                    [row?.original.id],
                  )}
                  onFieldLabelChange={useCallback(
                    (fieldKey, newFieldLabel) =>
                      setFieldGroupsRef.current((prevFieldGroups) =>
                        prevFieldGroups.map((fg) =>
                          fg.id === row?.original.id
                            ? ({
                                ...fg,
                                fieldLabels: {
                                  ...fg.fieldLabels,
                                  [fieldKey]: newFieldLabel,
                                },
                              } as any)
                            : fg,
                        ),
                      ),
                    [row?.original.id],
                  )}
                  onFieldValueChange={useCallback(
                    (newValue) =>
                      setFieldGroupsRef.current((prevFieldGroups) =>
                        prevFieldGroups.map((fg) =>
                          fg.id === row?.original.id
                            ? {...fg, value: newValue as any}
                            : fg,
                        ),
                      ),
                    [row?.original.id],
                  )}
                  onRequiredChange={useCallback(
                    (newRequired) =>
                      setFieldGroupsRef.current((prevFieldGroups) =>
                        prevFieldGroups.map((fg) =>
                          fg.id === row?.original.id
                            ? {...fg, required: newRequired}
                            : fg,
                        ),
                      ),

                    [row?.original.id],
                  )}
                  {...restRowProps}
                />
              )}
            </WebUI.Sortable>
          ),
        ),
      [],
    )

  return (
    <WebUI.SortableContext
      strategy={WebUI.verticalListSortingStrategy}
      items={contextValue.fieldGroups}
    >
      <WebUI.DataList
        ref={dataListRef as any}
        className={WebUI.cn(
          'FieldGroupEditList',
          'gap-4',
          `[&_>_.DataListRow:hover:not([aria-pressed=true])_.FieldGroupCard[class]]:bg-grey-100 [&_>_.DataListRow[data-rowfocused="true"]_.FieldGroupCard[class]]:border-grey-300 [&_>_.DataListRow[data-rowfocused="true"]_.FieldGroupCard[class]]:bg-gray100 [&_>_.DataListRow_.Button]:opacity-100 [&_>_.DataListRow_.DatePicker-disclosure:disabled]:opacity-100 [&_>_.DataListRow_.Input:disabled]:bg-trueWhite [&_>_.DataListRow_.Input:disabled]:opacity-100 [&_>_.DataListRow_.Select_>_.Select-select:disabled]:opacity-100 [&_>_.DataListRow_.Select_>_.Select-select:disabled_~_.Select-arrow]:opacity-100`,
          className,
        )}
        ListComponent={WebUI.List}
        EmptyStateViewComponent={FieldGroupEditListSortableContainer}
        DataRowComponent={FieldGroupDataRowWithHandlers}
        data={contextValue.fieldGroups}
        initialState={{focusedItemId: null, selectedItemIdsMap: {}}}
        {...restProps}
      />
    </WebUI.SortableContext>
  )
}

// MARK: – FieldGroupEditRow

interface FieldGroupDataRowProps<TType extends FieldGroupType = FieldGroupType>
  extends WebUI.DataRowComponentProps<FieldGroup<TType>> {
  dragListeners: WebUI.DraggableSyntheticListeners
  onReplicate: () => void
  onDelete: () => void
  onFieldLabelChange: (fieldKey: string, fieldLabel: string) => void
  onRequiredChange: (required: boolean) => void
  onFieldValueChange: (values: FieldGroupValue<TType>) => void
}

const FieldGroupDataRow = React.forwardRef<
  HTMLDivElement,
  FieldGroupDataRowProps
>(
  (
    {
      onReplicate,
      onDelete,
      onFieldLabelChange,
      onFieldValueChange,
      onRequiredChange,
      row,
      setSelected,
      ...restProps
    },
    forwardedRef,
  ) => {
    if (!row) {
      return null
    }

    const commonProps = {
      onReplicate,
      onDelete,
      ...restProps,
    }

    switch (row.original.type) {
      case 'layout_description':
        return (
          <FieldGroupCard ref={forwardedRef} {...commonProps}>
            <LayoutDescriptionFieldsEdit
              readOnly={!row.state.focused}
              value={row.original.value as string}
              onValueChange={onFieldValueChange}
            />
          </FieldGroupCard>
        )
      case 'layout_divider':
        return (
          <FieldGroupCard ref={forwardedRef} {...commonProps}>
            <DividerFieldsEdit />
          </FieldGroupCard>
        )
      default:
        return (
          <FieldGroupEdit
            ref={forwardedRef}
            focused={row.state.focused}
            required={row.original.required}
            onRequiredChange={onRequiredChange}
            {...commonProps}
          >
            <FieldGroupFieldsEdit
              fieldGroupType={row.original.type}
              initialValue={row.original.value as any}
              focused={
                row.state.focused &&
                [
                  'text',
                  'text_multiline',
                  'multiple_choice',
                  'checkbox',
                ].includes(row.original.type)
              }
              required={row.original.required}
              // biome-ignore lint/style/noNonNullAssertion:
              initialLabels={row.original.fieldLabels!}
              // biome-ignore lint/style/noNonNullAssertion:
              placeholders={row.original.placeholders!}
              onLabelChange={onFieldLabelChange}
              onValueChange={onFieldValueChange}
            />
          </FieldGroupEdit>
        )
    }
  },
)

// MARK: – FieldGroupEditListSortableContainer

const FieldGroupEditListSortableContainer = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const media = WebUI.useMedia()

  if (!media.sm) {
    return null
  }

  return (
    <WebUI.Droppable
      className={WebUI.cn('bg-gray100 p-8', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="items-center justify-center">
        <WebUI.Text className="text-center font-light text-ds-sm">
          Drag your first question here from the left
        </WebUI.Text>
      </WebUI.VStack>
    </WebUI.Droppable>
  )
}
