export type {Item as CompositeItem} from 'reakit/ts/Composite/__utils/types'

import {motion} from 'framer-motion'
import {
  Tab as ReakitTab,
  TabInitialState as ReakitTabInitialState,
  TabList as ReakitTabList,
  TabPanel as ReakitTabPanel,
  TabPanelOptions as ReakitTabPanelOptions,
  TabStateReturn as ReakitTabStateReturn,
  useTabState as useReakitTabState,
} from 'reakit'
import {
  ForwardRefComponent,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import React, {useContext, useImperativeHandle, useMemo, useRef} from 'react'
import {tailwindConfig} from '@cheddarup/tailwind-config'

import {Button} from './Button'
import {Stack} from './Stack'
import {cn} from '../utils'
import {adjustHue, lighten} from 'color2k'

export type TabVariant = 'default' | 'underlined' | 'stepper'

interface InternalTabsContextValue extends ReakitTabStateReturn {
  variant: TabVariant
  lazy?: boolean
}

export const InternalTabsContext = React.createContext(
  {} as InternalTabsContextValue,
)

// MARK: – Tabs

export interface TabsInstance extends ReakitTabStateReturn {}

export interface TabsProps
  extends ReakitTabInitialState,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  elementRef?: React.Ref<HTMLDivElement>
  variant?: TabVariant
  lazy?: boolean
  initialSelectedId?: string
  onChangeCurrentId?: (newCurrentId: string | null | undefined) => void
  onChangeSelectedId?: (newSelectedId: string | null | undefined) => void
  children: React.ReactNode | ((tabs: TabsInstance) => React.ReactNode)
}

export const Tabs = React.forwardRef<TabsInstance, TabsProps>(
  (
    {
      variant = 'default',
      children,
      className,
      onChangeCurrentId,
      onChangeSelectedId,
      baseId,
      unstable_virtual,
      rtl,
      orientation = 'horizontal' as const,
      currentId,
      lazy,
      loop,
      wrap,
      initialSelectedId,
      selectedId,
      manual,
      elementRef,
      ...restProps
    },
    forwardedRef,
  ) => {
    const tabs = useReakitTabState({
      baseId,
      unstable_virtual,
      rtl,
      orientation,
      currentId,
      loop,
      wrap,
      selectedId: selectedId ?? initialSelectedId,
      manual,
    })
    const tabsRef = useLiveRef(tabs)
    const onChangeCurrentIdRef = useLiveRef(onChangeCurrentId)
    const onChangeSelectedIdRef = useLiveRef(onChangeSelectedId)

    useImperativeHandle(forwardedRef, () => tabs, [tabs])

    useUpdateEffect(() => {
      if (currentId != null) {
        tabsRef.current.setCurrentId(currentId)
      }
    }, [currentId])

    useUpdateEffect(() => {
      if (selectedId != null) {
        tabsRef.current.setSelectedId(selectedId)
      }
    }, [selectedId])

    useUpdateEffect(() => {
      onChangeCurrentIdRef.current?.(tabs.currentId)
    }, [tabs.currentId])

    useUpdateEffect(() => {
      onChangeSelectedIdRef.current?.(tabs.selectedId)
    }, [tabs.selectedId])

    useUpdateEffect(() => {
      tabsRef.current.setOrientation(orientation)
    }, [orientation])

    const contextValue = useMemo(
      () => ({
        ...tabs,
        variant,
        lazy,
      }),
      [lazy, tabs, variant],
    )

    return (
      <InternalTabsContext.Provider value={contextValue}>
        <div
          ref={elementRef}
          className={cn(
            `Tabs Tabs--${variant}`,
            'flex',
            tabs.orientation === 'vertical' ? 'flex-row' : 'flex-col',
            className,
          )}
          {...restProps}
        >
          {typeof children === 'function' ? children(tabs) : children}
        </div>
      </InternalTabsContext.Provider>
    )
  },
)

// MARK: - TabList

export interface TabListProps {
  tabListUnderlineStyles?: React.CSSProperties
}

export const TabList = React.forwardRef(
  (
    {as = Stack, className, children, tabListUnderlineStyles, ...restProps},
    forwardedRef,
  ) => {
    const {variant, ...tabs} = useContext(InternalTabsContext)

    const selectedTabIdx = tabs.items.findIndex((t) => t.id === tabs.selectedId)
    const selectedTab = tabs.items[selectedTabIdx]
    const selectedTabWidth =
      selectedTab?.ref.current?.getBoundingClientRect().width ?? 0
    const underlineX = selectedTab?.ref.current?.offsetLeft ?? 0

    return (
      <ReakitTabList
        ref={forwardedRef}
        aria-orientation={tabs.orientation}
        className={cn(
          'TabList',
          'relative flex flex-row border-b',
          'aria-orientation-vertical:flex-col aria-orientation-vertical:border-r',
          variant === 'underlined'
            ? 'aria-orientation-horizontal:overflow-x-auto *:aria-orientation-horizontal:flex-0'
            : '*:aria-orientation-horizontal:flex-[1_0_auto]',
          variant === 'underlined' ? 'gap-6 sm:gap-12' : 'gap-0',
          variant === 'stepper' && 'gap-1 border-b-0',
          className,
        )}
        as={as}
        direction={tabs.orientation}
        {...tabs}
        {...restProps}
      >
        {children}
        {variant === 'underlined' && (
          <motion.div
            className={cn(
              'TabList-underline',
              '!ml-0 absolute bottom-0 left-0 h-[5px] rounded bg-teal-600',
            )}
            initial={{x: 0, width: selectedTabWidth}}
            animate={{
              x: underlineX,
              width: selectedTabWidth,
            }}
            transition={{ease: 'linear', duration: 0.2}}
            style={tabListUnderlineStyles}
          />
        )}
      </ReakitTabList>
    )
  },
) as ForwardRefComponent<typeof Stack, TabListProps>

// MARK: - Tab

export interface TabProps {}

export const Tab = React.forwardRef(
  (
    {
      as = Button,
      variant: variantProp = 'text',
      disabled,
      className,
      style,
      id,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {lazy, variant, ...tabs} = useContext(InternalTabsContext)

    const selectedIdIdx = tabs.items.findIndex((i) => i.id === tabs.selectedId)
    const currentIdIdx = tabs.items.findIndex((i) => i.id === id)
    const isCompleted = currentIdIdx <= selectedIdIdx

    return (
      <ReakitTab
        aria-checked={variant === 'stepper' ? isCompleted : undefined}
        ref={forwardedRef as any}
        as={as}
        className={cn(
          'Tab',
          'relative h-auto rounded-none text-center text-ds-sm focus:shadow-none focus:outline-none focus:data-[focus-visible-added]:shadow-[inset_0_0_0_1px_theme(colors.teal.600)] [&_.Button-iconBefore]:mr-4',
          variant === 'default' &&
            'justify-start px-5 py-4 text-left text-buttonGhostText aria-selected:bg-teal-80 aria-selected:font-semibold',
          variant === 'underlined' && 'py-4 text-ds-md',
          variant === 'stepper' &&
            'h-3 max-h-3 w-3 max-w-3 rounded-full bg-grey-300 transition-colors',
          className,
        )}
        style={{
          ...style,
          backgroundColor:
            variant === 'stepper' && isCompleted
              ? adjustHue(
                  lighten(
                    tailwindConfig.theme.colors.orange[500],
                    currentIdIdx * 0.01,
                  ),
                  360 * currentIdIdx * 0.05,
                )
              : undefined,
        }}
        id={id}
        disabled={
          disabled ??
          (variant === 'stepper' ? currentIdIdx > selectedIdIdx : undefined)
        }
        {...tabs}
        variant={variantProp}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<typeof Button, {}>

// MARK: - TabPanel

export interface TabPanelProps
  extends Omit<ReakitTabPanelOptions, keyof ReakitTabStateReturn> {}

export const TabPanel = React.forwardRef(
  ({className, tabId, children, ...restProps}, forwardedRef) => {
    const {lazy, variant, ...tabs} = useContext(InternalTabsContext)
    const childrenHasMountedRef = useRef(false)

    const tabPanelVisible = lazy ? tabs.selectedId === tabId : true

    if (tabPanelVisible) {
      childrenHasMountedRef.current = true
    }

    return (
      <ReakitTabPanel
        ref={forwardedRef}
        tabId={tabId}
        className={cn('TabPanel', 'focus:outline-none', className)}
        {...tabs}
        {...restProps}
      >
        {(tabPanelVisible || childrenHasMountedRef.current) && children}
      </ReakitTabPanel>
    )
  },
) as ForwardRefComponent<'div', TabPanelProps>
