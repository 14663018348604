import {Link, useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useState} from 'react'
import {SearchModal as CommonSearchModal} from 'src/components'
import {InferResponse, api, endpoints} from '@cheddarup/api-client'

const ItemCatalogsSearchPage = () => {
  type ItemCatalogSearchResponse = InferResponse<
    typeof endpoints.itemCatalogs.search
  >

  const navigate = useNavigate()
  const urlParams = useParams()
  const [items, setItems] = useState<ItemCatalogSearchResponse>([])
  const [searchSuccess, setSearchSuccess] = useState(false)
  const growlActions = WebUI.useGrowlActions()

  const renderResults = () =>
    searchSuccess ? (
      <>
        {items.length > 0 ? (
          <div className="border-grey-200 border-t py-[0.75rem]">
            {items.length > 0 ? (
              <div className="font-normal">
                <div className="px-[2.25rem] py-[0.5rem] text-ds-xs text-gray400 uppercase">
                  Items
                </div>
                {items
                  .sort((a, b) =>
                    a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
                  )
                  .map((item) => (
                    <Link
                      className="block"
                      key={item.id}
                      to={`../catalog/${item.tab.id}/items?item=${item.id}`}
                    >
                      <span
                        className={
                          'mb-[0.375rem] block cursor-pointer px-[2.25rem] py-[10px] text-ds-sm text-gray800 hover:bg-teal-80'
                        }
                      >
                        {item.name}
                      </span>
                    </Link>
                  ))}
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className={
              'border-grey-200 border-t px-[2.25rem] py-[0.5rem] font-normal text-ds-sm text-gray800'
            }
          >
            No results found
          </div>
        )}
      </>
    ) : null

  return (
    <CommonSearchModal
      onDidHide={() => navigate('..')}
      placeholder="Find an item"
      renderResults={renderResults}
      onClear={() => {
        setItems([])
        setSearchSuccess(false)
      }}
      search={async (keyword) => {
        try {
          const res = await api.itemCatalogs.search.fetch({
            queryParams: {
              organization_id: Number(urlParams.seller),
              search_term: keyword,
            },
          })

          setItems(res)
          setSearchSuccess(true)
        } catch (event: any) {
          setSearchSuccess(false)
          growlActions.clear()
          growlActions.show('error', {
            title: 'Error',
            body:
              event.response?.data?.error ||
              'Something went wrong. Please try again later.',
          })
        }
      }}
    />
  )
}

export default ItemCatalogsSearchPage
