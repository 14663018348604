import * as WebUI from '@cheddarup/web-ui'
import {memo, useCallback} from 'react'
import {api, useRemoveExternalAccountMutation} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {useManagerRole} from 'src/components/ManageRoleProvider'

const BankListContainer = () => {
  const [managerRole] = useManagerRole()
  const {data: externalAccounts, isLoading} =
    api.externalAccounts.list.useQuery(undefined, {
      enabled: !managerRole || managerRole.permissions.role === 'admin',
    })
  const removeExternalAccountMutation = useRemoveExternalAccountMutation()
  const growlActions = WebUI.useGrowlActions()

  const banks = externalAccounts?.banks ?? []

  const handleVerifyDelete = useCallback(
    async (paymentMethod: Api.BankAccount) => {
      try {
        await removeExternalAccountMutation.mutateAsync({
          pathParams: {
            accountId: paymentMethod.id,
          },
        })
        growlActions.show('success', {
          title: 'Success',
          body: 'Account was successfully deleted',
        })
      } catch (err: any) {
        growlActions.clear()
        if (err.response?.data?.errors[0]?.error === 'withdrawals_pending') {
          growlActions.show('error', {
            body: 'There is a pending transfer to this account. Account can not be deleted until funds have cleared.',
            title: 'Could not delete bank account.',
          })
        } else {
          growlActions.show('error', {
            body: err.response?.data?.error,
            title: 'Could not delete bank account.',
          })
        }
      }
    },
    [growlActions, removeExternalAccountMutation],
  )

  return (
    <>
      {isLoading ? (
        <>
          <WebUI.Skeleton width="100%" height={30} />
          <WebUI.Skeleton width="100%" height={30} />
          <WebUI.Skeleton width="100%" height={30} />
        </>
      ) : (
        banks.map((bank) => (
          <BankItem key={bank.id} bank={bank} onDelete={handleVerifyDelete} />
        ))
      )}
    </>
  )
}

// MARK: – BankItem

interface BankItemProps {
  bank: Api.BankAccount
  onDelete: (bank: Api.BankAccount) => void
}

const BankItem = memo<BankItemProps>(({bank, onDelete}) => {
  const {data: session} = api.auth.session.useSuspenseQuery()
  return (
    <WebUI.HStack
      className={
        'items-center justify-between gap-4 rounded-default border p-2 *:shrink-0'
      }
    >
      <WebUI.PhosphorIcon
        icon="bank"
        height={21}
        width={21}
        className="text-teal-600"
      />
      <WebUI.Ellipsis className="basis-2/6 font-light">
        {bank.nickname}
      </WebUI.Ellipsis>
      <WebUI.Text className="grow basis-2/6 font-light">
        *{bank.last4}
      </WebUI.Text>
      {bank.isDefault ? (
        <>
          <Link
            className="text-ds-sm"
            variant="primary"
            to={
              session.stripe_data.bankModificationsDisabled
                ? 'disabled'
                : 'primary'
            }
          >
            Primary
          </Link>

          <WebUI.IconButton
            className="basis-auto text-ds-md text-gray700"
            size="default_alt"
            as={Link}
            to={
              session.stripe_data.bankModificationsDisabled
                ? 'disabled'
                : 'primary'
            }
          >
            <WebUI.PhosphorIcon icon="trash" />
          </WebUI.IconButton>
        </>
      ) : (
        <WebUI.IconButton
          className="basis-auto text-ds-md text-gray700"
          size="default_alt"
          onClick={() => onDelete(bank)}
        >
          <WebUI.PhosphorIcon icon="trash" />
        </WebUI.IconButton>
      )}
    </WebUI.HStack>
  )
})

export default BankListContainer
