import * as Util from '@cheddarup/util'
import {StringParam, useQueryParams, withDefault} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
  api,
  useCloseTabMutation,
  useDeleteTabMutation,
} from '@cheddarup/api-client'
import {QueryDataList} from 'src/components/QueryDataList'
import {useFolderId} from 'src/components/FolderProvider'
import {useAllFolders} from 'src/hooks/useFolder'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import CardTabPencilFilledIcon from 'src/images/CardTabPencilFilledIcon.svg'

import {
  CollectionListBulkActionsToolbar,
  CollectionListToolbar,
} from './CollectionListToolbar'
import CollectionListItem from './CollectionListItem'
import CreateCollectionButton from './CreateCollectionButton'

export interface CollectionListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  defaultFolderName: string
}

const CollectionList = ({
  defaultFolderName,
  className,
  ...restProps
}: CollectionListProps) => {
  const [tabsSortParams, setTabsSortParams] = WebUI.useLocalStorage(
    'tabsSortParams',
    {sortBy: 'created_at', sortDirection: 'desc'},
  )

  const [queryParams, setQueryParams] = useQueryParams({
    sortBy: withDefault(StringParam, tabsSortParams.sortBy),
    sortDirection: withDefault(StringParam, tabsSortParams.sortDirection),
  })

  const [managerRole] = useManagerRole()
  const [folderId] = useFolderId()
  const folderName =
    useAllFolders().find(
      (f) =>
        (managerRole ? f.organizer?.id === managerRole?.id : f.owner) &&
        f.id === (folderId ?? null),
    )?.name ?? ''
  const collectionsQuery = api.tabs.list.useQuery(undefined, {
    select: (collections) => {
      const filteredCollections = collections
        .filter((c) =>
          managerRole ? c.organizer.id === managerRole.id : !!c.access?.owner,
        )
        .filter((c) =>
          folderId == null ? !c.folder : c.folder?.id === folderId,
        )

      const sortDir = queryParams.sortDirection === 'asc' ? 'asc' : 'desc'

      return Util.sort(filteredCollections)[sortDir](
        (c) => (c as any)[queryParams.sortBy],
      )
    },
  })
  const isDragging = WebUI.useIsDragging()
  const [collectionToClose, setCollectionToClose] = useState<Api.Tab | null>(
    null,
  )
  const [collectionToDelete, setCollectionToDelete] = useState<Api.Tab | null>(
    null,
  )
  const dataListRef = useRef<WebUI.DataListInstance>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    dataListRef.current?.dispatch({type: 'RESET'})
  }, [folderId])

  useEffect(() => {
    setTabsSortParams(queryParams)
  }, [queryParams, setTabsSortParams])

  const CollectionListItemWithHandlers: WebUI.DataRowComponentType<Api.Tab> =
    useMemo(
      () =>
        React.forwardRef((props, forwardedRef) => (
          <CollectionListItem
            ref={forwardedRef}
            collectionCreateAllowed={managerRole?.canCreate ?? true}
            managerPermissionRole={managerRole?.permissions?.role}
            // biome-ignore lint/style/noNonNullAssertion:
            onClose={() => setCollectionToClose(props.row!.original)}
            // biome-ignore lint/style/noNonNullAssertion:
            onDelete={() => setCollectionToDelete(props.row!.original)}
            {...props}
          />
        )),
      [managerRole?.canCreate, managerRole?.permissions?.role],
    )

  return (
    <>
      <QueryDataList
        ref={dataListRef}
        className="gap-3 px-2 py-6 sm:px-6"
        query={collectionsQuery}
        selectable={managerRole?.permissions?.role !== 'viewer'}
        ListComponent={WebUI.List}
        DataRowComponent={CollectionListItemWithHandlers}
        EmptyStateViewComponent={CollectionListEmptyStateView}
      >
        {({dispatch, state, listElement}, listQuery) => (
          <WebUI.VStack
            className={WebUI.cn('min-h-0 grow', className)}
            {...restProps}
          >
            <WebUI.VStack className="[&_>_.Stack]:px-2 sm:[&_>_.Stack]:px-6">
              <WebUI.HStack className="h-[84px] items-center">
                <WebUI.Heading className="leading-compact" as="h2">
                  {folderName}
                </WebUI.Heading>
              </WebUI.HStack>
              {listQuery.data.length > 0 ? (
                <>
                  {Object.values(state.selectedItemIdsMap).includes(true) ? (
                    <CollectionListBulkActionsToolbar
                      className="h-[54px] px-3"
                      collections={collectionsQuery.data ?? []}
                      defaultFolderName={defaultFolderName}
                      listState={state}
                      listDispatch={dispatch}
                    />
                  ) : (
                    <CollectionListToolbar
                      className="h-[54px] px-3"
                      sortBy={queryParams.sortBy as any}
                      sortDirection={queryParams.sortDirection as any}
                      onSortByChange={(newSortBy) =>
                        setQueryParams({sortBy: newSortBy})
                      }
                      onSortDirectionChange={(newSortDirection) =>
                        setQueryParams({sortDirection: newSortDirection})
                      }
                    />
                  )}
                </>
              ) : (
                <WebUI.Separator variant="primary" />
              )}
            </WebUI.VStack>
            <div className="relative min-h-0 grow">
              {listElement}
              {isDragging && (
                <div className="absolute top-0 right-0 bottom-0 left-0 bg-grey-200 opacity-50" />
              )}
            </div>
          </WebUI.VStack>
        )}
      </QueryDataList>

      <OpenCloseCollectionAlert
        visible={!!collectionToClose}
        collection={collectionToClose}
        onDidHide={() => setCollectionToClose(null)}
      />
      <DeleteCollectionAlert
        visible={!!collectionToDelete}
        collection={collectionToDelete}
        onDidHide={() => setCollectionToDelete(null)}
      />
    </>
  )
}

// MARK: – CollectionListEmptyStateView

const CollectionListEmptyStateView = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [folderId] = useFolderId()
  const {data: session} = api.auth.session.useQuery()

  const isDefaultFolder = folderId == null

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'flex-[1] items-center justify-center gap-6 pt-14',
        className,
      )}
      {...restProps}
    >
      {isDefaultFolder && (
        <img
          className="h-[1em] text-[166px]"
          src={CardTabPencilFilledIcon}
          alt=""
        />
      )}
      <WebUI.Text className="text-center font-bold text-ds-md">
        {isDefaultFolder
          ? 'You don’t have any collections yet'
          : 'This folder is empty'}
        <br />
        <span className="font-normal text-gray400">
          {isDefaultFolder
            ? 'Your collections will appear here'
            : 'Drag a collection into this folder or create a new collection'}
        </span>
      </WebUI.Text>
      {!!session?.user?.id && (
        <CreateCollectionButton className="[&.Button]:w-[220px]" />
      )}
    </WebUI.VStack>
  )
}

// MARK: – OpenCloseCollectionAlert

interface OpenCloseCollectionAlertProps extends WebUI.AlertProps {
  collection: Api.Tab | null
}

export const OpenCloseCollectionAlert = React.forwardRef<
  WebUI.DialogInstance,
  OpenCloseCollectionAlertProps
>(({collection, ...restProps}, forwardedRef) => {
  const closeCollectionMutation = useCloseTabMutation()
  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Close collection confirmation"
      {...restProps}
    >
      <WebUI.AlertHeader>Close Collection?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={`Are you sure you'd like to close this collection and stop accepting payments? Please note, any scheduled recurring payments will continue.`}
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                if (collection) {
                  await closeCollectionMutation.mutateAsync({
                    pathParams: {
                      tabId: collection.id,
                    },
                  })
                }
              }}
            >
              Close Collection
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

// MARK: – DeleteCollectionAlertContent

interface DeleteCollectionAlertProps extends WebUI.AlertProps {
  collection: Api.Tab | null
}

const DeleteCollectionAlert = React.forwardRef<
  WebUI.DialogInstance,
  DeleteCollectionAlertProps
>(({collection, ...restProps}, forwardedRef) => {
  const deleteCollectionMutation = useDeleteTabMutation()
  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete collection confirmation"
      {...restProps}
    >
      <WebUI.AlertHeader>
        Delete Collection. This cannot be undone.
      </WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <>
            You are going to delete{' '}
            <span className="font-bold">{collection?.name ?? ''}</span>
            .<br />
            This cannot be undone, and any scheduled recurring payments will be
            cancelled. Are you sure?`
          </>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                if (collection) {
                  await deleteCollectionMutation.mutateAsync({
                    pathParams: {
                      tabId: collection.id,
                    },
                  })
                }
              }}
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

export default CollectionList
