import * as Yup from 'yup'
import {useFormik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {
  useCreateItemMutation,
  useUpdateItemMutation,
} from '@cheddarup/api-client'
import {useSaveFields} from 'src/hooks/fields'
import type {FieldsEditValue} from 'src/components/FieldsEdit/FieldsEdit'
import * as WebUI from '@cheddarup/web-ui'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {saveItemImages} from 'src/helpers/item-helpers'
import {readApiError} from 'src/helpers/error-formatting'
import * as Util from '@cheddarup/util'

import {
  type ItemFormValues,
  getOrderedImages,
} from '../FixedItemForm/FixedItemForm'
import DonationItemFormDetails from './DonationItemFormDetails'
import {
  ItemFormImagesAndDescription,
  ItemFormItemFields,
} from '../../components'
import DonationItemFormSettings from './DonationItemFormSettings'

export interface DonationItemFormValues
  extends Pick<
    ItemFormValues,
    | 'name'
    | 'parent_id'
    | 'description'
    | 'required'
    | 'images'
    | 'available_quantity_enabled'
    | 'available_quantity'
  > {
  options: {
    subcategoryId: string | null
    makeTotalCollectedPublic: boolean
    makeAvailableQuantityPublic: boolean
    donation: {
      suggestedAmount: {
        enabled: boolean
        value: string
      }
    }
    fundraisingGoal: {
      enabled: boolean
      value: string
    }
  }
}

export type DonationItemFormFormik = ReturnType<
  typeof useFormik<DonationItemFormValues>
>

Yup.setLocale({mixed: {notType: 'Must be a number'}})

export interface DonationItemFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  collectionId: number
  item?: Api.TabItem
  fields?: Api.TabObjectField[]
  onDismiss: () => void
  onDirtyChange?: (value: boolean) => void
}

const DonationItemForm: React.FC<DonationItemFormProps> = ({
  collectionId,
  item,
  fields,
  onDismiss,
  onDirtyChange,
  className,
  ...restProps
}) => {
  const createItemMutation = useCreateItemMutation()
  const updateItemMutation = useUpdateItemMutation()
  const saveFields = useSaveFields()
  const fieldsEditValueRef = useRef<FieldsEditValue[]>([])
  const tabsRef = useRef<WebUI.TabsInstance>(null)
  const growlActions = WebUI.useGrowlActions()
  const [selectedTabId, setSelectedTabId] = useState('details')

  const formik = useFormik<DonationItemFormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .max(75, 'Must be 75 characters or less'),
      description: Yup.string().max(4000, 'Must be 4000 characters or less'),
      available_quantity_enabled: Yup.boolean(),
      available_quantity: Yup.number().when('available_quantity_enabled', {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      options: Yup.object().shape({
        donation: Yup.object().shape({
          suggestedAmount: Yup.object().shape({
            enabled: Yup.boolean(),
            value: Yup.number().when('enabled', ([enabled], schema) =>
              enabled
                ? schema
                    .required('Required')
                    .max(999999, 'Price must be less than 1,000,000.00')
                : schema,
            ),
          }),
        }),
        fundraisingGoal: Yup.object().shape({
          enabled: Yup.boolean(),
          value: Yup.number().when('enabled', ([enabled], schema) =>
            enabled ? schema.required('Required') : schema,
          ),
        }),
      }),
    }),
    initialValues: {
      name: item?.name ?? '',
      parent_id: item?.parent_id ?? null,
      options: {
        subcategoryId: item?.options?.subcategoryId ?? null,
        makeTotalCollectedPublic:
          item?.options.makeTotalCollectedPublic ?? false,
        makeAvailableQuantityPublic:
          item?.options?.makeAvailableQuantityPublic ?? false,
        donation: {
          suggestedAmount: {
            enabled: item?.options.donation?.suggestedAmount.enabled ?? false,
            value:
              item?.options.donation?.suggestedAmount.value == null
                ? ''
                : String(
                    item?.options.donation.suggestedAmount.value.toFixed(2),
                  ),
          },
        },
        fundraisingGoal: {
          enabled: item?.options.fundraisingGoal?.enabled ?? false,
          value:
            item?.options.fundraisingGoal?.value == null
              ? ''
              : String(item?.options.fundraisingGoal.value.toFixed(2)),
        },
      },
      description: item?.description ?? '',
      available_quantity_enabled: item
        ? item.available_quantity != null
        : false,
      available_quantity: String(item?.available_quantity ?? ''),
      required: item?.required ?? false,
      images: getOrderedImages(item?.images ?? []).map((itemImage) => ({
        id: itemImage.id,
        contentType: itemImage.metadata.contentType ?? 'image/jpeg',
        thumbnailCrop:
          Object.keys(itemImage.metadata.thumbnail.cropDetails ?? {}).length > 0
            ? itemImage.metadata.thumbnail.cropDetails
            : null,
        image: {
          ...itemImage,
          preview: ImagesUtils.getImageUrl(itemImage),
        },
      })) as any[],
    },
    onSubmit: async (values) => {
      const payload = {
        ...values,
        amount_type: 'open' as const,
        available_quantity: values.available_quantity_enabled
          ? Number(values.available_quantity)
          : null,
        images: values.images
          .filter((i) => i != null)
          .map((image, idx) => ({
            id: image.id,
            metadata: {
              contentType: image.contentType,
              thumbnail: {
                cropDetails: image.thumbnailCrop ?? {},
                order: idx,
              },
            },
          })),
        options: {
          ...values.options,
          makeAvailableQuantityPublic:
            values.available_quantity_enabled &&
            values.options.makeAvailableQuantityPublic,
          itemSubType: 'donation' as const,
          fieldSets: fieldsEditValueRef.current.map((fev) => fev.fieldSet),
          donation: {
            suggestedAmount: {
              enabled: values.options.donation.suggestedAmount.enabled,
              value: values.options.donation.suggestedAmount.enabled
                ? Number(values.options.donation.suggestedAmount.value)
                : 0,
            },
          },
          fundraisingGoal: {
            enabled: values.options.fundraisingGoal.enabled,
            value: values.options.fundraisingGoal.enabled
              ? Number(values.options.fundraisingGoal.value)
              : 0,
          },
        },
      }

      const localFields = fieldsEditValueRef.current.flatMap(
        (fev) => fev.fields,
      )

      const someCheckboxOrMultipleChoiceFieldsEmpty = localFields
        .filter(
          (f) =>
            f.field_type === 'checkbox' || f.field_type === 'multiple_choice',
        )
        .some((f) => 'values' in f && f.values?.length === 0)
      if (someCheckboxOrMultipleChoiceFieldsEmpty) {
        growlActions.show('error', {
          title: 'Error',
          body: 'Checkbox and dropdown questions require at least one option',
        })
        tabsRef.current?.select('questions')
        return
      }

      let savedItem = item ?? null

      try {
        if (item) {
          await saveItemImages({
            tabId: collectionId,
            item,
            images: values.images,
          })

          await updateItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              itemId: item.id,
            },
            body: payload,
          })
        } else {
          savedItem = await createItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body: payload,
          })

          await saveItemImages({
            tabId: collectionId,
            item: savedItem,
            images: values.images,
          })
        }

        if (savedItem) {
          await saveFields({
            tabId: collectionId,
            tabObjectId: savedItem.id,
            tabObjectType: 'item',
            existingFields: fields ?? [],
            newFields: localFields,
          })
        }
        onDismiss()
      } catch (err) {
        const errMessage = readApiError(err)
        if (errMessage) {
          growlActions.clear()
          growlActions.show('error', {body: errMessage})
        }
      }
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onDirtyChange?.(formik.dirty)
  }, [formik.dirty])

  return (
    <WebUI.Form
      className={WebUI.cn(
        'min-h-0 [&_>_.Form-inner]:h-full [&_>_.Form-inner]:gap-0',
        className,
      )}
      noValidate
      onSubmit={async (event) => {
        const errors = await formik.validateForm()
        if (Object.keys(errors).length > 0) {
          if (formik.errors.options?.fundraisingGoal) {
            tabsRef.current?.select('settings')
          } else if (Object.keys(formik.errors).length > 0) {
            tabsRef.current?.select('details')
          }
        }
        formik.handleSubmit(event)
      }}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.Tabs
        ref={tabsRef}
        className="min-h-0 grow [&_>_.TabPanel:not([id=questions])]:overflow-y-auto [&_>_.TabPanel:not([id=questions])]:p-6 sm:[&_>_.TabPanel:not([id=questions])]:px-13 sm:[&_>_.TabPanel:not([id=questions])]:py-6 [&_>_.TabPanel[id=questions]]:overflow-y-hidden [&_>_.TabPanel]:grow"
        variant="underlined"
        onChangeSelectedId={(newSelectedId) => {
          if (newSelectedId != null) {
            setSelectedTabId(newSelectedId)
          }
        }}
      >
        <WebUI.TabList
          aria-label="Item form navigation"
          className="mx-6 flex-0 border-b-0 sm:mx-13 [&_>_.TabList-underline]:bg-orange-500 [&_>_.Tab_>_.Button-content]:font-normal [&_>_.Tab_>_.Button-content]:text-ds-sm sm:[&_>_.Tab_>_.Button-content]:text-ds-base"
        >
          <WebUI.Tab id="details">Details</WebUI.Tab>
          <WebUI.Tab id="description">Description</WebUI.Tab>
          <WebUI.Tab id="settings">Settings</WebUI.Tab>
          <WebUI.Tab id="questions">Questions</WebUI.Tab>
        </WebUI.TabList>

        <WebUI.Separator variant="primary" />

        <WebUI.TabPanel id="details">
          <DonationItemFormDetails
            formik={formik}
            collectionId={collectionId}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="description">
          <ItemFormImagesAndDescription
            collectionId={collectionId}
            formik={formik}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="settings">
          <DonationItemFormSettings
            formik={formik}
            tabId={collectionId}
            item={item}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="questions">
          <ItemFormItemFields
            className="max-h-full"
            label="Need to collect information from your payers about this donation:"
            text="e.g., who is this donation in honor of?"
            initialFieldSets={item?.options.fieldSets ?? undefined}
            initialFields={fields}
            onInit={(initialFieldsEditValue) => {
              fieldsEditValueRef.current = initialFieldsEditValue
            }}
            onChange={(newFieldsEditValue) => {
              const localFieldSets = newFieldsEditValue.map(
                (fev) => fev.fieldSet,
              )
              const localFields = newFieldsEditValue.flatMap(
                (fev) => fev.fields,
              )

              onDirtyChange?.(
                formik.dirty ||
                  !Util.deepEqual(localFieldSets, item?.options.fieldSets) ||
                  !Util.deepEqual(localFields, fields),
              )

              fieldsEditValueRef.current = newFieldsEditValue
            }}
          />
        </WebUI.TabPanel>
      </WebUI.Tabs>

      <WebUI.Separator />
      <WebUI.HStack className="justify-end bg-trueWhite px-4 py-5">
        {!item && selectedTabId !== 'questions' ? (
          <WebUI.Button
            variant="default"
            size="large"
            onClick={() => tabsRef.current?.next()}
          >
            Continue
          </WebUI.Button>
        ) : (
          <WebUI.Button
            type="submit"
            variant="primary"
            size="large"
            loading={formik.isSubmitting}
          >
            Save
          </WebUI.Button>
        )}
      </WebUI.HStack>
    </WebUI.Form>
  )
}

export default DonationItemForm
