import * as WebUI from '@cheddarup/web-ui'
import {AppHeaderUserLinks} from 'src/components/AppHeader'
import {Link} from 'src/components/Link'
import {UserMenu} from 'src/components/UserMenu'
import {Logo} from 'src/components'
import {useIsAuthed} from 'src/hooks/useAuthToken'
import MilkCartonIcon from 'src/images/MilkCartonIcon.svg'

import config from '../config'

const NotFoundPage = () => {
  const isLoggedIn = useIsAuthed()
  const media = WebUI.useMedia()
  window.prerenderReady = true
  return (
    <WebUI.VStack className="min-h-screen">
      <WebUI.NavigationBar
        className="[&_>_.NavigationBar-centerWrapper]:justify-start"
        left={
          <WebUI.AnchorButton
            className="mr-2"
            href={config.links.marketingPage}
          >
            <Logo />
          </WebUI.AnchorButton>
        }
        center={media.sm ? <AppHeaderUserLinks /> : null}
        right={isLoggedIn ? <UserMenu /> : <LoginSignupNav />}
      />
      <WebUI.HStack className="grow justify-center bg-aquaLight">
        <WebUI.VStack className="mt-16 max-w-screen-lg shrink grow-0 gap-4 sm:flex-row">
          <WebUI.HStack className="grow justify-end">
            <img className="h-[250px]" src={MilkCartonIcon} alt="" />
          </WebUI.HStack>
          <WebUI.VStack className="grow gap-4">
            <WebUI.Heading>Whoops.</WebUI.Heading>
            <p className="font-normal text-ds-md">
              This page can&apos;t be found or
              <br />
              is currently unavailable.
              <br />
              <Link variant="primary" to="/contact">
                Contact us
              </Link>{' '}
              if you need help.
            </p>
          </WebUI.VStack>
        </WebUI.VStack>
      </WebUI.HStack>
    </WebUI.VStack>
  )
}

// MARK: – LoginSignupNav

const LoginSignupNav = () => (
  <nav className="flex items-center">
    <Link className="mx-4" variant="primary" preserveSearch to="/contact">
      Contact
    </Link>
    <Link className="mx-4 text-orange-500" preserveSearch to="/signup">
      Sign up FREE
    </Link>
    <Link className="mx-4" variant="primary" preserveSearch to="/login">
      Log in
    </Link>
  </nav>
)

export default NotFoundPage
