import {useNavigate, useParams} from 'react-router-dom'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useAcceptManagerInviteMutation} from '@cheddarup/api-client'
import {AuthLayout} from 'src/components/AuthLayout'
import * as Util from '@cheddarup/util'

const InvitationsPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [acceptingInvite, setAcceptingInvite] = useState(false)
  const acceptInviteMutation = useAcceptManagerInviteMutation()
  const growlActions = WebUI.useGrowlActions()
  const sessionQuery = api.auth.session.useQuery(undefined, {enabled: false})

  return (
    <AuthLayout>
      <div className="mt-4 flex flex-col p-10">
        <WebUI.Heading className="mb-1 text-center">
          Accept your invitation to manage
        </WebUI.Heading>
        <WebUI.Button
          className="mt-8 self-center"
          size="large"
          variant="primary"
          disabled={acceptingInvite}
          onClick={async () => {
            setAcceptingInvite(true)
            try {
              await acceptInviteMutation.mutateAsync({
                pathParams: {
                  // biome-ignore lint/style/noNonNullAssertion:
                  managerId: urlParams.manager!,
                },
                body: {invite_code: urlParams.invitation ?? ''},
              })
              // HACK: in case `manager_roles` doesn't get refreshed fast enough
              await Util.delay(200)
              const sessionRes = await sessionQuery.refetch()
              const managerRoleId =
                sessionRes.data?.manager_roles[
                  sessionRes.data.manager_roles.length - 1
                ]?.id ?? null
              navigate(`/collections?managerWelcome=1&mrId=${managerRoleId}`)
            } catch (err: any) {
              growlActions.show('error', {
                title: 'Error',
                body:
                  err.response?.data?.errors?.[0]?.details?.details ||
                  'An error happened while accepting the invite.',
              })
            } finally {
              setAcceptingInvite(false)
            }
          }}
        >
          Accept and Get Started
        </WebUI.Button>
        <div className="my-4 text-center text-ds-sm text-teal-600">
          <span
            className="cursor-pointer"
            onClick={() => {
              if (!acceptingInvite) {
                navigate('/collections')
              }
            }}
          >
            Decline my invitation
          </span>
        </div>
      </div>
    </AuthLayout>
  )
}

export default InvitationsPage
