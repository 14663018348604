import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {getLocalTimeZone} from '@internationalized/date'
import GoogleMapsIcon from 'src/images/GoogleMapsIcon.png'
import AppleMapsIcon from 'src/images/AppleMapsIcon.png'
import * as Util from '@cheddarup/util'
import {LinkButton} from 'src/components/LinkButton'

import usePublicCollection from '../hooks/usePublicCollection'
import {CollectionShareButton} from './CollectionShareButton'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {getPayerBrandKitColors} from '@cheddarup/core'

export const PayerInfoPanels: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()
  const media = WebUI.useMedia()

  const brandKitColors = getPayerBrandKitColors(
    publicCollection.organizer.branding?.color_palette.payerPage,
  )

  const orderedCollectionInfoBlocks = useMemo(() => {
    const collectionInfoBlocks = publicCollection.infoBlockSettings
    const infoBlocks = [
      {
        enabled: !!collectionInfoBlocks?.promoteSharing?.enabled,
        order: collectionInfoBlocks?.promoteSharing?.order,
        panel: (
          <PayerSharingInfoPanel
            iconStyles={{color: brandKitColors.primaryButton}}
          />
        ),
      },
      {
        enabled: !!collectionInfoBlocks?.nonProfitStatus?.enabled,
        order: collectionInfoBlocks?.nonProfitStatus?.order,
        panel: (
          <CollectionNonProfitStatusPanel
            iconStyles={{color: brandKitColors.primaryButton}}
          />
        ),
      },
      {
        enabled: !!publicCollection.paymentGoal?.enabled,
        order: publicCollection.paymentGoal?.order,
        panel: (
          <CollectionPaymentGoalPanel
            progressBarStyles={
              brandKitColors
                ? {
                    backgroundImage: `linear-gradient(to right, ${Util.lighten(brandKitColors.primaryButton, 0.5)}, ${brandKitColors.primaryButton})`,
                  }
                : {}
            }
          />
        ),
      },
      {
        enabled:
          !!collectionInfoBlocks?.totalCollected?.enabled &&
          !publicCollection.paymentGoal?.enabled,
        order: collectionInfoBlocks?.totalCollected?.order,
        panel: <CollectionTotalCollectedPanel />,
      },
      {
        // Ensure the payer list panel is only enabled if explicitly allowed and there are payers present
        enabled:
          collectionInfoBlocks?.payerList?.enabled &&
          (publicCollection.payers || []).length > 0,
        order: collectionInfoBlocks?.payerList?.order,
        panel: <CollectionPayersPanel />,
      },
      {
        enabled: !!collectionInfoBlocks?.time?.enabled,
        order: collectionInfoBlocks?.time?.order,
        panel: <CollectionDateAndTimePanel />,
      },
      {
        enabled: !!collectionInfoBlocks?.location?.enabled,
        order: collectionInfoBlocks?.location?.order,
        panel: <CollectionLocationPanel />,
      },
      {
        enabled: !!collectionInfoBlocks?.faqs?.enabled,
        order: collectionInfoBlocks?.faqs?.order,
        panel: <CollectionFaqsPanel />,
      },
    ]
    return Util.sort(infoBlocks.filter((block) => block.enabled)).asc(
      (ib) => ib.order ?? infoBlocks.length,
    )
  }, [publicCollection, brandKitColors])

  return (
    <WebUI.VStack
      className={WebUI.cn('min-w-[320px] gap-4', className)}
      {...restProps}
    >
      <PayerCollectionContactCard />
      {!media.lg && !!publicCollection.attachments?.length && (
        <PayerInfoPanel>
          <WebUI.Text className="font-semibold">Attachments</WebUI.Text>
          <CollectionAttachments className="mt-3" />
        </PayerInfoPanel>
      )}
      {orderedCollectionInfoBlocks.map((block) => block.panel)}
    </WebUI.VStack>
  )
}

// MARK: - CollectionDateAndTimePanel

const CollectionDateAndTimePanel: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()
  const dateTime = publicCollection.infoBlockSettings?.time

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-2', className)}
      as={PayerInfoPanel}
      {...restProps}
    >
      <WebUI.Text className="font-semibold">Date and Time</WebUI.Text>
      <WebUI.VStack className="w-3/5 gap-5">
        {!!dateTime && (
          <>
            <WebUI.Text className="font-light">
              {Util.formatDateRange(
                dateTime.startTime ?? '',
                dateTime.endTime,
                {
                  dateTimeFormatOptions: {
                    timeZone: dateTime.timeZone ?? getLocalTimeZone(),
                    timeZoneName: 'short',
                    month: 'short',
                    year: undefined,
                    hour12: true,
                    weekday: undefined,
                  },
                },
              )}
            </WebUI.Text>
            <WebUI.Button
              variant="link"
              onClick={() => {
                const icsContent = Util.makeIcsContent(
                  new Date(dateTime.startTime ?? ''),
                  new Date(dateTime.endTime ?? ''),
                  // biome-ignore lint/style/noNonNullAssertion:
                  publicCollection.infoBlockSettings!.time!.timeZone,
                  publicCollection.name,
                  publicCollection.infoBlockSettings?.location?.address,
                )
                const blob = new Blob([icsContent], {
                  type: 'text/calendar',
                })
                const url = URL.createObjectURL(blob)

                window.open(url)
              }}
            >
              Add to calendar
            </WebUI.Button>
          </>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: - CollectionLocationPanel

const CollectionLocationPanel: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()
  const {address, longitude, latitude} = publicCollection.infoBlockSettings
    ?.location ?? {address: '', latitude: null, longitude: null}

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-2', className)}
      as={PayerInfoPanel}
      {...restProps}
    >
      <WebUI.Text className="font-semibold">Location</WebUI.Text>
      <WebUI.VStack className="w-3/5 gap-5">
        <WebUI.Text className="font-light">{address}</WebUI.Text>
        {latitude && longitude && (
          <WebUI.HStack className="gap-3">
            <WebUI.AnchorButton
              variant="text"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
            >
              <WebUI.Image
                src={GoogleMapsIcon}
                width={40}
                height={40}
                alt="Google Maps Pin"
              />
            </WebUI.AnchorButton>
            <WebUI.AnchorButton
              variant="text"
              target="_blank"
              rel="noopener noreferrer"
              href={`http://maps.apple.com/?q=${address}&ll=${latitude},${longitude}`}
            >
              <WebUI.Image
                src={AppleMapsIcon}
                width={40}
                height={40}
                alt="Apple Maps Pin"
              />
            </WebUI.AnchorButton>
          </WebUI.HStack>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: - CollectionFaqsPanel

const CollectionFaqsPanel: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-2', className)}
      as={PayerInfoPanel}
      {...restProps}
    >
      <WebUI.Text className="font-semibold">FAQs</WebUI.Text>
      <WebUI.VStack className="gap-5">
        {publicCollection.faqs.map((faq) => (
          <WebUI.VStack key={faq.id}>
            <WebUI.Text>{faq.question}</WebUI.Text>
            <WebUI.Text className="font-light">{faq.answer}</WebUI.Text>
          </WebUI.VStack>
        ))}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: - CollectionPayersPanel

const CollectionPayersPanel: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()

  const MAX_VISIBLE_PLAYERS_COUNT = 30
  const payers = publicCollection.payers ?? []

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-3', className)}
      as={PayerInfoPanel}
      {...restProps}
    >
      <WebUI.Text className="font-semibold">
        {publicCollection.infoBlockSettings?.payerList?.customContributorName ||
          'Participants'}
      </WebUI.Text>
      <WebUI.HStack as="ul" className="flex-wrap [row-gap:theme(space.1)]">
        {payers.slice(0, MAX_VISIBLE_PLAYERS_COUNT).map((payer, idx) => (
          <li key={idx} className="w-1/2 text-ds-base">
            {payer}
          </li>
        ))}
      </WebUI.HStack>
      {payers.length > MAX_VISIBLE_PLAYERS_COUNT && (
        <LinkButton
          className="self-start"
          size="compact"
          variant="link"
          preserveSearch
          to="payers"
        >
          View All
        </LinkButton>
      )}
    </WebUI.VStack>
  )
}

// MARK: - CollectionTotalCollectedPanel

const CollectionTotalCollectedPanel: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-2', className)}
      as={PayerInfoPanel}
      {...restProps}
    >
      <WebUI.Text className="font-semibold">Total Collected</WebUI.Text>
      <WebUI.Heading className="font-semibold text-8xl leading-compact">
        {Util.formatAmount(publicCollection.totalCollected ?? 0)}
      </WebUI.Heading>
    </WebUI.VStack>
  )
}

// MARK: - CollectionPaymentGoalPanel

export const CollectionPaymentGoalPanel: React.FC<
  React.ComponentPropsWithoutRef<'div'> & {
    progressBarStyles?: React.CSSProperties
  }
> = ({className, progressBarStyles, ...restProps}) => {
  const {publicCollection} = usePublicCollection()
  const paymentGoalValue = publicCollection.paymentGoal?.value ?? 0

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-5', className)}
      as={PayerInfoPanel}
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="font-semibold text-8xl leading-compact">
          {Util.formatAmount(publicCollection.totalCollected ?? 0)}
        </WebUI.Heading>
        <WebUI.Text className="font-semibold">
          <span className="font-light">raised of </span>{' '}
          {Util.formatAmount(paymentGoalValue)} Goal
        </WebUI.Text>
      </WebUI.VStack>
      {(publicCollection.totalCollected ?? 0) > 0 && paymentGoalValue > 0 && (
        <WebUI.Bar
          className="h-6 rounded-[30px]"
          variant="goal"
          fill={(publicCollection.totalCollected ?? 0) / paymentGoalValue}
          filledBarStyles={progressBarStyles}
        />
      )}
    </WebUI.VStack>
  )
}

// MARK: - CollectionNonProfitStatusPanel

const CollectionNonProfitStatusPanel: React.FC<
  React.ComponentPropsWithoutRef<'div'> & {
    iconStyles?: React.CSSProperties
  }
> = ({className, iconStyles, ...restProps}) => (
  <WebUI.HStack
    className={WebUI.cn('items-center gap-4', className)}
    as={PayerInfoPanel}
    {...restProps}
  >
    <WebUI.PhosphorIcon
      className="text-teal-600"
      icon="seal-check-fill"
      width={25}
      style={iconStyles}
    />
    <WebUI.Text className="font-semibold">Verified 501(c)(3)</WebUI.Text>
  </WebUI.HStack>
)

// MARK: - PayerCollectionContactCard

const PayerCollectionContactCard: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()
  const showLinkToCollections =
    publicCollection.is_team && publicCollection.groupPage

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-4', className)}
      as={PayerInfoPanel}
      {...restProps}
    >
      <WebUI.HStack className="gap-5">
        {!!publicCollection?.organizer.profile_pic && (
          <SharpAvatar
            className="shrink-0"
            alt="Organizer profile oicture"
            size={50}
            image={publicCollection.organizer.profile_pic}
            name={publicCollection.organizer.name}
          />
        )}
        <WebUI.VStack className="min-w-0 gap-1">
          <WebUI.Ellipsis className="line-clamp-2 whitespace-normal font-semibold text-ds-md leading-compact">
            {publicCollection.organizer.displayName}
          </WebUI.Ellipsis>
          <LinkButton to="help" variant="link" size="compact">
            Contact Organizer
          </LinkButton>
        </WebUI.VStack>
      </WebUI.HStack>
      {showLinkToCollections && (
        <LinkButton
          className="[&_>_.Button-content]:font-semibold"
          size="large"
          variant="secondary"
          roundness="capsule"
          to={`/me/${publicCollection.organizer.id}`}
        >
          View all collections
        </LinkButton>
      )}
    </WebUI.VStack>
  )
}

// MARK: – PayerSharingInfoPanel

export const PayerSharingInfoPanel: React.FC<
  React.ComponentPropsWithoutRef<'div'> & {
    iconStyles?: React.CSSProperties
  }
> = ({iconStyles, ...restProps}) => (
  <PayerInfoPanel {...restProps}>
    <CollectionShareButton
      className="[&_>_.Button-iconBefore]:!mr-5 [&_>_.Button-content]:font-semibold [&_>_.Button-content]:leading-cosy"
      variant="text"
      iconBefore={
        <WebUI.PhosphorIcon
          className="text-ds-xl text-teal-600"
          icon="paper-plane-tilt-fill"
          style={iconStyles}
        />
      }
    >
      Share this collection
    </CollectionShareButton>
  </PayerInfoPanel>
)

// MARK: – PayerInfoPanel

const PayerInfoPanel: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => (
  <WebUI.Panel
    className={WebUI.cn('border-0 p-5', className)}
    variant="capsule"
    {...restProps}
  />
)

// MARK: – CollectionAttachments

export const CollectionAttachments: React.FC<
  React.ComponentPropsWithoutRef<'ul'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()

  if (
    !publicCollection.attachments ||
    publicCollection.attachments.length === 0
  ) {
    return null
  }

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-2', className)}
      as="ul"
      {...restProps}
    >
      {publicCollection.attachments.map(({file_name: {url}}) => (
        <li key={url}>
          <WebUI.Anchor
            className="text-ds-xs text-gray800"
            iconBefore={
              <WebUI.PhosphorIcon icon="download-simple" width={18} />
            }
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {Util.getFileNameFromUrl(url)}
          </WebUI.Anchor>
        </li>
      ))}
    </WebUI.VStack>
  )
}
